import React, { useEffect, useState } from 'react';
import fbt from 'fbt';

import ActionIconButton from '../../General/ActionIconButton';

const IconPicker = (props) => {
    const [minimized, setMinimized] = useState(true);

    useEffect(() => {
      if (props.minimized !== undefined) {
        setMinimized(props.minimized);
      }
    }, [props.minimized]);

    let icons = [
      '', 'image', 'camera', 'file-o', 'filter', 'pencil', 'plus', 'plus-circle',
      'question', 'search', 'star', 'times', 'cog',
      'trash-o', 'repeat', 'font', 'bold', 'italic',

      // Align
      'align-left', 'align-center', 'align-right', 'align-justify', 'list', 'indent',

      'arrows', 'chevron-right', 'arrow-left', 'arrow-right',
      'arrow-up', 'arrow-down', 'move-up', 'move-down',

      'folder-open', 'thumb-tack',
      'border_bottom', 'border_left', 'border_right', 'border_top',
      'list-ul', 'list-ol', 'strikethrough', 'underline',

      // Companies
      'facebook', 'twitter', 'google', 'linkedin-square', 'github', 'reddit', 'youtube-play',
      'instagram', 'amazon', 'react', 'html5', 'css3',

      // OS
      'apple', 'windows', 'linux', 'android',

      // Shopping
      'cc-visa', 'cc-paypal', 'cc-stripe', 'shopping-basket',

      // Devices
      'desktop', 'tablet', 'mobile', 'phone',

      // User
      'address-book-o', 'user-circle-o', 'user-plus', 'lock',

      // Chart
      'pie-chart', 'area-chart', 'line-chart',

      // Time
      'clock-o',

      // Map
      'map-marker', 'globe', 'map-signs', 'map-o',

      // Misc
      'history',

      'bell-o', 'chain', 'floppy-o',
      'magic', 'envelope', 'comments-o', 'cloud-download', 'angle-left', 'angle-right',
      'angle-up', 'angle-down', 'folder-open-o',
      'code', 'crop', 'eraser', 'ellipsis-h', 'ellipsis-v', 'life-ring',
      'toggle-off', 'toggle-on',
      'i-cursor', 'object-group', 'object-ungroup', 'clone',
      'hand-pointer-o',
      'format_color_fill',

      'format_color_text', 'books', 'book', 'contact_support', 'quote-left', 'quote-right', 'edit',
      'feed',

      // Exotic
      'home', 'heart-o', 'paperplane', 'lab', 'rocket', 'universal-access',
    ];

    if (!props.hasNone) {
      icons.splice(0, 1);
    }

    if (props.icon && minimized) {
      icons = [props.icon, ...icons.filter(i => i !== props.icon)];
    }

    const count = minimized ? (props.count || 6) : icons.length;

    return (
      <>
        {props.showLabel && <label style={{'--mb': '8px', '--d': 'block'}}>{props.title}</label>}
        {!props.showLabel && props.title && <span className="sr-only">{props.title}</span>}
        <div style={{
          '--d': 'grid',
          '--gtc': props.gtc || '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
          '--gg': '5px',
          '--mb': '15px',
        }}>
          {icons.filter((s, idx) => idx < count).map(icon => {
            return (
              <ActionIconButton key={icon}
                                icon={icon}
                                title={icon || fbt('None', 'IconPicker')}
                                active={icon === props.icon}
                                action={() => {
                                  props.action(icon);
                                }}
              />
            );
          })}

          {minimized && (
            <ActionIconButton icon="ellipsis-h"
                              title={fbt('More Icons', 'IconPicker')}
                              action={() => {
                                setMinimized(false);
                              }}
                              style={{'--bg': 'var(--grey)'}}
            />
          )}

          {!minimized && (
            <ActionIconButton icon="angle-up"
                              title={fbt('Hide Icons', 'IconPicker')}
                              action={() => {
                                setMinimized(true);
                              }}
                              style={{'--bg': 'var(--grey)'}}
            />
          )}
        </div>
      </>
    );
  }
;

export default IconPicker;
