import BaseService from './BaseService';

const paths = {
  get: '/session',
  delete: '/session',
};

/**
 * Service for querying the Sessions
 */
class SessionService extends BaseService {
  constructor() {
    super(paths);
    this.requireLogin = true;
  }
}

export default new SessionService();
