import React from 'react';
import fbt from 'fbt';

import Fieldset from '../FieldSet';
import SelectInput from '../Controls/SelectInput';
import TextInput from '../Controls/TextInput';
import InputWithUnitPicker from '../Controls/InputWithUnitPicker';
import InputGroup from '../InputGroup';

const PositionFieldset = props => {
  return (
    <Fieldset title={fbt('Positioning', 'PositionFieldset')} isOpen={props.isOpen || false}>
      <InputGroup>
        <SelectInput value={props.generalProperties.position}
                     action={val => {
                       props.action('position', val);
                     }}
                     title={fbt('Positioning', 'PositionFieldset')}
                     options={{
                       '': fbt('Default (static)', 'PositionFieldset'),
                       'relative': fbt('Relative', 'PositionFieldset'),
                       'absolute': fbt('Absolute', 'PositionFieldset'),
                       'fixed': fbt('Fixed', 'PositionFieldset'),
                       'sticky': fbt('Sticky', 'PositionFieldset'),
                     }}
        />
      </InputGroup>

      {['sticky', 'absolute', 'fixed'].includes(props.generalProperties.position) && (
        <>
          <InputGroup>
            <InputWithUnitPicker
              title={fbt('Top', 'PositionFieldset')}
              value={props.generalProperties.top || ''}
              action={val => props.action('top', val)}
              isHeight
              showLabel
            />
          </InputGroup>

          <InputGroup>
            <InputWithUnitPicker
              title={fbt('Right', 'PositionFieldset')}
              value={props.generalProperties.right || ''}
              action={val => props.action('right', val)}
              isWidth
              showLabel
            />
          </InputGroup>

          <InputGroup>
            <InputWithUnitPicker
              title={fbt('Bottom', 'PositionFieldset')}
              value={props.generalProperties.bottom || ''}
              action={val => props.action('bottom', val)}
              isHeight
              showLabel
            />
          </InputGroup>

          <InputGroup>
            <InputWithUnitPicker
              title={fbt('Left', 'PositionFieldset')}
              value={props.generalProperties.left || ''}
              action={val => props.action('left', val)}
              isWidth
              showLabel
            />
          </InputGroup>

          <InputGroup>
            <TextInput title={fbt('z-index (ordering)', 'PositionFieldset')}
                       value={props.generalProperties.zIndex || ''}
                       placeholder="99"
                       blur={val => props.action('zIndex', val)}
                       showLabel
            />
          </InputGroup>

          <InputGroup>
            <InputWithUnitPicker
              title={fbt('Width', 'PositionFieldset')}
              value={props.generalProperties.width || ''}
              action={val => props.action('width', val)}
              isWidth
              showLabel
            />
          </InputGroup>

          <InputGroup>
            <InputWithUnitPicker
              title={fbt('Height', 'PositionFieldset')}
              value={props.generalProperties.height || ''}
              action={val => props.action('height', val)}
              isHeight
              showLabel
            />
          </InputGroup>
        </>
      )}
    </Fieldset>
  );
};

export default PositionFieldset;
