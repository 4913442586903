import React from 'react';
import fbt from 'fbt';

import Fieldset from '../FieldSet';
import InputGroup from '../InputGroup';

const TextProperties = () => {
  return (
    <Fieldset title={fbt('Formatted Text', 'TextProperties')} isOpen>
      <InputGroup style={{'--m': '10px 0'}}>
        <fbt desc="TextProperties">
          Edit the text directly on the element itself.
        </fbt>
      </InputGroup>
    </Fieldset>
  );
};

export default TextProperties;
