import React from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import Fieldset from '../FieldSet';
import TextInput from '../Controls/TextInput';
import AdvancedColorPicker from '../Controls/AdvancedColorPicker';
import GenericSlider from '../Controls/GenericSlider';
import CheckboxInput from '../Controls/CheckboxInput';
import SelectInput from '../Controls/SelectInput';
import LinkGroup from '../Controls/LinkGroup';
import ItemsFieldsetWrapper from '../Fieldsets/ItemsFieldsetWrapper';
import HelpText from '../Controls/HelpText';
import { ElementColorClasses } from '../../../modules/consts/Elements';
import IconPicker from '../Controls/IconPicker';

const MenuProperties = props => {
  const {model, element} = props;
  const {componentProperties} = element;

  return (
    <>
      <Fieldset title={fbt('Menu Properties', 'MenuProperties')} isOpen>
        <SelectInput id="menu-style" value={componentProperties.style}
                     title={fbt('Menu Style', 'MenuProperties')}
                     showLabel
                     options={{
                       'horizontal': fbt('Horizontal', 'MenuProperties'),
                       'vertical': fbt('Vertical', 'MenuProperties')
                     }}
                     action={val => {
                       props.handlePropertyChange('style', val);
                     }}
        />

        {componentProperties.style !== 'vertical' && (
          <TextInput title={fbt('Caption (Optional)', 'MenuProperties')}
                     value={componentProperties.caption || ''}
                     showLabel
                     action={val => props.handlePropertyChange('caption', val)}
          />
        )}

        {componentProperties.style !== 'vertical' &&
        <SelectInput title={fbt('Caption Size', 'MenuProperties')}
                     value={componentProperties.captionSize || 'h3'}
                     id="buttons-caption-size"
                     showLabel
                     action={val => props.handlePropertyChange('captionSize', val)}
                     options={{
                       'h1': fbt('Heading 1', 'MenuProperties'),
                       'h2': fbt('Heading 2', 'MenuProperties'),
                       'h3': fbt('Heading 3', 'MenuProperties'),
                       'h4': fbt('Heading 4', 'MenuProperties'),
                       'h5': fbt('Heading 5', 'MenuProperties'),
                     }}
        />
        }

        {componentProperties.style !== 'vertical' && <>
          <SelectInput
            title={fbt('List Style', 'MenuProperties')}
            id="list-style"
            value={componentProperties.listStyle}
            action={val => {
              props.handlePropertyChange('listStyle', val);
            }}
            options={{
              'none': fbt('None', 'MenuProperties'),
              'disc': fbt('Disc', 'MenuProperties'),
              'circle': fbt('Circle', 'MenuProperties'),
              'square': fbt('Square', 'MenuProperties'),
              'decimal': fbt('Decimal', 'MenuProperties'),
              'lower-roman': fbt('Roman', 'MenuProperties'),
            }}
          />
        </>
        }

        <GenericSlider title={fbt('Space Between', 'MenuProperties')}
                       value={componentProperties.spaceBetween || 0}
                       showLabel
                       min="0" max="60"
                       action={val => props.handlePropertyChange('spaceBetween', val)}/>

        <TextInput title={fbt('Spacer', 'MenuProperties')}
                   value={componentProperties.spacer || ''} showLabel
                   action={val => props.handlePropertyChange('spacer', val)}
        />

        <AdvancedColorPicker title={fbt('Menu Item Text and Link Color', 'MenuProperties')}
                             value={componentProperties.linkColor}
                             actionReset={() => props.handlePropertyChange('linkColor', '')}
                             action={val => props.handlePropertyChange('linkColor', val)}
        />

        <CheckboxInput title={fbt('Generate Mobile Menu (For small devices)', 'MenuProperties')}
                       checked={componentProperties.generateMobileMenu}
                       action={() => {
                         props.handlePropertyChange('generateMobileMenu', !componentProperties.generateMobileMenu);
                       }}
        />
        <HelpText>
          <fbt desc="MenuProperties">
            Generates a separate extensible mobile menu for small devices (&lt; 768px). Should only be used once per
            page for the main menu.
          </fbt>
        </HelpText>
      </Fieldset>

      <ItemsFieldsetWrapper
        title={fbt('Menu Items', 'MenuProperties')}
        items={componentProperties.items}
        actionDispatch={props.dispatchItems}
        template={model.itemTemplate}
        isOpen
      >
        <Controls
          action={props.handleItemChange}/>
      </ItemsFieldsetWrapper>
    </>
  );
};

const Controls = props => {
  const item = props.item;
  const index = props.index;

  const linkTypeId = `link-type-${index}`;
  const colorId = `button-color-${index}`;
  const displayAsId = `display-as-${index}`;

  return (
    <>
      <TextInput title={fbt('Title (Label)', 'MenuProperties')}
                 value={item.title} showLabel
                 action={val => props.action(index, 'title', val)}
      />

      <IconPicker icon={item.icon} hasNone minimized
                  title={fbt('Icon (Optional)', 'MenuProperties')}
                  action={icon => props.action(index, 'icon', icon)}
                  showTitle
      />

      <LinkGroup
        linkTypeId={linkTypeId}
        linkType={item.linkType}
        actionLinkType={val => props.action(index, 'linkType', val)}
        link={item.link}
        actionLink={val => props.action(index, 'link', val)}
        page={item.page}
        actionPage={val => props.action(index, 'page', val)}
        linkTarget={item.linkTarget}
        actionLinkTarget={val => props.action(index, 'linkTarget', val)}
        isNofollow={item.isNofollow}
        actionNofollow={val => props.action(index, 'isNofollow', val)}
      />

      <SelectInput title={fbt('Display as', 'MenuProperties')}
                   showLabel
                   id={displayAsId}
                   value={item.displayAs || 'link'}
                   options={{'link': fbt('Link', 'MenuProperties'), 'button': fbt('Button', 'MenuProperties')}}
                   action={val => props.action(index, 'displayAs', val)}
      />

      {item.displayAs === 'button' && (
        <AdvancedColorPicker title={fbt('Button Color', 'MenuProperties')} hasCustom={false}
                             id={colorId}
                             value={item.buttonColor || ''}
                             action={val => props.action(index, 'buttonColor', val)}/>
      )}

      {item.displayAs === 'button' && ElementColorClasses.includes(item.buttonColor) && (
        <CheckboxInput
          checked={item.outline}
          title={fbt('Outline Only', 'MenuProperties')}
          action={() => props.action(index, 'outline', !item.outline)}
        />
      )}
    </>
  );
};

export default connect()(MenuProperties);

