import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import fbt from 'fbt';

import TextInput from '../components/Properties/Controls/TextInput';
import CheckboxInput from '../components/Properties/Controls/CheckboxInput';
import SocialLoginButton from '../components/Login/SocialLoginButton';
import serviceHelper from '../modules/serviceHelper';
import Alert from '../components/General/Alert';
import { setToken, setUser } from '../store/actions/user';
import { loginUserWithJwt } from '../store/actions/login';
import logger from '../modules/logger';
import SelectInput from '../components/Properties/Controls/SelectInput';
import { setSetting } from '../store/actions/settings';

const Login = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);

  const [error, setError] = useState('');
  const [view, setView] = useState(null);

  const loginService = serviceHelper.getService('LoginService');
  const history = useHistory();

  useEffect(() => {
    handleSocialLogin();
  });

  const handleSocialLogin = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const jwtToken = urlParams.get('t');

    if (!jwtToken) {
      return;
    }

    if (jwtToken) {
      props.dispatch(loginUserWithJwt(jwtToken));
      history.push('/');
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');

    if (!email || !password) {
      setError(fbt('Please enter an email address and password.', 'Login Validation'));
      return;
    }

    await loginService.login({email, password})
      .then(verifyAndLogin)
      .catch((error) => {
        logger.warn('Error logging in', error);

        if (!error.response) {
          setError(fbt('Error logging in, please try again and contact support if it is still not working.', 'Log error'));
          return;
        }

        if (error.response.status === 401) {
          // Error logging in
          setError(fbt('Invalid email or password.', 'Login Error'));
          return;
        }

        if (error.response.status === 403) {
          // Account not activated
          setError(fbt('Your account hasn\'t been activated yet. Please check your emails.', 'Login Error'));
          return;
        }

        // Else?
        setError(fbt('Error logging in, please try again and contact support if it happens again.', 'Login Error'));
      });
  };

  const verifyAndLogin = response => {
    const jwtToken = response.data.token;

    let tokenData = null;

    try {
      tokenData = jwt_decode(jwtToken);
    } catch (error) {
      logger.warn('Error decoding JWT token', error);

      return;
    }

    // Needs to be set first..
    axios.defaults.headers.token = tokenData.token;
    window.localStorage.setItem('jwtToken', jwtToken);

    props.dispatch(setUser(response.data.user));
    props.dispatch(setToken(tokenData.token));

    // Redirect the user
    history.push('/');
  };

  const handleLanguageChange = lang => {
    props.dispatch(setSetting('language', lang));
  };

  const handleRegistrationClick = e => {
    setView('registration');
  };

  const handleRecoveryClick = e => {
    setView('recovery');
  };

  if (view === 'registration') {
    return (
      <div
        style={{backgroundImage: 'url(/img/backgrounds/login-background.webp)', '--bgs': 'cover', '--minh': '100vh'}}>
        <div className="centered-fixed login-window" style={{'--pb': '20px'}}>
          <RegistrationForm {...props} cancelAction={() => setView('')}/>
        </div>
      </div>
    );
  }

  if (view === 'recovery') {
    return (
      <div
        style={{backgroundImage: 'url(/img/backgrounds/login-background.webp)', '--bgs': 'cover', '--minh': '100vh'}}>
        <div className="centered-fixed login-window" style={{'--pb': '20px'}}>
          <PasswordRecoveryForm {...props} cancelAction={() => setView('')}/>
        </div>
      </div>
    );
  }

  // Logged out
  if (!props.token) {
    return (
      <div className="mame-login"
           style={{backgroundImage: 'url(/img/backgrounds/login-background.webp)', '--bgs': 'cover', '--minh': '100vh'}}
      >
        <div style={{'--pos': 'absolute', '--right': '80px', '--top': '60px', '--w': '200px'}}>
          <SelectInput title={fbt('Language', 'SettingsModal')}
                       className="builder-select"
                       value={props.language}
                       options={{
                         'en_US': fbt('English', 'SettingsModal'),
                         'de_DE': fbt('German', 'SettingsModal'),
                       }}
                       action={val => {
                         handleLanguageChange(val);
                       }}
          />
        </div>

        <div className="centered-fixed login-window">
          <div style={{
            '--d': 'grid',
            '--gtc': '1fr',
            '--gg': '25px',
            '--gtc-lg': '1fr 35px 1fr',
          }}>
            <div style={{'--as': 'center'}}>
              <Alert style={{'--d-md': 'none'}}>
                <fbt desc="Warning for mobile users">
                  The Website Builder is not optimized for mobile use (Websites you create are).
                  Please use a device with a higher resolution.
                </fbt>
              </Alert>

              <SocialLoginButton service="Google"/>
              <SocialLoginButton service="Facebook"/>
              <SocialLoginButton service="GitHub"/>

              <div style={{'--mt': '10px'}}>
                <small>
                  <fbt desc="Mame ToS and Privacy Policy agreement">
                    By continuing you agree to Mame's <a href="https://mame.app/eula.html" rel="noreferrer noopener"
                                                         target="_blank">Terms of Service</a> and <a
                    href="https://mame.app/privacy-policy.html" rel="noreferrer noopener"
                    target="_blank">Privacy Policy</a>.
                  </fbt>
                </small>
              </div>
            </div>
            <div>
              <div className="border-word-wrap">
                <div className="border-line"/>
                <div className="border-word">
                  <fbt desc="Login social OR user">or</fbt>
                </div>
              </div>
            </div>
            <div style={{'--as': 'center'}}>
              <form onSubmit={handleLogin}>
                <h4 style={{'--m': 0, '--mb': '10px'}}>
                  <fbt desc="Login Form">
                    Sign in manually
                  </fbt>
                </h4>
                {error && (
                  <Alert className="error-login" variant="red"
                         radius="3px" my="10px" py="10px">{error}</Alert>
                )}
                <div>
                  <TextInput id="email"
                             title={fbt('Email', 'Login Form')}
                             type="email"
                             placeholder="example@mail.com"
                             autoComplete="email"
                             value={email}
                             action={val => setEmail(val)}
                             showLabel
                             required
                  />
                </div>
                <div style={{'--mt': '15px'}}>
                  <TextInput id="password"
                             title={fbt('Password', 'Login Form')}
                             type="password"
                             autoComplete="current-password"
                             value={password}
                             action={val => setPassword(val)}
                             showLabel
                             required
                  />
                </div>
                <div style={{'--mt': '15px'}}>
                  <CheckboxInput label={fbt('Remember me', 'Login Form')}
                                 checked={rememberMe}
                                 action={() => setRememberMe(!rememberMe)}
                  />
                </div>
                <div>
                  <button id="btn-login" className="mame-primary" onClick={handleLogin}>
                    <fbt desc="Login Form Login button">
                      Login
                    </fbt>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div style={{
            '--d': 'flex',
            '--jc': 'center',
            '--ai': 'center',
            '--bt': '1px dotted #ccc',
            '--pos': 'absolute',
            '--w': '100%',
            '--bottom': '0',
            '--left': '0',
            '--p': '10px',
          }}>
            <div className="fake-link" style={{'--mr': '10px'}}
                 onClick={handleRegistrationClick}>
              <fbt desc="Login Form">
                Create a New Account
              </fbt>
            </div>
            <div className="fake-link"
                 onClick={handleRecoveryClick}>
              <fbt desc="Login Form">
                Forgot Password?
              </fbt>
            </div>
          </div>
        </div>
        <div style={{'--ta': 'center', '--pos': 'absolute', '--bottom': '0px', '--w': '100%'}}>
          Copyright (C) 2020 - 2021 <a href="https://mame.app" rel="noreferrer noopener">Mame</a> - All rights reserved.
        </div>
      </div>
    );
  }

  return (
    <div style={{backgroundImage: 'url(/img/backgrounds/login-background.webp)', '--bgs': 'cover', '--minh': '100vh'}}>
      <div className="centered-fixed login-window" style={{'--pb': '20px'}}>
        <h3>
          <fbt desc="Login Form">Logged in as</fbt>
          <b style={{'--ml': '8px'}}>
            {props.user ? props.user.username || props.user.email : ''}
          </b>
        </h3>
        <Link to="/">
          <fbt desc="Login Form">
            Go to the Dashboard.
          </fbt>
        </Link>
      </div>
    </div>
  );
};

const PasswordRecoveryForm = props => {
  const [email, setEmail] = useState('');
  const [form, setForm] = useState('');

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  const handleRecovery = e => {
    e.preventDefault();

    setError('');

    if (!form.reportValidity()) {
      setError(fbt('Please fill out your email address.', 'LoginView'));
      return;
    }

    const loginService = serviceHelper.getService('LoginService');

    const data = {
      email,
    };

    loginService.recoverPassword(data)
      .then(() => {
        setSuccess(true);
      })
      .catch(error => {
        logger.warn('Error logging in', error);

        if (error.response.status === 404) {
          setError(fbt('Your email address is not registered.', 'LoginView'));
          return;
        }

        if (error.response.status === 407) {
          setError(fbt('Your email is registered over a social login, like Google or FaceBook.', 'LoginView'));
          return;
        }

        setError(JSON.stringify(error));
      });
  };

  return (
    <div id="password-recovery-form">
      <h4>
        <fbt desc="LoginView">
          Reset your password
        </fbt>
      </h4>

      {success && (
        <Alert variant="green" className="alert-success" style={{'--m': '10px 0'}}>
          <fbt desc="LoginView">
            An email with instructions on how to change your password has been sent to your email address.
          </fbt>
        </Alert>
      )}

      {error && (
        <Alert variant="red" className="alert-error" style={{'--m': '10px 0'}}>
          <fbt desc="LoginView">
            Error resetting your password.
          </fbt>
          <br/>
          {error}
        </Alert>
      )}

      <form onSubmit={handleRecovery} ref={setForm}>
        <TextInput title={fbt('Email *', 'LoginView')}
                   id="recovery-email"
                   showLabel
                   type="email"
                   autoComplete="email"
                   placeholder={fbt('The email address of your Mame account', 'LoginView')}
                   value={email}
                   action={setEmail}
                   minLength="3"
                   required
        />

        <button className="white" onClick={props.cancelAction}>
          <fbt desc="LoginView">
            Cancel
          </fbt>
        </button>

        <button className="mame-primary" onClick={handleRecovery}>
          <fbt desc="LoginView">
            Send recovery email
          </fbt>
        </button>
      </form>
    </div>
  );
};

const RegistrationForm = props => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [form, setForm] = useState('');

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  const handleRegistration = e => {
    e.preventDefault();
    setError('');

    if (!form.reportValidity()) {
      setError(fbt('Please fill out all required fields.', 'Login Form Error'));
      return;
    }

    const loginService = serviceHelper.getService('LoginService');

    const credentials = {
      username,
      email,
      password,
    };

    loginService.register(credentials)
      .then(() => {
        setSuccess(true);
      })
      .catch(error => {
        logger.warn('Error registering', error);

        if (error.response.status === 409) {
          setError(fbt('Your email address or the username are already registered.', 'Login Form Error'));
          return;
        }

        setError(error);
      });
  };

  return (
    <div id="register-form">
      <h4>
        <fbt desc="Registration Form">
          New Mame Account
        </fbt>
      </h4>

      {success && (
        <Alert variant="green" className="alert-success" style={{'--m': '10px 0'}}>
          <fbt desc="Registration Form Success Message">
            Your registration was successful. Please check your emails to confirm your account.
          </fbt>
        </Alert>
      )}

      {error && (
        <Alert variant="red" className="alert-error" style={{'--m': '10px 0'}}>
          <fbt desc="Error creating Account">
            Error creating your Account.
          </fbt>
          <br/>
          {error}
        </Alert>
      )}


      <form onSubmit={handleRegistration} ref={setForm}>
        <TextInput title={fbt('Username *', 'Registration Form')}
                   id="register-username"
                   filter="[^A-Za-z0-9_-]"
                   showLabel
                   autoComplete="username"
                   placeholder={fbt('Pick a username for Mame', 'Registration Form')}
                   value={username}
                   action={setUsername}
                   minLength="3"
                   required
        />

        <TextInput title={fbt('Email *', 'Registration Form')}
                   id="register-email"
                   showLabel
                   autoComplete="email"
                   placeholder={fbt('example@mail.com', 'Registration Form')}
                   type="email"
                   value={email}
                   action={setEmail}
                   minLength="5"
                   required
        />

        <TextInput title={fbt('Password *', 'Registration Form')}
                   id="register-password"
                   showLabel
                   autoComplete="new-password"
                   placeholder={fbt('At least 8 characters', 'Registration Form')}
                   type="password"
                   minLength="8"
                   value={password}
                   action={setPassword}
                   required
        />
      </form>

      <p>
        <fbt desc="Register Form ToS and Privacy Policy">
          By continuing you agree to Mame's <a href="https://mame.app/eula.html" target="_blank"
                                               rel="noreferrer noopener">Terms of Service</a> and <a
          href="https://mame.app/privacy-policy.html" target="_blank" rel="noreferrer noopener">Privacy Policy</a>.
        </fbt>
      </p>

      <button className="white" onClick={props.cancelAction}>
        <fbt desc="Registration Form">
          Cancel
        </fbt>
      </button>
      <button className="mame-primary" onClick={handleRegistration}>
        <fbt desc="Registration Form">
          Sign Up
        </fbt>
      </button>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.user.user,
  token: state.user.token,
  language: state.settings.language,
});

export default connect(mapStateToProps)(Login);
