import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import elementHelper from '../../modules/elementHelper';
import ElementDiv from '../ElementControls/ElementDiv';
import propertyHelper from '../../modules/propertyHelper';
import DropZone from '../Sortable/DropZone';
import FigureElementsRenderer from '../ElementControls/FigureElementsRenderer';
import { setActiveElement } from '../../store/actions/builder';
import { showModal } from '../../store/actions/modals';

const SlideshowElement = props => {
  const element = props.element;
  const {componentProperties} = element;

  const id = elementHelper.getId(element);
  const classes = elementHelper.getClasses(element, 'mame-slideshow');
  const style = elementHelper.getStyle(element);

  useEffect(() => {
    window.setupSlideshow();
  }, [componentProperties]);

  const addElement = (e, index) => {
    e.preventDefault();

    const options = {
      to: element._id,
      toType: 'item',
      index: index,
    };

    props.dispatch(showModal('AddElementModal', options));
  };

  const setActive = (e) => {
    const classes = ['slideshow'];

    // Make sure to only trigger grid (Stupid hack)
    if (e.target.classList.contains(classes[0])) {
      props.dispatch(setActiveElement(element));
    }
  };

  return (
    <ElementDiv {...props}
                id={id}
                classes={classes}
                action={setActive}
                style={style}>
      <div className="slideshow"
           data-speed={componentProperties.interval}
           data-auto-play={componentProperties.autoPlay || null}
           data-animation={componentProperties.animation || 'animate-slide-left-in'}
           style={{'--d': 'grid', '--ai': 'stretch', '--h': '100%', '--pos': 'relative'}}>
        {componentProperties.items.map((item, index) => {
          const slideStyle = {
            '--pos': 'absolute',
            '--top': '0',
            '--w': '100%',
            '--h': '100%',
          };

          propertyHelper.setBackground(slideStyle, item);

          return (
            <div className="slideshow__slide"
                 key={index}
                 style={slideStyle}>

              <DropZone element={element}
                        type="item"
                        index={index}
                        items={item.elements}>
                <FigureElementsRenderer
                  tag="div"
                  element={element}
                  addElement={e => addElement(e, index)}
                  parentType="item"
                  parentIndex={index}
                  className="slideshow__inner"
                  moveText={fbt('Move Element to Slide.', 'Element Slideshow')}
                  style={{
                    '--d': 'flex',
                    '--ai': 'center',
                    '--jc': 'center',
                    '--h': '100%',
                    '--ml': '50px',
                    '--mr': '50px',
                    '--of': 'hidden',
                  }}
                />
              </DropZone>
            </div>
          );
        })}
        <div
          className="slideshow__left"
          role="button"
          title={fbt('Previous Slide', 'Element Slideshow')}
          style={{
            '--pos': 'absolute',
            '--bgc': 'var(--white)',
            '--h': '30px',
            '--w': '30px',
            '--br': '50%',
            '--left': '10px',
            '--top': '50%',
            '--t': 'translateY(-50%)',
            '--size': '150%',
            '--d': componentProperties.controlsVisible ? 'flex' : 'none',
            '--ai': 'center',
            '--jc': 'center',
            '--z': 999,
          }}>
          <span className="slideshow__left icon icon-angle-left" aria-hidden/>
        </div>

        <div
          className="slideshow__right"
          role="button"
          title={fbt('Next Slide', 'Element Slideshow')}
          style={{
            '--pos': 'absolute',
            '--bgc': 'var(--white)',
            '--h': '30px',
            '--w': '30px',
            '--br': '50%',
            '--right': '10px',
            '--top': '50%',
            '--t': 'translateY(-50%)',
            '--size': '150%',
            '--d': componentProperties.controlsVisible ? 'flex' : 'none',
            '--ai': 'center',
            '--jc': 'center',
            '--z': 999,
          }}>
          <span className="icon icon-angle-right" aria-hidden/>
        </div>

        <div className="slideshow__pages" style={{
          '--pos': 'absolute',
          '--bottom': '5px',
          '--left': '50%',
          '--t': 'translateX(-50%)',
          '--z': 999,
          '--d': componentProperties.navigationVisible ? 'block': 'none',
        }}>
          <ul style={{'--p': '0'}}>
            {componentProperties.items.map((item, index) => {
              return (
                <li title={'Slide ' + (index + 1)} key={index} data-index={index} role="button"
                    style={{'--d': 'inline-block', '--mx': '5px', 'color': '#888'}}>
                  <span className="icon icon-circle"/>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </ElementDiv>
  );
};

export default connect()(SlideshowElement);
