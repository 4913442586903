import serviceHelper from '../../modules/serviceHelper';
import { ADD_NOTIFICATION, CLEAR_NOTIFICATIONS, REMOVE_NOTIFICATION, SET_NOTIFICATIONS } from '../actionTypes';

export const apiLoadUserNotifications = () => (
  async dispatch => {
    const notificationService = serviceHelper.getService('NotificationService');

    const res = await notificationService.get();

    if (!res || !res.data) {
      return;
    }

    await dispatch(setNotifications(res.data.items));
  }
);

export const apiRemoveNotification = notificationId => (
  async dispatch => {
    const notificationService = serviceHelper.getService('NotificationService');

    await notificationService.delete(notificationId);
  }
);

export const apiClearNotifications = () => (
  async dispatch => {
    /**
     * The Notification server
     * @type {NotificationService}
     */
    const notificationService = serviceHelper.getService('NotificationService');

    await notificationService.clear();
  }
);

// Normal actions
export const setNotifications = notifications => ({type: SET_NOTIFICATIONS, payload: {notifications}});
export const addNotification = notification => ({type: ADD_NOTIFICATION, payload: {notification}});
export const removeNotification = notificationId => ({type: REMOVE_NOTIFICATION, payload: {notificationId}});
export const clearNotifications = () => ({type: CLEAR_NOTIFICATIONS, payload: {}});
