import { SET_SETTING, SET_SETTINGS } from '../actionTypes';
import serviceHelper from '../../modules/serviceHelper';

// Async Actions
export const apiLoadSettings = () => (
  async dispatch => {
    const service = serviceHelper.getService('SettingService');

    const res = await service.get();

    if (!res) {
      return;
    }

    await dispatch(setSettings(res.data.item));
  }
);

export const apiStoreSettings = settings => (
  async dispatch => {
    const service = serviceHelper.getService('SettingService');

    await service.store(settings);
  }
);


// Actions
export const setSettings = settings => ({type: SET_SETTINGS, payload: {settings}});
export const setSetting = (key, value) => ({type: SET_SETTING, payload: {key, value}});
