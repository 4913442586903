import TypedTextProperties from '../../components/Properties/Elements/TypedTextProperties';
import { ElementVisible } from '../../modules/consts/Elements';

const TypedTextModel = {
  title: 'Typed Text',
  category: 'Actions',
  helpPage: '/documentation/elements/typedText',
  component: 'TypedTextElement',
  propertyComponent: TypedTextProperties,
  hasChildElements: false,
  generalProperties: {
    hasVisibility: true,
    hasTextAlign: true,
    hasTextColor: true,
    hasBackground: true,
  },
  defaults: {
    demo: true,
    _id: null,
    identifier: 'New Typed Text',
    component: 'TypedTextElement',
    componentProperties: {
      displayAs: 'h2',
      typeSpeed: 80,
      loop: true,
      showCursor: true,
      sentences: [
        'Hello this is',
        'a multiline typed text',
      ],
    },
    generalProperties: {
      visibility: [ElementVisible.sm, ElementVisible.md, ElementVisible.lg, ElementVisible.xl],
      published: true,
      margin: [0, 0, 0, 0],
      padding: [0, 0, 0, 0],
    },
  }
};

export default TypedTextModel;
