import InputProperties from '../../components/Properties/Elements/InputProperties';
import { ElementVisible } from '../../modules/consts/Elements';

const InputModel = {
  title: 'Input',
  category: 'Form',
  helpPage: '/documentation/elements/form',
  component: 'InputElement',
  propertyComponent: InputProperties,
  hasChildElements: false,
  generalProperties: {
    hasVisibility: true,
    hasTextAlign: true,
    hasTextColor: false,
    hasBackground: false,
  },
  defaults: {
    demo: true,
    _id: null,
    identifier: 'New Input',
    component: 'InputElement',
    componentProperties: {
      title: 'New Input',
      showLabel: true,
      customName: '',
      inputType: '',
      defaultValue: '',
      placeholder: '',
      required: false,
      invalidMessage: '',
      pattern: '',
      autoComplete: '',
    },
    generalProperties: {
      visibility: [ElementVisible.sm, ElementVisible.md, ElementVisible.lg, ElementVisible.xl],
      published: true,
      margin: [0, 0, 0, 0],
      padding: [0, 0, 0, 0],
    },
  },
};

export default InputModel;
