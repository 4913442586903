import TabsProperties from '../../components/Properties/Elements/TabsProperties';
import { ElementVisible } from '../../modules/consts/Elements';

const TabsModel = {
  title: 'Tabs',
  category: 'Basic',
  helpPage: '/documentation/elements/tabs',
  component: 'TabsElement',
  propertyComponent: TabsProperties,
  hasChildElements: false,
  generalProperties: {
    hasVisibility: true,
    hasTextAlign: true,
    hasTextColor: true,
    hasBackground: true,
  },
  defaults: {
    demo: true,
    _id: null,
    identifier: 'New Tabs',
    component: 'TabsElement',
    componentProperties: {
      style: '',
      items: [
        {
          itemId: 42,
          title: 'Tab 1',
          icon: '',
          elements: [],
        },
        {
          itemId: 43,
          title: 'Tab 2',
          icon: '',
          elements: [],
        },
      ],
    },
    generalProperties: {
      visibility: [ElementVisible.sm, ElementVisible.md, ElementVisible.lg, ElementVisible.xl],
      published: true,
      margin: [0, 0, 0, 0],
      padding: [25, 0, 25, 0],
    },
  },
  itemTemplate: {
    itemId: 0,
    title: 'New Tab',
    icon: '',
    elements: [],
  },
};

export default TabsModel;
