import {
  REMOVE_ELEMENT,
  REMOVE_PAGE,
  REMOVE_SECTION,
  REMOVE_SITE,
  REMOVE_SECTION_TEMPLATE,
  UPDATE_ELEMENT_HTML,
  UPDATE_ELEMENT_PROPERTY,
  UPDATE_ELEMENT_PROPERTIES,
  UPDATE_ELEMENT_ITEM_PROPERTY,
  UPDATE_PAGE_PROPERTY,
  UPDATE_SECTION_PROPERTY,
  UPDATE_SECTION_PROPERTIES,
  UPDATE_SITE_PROPERTY,
  UPDATE_USER_PROPERTY,
  REMOVE_PAGE_TEMPLATE,
  MOVE_ELEMENT,
  SET_SETTING,
  REMOVE_SITE_TEMPLATE,
  // Skip
  SET_ACTIVE_TYPE,
  SET_ACTIVE_ELEMENT,
  SET_ACTIVE_SECTION,
  SHOW_MODAL,
  HIDE_MODAL,
  CLOSE_ACTIVE_PROPERTY,
  SET_PRESET_STYLES,
  REMOVE_NOTIFICATION,
  CLEAR_NOTIFICATIONS,
} from '../actionTypes';

import serviceHelper from '../../modules/serviceHelper';

import {
  apiMoveElement,
  apiRemoveElement, apiRemovePage,
  apiRemoveSection,
  apiStoreElement,
  apiStoreSection,
  apiStoreSite,
} from '../actions/activeSite';

import { apiStorePage } from '../actions/activePage';
import { apiStoreUser } from '../actions/user';
import { apiRemoveSite } from '../actions/sites';
import { apiRemovePageTemplate, apiRemoveSectionTemplate, apiRemoveSiteTemplate } from '../actions/templates';
import { apiStoreSettings } from '../actions/settings';
import logger from '../../modules/logger';
import { apiClearNotifications, apiRemoveNotification } from '../actions/notifications';

const TIMEOUT = 500;

const saveElement = (store, element) => {
  if (!element) {
    return;
  }

  const updatedStore = store.getState();
  const updatedElement = updatedStore.activeSite.elements.find(e => e._id === element._id);

  store.dispatch(apiStoreElement(updatedElement));
};

const debounceElement = serviceHelper.debounce(saveElement, TIMEOUT);

const saveSection = (store, section) => {
  if (!section) {
    return;
  }

  const updatedStore = store.getState();
  const updated = updatedStore.activeSite.sections.find(e => e._id === section._id);

  store.dispatch(apiStoreSection(updated));
};

const debounceSection = serviceHelper.debounce(saveSection, TIMEOUT);

const saveSite = store => {
  store.dispatch(apiStoreSite(store.getState().activeSite.site));
};

const debounceSite = serviceHelper.debounce(saveSite, TIMEOUT);

const savePage = (page, store) => {
  if (!page) {
    return;
  }

  const updatedStore = store.getState();
  const updatedPage = updatedStore.activeSite.pages.find(p => p._id === page._id);

  store.dispatch(apiStorePage(updatedPage));
};

const debouncePage = serviceHelper.debounce(savePage, TIMEOUT);

const saveUser = store => {
  store.dispatch(apiStoreUser(store.getState().user.user));
};

const debounceUser = serviceHelper.debounce(saveUser, TIMEOUT);

const saveSettings = store => {
  store.dispatch(apiStoreSettings(store.getState().settings));
};

const debounceSettings = serviceHelper.debounce(saveSettings, TIMEOUT);

/**
 * Helper for storing changes to the API
 *
 * @param store
 * @return {function(*): function(*=): *}
 */
const apiStore = store => next => action => {
  let result = next(action);

  logger.debug('[apiStore] Trigger', action.type);

  // Painful, but more efficient than calling the removal and than remove it in store :/
  switch (action.type) {
    // Do nothing, just faster if we skip this check
    case SET_ACTIVE_TYPE:
    case SET_ACTIVE_ELEMENT:
    case SET_ACTIVE_SECTION:
    case SET_PRESET_STYLES:
    case SHOW_MODAL:
    case HIDE_MODAL:
    case CLOSE_ACTIVE_PROPERTY:
      break;

    // Element
    case UPDATE_ELEMENT_PROPERTY:
    case UPDATE_ELEMENT_PROPERTIES:
    case UPDATE_ELEMENT_ITEM_PROPERTY:
    case UPDATE_ELEMENT_HTML: {
      const {element} = action.payload;

      debounceElement(store, element);
      break;
    }

    case REMOVE_ELEMENT: {
      const {elementId} = action.payload;

      store.dispatch(apiRemoveElement(elementId));
      break;
    }

    case MOVE_ELEMENT: {
      const {elementId, options} = action.payload;

      store.dispatch(apiMoveElement(elementId, options));
      break;
    }

    // Section
    case UPDATE_SECTION_PROPERTIES:
    case UPDATE_SECTION_PROPERTY: {
      const {section} = action.payload;

      debounceSection(store, section);
      break;
    }

    case REMOVE_SECTION: {
      const {sectionId, pageId} = action.payload;

      store.dispatch(apiRemoveSection(sectionId, pageId));
      break;
    }

    // Site
    case UPDATE_SITE_PROPERTY: {
      debounceSite(store);
      break;
    }

    case REMOVE_SITE: {
      const {siteId} = action.payload;

      store.dispatch(apiRemoveSite(siteId));
      break;
    }

    // Page
    case UPDATE_PAGE_PROPERTY: {
      const {page} = action.payload;

      debouncePage(page, store);
      break;
    }

    case REMOVE_PAGE: {
      const {pageId} = action.payload;

      store.dispatch(apiRemovePage(pageId));
      break;
    }

    // User
    case UPDATE_USER_PROPERTY: {
      debounceUser(store);
      break;
    }

    // Templates
    case REMOVE_SECTION_TEMPLATE: {
      const {templateId} = action.payload;

      store.dispatch(apiRemoveSectionTemplate(templateId));
      break;
    }

    case REMOVE_PAGE_TEMPLATE: {
      const {templateId} = action.payload;

      store.dispatch(apiRemovePageTemplate(templateId));
      break;
    }

    case REMOVE_SITE_TEMPLATE: {
      const {templateId} = action.payload;

      store.dispatch(apiRemoveSiteTemplate(templateId));
      break;
    }

    // Settings
    case SET_SETTING: {
      debounceSettings(store);
      break;
    }

    // Notifications
    case REMOVE_NOTIFICATION: {
      const {notificationId} = action.payload;

      store.dispatch(apiRemoveNotification(notificationId));
      break;
    }

    case CLEAR_NOTIFICATIONS: {
      console.log('clearing notifications');
      store.dispatch(apiClearNotifications());
      break;
    }

    default: {
      break;
    }
  }

  return result;
};

export default apiStore;
