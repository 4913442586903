import BaseService from './BaseService';
import axios from 'axios';

const paths = {
  get: '/notifications',
  post: '/notifications',
  delete: '/notifications',
};

/**
 * Service for querying the User's notifications
 */
class NotificationService extends BaseService {
  constructor() {
    super(paths);
    this.requireLogin = true;
  }

  clear = () => {
    const url = '/notifications/clear';

    return axios.delete(url);
  };
}

export default new NotificationService();
