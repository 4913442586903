import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import fbt from 'fbt';

import Dialog from './Dialog';

import * as models from '../../models/Elements';
import ElementPreview from '../ElementControls/ElementPreview';
import { apiStoreElement } from '../../store/actions/activeSite';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { hideModal } from '../../store/actions/modals';

const AddElementModal = props => {
  const [elementSearch, setElementSearch] = useState('');
  const [categories, setCategories] = useState([]);
  const [categoryElements, setCategoryElements] = useState([]);
  const [openTab, setOpenTab] = useState(0);

  const {payload} = props;

  const createCategories = models => {
    let categories = Object.keys(models).map(key => {
      return models[key].category;
    });

    // Simple hack to get rid of duplicates, maybe use Set instead
    categories = categories.filter((item, index) => categories.indexOf(item) === index);

    const elements = {};

    Object.keys(models).forEach(key => {
      const model = models[key];

      // Only visible models
      if (model.hide) {
        return;
      }

      if (elements.hasOwnProperty(model.category)) {
        elements[model.category].push(model);
      } else {
        elements[model.category] = [model];
      }
    });

    setCategories(categories);
    setCategoryElements(elements);
  };

  useEffect(() => {
    createCategories(models);
  }, []);

  useEffect(() => {
    if (payload && payload.setCategoryTab) {
      const index = categories.findIndex(c => c === payload.setCategoryTab);

      if (index !== -1) {
        setOpenTab(index);
      }
    }
  }, [payload, categories]);

  if (!props.isVisible) {
    return null;
  }

  const handleSearch = searchTerm => {
    setElementSearch(searchTerm);

    if (!searchTerm) {
      createCategories(models);
      return;
    }

    const filteredModels = {};

    for (let m in models) {
      if (models[m].title.toLowerCase().includes(searchTerm.toLowerCase())) {
        filteredModels[m] = models[m];
      }
    }

    createCategories(filteredModels);
  };

  const add = (e, model) => {
    e.preventDefault();
    e.stopPropagation();

    const options = payload;

    const newElement = {
      ...model.defaults,
      site: props.siteId,
      createOptions: options,
    };

    if (options.toType === 'section') {
      newElement.section = options.to;
    } else if (options.toType === 'element') {
      newElement.element = options.to;
    }

    props.dispatch(apiStoreElement(newElement, payload));
    props.dispatch(hideModal('AddElementModal'));
  };

  const getCategoryIcon = (category) => {
    const icons = {
      'Basic': 'header',
      'Media': 'image',
      'Actions': 'lab',
      'Form': 'pencil',
    };

    if (!icons.hasOwnProperty(category)) {
      return 'books';
    }

    return icons[category];
  };

  return (
    <Dialog title={fbt('Add Element', 'AddElementModal')} name="AddElementModal" size="modal-xl">
      <Tabs forceRenderTabPanel selectedIndex={openTab} onSelect={index => setOpenTab(index)}>
        <TabList>
          {categories.map(category => {
            return (
              <Tab key={category}>
                <span className={`icon icon-${getCategoryIcon(category)}`} style={{'--mr': '10px'}}/>
                {category}
              </Tab>
            );
          })}
        </TabList>
        <div style={{'--bgc': 'var(--mame-white)', '--p': '15px 15px 0'}}>
          <input type="search"
                 value={elementSearch}
                 placeholder={fbt('Search Elements...', 'AddElementModal')}
                 style={{'--m': 0}}
                 onChange={(e) => handleSearch(e.target.value)}
          />
        </div>

        <div className="add-section modal-content"
             style={{'--radius': '0 0 5px 5px', '--p': '10px 10px 20px'}}>
          {categories.map(category => {
            return (
              <TabPanel key={`panel-${category}`}>
                {category === 'Form' && (
                  <div style={{'--ta': 'center', '--py': '10px'}}>
                    <fbt desc="AddElementModal">
                      Start with a Form and add elements to it.
                    </fbt>
                  </div>
                )}
                <div
                  style={{
                    '--d': 'grid',
                    '--gtc': 'repeat(4, minmax(0, 1fr))',
                    '--gg': '10px',
                    '--p': '10px 5px'
                  }}>
                  {categoryElements[category].map((model, index) => {
                    return (
                      <ElementPreview
                        model={model}
                        key={index}
                        action={(e) => add(e, model)}
                      />
                    );
                  })}
                </div>
              </TabPanel>
            );
          })}
        </div>
      </Tabs>
    </Dialog>
  );
};

const mapStateToProps = state => ({
  siteId: state.activeSite.site._id,
  isVisible: state.modals.AddElementModal,
  payload: state.modals.payload.AddElementModal,
});

export default connect(mapStateToProps)(AddElementModal);
