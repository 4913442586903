import React from 'react';
import fbt from 'fbt';

import ActionIcon from '../General/ActionIcon';

const SectionPreview = props => {
  const defaultImg = '/img/section/no-preview.jpg';
  const icon = props.icon;

  return (
    <figure className="accent preview"
            id={props.id || null}
            onClick={props.action}
            data-title={props.title || null}
            style={{
              '--cur': 'pointer',
              '--jc': 'space-between',
              '--levitate-hvr': '4',
              '--h': '275px',
              '--of': 'hidden'
            }}>
      <div style={{
        '--d': 'flex',
        '--ai': 'center',
        '--jc': 'center',
        '--fg': 1,
        '--bg': '#f1f1f1',
        '--maxh': '190px',
        '--of': 'hidden',
      }}>
        {!icon && (
          <img src={props.image}
               alt={fbt('Section Preview', 'SectionPreview')}
               style={props.imageStyle || null}
               onError={e => {
                 e.preventDefault();
                 e.target.onerror = null;
                 e.target.src = defaultImg;

                 if (props.makeScreenshot) {
                   props.makeScreenshot(e);
                 }
               }}/>
        )}
        {icon &&
        <span className={`icon icon-${props.icon}`}
              style={{
                '--size': '800%',
              }}
              title={props.title}/>
        }
      </div>

      <figcaption style={{
        '--h': '85px',
        '--d': 'flex',
        '--ai': 'center',
        '--jc': 'center',
      }}>
        <h5 style={{'--line-clamp': 2}}>{props.title}</h5>
      </figcaption>

      <div className="preview-menu"
           onClick={(e) => {
             e.stopPropagation();
           }}>
        {props.makeScreenshot && (
          <>
            <ActionIcon icon="image"
                        title={fbt('Update Screenshot', 'SectionPreview')}
                        action={props.makeScreenshot}
            />
            <br/>
          </>
        )}
        {props.markAsFavorite && (
          <>
            <ActionIcon icon={props.favorite ? 'star' : 'star-o'}
                        title={props.favorite ? fbt('Unstar', 'SectionPreview') : fbt('Mark as Favorite', 'SectionPreview')}
                        action={props.markAsFavorite}/>
            <br/>
          </>
        )}

        {props.delete && (
          <>
            <ActionIcon icon="trash-o"
                        title={fbt('Delete Template', 'SectionPreview')}
                        action={props.delete}/>
            <br/>
            <ActionIcon icon="shopping-basket"
                        title={fbt('Offer Template on the Store', 'SectionPreview')}
                        action={props.sell}/>
            <br/>
          </>
        )}
      </div>

    </figure>
  );
};

export default SectionPreview;
