import React from 'react';
import fbt from 'fbt';

import Fieldset from '../FieldSet';
import TextInput from '../Controls/TextInput';
import CheckboxInput from '../Controls/CheckboxInput';
import InputGroup from '../InputGroup';

const TextareaProperties = props => {
  const {element} = props;
  const {componentProperties} = element;

  return (
    <Fieldset title={fbt('Textarea Properties', 'TextareaProperties')} isOpen>
      <InputGroup style={{'--mt': '10px'}}>
        <TextInput title={fbt('Label (Title)', 'TextareaProperties')}
                   value={componentProperties.title}
                   id="input-input-title"
                   action={val => props.handlePropertyChange('title', val)}
                   showLabel
        />
      </InputGroup>

      <InputGroup>
        <CheckboxInput title={fbt('Show Label', 'TextareaProperties')}
                       id="check-show-label"
                       checked={componentProperties.showLabel}
                       action={() => props.handlePropertyChange('showLabel', !componentProperties.showLabel)}
        />
      </InputGroup>

      <InputGroup>
        <TextInput title={fbt('Custom Name (for email placeholders / 3rd party API)', 'TextareaProperties')}
                   value={componentProperties.customName}
                   action={val => props.handlePropertyChange('customName', val)}
                   id="input-custom-name"
                   filter="[^A-Za-z0-9_-]"
                   showLabel
        />
      </InputGroup>

      <InputGroup>
        <TextInput title={fbt('Placeholder', 'TextareaProperties')}
                   value={componentProperties.placeholder || ''}
                   id="input-placeholder"
                   action={val => props.handlePropertyChange('placeholder', val)}
                   showLabel
        />
      </InputGroup>

      <InputGroup>
        <TextInput title={fbt('Default Value', 'TextareaProperties')}
                   value={componentProperties.defaultValue || ''}
                   id="input-default-value"
                   action={val => props.handlePropertyChange('defaultValue', val)}
                   showLabel
        />
      </InputGroup>

      <InputGroup>
        <CheckboxInput title={fbt('Required', 'TextareaProperties')}
                       checked={componentProperties.required}
                       id="check-required"
                       action={() => props.handlePropertyChange('required', !componentProperties.required)}
        />
      </InputGroup>
    </Fieldset>
  );
};

export default TextareaProperties;
