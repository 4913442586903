import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import elementHelper from '../../modules/elementHelper';
import ElementDiv from '../ElementControls/ElementDiv';

const MapElement = props => {
  const {element, isRender} = props;
  const {componentProperties} = element;

  useEffect(() => {
    if (isRender) {
      return;
    }

    window.setupMaps();
  }, [element, isRender]);


  const id = elementHelper.getId(element);
  const classes = elementHelper.getClasses(element, 'mame-map');
  const style = elementHelper.getStyle(element);

  return (
    <ElementDiv {...props} id={id} classes={classes} style={style}>
      <div className="map-root"
           style={{'--h': componentProperties.height + 'px'}}
           data-lat={componentProperties.lat}
           data-long={componentProperties.long}
           data-zoom={componentProperties.zoom}
           data-show-routing={componentProperties.routing || null}
      />

      <link rel="stylesheet" href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css"
            integrity="sha512-xodZBNTC5n17Xt2atTPuE1HxjVMSvLVW9ocqUKLsCC5CXdbqCmblAshOMAS6/keqq/sMZMZ19scR4PsZChSR7A=="
            crossOrigin=""/>
    </ElementDiv>
  );
};

const mapStateToProps = state => ({
  isRender: state.builder.isRender,
});

export default connect(mapStateToProps)(MapElement);
