import { ElementVisible } from '../../modules/consts/Elements';
import LogoProperties from '../../components/Properties/Elements/LogoProperties';

const LogoModel = {
  title: 'Logo',
  category: 'Media',
  helpPage: '/documentation/elements/logo',
  component: 'LogoElement',
  propertyComponent: LogoProperties,
  hasChildElements: false,
  generalProperties: {
    hasVisibility: true,
    hasTextAlign: true,
    hasTextColor: false,
    hasBackground: true,
  },
  defaults: {
    demo: true,
    _id: null,
    identifier: 'New Logo',
    component: 'LogoElement',
    componentProperties: {
      linkToHomepage: true,
      lazy: false,
      autoDimensions: false,
      filterType: '',
      filterValue: 0,
    },
    generalProperties: {
      visibility: [ElementVisible.sm, ElementVisible.md, ElementVisible.lg, ElementVisible.xl],
      published: true,
      margin: [0, 0, 0, 0],
      padding: [0, 0, 0, 0],
      display: 'inline-block',
    },
  },
};

export default LogoModel;
