import SelectProperties from '../../components/Properties/Elements/SelectProperties';
import { ElementVisible } from '../../modules/consts/Elements';

const SelectModel = {
  title: 'Select',
  category: 'Form',
  helpPage: '/documentation/elements/form',
  component: 'SelectElement',
  propertyComponent: SelectProperties,
  hasChildElements: false,
  generalProperties: {
    hasVisibility: true,
    hasTextAlign: true,
    hasTextColor: false,
    hasBackground: false,
  },
  defaults: {
    demo: true,
    _id: null,
    identifier: 'New Select',
    component: 'SelectElement',
    componentProperties: {
      title: 'New Select Input',
      showLabel: true,
      customName: '',
      defaultValue: '',
      required: false,
      invalidMessage: '',
      items: [
        {itemId: 42, value: '', text: 'Please Select'},
        {itemId: 43, value: 'a', text: 'Option A Text'},
      ]
    },
    generalProperties: {
      visibility: [ElementVisible.sm, ElementVisible.md, ElementVisible.lg, ElementVisible.xl],
      published: true,
      margin: [0, 0, 0, 0],
      padding: [0, 0, 0, 0],
    },
  },
  itemTemplate: {
    itemId: 0,
    value: 'new',
    text: 'New Option'
  }
};

export default SelectModel;
