import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import elementHelper from '../../modules/elementHelper';
import ElementDiv from '../ElementControls/ElementDiv';
import DropZone from '../Sortable/DropZone';
import FigureElementsRenderer from '../ElementControls/FigureElementsRenderer';
import { showModal } from '../../store/actions/modals';
import { setActiveElement } from '../../store/actions/builder';
import { isPreviewSelector } from '../../store/selectors';

const TabsElement = props => {
  const element = props.element;
  const {componentProperties} = element;

  const id = elementHelper.getId(element);
  const classes = elementHelper.getClasses(element, 'mame-tabs');
  const style = elementHelper.getStyle(element);

  useEffect(() => {
    window.setupTabs();
  }, [componentProperties]);

  const setActive = e => {
    const classes = ['mame-tabs', 'tabs__list', 'active', 'tabs__header'];

    // Make sure to only trigger grid (Stupid hack)
    if (e.target.classList.contains(classes[0])
      || e.target.classList.contains(classes[1])
      || e.target.classList.contains(classes[2])
      || e.target.classList.contains(classes[3])) {
      props.dispatch(setActiveElement(element));
    }
  };

  const addElement = (e, index) => {
    e.preventDefault();

    const options = {
      to: element._id,
      toType: 'item',
      index: index,
    };

    props.dispatch(showModal('AddElementModal', options));
  };

  return (
    <ElementDiv {...props} id={id} classes={classes}
                style={style} action={setActive}>
      <div className={`tabs tabs--theme-${componentProperties.style || 'default'}`}>
        <ul className="tabs__list" role="tablist">
          {componentProperties.items.map((item, index) => {
            const ariaSelected = index === 0;
            const tabIndex = index === 0 ? 0 : -1;

            return (
              <li key={index}
                  data-index={index}
                  role="tab"
                  tabIndex={tabIndex}
                  aria-selected={ariaSelected}
                  onClick={setActive}>
                <div style={{'--d': 'grid', '--gtc': item.icon ? '25px 1fr' : '1fr', '--ai': 'center'}}
                     className="tabs__header">
                  {item.icon && (
                    <span className={`icon icon-${item.icon}`} aria-hidden/>
                  )}
                  {item.title}
                </div>
              </li>
            );
          })}
        </ul>

        {componentProperties.items.map((item, index) => {
          return (
            <div className="tabs__panel"
                 key={index}
                 data-index={index}
                 role="tabpanel"
                 aria-label={item.title}
                 tabIndex="0"
            >
              <DropZone element={element}
                        type="item"
                        index={index}
                        items={item.elements}>
                <FigureElementsRenderer
                  tag="div"
                  element={element}
                  addElement={e => addElement(e, index)}
                  parentType="item"
                  parentIndex={index}
                  className="tabs__inner"
                  moveText={fbt('Move Element to Tab.', 'Element Tab')}
                />
              </DropZone>
            </div>
          );
        })}
      </div>
    </ElementDiv>
  );
};

const mapStateToProps = state => ({
  isPreview: isPreviewSelector(state),
});

export default connect(mapStateToProps)(TabsElement);
