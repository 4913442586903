export const GoogleFonts = {
  'Roboto': {
    family: '\'Roboto\', sans-serif',
    url: 'https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap'
  },
  'Open Sans': {
    family: '\'Open Sans\', sans-serif',
    url: 'https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap'
  },
  'Lato': {
    family: '\'Lato\', sans-serif',
    url: 'https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap'
  },
  'Source Sans Pro': {
    family: '\'Source Sans Pro\', sans-serif',
    url: 'https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap'
  },
  'Inter': {
    family: '\'Inter\', sans-serif',
    url: 'https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap'
  },
  'Merriweather': {
    family: '\'Merriweather\', sans-serif',
    url: 'https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700&display=swap'
  },
};

const fontsHelper = {
  getGoogleFont(font) {
    if (!GoogleFonts.hasOwnProperty(font)) {
      return null;
    }

    return GoogleFonts[font];
  },

  getFont(font, fontFamily, fontUrl, isPrimary = false) {
    const google = this.getGoogleFont(font);

    if (!font) {
      // Prevent empty font
      return {
        family: isPrimary ? 'Arial' : '',
        url: '',
      };
    }

    if (google) {
      return {
        family: google.family,
        url: google.url,
      };
    }

    if (font === 'custom') {
      return {
        family: fontFamily || 'Arial',
        url: fontUrl || '',
      };
    }

    return {
      family: font,
      url: '',
    };
  }
};

export default fontsHelper;
