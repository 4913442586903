import React from 'react';
import { connect } from 'react-redux';

import elementHelper from '../../modules/elementHelper';
import ElementDiv from '../ElementControls/ElementDiv';

const SeparatorElement = props => {
  const element = props.element;
  const {componentProperties} = element;

  const id = elementHelper.getId(element);
  const classes = elementHelper.getClasses(element, 'mame-separator');
  const style = elementHelper.getStyle(element);

  const hrSize = componentProperties.height;
  const hrType = componentProperties.style;
  const hrColor = elementHelper.getColor(componentProperties.color);

  const hrStyle = {
    '--bt': `${hrSize}px ${hrType} ${hrColor}`,
  };

  return (
    <ElementDiv {...props} id={id} classes={classes} element={element} style={style}>
      <hr style={hrStyle} />
    </ElementDiv>
  );
};

export default connect()(SeparatorElement);
