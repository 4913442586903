import TextareaProperties from '../../components/Properties/Elements/TextareaProperties';
import { ElementVisible } from '../../modules/consts/Elements';

const TextareaModel = {
  title: 'Textarea',
  category: 'Form',
  helpPage: '/documentation/elements/form',
  component: 'TextareaElement',
  propertyComponent: TextareaProperties,
  hasChildElements: false,
  generalProperties: {
    hasVisibility: true,
    hasTextAlign: true,
    hasTextColor: false,
    hasBackground: false,
  },
  defaults: {
    demo: true,
    _id: null,
    identifier: 'New Text Area',
    component: 'TextareaElement',
    componentProperties: {
      title: 'New Text Area',
      showLabel: true,
      rows: 5,
      customName: '',
      inputType: '',
      defaultValue: '',
      placeholder: '',
      required: false,
      invalidMessage: '',
    },
    generalProperties: {
      visibility: [ElementVisible.sm, ElementVisible.md, ElementVisible.lg, ElementVisible.xl],
      published: true,
      margin: [0, 0, 0, 0],
      padding: [0, 0, 0, 0],
    },
  },
};

export default TextareaModel;
