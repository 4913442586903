import React, { useState } from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';
import md5 from 'js-md5';

import Dialog from './Dialog';
import TextInput from '../Properties/Controls/TextInput';
import CheckboxInput from '../Properties/Controls/CheckboxInput';
import HelpText from '../Properties/Controls/HelpText';
import serviceHelper from '../../modules/serviceHelper';
import { setActiveSite } from '../../store/actions/activeSite';
import { hideModal } from '../../store/actions/modals';

const AddDomainModal = props => {
  const [view, setView] = useState('first');
  const [domain, setDomain] = useState('');
  const [includeSubdomains, setIncludeSubdomains] = useState(true);

  if (!props.isVisible) {
    // Make sure to reset it
    return null;
  }

  const handleDialogClose = () => {
    setView('first');
  };

  const handleFirstPage = (domain, includeSubdomains) => {
    setDomain(domain);
    setIncludeSubdomains(includeSubdomains);
    setView('second');
  };

  const handleSecondPage = () => {
    setView('third');
  };

  const handleThirdPage = async () => {
    const service = serviceHelper.getService('SiteService');

    const result = await service.addDomain(props.site._id, domain, includeSubdomains);

    props.dispatch(setActiveSite(result.data.item));
    setView('first');
    props.dispatch(hideModal('AddDomainModal'));
  };

  return (
    <Dialog title={fbt('Add a Domain', 'AddDomainModal')} name="AddDomainModal" closeAction={handleDialogClose}>
      <div className="modal-content" style={{'--radius': '0 0 5px 5px', '--p': '20px'}}>
        {view === 'first' && <FirstPage {...props} action={handleFirstPage}/>}
        {view === 'second' && <SecondPage {...props} domain={domain} action={handleSecondPage}/>}
        {view === 'third' && (
          <ThirdPage {...props} domain={domain} includeSubdomains={includeSubdomains} action={handleThirdPage}/>
        )}
      </div>
    </Dialog>
  );
};

const ThirdPage = props => {
  return (
    <div>
      <h3 style={{'--ta': 'center'}}>
        <fbt desc="AddDomainModal">
          Set the DNS records
        </fbt>
      </h3>
      <p>
        <fbt desc="AddDomainModal">
          Please set the DNS records for the domain like the following:
        </fbt>
      </p>

      <div>
        <table>
          <thead>
          <tr>
            <th>
              <fbt desc="AddDomainModal">
                Record-Type
              </fbt>
            </th>
            <th>
              <fbt desc="AddDomainModal">
                Record-Name
              </fbt>
            </th>
            <th>
              <fbt desc="AddDomainModal">
                Target
              </fbt>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              A
            </td>
            <td>
              @ (or None)
            </td>
            <td>
              46.4.81.12
            </td>
          </tr>
          <tr>
            <td>
              A
            </td>
            <td>
              www
            </td>
            <td>
              46.4.81.12
            </td>
          </tr>
          {props.includeSubdomains && (
            <tr>
              <td>
                A
              </td>
              <td>
                *
              </td>
              <td>
                46.4.81.12
              </td>
            </tr>
          )}
          </tbody>
        </table>
      </div>

      <p>
        <fbt desc="AddDomainModal">
          Click on Finish to setup the Domain. Please note that it may take a couple of hours (up to 48h) until
          DNS changes propagate.
        </fbt>
      </p>

      <div style={{'--mt': '25px'}}>
        <button className="mame-primary"
                onClick={props.action}>
          <fbt desc="AddDomainModal">
            Finish
          </fbt>
        </button>
      </div>
    </div>
  );
};

const SecondPage = props => {
  const txtValue = md5('mame-' + props.domain).toString();

  return (
    <div>
      <h3 style={{'--ta': 'center'}}>Authorization Check</h3>
      <p>
        <fbt desc="AddDomainModal">
          <fbt:param name="domain">
            We need to verify that you are the owner of the domain: {props.domain}.
          </fbt:param>
        </fbt>
      </p>
      <p>
        <fbt desc="AddDomainModal">
          <fbt:param name="value">
            Please configure a new DNS record of type TXT with the value 'mame-verification={txtValue}'.
          </fbt:param>
        </fbt>
      </p>
      <div>
        <table>
          <thead>
          <tr>
            <th>
              <fbt desc="AddDomainModal">
                Type
              </fbt>
            </th>
            <th>
              <fbt desc="AddDomainModal">
                Host
              </fbt>
            </th>
            <th>
              <fbt desc="AddDomainModal">
                Value
              </fbt>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              TXT
            </td>
            <td>
              @ (or None)
            </td>
            <td>
              mame-verification={txtValue}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div style={{'--mt': '25px'}}>
        <button className="mame-primary"
                onClick={props.action}>
          <fbt desc="AddDomainModal">
            Next
          </fbt>
        </button>
      </div>
    </div>
  );
};

const FirstPage = props => {
  const [domain, setDomain] = useState('');
  const [domainClassName, setDomainClassName] = useState(null);
  const [includeSubdomains, setIncludeSubdomains] = useState(true);
  const [ready, setReady] = useState(false);

  const checkSubDomain = async domain => {
    domain = domain.toLowerCase().replace(/[^A-Za-z0-9._-]/, '');

    setDomain(domain);
    setDomainClassName('');
    setReady(false);

    // TODO real domain pattern
    if (domain.length < 3 || !domain.includes('.')) {
      setDomainClassName('invalid');
      return;
    }

    // We may do more here
    setDomainClassName('valid');
    setReady(true);
  };

  const handleConfirmDomain = () => {
    props.action(domain, includeSubdomains);
  };

  return (
    <>
      <h3 style={{'--ta': 'center'}}>
        <fbt desc="AddDomainModal">
          Add a custom domain
        </fbt>
      </h3>
      <div>
        <p>
          <fbt desc="AddDomainModal">
            With Mame you can use your custom domain in just a few steps.
          </fbt>
        </p>
        <p>
          <fbt desc="AddDomainModal">
            During the setup you will need to make changes to your Domains DNS configuration. Please open
            another browser window and log into the administration of your domain provider to apply changes
            when necessary.
          </fbt>
          <br/>
          <fbt desc="AddDomainModal">
            This wizard will guide you through the necessary steps.
          </fbt>
        </p>
      </div>

      <div style={{'--mt': '20px'}}>
        <TextInput title={fbt('Custom domain name', 'AddDomainModal')}
                   showLabel
                   placeholder="example.com"
                   type="url"
                   className={domainClassName}
                   value={domain}
                   action={checkSubDomain}
        />
        <HelpText>
          <fbt desc="AddDomainModal">
            Usually without subdomain (e.g. not www.example.com, but example.com)
          </fbt>
        </HelpText>
        <CheckboxInput title={fbt('Include Subdomains', 'AddDomainModal')}
                       checked={includeSubdomains}
                       action={() => setIncludeSubdomains(!includeSubdomains)}
        />
      </div>

      <div style={{'--mt': '25px'}}>
        <button className="mame-primary" disabled={!ready}
                onClick={handleConfirmDomain}>
          <fbt desc="AddDomainModal">
            Next
          </fbt>
        </button>
      </div>
    </>
  );
};


const mapStateToProps = state => ({
  site: state.activeSite.site,
  isVisible: state.modals.AddDomainModal,
  payload: state.modals.payload.AddDomainModal,
});

export default connect(mapStateToProps)(AddDomainModal);
