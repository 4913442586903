import React from 'react';
import { connect } from 'react-redux';
import { updateElementItemProperty, updateElementProperty } from '../../store/actions/activeSite';

/**
 * Holds duplicate code for properties
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const ComponentPropertiesHandler = props => {
  const {element} = props;
  const {componentProperties} = element;

  /**
   * Handle Property update
   * @param key
   * @param value
   */
  const handlePropertyChange = (key, value) => {
    props.dispatch(updateElementProperty(element, 'componentProperties', key, value));
  };

  /**
   * Helper for dispatching items
   * @param items
   */
  const dispatchItems = (items) => {
    props.dispatch(updateElementProperty(
      element,
      'componentProperties',
      'items',
      items,
    ));
  };

  /**
   * Helper for changing a value in an item
   * @param index
   * @param key
   * @param value
   */
  const handleItemChange = (index, key, value) => {
    props.dispatch(updateElementItemProperty(element, index, key, value));
  };

  const handleWholeItemChange = (index, item) => {
    const items = JSON.parse(JSON.stringify(componentProperties.items));
    items[index] = item;

    dispatchItems(items);
  };

  const childrenWithProps = React.Children.map(props.children, child => {
    const addProps = {
      handlePropertyChange,
      handleItemChange,
      handleWholeItemChange,
      dispatchItems,
    };

    return React.cloneElement(child, addProps);
  });

  return (
    <>
      {childrenWithProps}
    </>
  );
};

export default connect()(ComponentPropertiesHandler);
