import BaseService from './BaseService';

const paths = {
  get: '/templates/section',
  post: '/templates/section',
  patch: '/templates/section',
  delete: '/templates/section',
};

/**
 * Service for querying the Sites
 */
class SectionTemplateService extends BaseService {
  constructor() {
    super(paths);
    this.requireLogin = true;
  }
}

export default new SectionTemplateService();
