import React from 'react';
import fbt from 'fbt';
import { connect } from 'react-redux';

import Fieldset from '../FieldSet';
import TextInput from '../Controls/TextInput';
import HelpText from '../Controls/HelpText';
import InputGroup from '../InputGroup';
import { updateSiteProperty } from '../../../store/actions/activeSite';

const MetaFieldset = props => {
  const meta = props.meta === 'site' ? props.siteMeta : props.pageMeta;
  const languageText = props.override ? fbt('Language Override for this page', 'MetaFieldset') : fbt('Language', 'MetaFieldset');

  const handleChange = (key, value) => {
    if (props.meta === 'site') {
      props.dispatch(updateSiteProperty('meta', key, value));
    } else {
      props.action(key, value);
    }
  };

  return (
    <>
      <Fieldset title={props.title || fbt('Meta Tags', 'MetaFieldset')} isOpen>
        <InputGroup style={{'--mt': '10px'}}>
          <TextInput title={languageText}
                     className="b-meta__language"
                     value={meta.language || ''}
                     placeholder={fbt('For example en, fr or de', 'MetaFieldset')}
                     blur={val => handleChange('language', val)}
                     showLabel
          />
          <HelpText>
            <fbt desc="MetaFieldset">
              Short code for the sites language (like en, de-DE, fr..)
            </fbt>
          </HelpText>
        </InputGroup>

        <InputGroup>
          <TextInput title={fbt('Meta Description', 'MetaFieldset')}
                     className="b-meta__description"
                     value={meta.description}
                     blur={val => handleChange('description', val)}
                     showLabel
          />
          <HelpText>
            <fbt desc="MetaFieldset">
              Brief summary of the page. Often displayed by search engines in their search results.
            </fbt>
          </HelpText>
        </InputGroup>

        <InputGroup>
          <TextInput title={fbt('Meta Keywords', 'MetaFieldset')}
                     className="b-meta__keywords"
                     placeholder="E.g. web, web design, website builder, website designer"
                     value={meta.keywords}
                     blur={val => handleChange('keywords', val)}
                     showLabel
          />
        </InputGroup>
      </Fieldset>

      <Fieldset title={fbt('Open Graph (Facebook)', 'MetaFieldset')}>
        <HelpText style={{'--m': '10px 0'}}>
          <fbt desc="MetaFieldset">
            See <a href="https://ogp.me/" rel="noreferrer noopener nofollow" target="_blank">https://ogp.me</a>
          </fbt>
        </HelpText>

        <InputGroup>
          <TextInput title={fbt('Open Graph Title', 'MetaFieldset')}
                     className="b-meta__og-title"
                     value={meta.ogTitle}
                     blur={val => handleChange('ogTitle', val)}
                     showLabel
          />
        </InputGroup>
        <InputGroup>
          <TextInput title={fbt('Open Graph Description', 'MetaFieldset')}
                     className="b-meta__og-description"
                     value={meta.ogDescription}
                     blur={val => handleChange('ogDescription', val)}
                     showLabel
          />
        </InputGroup>
        <InputGroup>
          <TextInput title={fbt('Open Graph Image', 'MetaFieldset')}
                     className="b-meta__og-image"
                     value={meta.ogImage}
                     blur={val => handleChange('ogImage', val)}
                     showLabel
          />
        </InputGroup>
        <InputGroup>
          <TextInput title={fbt('Open Graph URL', 'MetaFieldset')}
                     className="b-meta__og-url"
                     value={meta.ogUrl}
                     blur={val => handleChange('ogUrl', val)}
                     showLabel
          />
          <HelpText>
            <fbt desc="MetaFieldset">
              The og:url tag is automatically generated when you set a global website canonical URLs.
            </fbt>
          </HelpText>
        </InputGroup>

        <InputGroup>
          <TextInput title={fbt('Open Graph Site Name', 'Open Graph Site Name')}
                     className="b-meta__og-site-name"
                     value={meta.ogSiteName}
                     blur={val => handleChange('ogSiteName', val)}
                     showLabel
          />
        </InputGroup>

        <InputGroup>
          <TextInput title={fbt('Open Graph Type', 'Open Graph Site Name')}
                     className="b-meta__og-type"
                     value={meta.ogType}
                     blur={val => handleChange('ogType', val)}
                     showLabel
          />
        </InputGroup>
      </Fieldset>

      <Fieldset title={fbt('Twitter Card', 'MetaFieldset')}>
        <HelpText style={{'--m': '10px 0'}}>
          <fbt desc="MetaFieldset">
            See <a href="https://developer.twitter.com/en/docs/twitter-for-websites/cards/guides/getting-started"
                   rel="noreferrer noopener nofollow" target="_blank">twitter card documentation</a>.
          </fbt>
        </HelpText>

        <InputGroup>
          <TextInput title={fbt('Twitter Card', 'MetaFieldset')}
                     className="b-meta__twitter-card"
                     placeholder={fbt('summary, summary_large_image etc.', 'MetaFieldset')}
                     value={meta.twitterCard}
                     blur={val => handleChange('twitterCard', val)}
                     showLabel
          />
        </InputGroup>

        <InputGroup>
          <TextInput title={fbt('Twitter Title', 'MetaFieldset')}
                     className="b-meta__twitter-title"
                     placeholder={fbt('What is this page about? (Page title is fallback)', 'MetaFieldset')}
                     value={meta.twitterTitle}
                     blur={val => handleChange('twitterTitle', val)}
                     showLabel
          />
        </InputGroup>
        <InputGroup>
          <TextInput title={fbt('Twitter Image', 'MetaFieldset')}
                     className="b-meta__twitter-image"
                     value={meta.twitterImage}
                     placeholder={fbt('https://mame.app/img/mame.png', 'MetaFieldset')}
                     blur={val => handleChange('twitterImage', val)}
                     showLabel
          />
        </InputGroup>
        <InputGroup>
          <TextInput title={fbt('Twitter Site', 'MetaFieldset')}
                     className="b-meta__twitter-site"
                     value={meta.twitterSite}
                     placeholder={fbt('@mame_builder', 'MetaFieldset')}
                     blur={val => handleChange('twitterSite', val)}
                     showLabel
          />
        </InputGroup>
      </Fieldset>
    </>
  );
};

const mapStateToProps = state => ({
  siteMeta: state.activeSite.site.meta,
  pageMeta: state.activeSite.page.meta,
});

export default connect(mapStateToProps)(MetaFieldset);
