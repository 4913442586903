import BaseService from './BaseService';
import axios from 'axios';

const paths = {
  get: '/section',
  post: '/section',
  patch: '/section',
  delete: '/section',
};

/**
 * Service for querying the Sites
 */
class SectionService extends BaseService {
  constructor() {
    super(paths);
    this.requireLogin = true;
  }

  /**
   * Delete request with the given item
   *
   * @param {string} _id - The mongoose id to delete
   * @param {string} pageId - The mongoose page of the section
   *
   * @returns {AxiosPromise} The axios request
   *
   * @example
   *
   * // Returns the axios request
   * await delete(_id);
   */
  delete = (_id, pageId = '') => axios({
    method: 'delete',
    url: this.getPath('delete'),
    data: JSON.stringify({_id, pageId}),
  });
}

export default new SectionService();
