import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import fbt from 'fbt';

import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { formatRelative } from 'date-fns';

import Dialog from './Dialog';
import ComingSoon from '../General/ComingSoon';
import UserProfile from '../User/UserProfile';
import UserInformation from '../User/UserInformation';
import SelectInput from '../Properties/Controls/SelectInput';
import CheckboxInput from '../Properties/Controls/CheckboxInput';
import { setSetting } from '../../store/actions/settings';
import { apiLoadUserSessions, apiRemoveSession } from '../../store/actions/sessions';
import HelpText from '../Properties/Controls/HelpText';
import logger from '../../modules/logger';
import { logoutUser } from '../../store/actions/user';

const SettingsModal = props => {
  const [selectedIndex, setSelectedIndex] = useState(props.payload ? props.payload.selectedIndex : 0);

  const {isVisible, payload} = props;

  useEffect(() => {
    setSelectedIndex(payload ? payload.selectedIndex : 0);
  }, [isVisible, payload]);

  if (!isVisible) {
    // Make sure to reset it
    return null;
  }

  const set = (key, value) => {
    props.dispatch(setSetting(key, value));
  };

  return (
    <Dialog title={fbt('Mame Settings', 'SettingsModal')} name="SettingsModal" size="modal-xl">
      <div className="modal-content" style={{'--radius': '0 0 5px 5px'}}>
        <Tabs forceRenderTabPanel
              selectedIndex={selectedIndex}
              onSelect={index => setSelectedIndex(index)}>
          <div style={{'--d': 'grid', '--gtc': '300px 1fr'}}>
            <div style={{'--br': '1px solid var(--mame-border)'}}>
              <TabList className="tabs-vertical">
                <Tab>
                  <span className="icon icon-cog" style={{'--mr': '10px'}} aria-hidden="true"/>
                  <fbt desc="SettingsModal">
                    General
                  </fbt>
                </Tab>
                <Tab>
                  <span className="icon icon-user-circle-o" style={{'--mr': '10px'}} aria-hidden="true"/>
                  <fbt desc="SettingsModal">
                    Profile
                  </fbt>
                </Tab>
                <Tab>
                  <span className="icon icon-credit-card-alt" style={{'--mr': '10px'}} aria-hidden="true"/>
                  <fbt desc="SettingsModal">
                    Personal Information
                  </fbt>
                </Tab>
                <Tab>
                  <span className="icon icon-user-circle-o" style={{'--mr': '10px'}} aria-hidden="true"/>
                  <fbt desc="SettingsModal">
                    Teams
                  </fbt>
                </Tab>
                <Tab>
                  <span className="icon icon-desktop" style={{'--mr': '10px'}} aria-hidden="true"/>
                  <fbt desc="SettingsModal">
                    Sessions
                  </fbt>
                </Tab>
              </TabList>
            </div>
            <div style={{'--p': '20px'}}>
              <TabPanel>
                <SelectInput title={fbt('Language', 'SettingsModal')}
                             value={props.settings.language}
                             showLabel
                             options={{
                               'en_US': fbt('English', 'SettingsModal'),
                               'de_DE': fbt('German', 'SettingsModal'),
                             }}
                             action={val => {
                               set('language', val);
                             }}
                />

                <SelectInput title={fbt('Theme', 'SettingsModal')}
                             value={props.settings.theme}
                             showLabel
                             options={{
                               'light': fbt('Light (Default)', 'SettingsModal'),
                               'dark': fbt('Dark (Experimental)', 'SettingsModal'),
                             }}
                             action={val => {
                               set('theme', val);
                             }}
                />

                <CheckboxInput title={fbt('Show Help and Tooltips', 'SettingsModal')}
                               checked={props.settings.showTooltips}
                               style={{'--mt': '15px'}}
                               action={val => {
                                 set('showTooltips', !props.settings.showTooltips);
                               }}
                />

                {/*<CheckboxInput title={fbt('Show Mame Tour', 'SettingsModal')}*/}
                {/*               checked={props.settings.showTour}*/}
                {/*               style={{'--mt': '15px'}}*/}
                {/*               action={val => {*/}
                {/*                 set('showTour', !props.settings.showTour);*/}
                {/*               }}*/}
                {/*/>*/}

                <h4 style={{'--mt': '25px', '--mb': '5px'}}>
                  <fbt desc="SettingsModal">
                    Builder View
                  </fbt>
                </h4>

                <SelectInput title={fbt('Sidebar Group Style', 'SettingsModal')}
                             value={props.settings.fieldsetType}
                             showLabel
                             options={{
                               'details': fbt('Accordions (Collapsible)', 'SettingsModal'),
                               'fieldset': fbt('Fieldsets (Not collapsible)', 'SettingsModal'),
                             }}
                             action={val => {
                               set('fieldsetType', val);
                             }}
                />

                <SelectInput title={fbt('Builder Sidebar position', 'SettingsModal')}
                             value={props.settings.sidebarPlacement}
                             showLabel
                             options={{'right': fbt('Right', 'SettingsModal'), 'left': fbt('Left', 'SettingsModal')}}
                             action={val => {
                               set('sidebarPlacement', val);
                             }}
                />

                <CheckboxInput title={fbt('Enable Breadcrumb in Builder', 'SettingsModal')}
                               checked={props.settings.showBreadcrumb}
                               style={{'--mt': '15px'}}
                               action={val => {
                                 set('showBreadcrumb', !props.settings.showBreadcrumb);
                               }}
                />

              </TabPanel>
              <TabPanel>
                <UserProfile/>
              </TabPanel>
              <TabPanel>
                <UserInformation/>
              </TabPanel>
              <TabPanel>
                <ComingSoon/>
              </TabPanel>
              <TabPanel>
                {selectedIndex === 4 && <SessionTab sessions={props.sessions} token={props.token}/>}
              </TabPanel>
            </div>
          </div>
        </Tabs>
      </div>
    </Dialog>
  );
};

const SessionTab = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(apiLoadUserSessions());
  }, [dispatch]);

  const deleteSession = (e, item) => {
    e.preventDefault();

    dispatch(apiRemoveSession(item._id));

    if (props.token === item.token) {
      logger.info('Destroying current Session, redirecting to Login');
      dispatch(logoutUser());
    }
  };

  return (
    <div>
      <HelpText>
        <fbt desc="SettingsSessionTab">
          These are your active Mame sessions. During the Beta they are not automatically cleaned. If you notice any
          unknown clients or addresses, please change your password immediately.
        </fbt>
      </HelpText>

      {props.sessions.map(item => {
        return (
          <div key={item.token} style={{
            '--d': 'grid',
            '--gg': '15px',
            '--gtc': '1fr 1fr 1fr 1fr',
            '--ta': 'center',
            '--ai': 'center'
          }}>
            <div style={{'--ta': 'left'}}>
              {props.token === item.token && (
                <strong>
                  <fbt desc="SettingsSessionTab">
                    Current Session
                  </fbt>
                </strong>
              )}
            </div>
            <div>
              Mame Web ({item.app_version})
              <br/>
              {item.ip}
            </div>
            <div>
              {formatRelative(new Date(item.created_at), new Date())}
            </div>
            <div>
              <button className="mame-primary" onClick={e => deleteSession(e, item)}>
                <fbt desc="SettingsSessionTab">
                  Destroy
                </fbt>
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const mapStateToProps = state => ({
  settings: state.settings,
  isVisible: state.modals.SettingsModal,
  payload: state.modals.payload.SettingsModal,
  sessions: state.sessions.sessions,
  token: state.user.token,
});

export default connect(mapStateToProps)(SettingsModal);
