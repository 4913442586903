import React from 'react';
import fbt from 'fbt';
import Select from 'react-select';
import { connect } from 'react-redux';

import ColorPicker from './ColorPicker';
import { ElementColorClasses } from '../../../modules/consts/Elements';
import ActionIcon from '../../General/ActionIcon';
import InputGroup from '../InputGroup';

const AdvancedColorPicker = props => {
  let valueIsCustomColor = true;
  let valueIsClass = false;

  let hasCustom = props.hasOwnProperty('hasCustom') ? props.custom : true;

  if (ElementColorClasses.includes(props.value)) {
    valueIsClass = true;
  }

  if (valueIsClass || props.value === '') {
    valueIsCustomColor = false;
  }

  const dot = (color = '#fff') => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: 'block',
      marginRight: 8,
      height: 10,
      width: 10,
    },
  });

  const getColor = key => {
    if (props.page.colors[key]) {
      return props.page.colors[key];
    }

    return props.site.colors[key];
  };

  const colorStyles = {
    control: styles => ({...styles, backgroundColor: 'white'}),
    input: styles => ({...styles, ...dot()}),
    placeholder: styles => ({...styles, ...dot()}),
    singleValue: (styles, {data}) => ({...styles, ...dot(data.color)}),
    option: (styles, {data}) => ({...styles, ...dot(data.color)}),
  };

  const options = [];

  if (props.value === '') {
    options.push({
      value: '',
      label: fbt('None (Inherit)', 'AdvancedColorPicker'),
      color: '#fff',
    });
  }

  if (props.value !== '' && hasCustom && !valueIsClass) {
    options.push({
      value: props.value,
      label: fbt('Custom', 'AdvancedColorPicker'),
      color: props.value,
    });
  } else if (props.value !== '' && hasCustom && valueIsClass) {
    options.push({
      value: '#f1f1f1',
      label: fbt('Custom', 'AdvancedColorPicker'),
      color: '#fff',
    });
  } else if (props.value === '' && hasCustom) {
    options.push({
      value: '#f1f1f1',
      label: fbt('Custom', 'AdvancedColorPicker'),
      color: '#fff',
    });
  }

  options.push({
    value: 'primary',
    label: fbt('Primary', 'AdvancedColorPicker'),
    color: getColor('primaryColor'),
  });

  options.push({
    value: 'secondary',
    label: fbt('Secondary', 'AdvancedColorPicker'),
    color: getColor('secondaryColor'),
  });

  options.push({
    value: 'blue',
    label: fbt('Blue', 'AdvancedColorPicker'),
    color: getColor('blue'),
  });

  options.push({
    value: 'green',
    label: fbt('Green', 'AdvancedColorPicker'),
    color: getColor('green'),
  });

  options.push({
    value: 'orange',
    label: fbt('Orange', 'AdvancedColorPicker'),
    color: getColor('orange'),
  });

  options.push({
    value: 'red',
    label: fbt('Red', 'AdvancedColorPicker'),
    color: getColor('red'),
  });

  options.push({
    value: 'white',
    label: fbt('White', 'AdvancedColorPicker'),
    color: getColor('white'),
  });

  options.push({
    value: 'grey',
    label: fbt('Grey', 'AdvancedColorPicker'),
    color: getColor('grey'),
  });

  options.push({
    value: 'darkGrey',
    label: fbt('Dark Grey', 'AdvancedColorPicker'),
    color: getColor('darkGrey'),
  });

  options.push({
    value: 'black',
    label: fbt('Black', 'AdvancedColorPicker'),
    color: getColor('black'),
  });

  const value = options.find(i => i.value === props.value);

  return (
    <>
      <InputGroup>
        <label>
          {props.title}
          {props.actionReset && props.value !== '' && (
            <ActionIcon icon="times"
                        title={fbt('Reset to default', 'AdvancedColorPicker')}
                        action={props.actionReset}
            />
          )}
          <Select options={options}
                  className="builder-select"
                  value={value}
                  onChange={result => {
                    props.action(result.value);
                  }}
                  styles={colorStyles}
                  isSearchable
          />
        </label>
      </InputGroup>

      {valueIsCustomColor && (
        <>
          <InputGroup>
            <ColorPicker title={fbt('Custom Color', 'AdvancedColorPicker')}
                         value={props.value}
                         action={val => props.action(val)}
                         showLabel
            />
          </InputGroup>
          {props.hasOwnProperty('valueText') && (
            <InputGroup>
              <ColorPicker title={fbt('Text Color', 'AdvancedColorPicker')}
                           value={props.valueText}
                           action={val => props.actionText(val)}
                           showLabel
              />
            </InputGroup>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  site: state.activeSite.site,
  page: state.activeSite.page,
});

export default connect(mapStateToProps)(AdvancedColorPicker);
