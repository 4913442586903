import React from 'react';
import propertyHelper from '../../modules/propertyHelper';

const VideoBackground = (props) => {
  const {section} = props;
  const p = section.generalProperties;

  if (p.backgroundType !== 'video') {
    return (
      <>
        {props.children}
      </>
    );
  }

  return (
    <>
      <video className="background-video"
             src={propertyHelper.getImagePath(p.backgroundVideo)}
             autoPlay={p.backgroundVideoAutoPlay}
             loop={p.backgroundVideoLoop}
             muted={true}
             controls={false}/>
      <div className="background-video-content">
        {props.children}
      </div>
    </>
  );
};

export default VideoBackground;
