import React, { useState } from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';
import { toast, ToastContainer, Zoom } from 'react-toastify';

import SiteMenu from './SiteMenu';
import UserMenu from './UserMenu';
import ActionIconButton from '../General/ActionIconButton';
import { apiUpdatePublishedSite } from '../../store/actions/activeSite';
import Dropdown from '../General/Dropdown';
import { showModal } from '../../store/actions/modals';
import serviceHelper from '../../modules/serviceHelper';
import { apiLoadSiteTemplates, siteToTemplate } from '../../store/actions/templates';
import { isPreviewSelector } from '../../store/selectors';
import NotificationMenu from './NotificationMenu';

const TopMenu = props => {
  const [isSticky, setIsSticky] = useState(!props.isPreview);

  const style = {};

  if (isSticky) {
    style['--h'] = '60px';
  }

  const iconSize = '130';
  const iconStyle = {'--c': 'var(--mame-dark-grey)', '--w': '28px', '--ta': 'center'};

  return (
    <>
      <div className="top-menu-spacer hidden-sm" style={style || null}>
        <div className="top-menu hidden-sm" style={style || null}>
          <img src="/img/mame.webp" alt="Mame Website Builder" style={{'--h': '36px', '--mr': '15px'}}/>
          <SiteMenu/>
          {process.env.NODE_ENV === 'development' && (
            <div className="tag grey" style={{'--mr': '20px', '--d': 'none'}}>
              Development Mode
            </div>
          )}
          <div style={{'--d': 'flex', '--jc': 'center', '--ai': 'center', '--fg': 1}}>
            <CenterMenu {...props}/>
          </div>
          <div style={{'--d': 'inline-flex', '--ai': 'center', '--jc': 'flex-end'}}>
            <div style={{
              '--mx': '15px',
              '--d': 'flex',
              '--gg': '8px'
            }}>
              <ActionIconButton size="90"
                                icon="thumb-tack"
                                id="btn-toggle-sticky-top"
                                style={{'--w': '28px', '--ta': 'center'}}
                                title={fbt('Sticky Top Menu', 'Top Menu')}
                                active={isSticky}
                                action={() => setIsSticky(!isSticky)}
              />

              <NotificationMenu iconStyle={iconStyle}
                                iconSize={iconSize}
              />

              {props.site && (
                <ActionIconButton icon="save"
                                  size={iconSize}
                                  style={iconStyle}
                                  title={fbt('Save Site as Template', 'Top Menu')}
                                  action={() => {
                                    props.dispatch(showModal('SaveTemplateModal', {type: 'site', item: props.site}));
                                  }}
                />
              )}

              {process.env.NODE_ENV === 'development' && (
                <>
                  <ActionIconButton icon="cloud-download"
                                    size={iconSize}
                                    title={fbt('Save Site as System', 'TopMenu')}
                                    style={{'--c': 'darkred'}}
                                    action={async () => {
                                      console.log('Saving as system template');

                                      const template = siteToTemplate(props.site, props.pages, props.sections, props.elements);
                                      template.kind = 'system';
                                      template.fromTemplateId = null;
                                      template.title = props.site.title;
                                      template.img = `/img/site/${props.site.title}.webp`;

                                      const service = serviceHelper.getService('SiteTemplateService');
                                      await service.store(template);

                                      props.dispatch(apiLoadSiteTemplates());
                                    }}
                  />

                  <ActionIconButton icon="repeat"
                                    size={iconSize}
                                    title={fbt('Update System Template (Site)', 'TopMenu')}
                                    style={{'--c': 'darkred'}}
                                    action={async () => {
                                      console.log('Updating system template');

                                      if (!props.site.fromTemplateId) {
                                        console.warn('No Template Id');
                                        return;
                                      }

                                      const template = siteToTemplate(props.site, props.pages, props.sections, props.elements);

                                      template.kind = 'system';
                                      template._id = props.site.fromTemplateId;

                                      const service = serviceHelper.getService('SiteTemplateService');
                                      await service.store(template);

                                      props.dispatch(apiLoadSiteTemplates());
                                    }}
                  />
                </>
              )}

              <HelpMenu iconStyle={iconStyle} iconSize={iconSize}/>

              <ActionIconButton icon="cog"
                                size={iconSize}
                                style={iconStyle}
                                title={fbt('Settings', 'Top Menu')}
                                action={() => {
                                  props.dispatch(showModal('SettingsModal', {selectedIndex: 0}));
                                }}
              />
            </div>
            <UserMenu/>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        pauseOnFocusLoss={false}
        transition={Zoom}
      />
    </>
  );
};

const HelpMenu = props => {
  return (
    <Dropdown
      placement="bottom-end"
      width="250px"
      menu={<ActionIconButton icon="question"
                              size={props.iconSize}
                              style={props.iconStyle}
                              title={fbt('Help', 'Top Menu')}
      />}
      content={<>
        <h5>
          <fbt desc="Help">
            Mame Help
          </fbt>
        </h5>

        <div className="menu-entry">
          <a href="https://mame.app/help" target="_blank" rel="noreferrer noopener">
            <fbt desc="Help Center Link">
              Help Center
            </fbt>
          </a>
        </div>
        <div className="menu-entry">
          <a href="https://mame.app/help/getting-started" target="_blank" rel="noreferrer noopener">
            <fbt desc="Help Getting Started Link">
              Getting Started Guide
            </fbt>
          </a>
        </div>
        <div className="menu-entry">
          <a href="https://mame.app/help/faq" target="_blank" rel="noreferrer noopener">
            <fbt desc="Help FAQ Link">
              Frequently Asked Questions
            </fbt>
          </a>
        </div>
        <div className="menu-entry">
          <a href="https://mame.app/blog" target="_blank" rel="noreferrer noopener">
            <fbt desc="Help Whats new">
              What's new?
            </fbt>
          </a>
        </div>
        <div className="menu-entry">
          <a href="https://mame.app/help/roadmap" target="_blank" rel="noreferrer noopener">
            <fbt desc="Help Roadmap">
              Mame Roadmap
            </fbt>
          </a>
        </div>
        <h5 style={{'--mt': '30px'}}>Getting in Touch</h5>
        <div className="menu-entry">
          <a href="https://mame-pagebuilder.atlassian.net/servicedesk/customer/portal/3" target="_blank"
             rel="noreferrer noopener">
            <fbt desc="Help Open Ticket">
              Open a Ticket
            </fbt>
          </a>
        </div>
      </>}
    />
  );
};

const CenterMenu = props => {
  if (!props.site) {
    return null;
  }

  const {site} = props;

  const previewLink = `/preview/${site._id}/${props.page ? props.page.alias : ''}`;

  const isPublished = !!site.hosting.deployType;

  const handleDownloadClick = e => {
    e.preventDefault();
    const service = serviceHelper.getService('SiteService');
    service.download(site._id).then(res => {
      const downloadLink = res.data.download;
      const link = `https://api.mame.app/${downloadLink}`;

      toast(
        <>
          <fbt desc="TopMenu">
            Your website download is ready. <fbt:param name="link"><a href={link} rel="noopener noreferrer"
                                                                      target="_blank">Download now</a>.</fbt:param>
          </fbt>
        </>
      );
    }).catch(err => {
      toast(
        <>
          <fbt desc="TopMenu">
            Error downloading website.
          </fbt>
          <br/>
          {JSON.stringify(err)}
        </>
      );
    });

    toast(fbt('Your download is now being prepared. You are notified once it\'s ready.', 'Download Ready Toast'));
  };

  const handleUpdatePublishedClick = e => {
    e.preventDefault();
    props.dispatch(apiUpdatePublishedSite(site));
    toast(<>
      <fbt desc="Your site is updating Toast">
        Your <a href={`https://${site.hosting.subDomain}.mame.app`} rel="noopener noreferrer" target="_blank">live
        site</a> is now being updated.
      </fbt>
    </>);
  };

  const buttonStyle = {
    '--x': 90,
    '--shadow': 'none',
  };

  const buttonClass = 'button mame-white';

  return (
    <div>
      {!props.isPreview && (
        <a className={buttonClass} href={previewLink} style={buttonStyle}
           title={fbt('Preview the current site (Not optimized for downloading)', 'Preview Link')}
           rel="noopener noreferrer" target="_blank">
          <fbt desc="TopMenu">
            Preview
          </fbt>
        </a>
      )}

      {false && (
        <a className={buttonClass} href={previewLink} style={{'--x': 90}}
           rel="noopener noreferrer" target="_blank">
          <fbt desc="Offer on Store Button">
            Offer on Store
          </fbt>
        </a>
      )}

      {!isPublished && (
        <button id="btn-publish-site" className={buttonClass} style={buttonStyle} onClick={() => {
          props.dispatch(showModal('PublishModal'));
        }}>
          <fbt desc="Top Menu Publish Button">
            Publish
          </fbt>
        </button>
      )}

      {isPublished && (
        <button id="btn-update-site" className={buttonClass} style={buttonStyle}
                onClick={handleUpdatePublishedClick}>
          <fbt desc="Top Menu Update Button">
            Update live site
          </fbt>
        </button>
      )}

      {previewLink && (
        <button id="btn-download-site" className={buttonClass} style={buttonStyle}
                onClick={handleDownloadClick}>
          <fbt desc="Top Menu Download Button">
            Download
          </fbt>
        </button>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  isPreview: isPreviewSelector(state),
  site: state.activeSite.site,
  page: state.activeSite.page,
  pages: state.activeSite.pages,
  sections: state.activeSite.sections,
  elements: state.activeSite.elements,
  user: state.user.user,
});

export default connect(mapStateToProps)(TopMenu);
