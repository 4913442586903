import React from 'react';

const ActionIcon = (props) => {
  let iconClass = `icon icon-${props.icon}`;

  if (props.size) {
    iconClass += ` icon-${props.size}x`;
  }

  return (
    <span className={iconClass}
          ref={props.actionRef || null}
          style={{
            ...props.style,
            '--px': '7px',
            '--cur': props.cursor || 'pointer',
            '--size': `${props.size || 100}%`
          }}
          title={props.title || ''}
          onClick={props.action || null}
    />
  );
};

export default ActionIcon;
