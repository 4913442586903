import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import apiStore from './middleware/apiStore';

const composeEnhancers =
  process.env.NODE_ENV !== "production" ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;

export default createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk, apiStore)),
);
