import propertyHelper from './propertyHelper';

import * as models from '../models/Elements/';
import { ElementColorClasses } from './consts/Elements';

const elementHelper = {
  getId(element, prefix = 'e-') {
    if (!element._id) {
      return null;
    }

    return element.generalProperties.customId || null;
  },

  getClasses(element, additionalClasses = null, baseClass = true) {
    const p = element.generalProperties;
    let classes = baseClass ? ['mame-element'] : [];

    if (p.customClasses) {
      classes.push(p.customClasses);
    }

    if (additionalClasses) {
      classes.push(additionalClasses);
    }

    propertyHelper.setVisibility(classes, p);

    if (element.generalProperties.animation) {
      classes.push('animate');
      classes.push(`animate-${element.generalProperties.animation}`);
    }

    return classes.join(' ');
  },

  getStyle(element) {
    const p = element.generalProperties;
    let style = {};

    //General Style
    propertyHelper.setBackground(style, p);

    // Spacing
    propertyHelper.setSpacing(style, p.padding);
    propertyHelper.setSpacing(style, p.margin, false);

    // Font
    propertyHelper.setTextAlignment(style, p);
    propertyHelper.setFontSize(style, p);
    propertyHelper.setTextAlign(style, p);
    propertyHelper.setTextColor(style, p);
    propertyHelper.setText3d(style, p);
    propertyHelper.setLineClamp(style, p);

    // Border + Shadow
    propertyHelper.setBorder(style, p);
    propertyHelper.setBorderRadius(style, p);
    propertyHelper.setShadow(style, p);
    propertyHelper.setLevitate(style, p);

    // Dimensions
    propertyHelper.setDisplay(style, p);
    propertyHelper.setPosition(style, p);
    propertyHelper.setDimensions(style, p);

    // Effects
    propertyHelper.setHoverEffect(style, p);
    propertyHelper.setTransform(style, p);

    if (p.css) {
      // TODO migrate to JSX
      // style += p.css;
    }

    return style;
  },

  getElementModel(component) {
    // TODO automate this ['Base' + 'Model']
    const classes = {
      GridElement: models.GridModel,
      TextElement: models.TextModel,
      ImageFigureElement: models.ImageFigureModel,
      ButtonsElement: models.ButtonsModel,
      ImageElement: models.ImageModel,
      IconElement: models.IconModel,
      ImageOverlayElement: models.ImageOverlayModel,
      MenuElement: models.MenuModel,
      LogoElement: models.LogoModel,
      VideoElement: models.VideoModel,
      SeparatorElement: models.SeparatorModel,
      AccordionElement: models.AccordionModel,
      CountdownElement: models.CountdownModel,
      MapElement: models.MapModel,
      PricingTableElement: models.PricingTableModel,
      SlideshowElement: models.SlideshowModel,
      TabsElement: models.TabsModel,
      YoutubeElement: models.YoutubeModel,
      FormElement: models.FormModel,
      InputElement: models.InputModel,
      // CaptchaElement: models.CaptchaModel,
      RangeElement: models.RangeModel,
      SelectElement: models.SelectModel,
      TextareaElement: models.TextareaModel,
      CheckboxElement: models.CheckboxModel,
      IconFigureElement: models.IconFigureModel,
      GalleryElement: models.GalleryModel,
      TestimonialElement: models.TestimonialModel,
      EmbedHtmlElement: models.EmbedHtmlModel,
      BreadcrumbElement: models.BreadcrumbModel,
      FigureElement: models.FigureModel,
      ColumnElement: models.ColumnModel,
      PayPalDonateElement: models.PayPalDonateModel,
      DisqusElement: models.DisqusModel,
      QuizElement: models.QuizModel,
      TypedTextElement: models.TypedTextModel,
    };

    return classes[component];
  },

  /**
   * Useless method
   *
   * @deprecated use it directly or the selector
   * @param elements
   * @param _id
   * @return {*}
   */
  getElementForId(elements, _id) {
    return elements.find(e => e._id === _id);
  },

  getColorPickerPopperOptions(preview = false) {
    // Fix for early hooks
    if (preview) {
      return {};
    }

    return {
      placement: 'top-end',
      strategy: 'fixed',
      modifiers: [
        {
          name: 'preventOverflow',
          enabled: true,
          options: {
            boundary: document.getElementById('site-content')
          },
        },
        {
          name: 'offset',
          options: {
            offset: [-35, -15],
          }
        },
      ],
    };
  },

  getDataAttributes(element) {
    const attr = {};

    if (element.generalProperties.animation) {
      attr['data-animation'] = element.generalProperties.animation;
    }

    return attr;
  },

  getColor(color) {
    if (ElementColorClasses.includes(color)) {
      return `var(--${color})`;
    }

    return color;
  },
};

export default elementHelper;
