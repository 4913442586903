import SeparatorProperties from '../../components/Properties/Elements/SeparatorProperties';
import { ElementVisible } from '../../modules/consts/Elements';

const SeparatorModel = {
  title: 'Separator',
  image: '/img/elements/separator.webp',
  helpPage: '/documentation/elements/separator',
  category: 'Basic',
  component: 'SeparatorElement',
  propertyComponent: SeparatorProperties,
  hasChildElements: false,
  generalProperties: {
    hasVisibility: true,
    hasTextAlign: false,
    hasTextColor: false,
    hasBackground: true,
  },
  defaults: {
    demo: true,
    _id: null,
    identifier: 'New Separator',
    component: 'SeparatorElement',
    componentProperties: {
      color: 'grey',
      height: 1,
      style: 'solid',
    },
    generalProperties: {
      visibility: [ElementVisible.sm, ElementVisible.md, ElementVisible.lg, ElementVisible.xl],
      published: true,
      margin: [0, 0, 0, 0],
      padding: [15, 0, 15, 0],
    },
  },
};

export default SeparatorModel;
