import serviceHelper from '../../modules/serviceHelper';
import {
  ADD_PAGE_TEMPLATE,
  ADD_SECTION_TEMPLATE,
  ADD_SITE_TEMPLATE,
  REMOVE_PAGE_TEMPLATE,
  REMOVE_SECTION_TEMPLATE,
  REMOVE_SITE_TEMPLATE,
  SET_PAGE_TEMPLATES,
  SET_SECTION_TEMPLATES,
  SET_SITE_TEMPLATES,
  UPDATE_PAGE_TEMPLATE,
  UPDATE_SECTION_TEMPLATE,
  UPDATE_SITE_TEMPLATE,
} from '../actionTypes';


/* Helpers */
/**
 * Store a section as Template
 *
 * @param {Array} elements
 * @param {object} section
 * @return {*&{allElements: *[], elements: *[], sectionElements: *[]}}
 */
export const sectionToTemplate = (elements, section) => {
  const sectionElements = [];
  const allElements = [];

  let counter = 0;

  const recursiveElements = (elementId, type = 'element') => {
    const element = elements.find(e => e._id === elementId);
    const elementElements = [];
    const elementItems = [];

    // Spread {...} issue with immer immutable
    const template = JSON.parse(JSON.stringify(element));

    if (element.elements && element.elements.length) {
      element.elements.forEach(childElement => {
        const index = recursiveElements(childElement);
        elementElements.push(index);
      });
    }

    if (element.componentProperties.items
      && element.componentProperties.items.length
      && element.componentProperties.items[0].hasOwnProperty('elements')) {
      // Tabs, Accordions etc.
      let tabIndex = 0;

      // Clear it for saving
      template.componentProperties.items.forEach(item => {
        item.elements = [];
      });

      for (const subItem of element.componentProperties.items) {
        for (const subId of subItem.elements) {
          const index = recursiveElements(subId, 'item');
          elementItems.push({
            tab: tabIndex,
            element: index,
          });
        }

        tabIndex++;
      }
    }

    const elementIndex = counter;
    template.index = elementIndex;
    template.elements = [];
    template.elementElements = elementElements;
    template.elementItems = elementItems;

    delete template.user;
    delete template.created_at;
    delete template.updated_at;
    delete template.__v;
    delete template._id;
    delete template.site;

    allElements.push(template);
    counter++;
    return elementIndex;
  };

  section.elements.forEach(elementId => {
    const index = recursiveElements(elementId);
    sectionElements.push(index);
  });

  const sectionTemplate = {
    ...section,
    elements: [],
    sectionElements,
    allElements,
  };

  delete sectionTemplate._id;
  delete sectionTemplate.user;
  delete sectionTemplate.created_at;
  delete sectionTemplate.updated_at;
  delete sectionTemplate.__v;
  delete sectionTemplate.site;

  return sectionTemplate;
};

/**
 * Store a page as Template
 *
 * @param {Object} page
 * @param {Array} sections
 * @param {Array} elements
 * @return {*&{allSections: *[], sections: *[]}}
 */
export const pageToTemplate = (page, sections, elements) => {
  const allSections = [];

  // We shouldn't need an index, as we are using the page ordering
  page.sections.forEach(sectionId => {
    const section = sections.find(s => s._id === sectionId);

    const sectionTemplate = sectionToTemplate(elements, section);

    allSections.push(sectionTemplate);
  });

  const pageTemplate = {
    ...page,
    sections: [],
    allSections,
  };

  delete pageTemplate._id;
  delete pageTemplate.user;
  delete pageTemplate.created_at;
  delete pageTemplate.updated_at;
  delete pageTemplate.__v;
  delete pageTemplate.site;

  // TODO: Think on alias handling here

  return pageTemplate;
};

/**
 * Store a website as template
 *
 * @param {Object} site
 * @param {Array} pages
 * @param {Array} sections
 * @param {Array} elements
 * @return {*&{allPages: *[], pages: *[], hosting: {subDomain: string, domains: *[], deployType: string}, elements: *[], sections: *[]}}
 */
export const siteToTemplate = (site, pages, sections, elements) => {
  const allPages = [];

  site.pages.forEach(pageId => {
    const page = pages.find(p => p._id === pageId);

    if (!page) {
      return;
    }

    allPages.push(pageToTemplate(page, sections, elements));
  });

  const siteTemplate = {
    ...site,
    hosting: {
      deployType: '',
      subDomain: '',
      domains: [],
    },
    pages: [],
    sections: [],
    elements: [],
    allPages,
  };

  delete siteTemplate._id;
  delete siteTemplate.user;
  delete siteTemplate.created_at;
  delete siteTemplate.updated_at;
  delete siteTemplate.__v;

  return siteTemplate;
};
/* Store */

/**
 * API store a section template
 *
 * @param {Object} section
 * @return {(function(*, *): Promise<void>)|*}
 */
export const apiAddSectionTemplate = section => (
  async (dispatch, getState) => {
    // We need two different methods for updating and adding templates in this case
    const service = serviceHelper.getService('SectionTemplateService');

    const template = sectionToTemplate(getState().activeSite.elements, section);

    const res = await service.store(template);
    const item = res.data.item;

    // Screenshot time
    dispatch(apiCaptureSectionTemplate(section, item));
    dispatch(addSectionTemplate(item));
  }
);

export const apiAddPageTemplate = page => (
  async (dispatch, getState) => {
    // We need two different methods for updating and adding templates in this case
    const service = serviceHelper.getService('PageTemplateService');

    const template = pageToTemplate(page, getState().activeSite.sections, getState().activeSite.elements);

    const res = await service.store(template);
    const item = res.data.item;

    // Screenshot time
    dispatch(apiCapturePageTemplate(page, item));
    dispatch(addPageTemplate(item));
  }
);

export const apiAddSiteTemplate = site => (
  async (dispatch, getState) => {
    // We need two different methods for updating and adding templates in this case
    const service = serviceHelper.getService('SiteTemplateService');

    const template = siteToTemplate(
      site,
      getState().activeSite.pages,
      getState().activeSite.sections,
      getState().activeSite.elements
    );

    const res = await service.store(template);
    const item = res.data.item;

    // Screenshot time
    dispatch(apiCaptureSiteTemplate(site, item));
    dispatch(addSiteTemplate(item));
  }
);

export const apiUpdateSectionTemplate = (section, templateId) => (
  async (dispatch, getState) => {
    // Triggered by apiStore, don't dispatch
    const service = serviceHelper.getService('SectionTemplateService');

    const template = sectionToTemplate(getState().activeSite.elements, section);
    template._id = templateId;

    const res = await service.store(template);
    const item = res.data.item;

    dispatch(apiCaptureSectionTemplate(section, item));
    dispatch(updateSectionTemplate(item));
  }
);

export const apiUpdatePageTemplate = (page, templateId) => (
  async (dispatch, getState) => {
    // Triggered by apiStore, don't dispatch
    const service = serviceHelper.getService('PageTemplateService');

    const template = pageToTemplate(page, getState().activeSite.sections, getState().activeSite.elements);
    template._id = templateId;

    const res = await service.store(template);
    const item = res.data.item;

    dispatch(apiCapturePageTemplate(page, item));
    dispatch(updatePageTemplate(item));
  }
);

export const apiUpdateSiteTemplate = (site, templateId) => (
  async (dispatch, getState) => {
    // Triggered by apiStore, don't dispatch
    const service = serviceHelper.getService('SiteTemplateService');

    const template = siteToTemplate(
      site,
      getState().activeSite.pages,
      getState().activeSite.sections,
      getState().activeSite.elements
    );

    template._id = templateId;

    const res = await service.store(template);
    const item = res.data.item;

    dispatch(apiCaptureSiteTemplate(site, item));
    dispatch(updateSiteTemplate(item));
  }
);

/* Screenshots */

export const apiCaptureSectionTemplate = (section, template) => (
  async () => {
    const service = serviceHelper.getService('CaptureService');

    const sectionId = section._id;
    const htmlId = section.generalProperties.customId || `s-${section._id}`;

    service.takeSectionTemplateScreenshot(section.site, template._id, sectionId, htmlId);
  }
);

export const apiCapturePageTemplate = (page, template) => (
  async () => {
    const service = serviceHelper.getService('CaptureService');

    service.takePageTemplateScreenshot(page.site, page._id, template._id);
  }
);

export const apiCaptureSiteTemplate = (site, template) => (
  async () => {
    const service = serviceHelper.getService('CaptureService');

    service.takeSiteTemplateScreenshot(site._id, template._id);
  }
);

/*
 * Loading / GET
 */
export const apiLoadSectionTemplates = () => (
  async dispatch => {
    const siteService = serviceHelper.getService('SectionTemplateService');

    const res = await siteService.get();

    await dispatch(setSectionTemplates(res.data.items));
  }
);

export const apiLoadPageTemplates = () => (
  async dispatch => {
    const siteService = serviceHelper.getService('PageTemplateService');

    const res = await siteService.get();

    await dispatch(setPageTemplates(res.data.items));
  }
);

export const apiLoadSiteTemplates = () => (
  async dispatch => {
    const siteService = serviceHelper.getService('SiteTemplateService');

    const res = await siteService.get();

    await dispatch(setSiteTemplates(res.data.items));
  }
);

/*
 *  Delete Template
 */
export const apiRemoveSectionTemplate = templateId => (
  async () => {
    // Triggered by apiStore, don't dispatch
    const service = serviceHelper.getService('SectionTemplateService');

    await service.delete(templateId);
  }
);

export const apiRemovePageTemplate = templateId => (
  async () => {
    // Triggered by apiStore, don't dispatch
    const service = serviceHelper.getService('PageTemplateService');

    await service.delete(templateId);
  }
);

export const apiRemoveSiteTemplate = templateId => (
  async () => {
    // Triggered by apiStore, don't dispatch
    const service = serviceHelper.getService('SiteTemplateService');

    await service.delete(templateId);
  }
);

/* Non-Async Actions */
export const addSectionTemplate = template => ({type: ADD_SECTION_TEMPLATE, payload: {template}});
export const updateSectionTemplate = template => ({type: UPDATE_SECTION_TEMPLATE, payload: {template}});
export const setSectionTemplates = sectionTemplates => ({type: SET_SECTION_TEMPLATES, payload: {sectionTemplates}});
export const removeSectionTemplate = templateId => ({type: REMOVE_SECTION_TEMPLATE, payload: {templateId}});

export const addPageTemplate = template => ({type: ADD_PAGE_TEMPLATE, payload: {template}});
export const updatePageTemplate = template => ({type: UPDATE_PAGE_TEMPLATE, payload: {template}});
export const setPageTemplates = pageTemplates => ({type: SET_PAGE_TEMPLATES, payload: {pageTemplates}});
export const removePageTemplate = templateId => ({type: REMOVE_PAGE_TEMPLATE, payload: {templateId}});

export const addSiteTemplate = template => ({type: ADD_SITE_TEMPLATE, payload: {template}});
export const updateSiteTemplate = template => ({type: UPDATE_SITE_TEMPLATE, payload: {template}});
export const setSiteTemplates = siteTemplates => ({type: SET_SITE_TEMPLATES, payload: {siteTemplates}});
export const removeSiteTemplate = templateId => ({type: REMOVE_SITE_TEMPLATE, payload: {templateId}});
