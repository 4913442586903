import React from 'react';

// Redux / Form
import { connect, useSelector } from 'react-redux';
import fbt from 'fbt';

// Tabs
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

// Property Controls
import VisibilitySelection from './Controls/VisibilitySelection';
import Spacing from './Controls/Spacing';

// Actions
import elementHelper from '../../modules/elementHelper';
import { ElementVisible } from '../../modules/consts/Elements';
import TextInput from './Controls/TextInput';
import CheckboxInput from './Controls/CheckboxInput';
import Fieldset from './FieldSet';
import AdvancedColorPicker from './Controls/AdvancedColorPicker';
import BorderFieldset from './Fieldsets/BorderFieldset';
import HoverFieldset from './Fieldsets/HoverFieldset';
import DisplayFieldset from './Fieldsets/DisplayFieldset';
import AnimationFieldset from './Fieldsets/AnimationFieldset';
import SelectInput from './Controls/SelectInput';
import GenericSlider from './Controls/GenericSlider';
import ComponentPropertiesHandler from './ComponentPropertiesHandler';
import { updateElementProperty } from '../../store/actions/activeSite';
import HelpText from './Controls/HelpText';
import BackgroundPicker from './Controls/BackgroundPicker';
import TransformFieldset from './Fieldsets/TransformFieldset';
import PositionFieldset from './Fieldsets/PositionFieldset';
import ElementChildrenOverview from './ElementChildrenOverview';
import { selectElementById } from '../../store/selectors';
import InputWithUnitPicker from './Controls/InputWithUnitPicker';
import InputGroup from './InputGroup';

/**
 * TODO Implement Element properties
 *
 * -[x] margin
 * -[x] padding
 * -[x] visibility
 * -[x] name (identifier)
 * -[x] component (Is it fixed)? Yeah - read-only
 * -[] published
 * -[x] custom classes
 * -[x] customId
 *
 * @param props
 * @returns {JSX.Element|string}
 * @constructor
 */
const ElementProperties = props => {
  // Remember, always reference the store, not even a referenced object
  const element = useSelector(state => selectElementById(state, props.activeElement));

  if (!props.activeElement) {
    return (
      <div style={{'--ta': 'center'}}>
        <h3>
          <fbt desc="ElementProperties">
            No element selected.
          </fbt>
        </h3>
        <p>
          <fbt desc="ElementProperties">
            You can select an element by clicking on it.
          </fbt>
        </p>
      </div>
    );
  }

  if (!element) {
    return (
      <h3>
        <fbt desc="ElementProperties">
          Element not found.
        </fbt>
      </h3>
    );
  }

  const model = elementHelper.getElementModel(element.component);

  const handleGeneralChange = (key, value, index = undefined) => {
    props.dispatch(updateElementProperty(element, 'generalProperties', key, value, index));
  };

  const handleSpacingChange = (index, value, padding = true) => {
    const key = padding ? 'padding' : 'margin';
    handleGeneralChange(key, value, index);
  };

  const handleVisibilityChange = (index) => {
    const allVisibilities = [ElementVisible.sm, ElementVisible.md, ElementVisible.lg, ElementVisible.xl];

    const newVis = [...element.generalProperties.visibility || allVisibilities];

    if (newVis.includes(index)) {
      newVis.splice(newVis.indexOf(index), 1);
    } else {
      newVis.push(index);
    }

    handleGeneralChange('visibility', newVis);
  };

  const handleTextChange = (key, value, category = 'generalProperties') => {
    props.dispatch(updateElementProperty(element, category, key, value));
  };

  const handleAnimationChange = value => {
    handleGeneralChange('animation', value);
    // TODO Just a hack for now, should be triggered on callback
    setTimeout(window.triggerAnimatedElements, 300);
  };

  return (
    <div className="property-editor">
      <Tabs forceRenderTabPanel={true}>
        <TabList>
          <Tab>
            <fbt desc="ElementProperties">
              General
            </fbt>
          </Tab>
          <Tab>
            <fbt desc="ElementProperties">
              Style
            </fbt>
          </Tab>
          <Tab>
            <fbt desc="ElementProperties">
              Advanced
            </fbt>
          </Tab>
          {model.hasChildElements && (
            <Tab>
              <fbt desc="ElementProperties">
                Elements
              </fbt>
            </Tab>
          )}
        </TabList>

        <TabPanel>
          <Fieldset title={fbt('Element Identifier', 'ElementProperties')} isOpen>
            <InputGroup style={{'--m': '10px 0 5px'}}>
              <TextInput title={fbt('Element Identifier', 'ElementProperties')}
                         tooltip={fbt('Internal identifier to easier find the element', 'ElementProperties')}
                         id="element-identifier"
                         inputStyle={{'--m': 0}}
                         value={element.identifier}
                         blur={val => handleTextChange('identifier', val, null)}
              />
            </InputGroup>
          </Fieldset>

          {model.propertyComponent && (
            <ComponentPropertiesHandler {...props} element={element}>
              {React.createElement(model.propertyComponent, {
                element,
                model: model,
              })}
            </ComponentPropertiesHandler>
          )}

          <Fieldset title={fbt('Visibility', 'ElementProperties')} isOpen>
            {model.generalProperties.hasVisibility && (
              <VisibilitySelection
                type="element"
                action={handleVisibilityChange}
                values={element.generalProperties.visibility}
              />
            )}

            <InputGroup>
              <CheckboxInput label={fbt('Published', 'ElementProperties')}
                             tooltip={fbt('Unpublished elements are not included in the published and downloaded site HTML.', 'ElementProperties')}
                             checked={element.generalProperties.published}
                             id="section-published"
                             action={() => handleTextChange('published', !element.generalProperties.published)}/>
            </InputGroup>
          </Fieldset>

          {model.generalProperties.hasBackground && (
            <BackgroundPicker title={fbt('Element Background', 'ElementProperties')}
                              hasAdvancedImage
                              hasNone
                              hideVideo
                              isOpen
                              type={element.generalProperties.backgroundType}
                              image={element.generalProperties.backgroundImage}
                              imageSize={element.generalProperties.backgroundImageSize}
                              imagePosition={element.generalProperties.backgroundImagePosition}
                              imageRepeat={element.generalProperties.backgroundImageRepeat}
                              color={element.generalProperties.backgroundColor}
                              gradientColor={element.generalProperties.backgroundGradientColor}
                              gradientDegree={element.generalProperties.backgroundGradientDegree}
                              pattern={element.generalProperties.backgroundPattern}
                              typeAction={val => {
                                handleTextChange('backgroundType', val);
                              }}
                              colorAction={val => {
                                handleTextChange('backgroundColor', val);
                              }}
                              imageAction={val => {
                                handleTextChange('backgroundImage', val);
                              }}
                              imageSizeAction={val => handleTextChange('backgroundImageSize', val)}
                              imagePositionAction={val => handleTextChange('backgroundImagePosition', val)}
                              imageRepeatAction={val => handleTextChange('backgroundImageRepeat', val)}
                              gradientColorAction={val => {
                                handleTextChange('backgroundGradientColor', val);
                              }}
                              gradientDegreeAction={val => {
                                handleTextChange('backgroundGradientDegree', val);
                              }}
                              patternAction={val => {
                                handleTextChange('backgroundPattern', val);
                              }}
            />
          )}

          <AnimationFieldset animation={element.generalProperties.animation}
                             animationAction={handleAnimationChange}
                             isOpen
          />
        </TabPanel>
        <TabPanel>
          <BorderFieldset
            borderType={element.generalProperties.borderType}
            border={element.generalProperties.border}
            borderColor={element.generalProperties.borderColor}
            borderWidth={element.generalProperties.borderWidth}
            levitate={element.generalProperties.levitate}
            borderRadius={element.generalProperties.borderRadius}
            shadow={element.generalProperties.shadow}
            shadowType={element.generalProperties.shadowType}
            borderTypeAction={val => handleGeneralChange('borderType', val)}
            borderAction={val => handleGeneralChange('border', val)}
            borderColorAction={val => handleGeneralChange('borderColor', val)}
            borderWidthAction={val => handleGeneralChange('borderWidth', val)}
            levitateAction={val => handleGeneralChange('levitate', val)}
            borderRadiusAction={val => handleGeneralChange('borderRadius', val)}
            shadowAction={val => handleGeneralChange('shadow', val)}
            shadowTypeAction={val => handleGeneralChange('shadowType', val)}
          />

          <Fieldset title={fbt('Spacing (Padding and Margin)', 'ElementProperties')}>
            <InputGroup style={{'--mt': '15px'}}>
              <Spacing min="0" max="300"
                       action={(index, value) => handleSpacingChange(index, value)}
                       values={element.generalProperties.padding}
                       category="Padding"
              />
            </InputGroup>

            <InputGroup>
              <Spacing min="-300" max="300"
                       action={(index, value) => handleSpacingChange(index, value, false)}
                       values={element.generalProperties.margin}
                       category="Margin"
              />
            </InputGroup>
          </Fieldset>

          {(model.generalProperties.hasTextAlign || model.generalProperties.hasTextColor) && (
            <Fieldset title={fbt('Text Format', 'ElementProperties')}>
              <InputGroup style={{'--mt': '10px'}}>
                <SelectInput id="element-text-align"
                             title={fbt('Text Align', 'ElementProperties')}
                             showLabel
                             value={element.generalProperties.textAlign}
                             action={val => {
                               handleTextChange('textAlign', val);
                             }}
                             options={{
                               '': fbt('Default (Inherited)', 'ElementProperties'),
                               'left': fbt('Left', 'ElementProperties'),
                               'center': fbt('Center', 'ElementProperties'),
                               'right': fbt('Right', 'ElementProperties'),
                             }}
                />
              </InputGroup>

              {!model.generalProperties.hideFont && (
                <>
                  <InputGroup>
                    <GenericSlider id="element-font-size"
                                   title={fbt('Font Size (Override)', 'ElementProperties')}
                                   showLabel
                                   min="6" max="100"
                                   value={element.generalProperties.fontSize}
                                   action={val => {
                                     handleTextChange('fontSize', val);
                                   }}
                                   hasReset
                    />
                  </InputGroup>

                  <InputGroup>
                    <GenericSlider id="element-font-weight"
                                   title={fbt('Font Weight (Override)', 'ElementProperties')}
                                   showLabel
                                   min="100" max="900" step="100"
                                   value={element.generalProperties.fontWeight}
                                   action={val => {
                                     handleTextChange('fontWeight', val);
                                   }}
                                   hasReset
                    />
                  </InputGroup>
                </>
              )}

              {model.generalProperties.hasTextColor && (
                <>
                  <AdvancedColorPicker title={fbt('Text Color (default)', 'ElementProperties')}
                                       value={element.generalProperties.color}
                                       action={val => {
                                         handleTextChange('color', val);
                                       }}
                                       actionReset={() => {
                                         handleTextChange('color', '');
                                       }}
                  />

                  <AdvancedColorPicker title={fbt('3D Text Color', 'ElementProperties')}
                                       value={element.generalProperties.text3d}
                                       action={val => handleTextChange('text3d', val)}
                                       actionReset={() => {
                                         handleTextChange('text3d', '');
                                       }}
                  />
                </>
              )}

              {!model.generalProperties.hideFont && (
                <GenericSlider title={fbt('Line Clamp', 'ElementProperties')}
                               min="0" max="20" step="1"
                               value={element.generalProperties.lineClamp || 0}
                               action={val => {
                                 handleTextChange('lineClamp', val);
                               }}
                               hasReset
                               defaultValue="0"
                               showLabel
                />
              )}
            </Fieldset>
          )}

          <HoverFieldset
            hover={element.generalProperties.hover}
            hoverType={element.generalProperties.hoverType}
            hoverColor={element.generalProperties.hoverColor}
            hoverAction={val => handleGeneralChange('hover', val)}
            hoverTypeAction={val => handleGeneralChange('hoverType', val)}
            hoverColorAction={val => handleGeneralChange('hoverColor', val)}
          />
        </TabPanel>
        <TabPanel>
          <Fieldset title={fbt('Customize HTML', 'ElementProperties')}>
            <InputGroup style={{'--mt': '8px'}}>
              <TextInput title={fbt('Custom Id', 'ElementProperties')}
                         value={element.generalProperties.customId}
                         blur={val => handleTextChange('customId', val)}
                         placeholder="great-element"
                         filter="[^A-Za-z0-9_-]"
                         showLabel
              />
            </InputGroup>
            <InputGroup>
              <TextInput title={fbt('Custom Classes (Space separated)', 'ElementProperties')}
                         value={element.generalProperties.customClasses}
                         blur={val => handleTextChange('customClasses', val)}
                         placeholder="my-class my-other-class"
                         filter="[^A-Za-z0-9 _-]"
                         showLabel
              />
            </InputGroup>
          </Fieldset>
          <Fieldset title={fbt('Custom Dimensions', 'ElementProperties')}>
            <InputGroup style={{'--mt': '8px'}}>
              <InputWithUnitPicker
                title={fbt('Fixed Width', 'ElementProperties')}
                value={element.generalProperties.width}
                action={val => handleTextChange('width', val)}
                isWidth
                showLabel
              />
            </InputGroup>

            <InputGroup>
              <InputWithUnitPicker
                title={fbt('Fixed Height', 'ElementProperties')}
                value={element.generalProperties.height}
                action={val => handleTextChange('height', val)}
                isHeight
                showLabel
              />
            </InputGroup>

            <InputGroup>
              <InputWithUnitPicker
                title={fbt('Maximum Width', 'ElementProperties')}
                value={element.generalProperties.maxWidth || ''}
                action={val => handleTextChange('maxWidth', val)}
                isWidth
                showLabel
              />
            </InputGroup>

            <InputGroup>
              <InputWithUnitPicker
                title={fbt('Maximum Height', 'ElementProperties')}
                value={element.generalProperties.maxHeight || ''}
                action={val => handleTextChange('maxHeight', val)}
                isHeight
                showLabel
              />
            </InputGroup>
          </Fieldset>

          {!model.generalProperties.hideDisplay && (
            <DisplayFieldset
              display={element.generalProperties.display}
              actionDisplay={val => handleTextChange('display', val)}
              alignItems={element.generalProperties.alignItems}
              actionAlignItems={val => handleTextChange('alignItems', val)}
              justifyContent={element.generalProperties.justifyContent}
              actionJustifyContent={val => handleTextChange('justifyContent', val)}
            />
          )}

          <TransformFieldset
            transform={element.generalProperties.transform}
            actionTransform={val => handleTextChange('transform', val)}
          />

          <PositionFieldset
            generalProperties={element.generalProperties}
            action={handleTextChange}
          />
        </TabPanel>
        {model.hasChildElements && (
          <TabPanel style={{'--p': '0 15px'}}>
            <HelpText style={{'--ta': 'center'}}>
              <fbt desc="ElementProperties">
                Overview of Child elements.
              </fbt>
            </HelpText>

            <ElementChildrenOverview {...props} element={element} model={model}/>
          </TabPanel>
        )}
      </Tabs>
    </div>
  );
};

const mapStateToProps = state => {
  const activeElement = state.builder.activeElement;
  const activeType = state.builder.activeType;

  return {activeElement, activeType};
};

export default connect(mapStateToProps)(ElementProperties);
