import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import fbt from 'fbt';

import config from '../../config';

import {
  systemPageTemplatesSelector,
  userPageTemplatesSelector,
} from '../../store/selectors';

import { apiAddTemplatePage } from '../../store/actions/activeSite';
import { confirmationDialog } from '../../store/actions/builder';
import { removePageTemplate } from '../../store/actions/templates';
import { hideModal } from '../../store/actions/modals';

import Dialog from './Dialog';
import ComingSoon from '../General/ComingSoon';
import PagePreview from '../PageControls/PagePreview';
import ThreeColumnGrid from '../General/ThreeColumnGrid';
import logger from '../../modules/logger';

const AddPageModal = props => {
  const {systemTemplates = [], userTemplates = [], isVisible = false} = props;

  const [searchTerm, setSearchTerm] = useState('');

  const [filteredSystemTemplates, setFilteredSystemTemplates] = useState(systemTemplates);
  const [filteredUserTemplates, setFilteredUserTemplates] = useState(userTemplates);

  useEffect(() => {
    setFilteredSystemTemplates(systemTemplates);
  }, [systemTemplates]);

  useEffect(() => {
    setFilteredUserTemplates(userTemplates);
  }, [userTemplates]);

  useEffect(() => {
    if (!isVisible) {
      setSearchTerm('');
      setFilteredSystemTemplates(systemTemplates);
      setFilteredUserTemplates(userTemplates);
    }
  }, [isVisible, systemTemplates, userTemplates]);

  if (!isVisible) {
    return null;
  }

  const handleSearch = searchTerm => {
    setSearchTerm(searchTerm);

    if (!searchTerm) {
      setFilteredSystemTemplates(systemTemplates);
      setFilteredUserTemplates(userTemplates);
      return;
    }

    const filteredSystem = systemTemplates.filter(t => t.title.toLowerCase().includes(searchTerm));
    setFilteredSystemTemplates(filteredSystem);

    const filteredUser = userTemplates.filter(t => t.title.toLowerCase().includes(searchTerm));
    setFilteredUserTemplates(filteredUser);
  };

  const handleAdd = (e, template) => {
    e.preventDefault();

    props.dispatch(apiAddTemplatePage(template, props.site._id));
    props.dispatch(hideModal('AddPageModal'));
  };

  const handleDelete = (e, template) => {
    e.stopPropagation();
    props.dispatch(confirmationDialog(
      fbt('Do you really want to delete this Template?', 'AddPageModal'),
      () => {
        props.dispatch(removePageTemplate(template._id));
      },
    ));
  };

  const handleSell = (e, template) => {
    e.stopPropagation();
    // TODO implement
    logger.info('TODO', template.title);
  };

  return (
    <Dialog title={fbt('Add Page', 'AddPageModal')} name="AddPageModal" size="modal-xl">
      <Tabs forceRenderTabPanel>
        <TabList>
          <Tab>
            <span className="icon icon-books" style={{'--mr': '10px'}}/>
            <fbt desc="AddPageModal">
              Default Templates
            </fbt>
          </Tab>
          <Tab>
            <span className="icon icon-shopping-basket" style={{'--mr': '10px'}}/>
            <fbt desc="AddPageModal">
              Shop
            </fbt>
          </Tab>
          <Tab>
            <span className="icon icon-folder-open" style={{'--mr': '10px'}}/>
            <fbt desc="AddPageModal">
              Your Page Templates
            </fbt>
          </Tab>
        </TabList>
        <div style={{'--bgc': 'var(--mame-white)', '--p': '15px 15px 0'}}>
          <input type="search"
                 value={searchTerm}
                 placeholder={fbt('Search Pages...', 'AddPageModal')}
                 style={{'--m': 0}}
                 onChange={e => handleSearch(e.target.value)}
          />
        </div>

        <div className="modal-content" style={{'--radius': '0 0 5px 5px', '--p': '10px 10px 20px'}}>
          <TabPanel>
            <ThreeColumnGrid>
              {filteredSystemTemplates.map((item, index) => {
                return (
                  <PagePreview key={index}
                               title={item.title}
                               previewImg={item.image}
                               imageStyle={{'--h': '100%'}}
                               icon={item.icon}
                               action={(e) => handleAdd(e, item)}
                  />
                );
              })}
            </ThreeColumnGrid>
          </TabPanel>
          <TabPanel>
            <ComingSoon/>
          </TabPanel>
          <TabPanel>
            {!props.userTemplates.length && (
              <div style={{'--ta': 'center'}}>
                <h4>
                  <fbt desc="AddPageModal">
                    You don't have any page templates.
                  </fbt>
                </h4>
                <fbt desc="AddPageModal">
                  You can add one in the Site Properties in the Pages tab.
                </fbt>
              </div>
            )}

            <ThreeColumnGrid>
              {filteredUserTemplates.map(item => {
                const previewImg = config.baseUrl + `/preview/page-template/${item._id}/preview.jpg`;

                return (
                  <PagePreview key={item._id}
                               id={`t-${item._id}`}
                               title={item.title}
                               previewImg={previewImg}
                               action={e => handleAdd(e, item)}
                               handleDelete={e => handleDelete(e, item)}
                               handleSell={e => handleSell(e, item)}
                  />
                );
              })}
            </ThreeColumnGrid>
          </TabPanel>
        </div>
      </Tabs>
    </Dialog>
  );
};

const mapStateToProps = state => ({
  site: state.activeSite.site,
  systemTemplates: systemPageTemplatesSelector(state),
  userTemplates: userPageTemplatesSelector(state),
  isVisible: state.modals.AddPageModal,
  payload: state.modals.payload.AddPageModal,
});

export default connect(mapStateToProps)(AddPageModal);
