import React from 'react';
import fbt from 'fbt';
import { connect } from 'react-redux';

import elementHelper from '../../modules/elementHelper';
import ElementDiv from '../ElementControls/ElementDiv';
import propertyHelper from '../../modules/propertyHelper';

const VideoElement = props => {
  const element = props.element;

  const id = elementHelper.getId(element);
  const classes = elementHelper.getClasses(element, 'mame-video');
  const style = elementHelper.getStyle(element);

  const poster = element.componentProperties.poster
    ? propertyHelper.getImagePath(element.componentProperties.poster)
    : null;

  return (
    <ElementDiv {...props} id={id} style={style} classes={classes}>
      <video width="100%" height="100%" style={{'--d': 'block', objectFit: 'cover'}}
             poster={poster}
             preload={element.componentProperties.preload || 'auto'}
             controls={element.componentProperties.controls}
             loop={element.componentProperties.loop}
             muted={element.componentProperties.muted}
             autoPlay={element.componentProperties.autoPlay}>
        <source src={element.componentProperties.url} type="video/mp4"/>
        <fbt desc="VideoProperties">
          Your browser does not support videos.
        </fbt>
      </video>
    </ElementDiv>
  );
};
export default connect()(VideoElement);
