import { HIDE_LOADING, HIDE_MODAL, SHOW_LOADING, SHOW_MODAL } from '../actionTypes';
import produce from 'immer';

const initialState = {
  AddElementModal: false,
  AddSectionModal: false,
  SaveTemplateModal: false,
  NewSiteModal: false,
  AddPageModal: false,
  MediaManagerModal: false,
  ShortcutModal: false,
  SettingsModal: false,
  PublishModal: false,
  ReportModal: false,
  ConfirmDialog: false,
  AddDomainModal: false,
  PremiumModal: false,
  LoadingModal: false,
  payload: {},
};

const modalState = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MODAL: {
      const {name, payload} = action.payload;

      return produce(state, draft => {
        draft[name] = true;
        draft.payload[name] = payload;
      });
    }

    case HIDE_MODAL: {
      const {name} = action.payload;

      return produce(state, draft => {
        draft[name] = false;
        draft.payload[name] = null;
      });
    }

    case SHOW_LOADING: {
      return produce(state, draft => {
        draft.LoadingModal = true;
      });
    }

    case HIDE_LOADING: {
      return produce(state, draft => {
        draft.LoadingModal = false;
      });
    }

    default:
      return state;
  }
};

export default modalState;
