import React from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import elementHelper from '../../modules/elementHelper';
import ElementDiv from '../ElementControls/ElementDiv';
import menuHelper from '../../modules/menuHelper';
import { ElementColorClasses } from '../../modules/consts/Elements';
import { isPreviewSelector, isRenderSelector, siteIdSelector } from '../../store/selectors';

const BreadcrumbElement = props => {
  const {element} = props;
  const {componentProperties = {}} = element;

  const id = elementHelper.getId(element);
  const classes = elementHelper.getClasses(element, 'mame-breadcrumb');
  const style = elementHelper.getStyle(element);

  style['--d'] = 'flex';

  let route = [];

  const buildBreadcrumb = page => {
    if (componentProperties.hasHome) {
      route.push({
        link: menuHelper.getPageLink('home', props.siteId, props.isPreview, props.isRender),
        title: fbt('Home', 'BreadcrumbElement')
      });
    }

    if (page.alias === 'home' || page.isDefault) {
      return;
    }

    if (!page.alias.replace('/index', '').includes('/')) {
      route.push({link: '', title: page.title});
      return;
    }

    const parts = page.alias.replace('/index', '').split('/');
    parts.pop(); // Get rid of the last one, this is the current page

    parts.forEach(alias => {
      const p = props.pages.find(p => p.alias.replace('/index', '').endsWith(alias));

      if (!p) {
        return;
      }

      const link = menuHelper.getPageLink(p.alias, props.siteId, props.isPreview, props.isRender);

      route.push({
        link,
        title: p.title,
      });
    });

    // Current page
    route.push({link: '', title: page.title});
  };

  buildBreadcrumb(props.page);

  const linkStyle = {};

  if (componentProperties.linkColor) {
    if (ElementColorClasses.includes(componentProperties.linkColor)) {
      linkStyle['--c'] = `var(--${componentProperties.linkColor})`;
    } else {
      linkStyle['--c'] = componentProperties.linkColor;
    }
  }

  return (
    <ElementDiv {...props}
                id={id}
                classes={classes}
                style={style}
                tag="nav"
                role={null}
                aria-label={fbt('Breadcrumb', 'Aria')}>
      {route.map((item, index) => {
        return (
          <div key={index} style={{'--mr': '15px', '--line-clamp': 1}}>
            {componentProperties.hasLinks && item.link && (
              <a href={item.link} style={linkStyle}>
                {item.title}
              </a>
            )}

            {(!componentProperties.hasLinks || !item.link) && <span style={linkStyle}>{item.title}</span>}

            {route.length - 1 !== index && (
              <span className="icon icon-angle-right" style={{'--ml': '15px'}} aria-hidden="true"/>
            )}
          </div>
        );
      })}
    </ElementDiv>
  );
};

const mapStateToProps = state => ({
  page: state.activeSite.page,
  pages: state.activeSite.pages,
  siteId: siteIdSelector(state),
  isPreview: isPreviewSelector(state),
  isRender: isRenderSelector(state),
});

export default connect(mapStateToProps)(BreadcrumbElement);
