import React from 'react';
import fbt from 'fbt';

import Fieldset from '../FieldSet';
import GenericSlider from '../Controls/GenericSlider';
import AdvancedColorPicker from '../Controls/AdvancedColorPicker';
import SelectInput from '../Controls/SelectInput';
import InputGroup from '../InputGroup';

const HoverFieldset = props => {
  return (
    <Fieldset title={fbt('Hover Effect', 'HoverFieldset')} isOpen={props.isOpen || false}>
      <InputGroup style={{'--mt': '10px'}}>
        <SelectInput
          title={fbt('Hover Effect', 'HoverFieldset')}
          value={props.hoverType}
          action={props.hoverTypeAction}
          options={{
            '': fbt('None', 'HoverFieldset'),
            'shadow': fbt('Shadow', 'HoverFieldset'),
            'levitate': fbt('Levitate', 'HoverFieldset'),
            'background': fbt('Change Background', 'HoverFieldset'),
            'border': fbt('Border', 'HoverFieldset'),
            'translateX': fbt('Transform X', 'HoverFieldset'),
            'translateY': fbt('Transform Y', 'HoverFieldset'),
            'scale': fbt('Scale', 'HoverFieldset'),
            'rotate': fbt('Rotate', 'HoverFieldset'),
            'skew': fbt('Skew', 'HoverFieldset'),
          }}
          showLabel
        />
      </InputGroup>

      {props.hoverType && props.hoverType !== 'background' && (
        <GenericSlider min="-30" max="30"
                       value={props.hover}
                       title={fbt('Hover Effect Intensity / Size', 'HoverFieldset')}
                       action={props.hoverAction}
        />
      )}

      {props.hoverType && ['background', 'border'].includes(props.hoverType) && (
        <AdvancedColorPicker title={fbt('Color', 'HoverFieldset')}
                             value={props.hoverColor}
                             action={props.hoverColorAction}
        />
      )}
    </Fieldset>
  );
};

export default HoverFieldset;
