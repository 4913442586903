import md5 from 'js-md5';
import propertyHelper from './propertyHelper';

const userHelper = {
  getUserAvatarUrl(user, size = 40) {
    if (user.profile.avatar) {
      return propertyHelper.getImagePath(user.profile.avatar);
    }

    // Gravatar next
    const hash = md5(user.email);

    return `https://www.gravatar.com/avatar/${hash}/?s=${size}`;
  },
};

export default userHelper;
