import React, { useEffect, useState } from 'react';
import ContentEditable from '../../General/ContentEditable';
import { usePopper } from 'react-popper';
import fbt from 'fbt';
import { ChromePicker } from 'react-color';
import { connect } from 'react-redux';
import Interweave from 'interweave';

import Dropdown from '../../General/Dropdown';
import LinkInput from './LinkInput';
import IconPicker from './IconPicker';
import SelectInput from './SelectInput';
import MenuPagePicker from './MenuPagePicker';
import LinkTarget from './LinkTarget';
import menuHelper from '../../../modules/menuHelper';
import logger from '../../../modules/logger';
import CheckboxInput from './CheckboxInput';
import { isPreviewSelector, siteIdSelector } from '../../../store/selectors';

// TODO Major refactor and Cleanup
const HtmlEdit = props => {
  const [mode, setMode] = useState({cmd: null, tag: null, parentTags: [], opts: null});
  const [link, setLink] = useState('');
  const [showEditor, setShowEditor] = useState(false);
  const [html, setHtml] = useState(props.html);
  // const html = useRef(props.html);

  // Fix outside changes
  useEffect(() => {
    setHtml(props.html);
  }, [props.html]);

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperEditorElement, setPopperEditorElement] = useState(null);

  const editorPopper = usePopper(referenceElement, popperEditorElement, {
    placement: 'top-start',
    strategy: 'fixed',
    modifiers: [{
      name: 'preventOverflow',
      enabled: true,
      options: {
        boundary: document.getElementById('site-content')
      },
    }],
  });

  const editorStyles = editorPopper.styles;
  const editorAttributes = editorPopper.attributes;

  const {update: updatePopper} = editorPopper;

  useEffect(() => {
    if (showEditor && updatePopper) {
      updatePopper();
    }
  }, [showEditor, updatePopper]);

  useEffect(() => {
    const handleOutsideClick = e => {
      if (popperEditorElement && popperEditorElement.contains(e.target)) {
        return;
      }

      if (e.target.parentElement.classList.contains('mame-contenteditable')) {
        return;
      }

      if (e.target.classList.contains('mame-contenteditable')) {
        return;
      }

      setShowEditor(false);
    };

    if (showEditor) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  });

  const detectCurrentTag = range => {
    return range.startContainer.parentElement.tagName.toLowerCase();
  };

  const detectParents = range => {
    const parent = range.startContainer.parentElement.parentElement;

    logger.debug('[HtmlEdit] HTML Element has Parents',
      parent.tagName,
      parent.classList
    );

    if (parent.classList.contains('mame-contenteditable')) {
      logger.debug('[HtmlEdit] Element has NO Parents');
      return [];
    }

    return [range.startContainer.parentElement.parentElement.tagName];
  };

  const handleOnClick = e => {
    const selection = window.getSelection();
    const range = selection && selection.rangeCount ? selection.getRangeAt(0) : null;

    const currentMode = {cmd: null, tag: null, parentTags: [], opts: null};
    setLink('');
    let isEditorVisible = true;

    if (range) {
      // logger.info('TODO BUILT IN ELEMENT CHECK', range);
      isEditorVisible = true;
      const currentTag = detectCurrentTag(range);
      currentMode.parentTags = detectParents(range);

      logger.debug('[HtmlEdit] Currently in Element', currentTag);

      switch (currentTag) {
        case 'strong':
        case 'b':
          currentMode.cmd = 'bold';
          break;

        case 'i':
          currentMode.cmd = 'italic';
          break;

        case 'u':
          currentMode.cmd = 'underline';
          break;

        case 'strike':
          currentMode.cmd = 'strikethrough';
          break;

        case 'li':
          currentMode.cmd = 'insertUnorderedList';
          break;

        case 'h1':
        case 'h2':
        case 'h3':
        case 'h4':
        case 'h5':
        case 'h6':
          currentMode.cmd = 'heading';
          currentMode.tag = currentTag;
          break;

        case 'a':
          currentMode.cmd = 'createLink';
          setLink(range.startContainer.parentElement.getAttribute('href'));
          break;

        default:
          break;
      }
    }

    setMode(currentMode);
    setShowEditor(isEditorVisible);

    if (props.onClick) {
      props.onClick(e);
    }

    if (props.onKeyUp) {
      props.onClick(e);
    }
  };

  const handleOnChange = e => {
    setHtml(e.target.value);
  };

  const handleOnBlur = () => {
    // setShowEditor(false);

    if (html === props.html) {
      return;
    }

    if (props.action) {
      props.action(html);
    }

    if (props.onBlur) {
      props.onBlur();
    }
  };

  const editorClasses = ['editor-nav'];

  if (showEditor) {
    editorClasses.push('editor-visible');
  } else {
    editorClasses.slice(editorClasses.indexOf('editor-visible'), 1);
  }

  if (props.preview || props.isPreview) {
    const CustomTag = props.tag || 'div';

    return (
      <CustomTag style={props.style} id={props.id || null}>
        <Interweave content={html}
                    allowAttributes allowElements noWrap/>
      </CustomTag>
    );
  }

  return (
    <>
      <ContentEditable
        ref={setReferenceElement}
        id={props.id || null}
        className="mame-contenteditable"
        tagName={props.tag || 'div'}
        html={html}
        style={props.style}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        onClick={handleOnClick}
        onKeyUp={handleOnClick}
      />

      {showEditor && (
        <div className={editorClasses.join(' ')}
             ref={setPopperEditorElement}
             style={editorStyles.popper}
             {...editorAttributes.popper}>
          <div className="group">
            <EditIcon shortcut={['Control', 'B']} title={fbt('Bold', 'HtmlEdit')} cmd="bold" icon="bold" mode={mode}/>
            <EditIcon shortcut={['Control', 'I']} title={fbt('Italic', 'HtmlEdit')} cmd="italic" icon="italic"
                      mode={mode}/>
            <EditIcon shortcut={['Control', 'U']} title={fbt('Underline', 'HtmlEdit')} cmd="underline" icon="underline"
                      mode={mode}/>
            <EditIcon shortcut={['Control', 'Shift', 'S']} title={fbt('Strikethrough', 'HtmlEdit')} cmd="strikethrough"
                      icon="strikethrough" mode={mode}/>
          </div>

          <div className="group">
            <EditIcon shortcut={['Control', 'Shift', 'O']} title={fbt('Ordered List', 'HtmlEdit')}
                      cmd="insertOrderedList"
                      icon="list-ol" mode={mode}/>
            <EditIcon shortcut={['Control', 'Shift', 'U']} title={fbt('Unordered List', 'HtmlEdit')}
                      cmd="insertUnorderedList" icon="list-ul" mode={mode}/>
            <SurroundHtmlIcon title={fbt('Blockquote', 'HtmlEdit')} element="blockquote" icon="quote-right"
                              mode={mode}/>
            <SurroundHtmlIcon title={fbt('Code', 'HtmlEdit')} element="code" icon="code" mode={mode}/>
          </div>

          <div className="group">
            <EditIcon shortcut={['Control', 'L']} title={fbt('Justify Left', 'HtmlEdit')} cmd="justifyLeft"
                      icon="align-left" mode={mode}/>
            <EditIcon shortcut={['Control', 'E']} title={fbt('Justify Center', 'HtmlEdit')} cmd="justifyCenter"
                      icon="align-center" mode={mode}/>
            <EditIcon shortcut={['Control', 'Shift', 'R']} title={fbt('Justify Right', 'HtmlEdit')} cmd="justifyRight"
                      icon="align-right" mode={mode}/>
          </div>

          <div className="group-no-margin">
            <HeadingIcon title={fbt('Heading', 'HtmlEdit')} cmd="heading" icon="header" mode={mode}/>
            <LinkEditIcon title={fbt('Add Link', 'HtmlEdit')} cmd="createLink" icon="link" mode={mode} link={link}/>
            <ColorEditIcon title={fbt('Background Color', 'HtmlEdit')} cmd="backColor" icon="format_color_fill"
                           mode={mode}/>
            <ColorEditIcon title={fbt('Text Color', 'HtmlEdit')} cmd="foreColor" icon="format_color_text" mode={mode}/>
            <InsertIconIcon title={fbt('Insert Icon', 'HtmlEdit')} cmd="icon" icon="paperplane" mode={mode}/>
          </div>

          <div className="group" style={{'--mr': '0'}}>
            <EditIcon shortcut={['Control', 'U']} title={fbt('Undo', 'HtmlEdit')}
                      cmd="undo" icon="history" mode={mode}/>
            <EditIcon shortcut={['Control', 'R']} title={fbt('Redo', 'HtmlEdit')}
                      cmd="redo" icon="repeat" mode={mode}/>
            <EditIcon title={fbt('Remove Formatting', 'HtmlEdit')}
                      cmd="removeFormat" icon="eraser" mode={mode}/>
          </div>
        </div>
      )}
    </>
  );
};

const InsertIconIcon = props => {
  let classNames = 'white icon icon-' + props.icon;

  const handleOnClick = icon => {
    document.execCommand('insertHTML', false,
      `<span class="icon icon-${icon}"></span>`);
  };

  return (
    <Dropdown
      key={props.cmd}
      width="auto"
      placement="top-start"
      placementArrow="bottom"
      padding="0"
      border="0"
      disableFlip
      menu={
        <button className={classNames}
                title={props.title || props.cmd}
                style={{'--px': '8px', '--cur': 'pointer'}}/>
      }
      content={
        <IconContent action={handleOnClick}/>
      }
    />
  );
};

const IconContent = props => {
  const handleClick = icon => {
    props.action(icon);
    props.toggleOpen(null);
  };

  const {isOpen, updatePopper} = props;

  useEffect(() => {
    if (isOpen && updatePopper) {
      updatePopper();
    }
  }, [isOpen, updatePopper]);

  if (!props.isOpen) {
    return (
      <div style={{'--w': '220px', '--h': '318px'}}/>
    );
  }

  return (
    <div style={{'--bb': '3px solid var(--mame-primary)', '--br': '5px', '--p': '10px 10px 0 10px'}}>
      <IconPicker icon={''}
                  count="14"
                  minimized={false}
                  gtc="1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr"
                  action={handleClick}
      />
    </div>
  );
};

const SurroundHtmlIcon = props => {
  let classNames = 'icon icon-' + props.icon || 'undefined';

  if (props.mode.cmd === props.element || props.mode.parentTags.includes(props.element)) {
    classNames += ' mame-primary';
  } else {
    classNames += ' white';
  }

  const handleClick = e => {
    e.preventDefault();

    const sel = window.getSelection().getRangeAt(0).toString().trim();

    if (sel) {
      document.execCommand('insertHTML', false,
        `<${props.element}>${sel}</${props.element}>`);
    }
  };

  return (
    <button className={classNames}
            title={props.title}
            style={{'--px': '8px', '--cur': 'pointer'}}
            onMouseDown={handleClick}>
    </button>
  );
};

const LinkEditIcon = props => {
  const [link, setLink] = useState(props.link);

  useEffect(() => {
    setLink(props.link);
  }, [props.link]);

  const [selection, setSelection] = useState(null);

  let classNames = 'icon icon-' + props.icon;

  if (props.mode.cmd === props.cmd || props.mode.parentTags.includes(props.cmd)) {
    classNames += ' mame-primary';
  } else {
    classNames += ' white';
  }

  const addLink = (newLink) => {
    setLink(newLink);

    if (!newLink) {
      return;
    }

    if (selection) {
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(selection);

      const sel = selection.toString().trim();
      document.execCommand('insertHTML', false,
        newLink + sel + '</a>');
    }
  };

  const handleOnClick = () => {
    if (window.getSelection()) {
      setSelection(window.getSelection().getRangeAt(0));
    }
  };

  return (
    <Dropdown
      key={props.cmd}
      width="auto"
      placement="top-start"
      placementArrow="bottom"
      padding="0"
      border="0"
      menu={
        <button className={classNames}
                onClick={handleOnClick}
                title={props.title || props.cmd}
                style={{'--px': '8px', '--cur': 'pointer'}}/>
      }
      content={
        <LinkActionContent
          link={link}
          action={addLink}
        />
      }
    />
  );
};

const LinkActionContent = props => {
  const [linkType, setLinkType] = useState('');
  const [linkTarget, setLinkTarget] = useState('_self');
  const [isNofollow, setIsNofollow] = useState(false);
  const [link, setLink] = useState('');
  const [pageAlias, setPageAlias] = useState('');

  const {isOpen, updatePopper} = props;

  useEffect(() => {
    resetLink();
    // Only works for http kind links, but good enough for now
    if (props.link.startsWith('http')) {
      setLinkType('external');
      setLink(props.link);
    } else if (props.link === '') {
      // Empty link?
    } else {
      setLinkType('internal');

      // This is a bit hacky.. Should work most times, not all times
      const linkAlias = props.link.replace('.html', '').substr(1);
      setPageAlias(linkAlias);
    }
  }, [props.link]);

  useEffect(() => {
    if (isOpen && updatePopper) {
      updatePopper();
    }
  }, [isOpen, updatePopper]);

  const resetLink = () => {
    setLinkType('');
    setLink('');
    setLinkTarget('_self');
    setPageAlias('');
  };

  const handleClick = e => {
    // Build link
    if (linkType === '') {
      return;
    }

    if (linkType === 'external') {
      let rel = 'noopener noreferrer';

      if (isNofollow) {
        rel += ' nofollow';
      }

      props.action(`<a rel="${rel}" href="${link}" target="${linkTarget}">`);
      props.toggleOpen(e);
      resetLink();
      return;
    }

    const pageLink = menuHelper.getPageLink(pageAlias, props.siteId, true, true);

    props.action(`<a href="${pageLink}" target="${linkTarget}">`);
    resetLink();
    props.toggleOpen(e);
  };

  return (
    <div style={{'--bb': '3px solid var(--mame-primary)', '--br': '5px', '--p': '15px'}}>
      <SelectInput title="Link Type" showLabel
                   value={linkType}
                   options={{
                     '': fbt('Please Select', 'HtmlEdit'),
                     'internal': fbt('Internal', 'HtmlEdit'),
                     'external': fbt('External', 'HtmlEdit')
                   }}
                   action={setLinkType}
      />

      {linkType === 'internal' && (
        <MenuPagePicker title={fbt('Page', 'HtmlEdit')}
                        value={pageAlias}
                        action={setPageAlias}
        />
      )}

      {linkType === 'external' && (
        <>
          <LinkInput title={fbt('Link', 'HtmlEdit')}
                     value={link}
                     showLabel
                     onReturnKey={val => {
                       handleClick(null);
                     }}
                     action={setLink}
          />

          <CheckboxInput title={fbt('No Follow', 'HtmlEdit')}
                         checked={isNofollow}
                         action={() => {
                           setIsNofollow(!isNofollow);
                         }}
          />
        </>
      )}

      {linkType && (
        <LinkTarget title={fbt('Target', 'HtmlEdit')}
                    value={linkTarget}
                    action={setLinkTarget}/>
      )}

      <button className="mame-primary" onClick={handleClick}>
        <fbt desc="HtmlEdit">
          Add Link
        </fbt>
      </button>
    </div>
  );
};

const ColorEditIcon = props => {
  const [color, setColor] = useState(props.color || '#287bb8');

  let classNames = 'icon icon-' + props.icon;
  if (props.mode.cmd === props.cmd || props.mode.parentTags.includes(props.cmd)) {
    classNames += ' mame-primary';
  } else {
    classNames += ' white';
  }

  const handleChangeComplete = (color, e) => {
    e.preventDefault();

    let c = '';

    if (color.rgb.a === 1) {
      c = color.hex;
    } else {
      c = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
    }

    setColor(c);
    document.execCommand('styleWithCss', false, true);
    document.execCommand(props.cmd, false, c);
  };

  return (
    <Dropdown
      key={props.cmd}
      width="auto"
      placement="top-start"
      placementArrow="bottom"
      padding="0"
      border="0"
      menu={
        <button className={classNames}
                title={props.title || props.cmd}
                style={{'--px': '8px', '--cur': 'pointer'}}/>
      }
      content={
        <ColorEditContent color={color} action={handleChangeComplete}/>
      }
    />
  );
};

const ColorEditContent = props => {
  const {isOpen, updatePopper} = props;

  useEffect(() => {
    if (isOpen && updatePopper) {
      updatePopper();
    }
  }, [isOpen, updatePopper]);

  if (!props.isOpen) {
    return (
      <div style={{'--w': '220px', '--h': '318px'}}/>
    );
  }

  return (
    <div style={{'--bb': '3px solid var(--mame-primary)', '--br': '5px'}}>
      <ChromePicker
        color={props.color}
        onChange={props.action}
      />
    </div>
  );
};

const HeadingIcon = props => {
  const tags = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
  const [selection, setSelection] = useState(null);

  let classNames = 'icon icon-' + props.icon;

  if (props.mode.cmd === props.cmd || props.mode.parentTags.filter(t => tags.includes(t.toLowerCase())).length) {
    classNames += ' mame-primary';
  } else {
    classNames += ' white';
  }

  const handleChange = (size, e) => {
    e.preventDefault();
    logger.debug('Heading', size);

    if (selection) {
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(selection);

      const sel = selection.toString().trim();

      const newNode = document.createElement(size);
      document.execCommand('insertHTML', false,
        `<${size}>${sel}</${size}>`);

      const range = window.getSelection().getRangeAt(0);
      newNode.appendChild(range.extractContents());

      range.insertNode(newNode);

      // props.toggleOpen(e);
    }
  };

  const handleOnClick = e => {
    e.preventDefault();

    const sel = window.getSelection && window.getSelection();

    if (sel && sel.rangeCount > 0) {
      setSelection(window.getSelection().getRangeAt(0));
    }
  };

  return (
    <Dropdown
      key={props.cmd}
      width="auto"
      placement="top-start"
      placementArrow="bottom"
      padding="0"
      border="0"
      menu={
        <button className={classNames}
                onClick={handleOnClick}
                title={props.title || props.cmd}
                style={{'--px': '8px', '--cur': 'pointer'}}/>
      }
      content={
        <HeadingContent action={handleChange} mode={props.mode}/>
      }
    />
  );
};


const HeadingContent = props => {
  const [selected, setSelected] = useState(props.mode.tag);

  useEffect(() => {
    setSelected(props.mode.tag);
  }, [props.mode]);

  const {isOpen, updatePopper} = props;

  useEffect(() => {
    if (isOpen && updatePopper) {
      updatePopper();
    }
  }, [isOpen, updatePopper]);

  const handleClick = (size, e) => {
    props.action(size, e);
    props.toggleOpen(e);
  };

  return (
    <div style={{'--p': '10px 20px', '--bb': '3px solid var(--mame-primary)', '--br': '5px'}}>
      <h1 style={{'--cur': 'pointer', background: (selected === 'h1' ? '#f1f1f1' : 'transparent')}}
          onClick={(e) => handleClick('h1', e)}>
        <fbt desc="HtmlEdit">
          Heading 1
        </fbt>
      </h1>
      <h2 style={{'--cur': 'pointer', background: (selected === 'h2' ? '#f1f1f1' : 'transparent')}}
          onClick={(e) => handleClick('h2', e)}>
        <fbt desc="HtmlEdit">
          Heading 2
        </fbt>
      </h2>
      <h3 style={{'--cur': 'pointer', background: (selected === 'h3' ? '#f1f1f1' : 'transparent')}}
          onClick={(e) => handleClick('h3', e)}>
        <fbt desc="HtmlEdit">
          Heading 3
        </fbt>
      </h3>
      <h4 style={{'--cur': 'pointer', background: (selected === 'h4' ? '#f1f1f1' : 'transparent')}}
          onClick={(e) => handleClick('h4', e)}>
        <fbt desc="HtmlEdit">
          Heading 4
        </fbt>
      </h4>
      <h5 style={{'--cur': 'pointer', background: (selected === 'h5' ? '#f1f1f1' : 'transparent')}}
          onClick={(e) => handleClick('h5', e)}>
        <fbt desc="HtmlEdit">
          Heading 5
        </fbt>
      </h5>
    </div>
  );
};

const EditIcon = props => {
  const {icon, mode, cmd, arg, title} = props;

  let classNames = 'icon icon-' + icon;

  if (mode.cmd === cmd || mode.parentTags.includes(cmd)) {
    classNames += ' mame-primary';
  } else {
    classNames += ' white';
  }

  return (
    <button key={cmd}
            className={classNames}
            title={title || cmd}
            style={{'--px': '8px', '--cur': 'pointer'}}
            onMouseDown={e => {
              e.preventDefault();
              document.execCommand(cmd, false, arg);
            }}>
    </button>
  );
};

const mapStateToProps = state => ({
  isPreview: isPreviewSelector(state),
  siteId: siteIdSelector(state),
});

export default connect(mapStateToProps)(HtmlEdit);
