import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import fbt from 'fbt';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import Dialog from './Dialog';
import ComingSoon from '../General/ComingSoon';
import SitePreview from '../SiteControls/SitePreview';
import { apiNewSite } from '../../store/actions/sites';
import { hideModal } from '../../store/actions/modals';
import { systemSiteTemplatesSelector, userSiteTemplatesSelector } from '../../store/selectors';
import ThreeColumnGrid from '../General/ThreeColumnGrid';
import config from '../../config';
import { confirmationDialog } from '../../store/actions/builder';
import { removeSiteTemplate } from '../../store/actions/templates';
import logger from '../../modules/logger';

const NewSiteModal = props => {
  const {systemTemplates, userTemplates, isVisible} = props;

  const [filteredSystemTemplates, setFilteredSystemTemplates] = useState(systemTemplates);
  const [filteredUserTemplates, setFilteredUserTemplates] = useState(userTemplates);
  const [searchTerm, setSearchTerm] = useState('');

  const history = useHistory();

  useEffect(() => {
    setFilteredUserTemplates(userTemplates);
  }, [userTemplates]);

  useEffect(() => {
    setFilteredSystemTemplates(systemTemplates);
  }, [systemTemplates]);

  useEffect(() => {
    if (!isVisible) {
      setSearchTerm('');
      setFilteredUserTemplates(userTemplates);
      setFilteredSystemTemplates(systemTemplates);
    }
  }, [isVisible, systemTemplates, userTemplates]);

  if (!isVisible) {
    return null;
  }

  const handleSearch = searchTerm => {
    setSearchTerm(searchTerm);

    const filteredSystem = systemTemplates.filter(t => t.title.toLowerCase().includes(searchTerm));
    setFilteredSystemTemplates(filteredSystem);

    const filteredUser = userTemplates.filter(t => t.title.toLowerCase().includes(searchTerm));
    setFilteredUserTemplates(filteredUser);
  };

  const handleAdd = (e, item) => {
    e.preventDefault();
    logger.debug('ADD Site', item.title);

    props.dispatch(apiNewSite(item, (siteId) => {
      history.push(`/builder/${siteId}`);
    }));

    props.dispatch(hideModal('NewSiteModal'));
  };

  const handleDelete = (e, template) => {
    e.stopPropagation();
    props.dispatch(confirmationDialog(
      fbt('Do you really want to delete this Template?', 'NewSiteModal'),
      () => {
        props.dispatch(removeSiteTemplate(template._id));
      },
    ));
  };

  return (
    <Dialog title={fbt('New Website', 'NewSiteModal')} name="NewSiteModal" size="modal-xl">
      <Tabs forceRenderTabPanel>
        <TabList>
          <Tab>
            <span className="icon icon-books" style={{'--mr': '10px'}}/>
            <fbt desc="NewSiteModal">
              Default Templates
            </fbt>
          </Tab>
          <Tab>
            <span className="icon icon-shopping-basket" style={{'--mr': '10px'}}/>
            <fbt desc="NewSiteModal">
              Shop
            </fbt>
          </Tab>
          <Tab>
            <span className="icon icon-folder-open" style={{'--mr': '10px'}}/>
            <fbt desc="NewSiteModal">
              Your Site Templates
            </fbt>
          </Tab>
        </TabList>
        <div style={{'--bgc': 'var(--mame-white)', '--p': '15px 15px 0'}}>
          <input type="search"
                 value={searchTerm}
                 placeholder={fbt('Search Sites...', 'NewSiteModal')}
                 style={{'--m': 0}}
                 onChange={e => handleSearch(e.target.value)}
          />
        </div>

        <div className="add-section accent" style={{'--radius': '0 0 5px 5px', '--p': '20px 10px'}}>
          <TabPanel>
            <ThreeColumnGrid>
              {filteredSystemTemplates.map(item => {
                return (
                  <SitePreview
                    key={item._id}
                    title={item.title}
                    previewImg={item.image}
                    icon={item.icon}
                    action={(e) => handleAdd(e, item)}
                    hideMenu
                  />
                );
              })}
            </ThreeColumnGrid>
          </TabPanel>
          <TabPanel>
            <ComingSoon/>
          </TabPanel>
          <TabPanel>
            {!filteredUserTemplates.length && (
              <div style={{'--ta': 'center'}}>
                <h4>
                  <fbt desc="NewSiteModal">
                    You don't have any website templates.
                  </fbt>
                </h4>
                <fbt desc="NewSiteModal">
                  You can create one by clicking on the "Save as Template" icon on the top menu while you edit a
                  website.
                </fbt>
              </div>
            )}

            <ThreeColumnGrid>
              {filteredUserTemplates.map((item, index) => {
                const previewImg = config.baseUrl + `/preview/site-template/${item._id}/preview.jpg`;

                return (
                  <SitePreview
                    key={index}
                    title={item.title}
                    previewImg={previewImg}
                    action={(e) => handleAdd(e, item)}
                    handleDelete={e => handleDelete(e, item)}
                  />
                );
              })}
            </ThreeColumnGrid>
          </TabPanel>
        </div>
      </Tabs>
    </Dialog>
  );
};

const mapStateToProps = state => ({
  isVisible: state.modals.NewSiteModal,
  payload: state.modals.payload.NewSiteModal,
  systemTemplates: systemSiteTemplatesSelector(state),
  userTemplates: userSiteTemplatesSelector(state),
});

export default connect(mapStateToProps)(NewSiteModal);
