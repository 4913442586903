import React from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import Dropdown from '../General/Dropdown';
import userHelper from '../../modules/userHelper';
import { logoutUser } from '../../store/actions/user';
import config from '../../config';
import { showModal } from '../../store/actions/modals';

const UserMenu = props => {
  const {user} = props;

  if (!user) {
    return <div/>;
  }

  const avatar = userHelper.getUserAvatarUrl(user);
  const username = user.username;

  return (
    <Dropdown
      placement="bottom-start"
      width="250px"
      menu={<MenuTitle username={username} avatar={avatar}/>}
      content={<Content {...props} username={username} avatar={avatar}/>}>
    </Dropdown>
  );
};

const MenuTitle = (props) => {
  return (
    <div className="user-menu">
      {props.username}
      <img src={props.avatar} alt={props.username} style={{'--maxh': '40px', '--p': '2px 0', '--ml': '8px'}}/>
    </div>
  );
};

const Content = (props) => {
  return (
    <>
      <div style={{'--d': 'grid', '--gtc': '60px 1fr', '--gg': '10px', '--ai': 'center', '--pb': '10px'}}>
        <img src={props.avatar} alt={props.username}
             style={{'--maxh': '40px', '--br': '50%'}}/>
        <div>
          {props.username}
          <br/>
          <div className="tag grey" style={{'--m': 0, '--p': '1px 8px', '--size': '80%'}}>
            {props.user.account_subscription === 'free' ? 'Mame Free' : 'Mame Premium'}
          </div>
        </div>
      </div>
      <hr/>
      <div className="menu-entry">
        <a href="https://mame.app/help/changelog"
           target="_blank"
           rel="noopener noreferrer"
           title={`API Version: ${props.apiVersion}`}
        >
          <fbt desc="User Top Menu">
            Version:
          </fbt>
          <span style={{'--ml': '5px'}}>{config.versionString}</span>
        </a>
      </div>
      <hr/>
      <div className="menu-entry fake-link">
        <fbt desc="User Top Menu">
          Your Store Offers
        </fbt>
      </div>
      <div className="menu-entry fake-link" onClick={
        e => {
          props.toggleOpen(e);
          props.dispatch(showModal('SettingsModal', {selectedIndex: 3}));
        }
      }
      >
        <fbt desc="User Top Menu">
          Teams (Coming soon)
        </fbt>
      </div>
      <div className="menu-entry fake-link" onClick={
        e => {
          props.toggleOpen(e);
          props.dispatch(showModal('SettingsModal', {selectedIndex: 1}));
        }
      }>
        <fbt desc="User Top Menu">
          Your Profile
        </fbt>
      </div>
      <hr/>

      {props.user.account_subscription === 'free' && (
        <>
          <div className="menu-entry fake-link" onClick={
            e => {
              props.toggleOpen(e);
              props.dispatch(showModal('PremiumModal'));
            }
          }>
            <fbt desc="User Top Menu">
              Get Premium
            </fbt>
          </div>
          <hr/>
        </>
      )}

      <div className="menu-entry fake-link" onClick={
        e => {
          props.toggleOpen(e);
          props.dispatch(showModal('ReportModal'));
        }
      }>
        <fbt desc="User Top Menu">
          Report Bugs and Feedback
        </fbt>
      </div>
      <div className="menu-entry fake-link" onClick={e => {
        props.toggleOpen(e);
        props.dispatch(showModal('ShortcutModal'));
      }}>
        <fbt desc="User Top Menu">
          Keyboard Shortcuts
        </fbt>
      </div>
      <hr/>
      <div className="menu-entry fake-link" onClick={e => {
        props.toggleOpen(e);
        props.dispatch(logoutUser());
      }}>
        <fbt desc="User Top Menu">
          Logout
        </fbt>
      </div>
    </>
  );
};


const mapStateToProps = state => ({
  apiVersion: state.builder.apiVersion,
  user: state.user.user,
});

export default connect(mapStateToProps)(UserMenu);
