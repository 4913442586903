import React from 'react';
import fbt from 'fbt';

const Footer = () => {
  return (
    <div style={{'--ta': 'center', '--py': '30px', '--bg': 'var(--sidebar-background)', '--c': 'var(--sidebar-color)'}}>
      <fbt desc="Footer">
        Copyright (C) 2020 - 2021 by <a rel="noopener noreferrer" href="https://mame.app">mame.app</a> - All rights
        reserved.
      </fbt>
    </div>
  );
};

export default Footer;
