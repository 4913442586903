import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import elementHelper from '../../modules/elementHelper';
import ElementDiv from '../ElementControls/ElementDiv';
import propertyHelper from '../../modules/propertyHelper';
import ElementLink from '../ElementControls/ElementLink';

const GalleryElement = props => {
  const element = props.element;
  const {componentProperties} = element;

  const id = elementHelper.getId(element);
  const classes = elementHelper.getClasses(element, 'mame-gallery');
  const style = elementHelper.getStyle(element);

  useEffect(() => {
    window.setupGalleries();
  }, [componentProperties]);

  return (
    <ElementDiv {...props} id={id} classes={classes} style={style}>
      <div style={{
        '--d': 'grid',
        '--gtc': '1fr 1fr 1fr',
        '--gtc-sm': '1fr',
        '--gtc-md': '1fr 1fr',
        '--gtc-lg': '1fr 1fr 1fr',
        '--gtc-xl': '1fr 1fr 1fr',
        '--gg': '10px',
      }}>
        {componentProperties.items.map((item, index) => {
          const imgUrl = propertyHelper.getImagePath(item.thumbnail || item.image);

          const content = (
            <>
              <div className="mame-gallery__image"
                   role="img"
                   aria-label={item.alt || item.title}
                   title={item.title}
                   style={{
                     '--bgi': `url("${imgUrl}")`,
                   }}
              />
              <div className="mame-gallery__border">
                <h1 style={{'--m': 0}}>+</h1>
              </div>
            </>
          );

          // TODO title
          return (
            <div className="mame-gallery__item"
                 key={item.itemId || index}
                 data-title={item.title}
                 data-image={propertyHelper.getImagePath(item.image)}
                 data-alt={item.alt || item.title}>
              {item.linkType && (
                <ElementLink
                  linkType={item.linkType}
                  link={item.link}
                  target={item.linkTarget}
                  page={item.page}
                  isNofollow={item.isNofollow}
                  onClick={e => {
                    e.preventDefault();
                  }}
                >
                  {content}
                </ElementLink>
              )}

              {!item.linkType && content}
            </div>
          );
        })}
      </div>
    </ElementDiv>
  );
};

export default connect()(GalleryElement);
