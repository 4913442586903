import React from 'react';

const TextArea = (props) => {
  const classes = props.required ? 'required' : null;

  return (
    <label>
      {props.showLabel && props.title}
      {!props.showLabel && <span className="sr-only">props.title</span>}
      <textarea id={props.id || null}
                placeholder={props.placeholder || props.title}
                title={props.tooltip || props.title || ''}
                className={classes}
                rows={props.rows || 5}
                value={props.value}
                required={props.required}
                onChange={(e) => props.action(e.target.value)}/>
    </label>
  );
};

export default TextArea;
