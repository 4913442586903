import DisqusProperties from '../../components/Properties/Elements/DisqusProperties';
import { ElementVisible } from '../../modules/consts/Elements';

const DisqusModel = {
  title: 'Disqus Comments',
  image: '/img/elements/disqus.webp',
  category: 'Actions',
  helpPage: '/documentation/elements/disqus',
  component: 'DisqusElement',
  propertyComponent: DisqusProperties,
  hasChildElements: false,
  generalProperties: {
    hasVisibility: true,
    hasTextAlign: false,
    hasTextColor: false,
    hasBackground: true,
  },
  defaults: {
    demo: true,
    _id: null,
    identifier: 'New Disqus Element',
    component: 'DisqusElement',
    componentProperties: {
      shortName: '',
      pageIdentifierType: '', // Auto
      pageIdentifier: '',
      pageUrlType: '', // Auto
      pageUrl: '',
    },
    generalProperties: {
      visibility: [ElementVisible.sm, ElementVisible.md, ElementVisible.lg, ElementVisible.xl],
      published: true,
      margin: [0, 0, 0, 0],
      padding: [0, 0, 0, 0],
    },
  },
};

export default DisqusModel;
