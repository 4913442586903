import React from 'react';
import fbt from 'fbt';

import Fieldset from '../FieldSet';
import ItemsFieldsetWrapper from '../Fieldsets/ItemsFieldsetWrapper';
import TextInput from '../Controls/TextInput';
import BackgroundPicker from '../Controls/BackgroundPicker';
import GenericSlider from '../Controls/GenericSlider';
import CheckboxInput from '../Controls/CheckboxInput';
import SelectInput from '../Controls/SelectInput';
import InputGroup from '../InputGroup';
import HelpText from '../Controls/HelpText';

const SlideshowProperties = props => {
  const {model, element} = props;
  const {componentProperties} = element;

  return (
    <>
      <Fieldset title={fbt('Slideshow Properties', 'SlideshowProperties')} isOpen>
        <InputGroup style={{'--mt': '10px'}}>
          <GenericSlider title={fbt('Slideshow Speed', 'SlideshowProperties')}
                         min="0.5" max="30" step="0.5"
                         value={componentProperties.interval}
                         action={val => props.handlePropertyChange('interval', val)}
                         showLabel
          />
        </InputGroup>

        <InputGroup>
          <SelectInput title={fbt('Animation', 'SlideshowProperties')}
                       value={componentProperties.animation} showLabel
                       action={val => props.handlePropertyChange('animation', val)}
                       options={{
                         'animate-slide-left-in': fbt('Slide in Left', 'SlideshowProperties'),
                         'animate-slide-right-in': fbt('Slide in Right', 'SlideshowProperties'),
                         'animate-slide-top-in': fbt('Slide in Top', 'SlideshowProperties'),
                         'animate-slide-bottom-in': fbt('Slide in Bottom', 'SlideshowProperties'),
                       }}
          />
        </InputGroup>

        <InputGroup>
          <CheckboxInput title={fbt('Autoplay', 'SlideshowProperties')}
                         checked={componentProperties.autoPlay}
                         action={() => {
                           props.handlePropertyChange('autoPlay', !componentProperties.autoPlay);
                         }}
          />
        </InputGroup>

        <InputGroup>
          <CheckboxInput title={fbt('Show slide navigation', 'SlideshowProperties')}
                         checked={componentProperties.navigationVisible}
                         action={() => {
                           props.handlePropertyChange('navigationVisible', !componentProperties.navigationVisible);
                         }}
          />
        </InputGroup>

        <InputGroup>
          <CheckboxInput title={fbt('Show slide controls', 'SlideshowProperties')}
                         checked={componentProperties.controlsVisible}
                         action={() => {
                           props.handlePropertyChange('controlsVisible', !componentProperties.controlsVisible);
                         }}
          />
        </InputGroup>

        {/* Effect */}
      </Fieldset>
      <ItemsFieldsetWrapper
        title={fbt('Items', 'SlideshowProperties')}
        items={componentProperties.items}
        actionDispatch={props.dispatchItems}
        template={model.itemTemplate}
        isOpen
      >
        <Controls
          action={props.handleItemChange}/>
      </ItemsFieldsetWrapper>
    </>
  );
};

const Controls = props => {
  const item = props.item;
  const index = props.index;

  return (
    <>
      <HelpText>
        <fbt desc="SlideshowProperties">
          Set the optional content directly on the slideshow.
        </fbt>
      </HelpText>

      <InputGroup>
        <TextInput title={fbt('Identifier', 'SlideshowProperties')}
                   value={item.identifier}
                   action={val => props.action(index, 'identifier', val)}
                   showLabel
        />
      </InputGroup>

      <BackgroundPicker title={fbt('Slide Image / Background', 'SlideshowProperties')}
                        hideFieldset showLabel
                        type={item.backgroundType}
                        image={item.backgroundImage}
                        color={item.backgroundColor}
                        gradientColor={item.backgroundGradientColor}
                        gradientDegree={item.backgroundGradientDegree}
                        pattern={item.backgroundPattern}
                        video={item.backgroundVideo}
                        videoAutoPlay={item.backgroundVideoAutoPlay}
                        videoLoop={item.backgroundVideoLoop}
                        typeAction={val => {
                          props.action(index, 'backgroundType', val);
                        }}
                        colorAction={val => {
                          props.action(index, 'backgroundColor', val);
                        }}
                        imageAction={val => {
                          props.action(index, 'backgroundImage', val);
                        }}
                        gradientColorAction={val => {
                          props.action(index, 'backgroundGradientColor', val);
                        }}
                        gradientDegreeAction={val => {
                          props.action(index, 'backgroundGradientDegree', val);
                        }}
                        patternAction={val => {
                          props.action(index, 'backgroundPattern', val);
                        }}
                        videoAction={val => {
                          props.action(index, val, 'backgroundVideo');
                        }}
                        videoLoopAction={val => {
                          props.action(index, 'backgroundVideoLoop', val);
                        }}
                        videoAutoPlayAction={val => {
                          props.action(index, 'backgroundVideoAutoPlay', val);
                        }}
      />
    </>
  );
};

export default SlideshowProperties;
