import React from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import Dialog from './Dialog';
import ActionIcon from '../General/ActionIcon';
import { hideModal } from '../../store/actions/modals';

const ConfirmDialog = props => {
  if (!props.isVisible) {
    return null;
  }

  const handleAgreeClick = e => {
    e.preventDefault();

    if (props.payload.action) {
      props.payload.action();
    }

    props.dispatch(hideModal('ConfirmDialog'));
  };

  const handleCancelClick = () => {
    if (props.payload.cancelAction) {
      props.payload.cancelAction();
    }

    props.dispatch(hideModal('ConfirmDialog'));
  };

  return (
    <Dialog title={props.payload.title || fbt('Confirmation', 'ConfirmDialog')}
            name="ConfirmDialog"
            size="modal-xsm" style={{'--z': '9999'}}
            closeAction={handleCancelClick}>
      <div style={{'--bgc': 'var(--sidebar-background)', '--c': 'var(--sidebar-color)', '--p': '20px'}}>

        <div style={{'--ta': 'center'}}>
          <ActionIcon icon="contact_support" size="600"
                      style={{'--c': 'var(--mame-primary)'}}/>
        </div>

        <div style={{'--ta': 'center', '--mt': '20px'}}>
          <h4>{props.payload.text || fbt('No Message', 'ConfirmDialog')}</h4>
        </div>

        <div style={{'--ta': 'center', '--mt': '20px'}}>
          <button className="mame-white" onClick={handleCancelClick}>
            {props.payload.cancelText || fbt('Cancel', 'ConfirmDialog')}
          </button>
          <button className="mame-primary"
                  onClick={handleAgreeClick}>
            {props.payload.continueText || fbt('Continue', 'ConfirmDialog')}
          </button>
        </div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = state => ({
  isVisible: state.modals.ConfirmDialog,
  payload: state.modals.payload.ConfirmDialog,
});

export default connect(mapStateToProps)(ConfirmDialog);
