import React from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import TextInput from '../Controls/TextInput';
import AdvancedColorPicker from '../Controls/AdvancedColorPicker';
import GenericSlider from '../Controls/GenericSlider';
import LinkGroup from '../Controls/LinkGroup';
import BackgroundPicker from '../Controls/BackgroundPicker';
import ItemsFieldsetWrapper from '../Fieldsets/ItemsFieldsetWrapper';
import { ElementColorClasses } from '../../../modules/consts/Elements';
import CheckboxInput from '../Controls/CheckboxInput';
import InputGroup from '../InputGroup';

const PricingTableProperties = props => {
  const {model, element} = props;
  const {componentProperties} = element;

  return (
    <ItemsFieldsetWrapper
      title={fbt('Items', 'PricingTableProperties')}
      items={componentProperties.items}
      actionDispatch={props.dispatchItems}
      template={model.itemTemplate}
      isOpen
    >
      <Controls
        action={props.handleItemChange}/>
    </ItemsFieldsetWrapper>
  );
};

const Controls = props => {
  const item = props.item;
  const index = props.index;

  const colorId = `button-color-${index}`;
  const linkTypeId = `link-type-${index}`;

  return (
    <>
      <InputGroup>
        <TextInput title={fbt('Identifier', 'PricingTableProperties')}
                   value={item.identifier}
                   action={val => props.action(index, 'identifier', val)}
                   showLabel
        />
      </InputGroup>

      <BackgroundPicker title={fbt('Background', 'PricingTableProperties')}
                        type={item.backgroundType}
                        image={item.backgroundImage}
                        color={item.backgroundColor}
                        gradientColor={item.backgroundGradientColor}
                        gradientDegree={item.backgroundGradientDegree}
                        pattern={item.backgroundPattern}
                        typeAction={(val) => {
                          props.action(index, 'backgroundType', val);
                        }}
                        colorAction={(val) => {
                          props.action(index, 'backgroundColor', val);
                        }}
                        imageAction={(val) => {
                          props.action(index, 'backgroundImage', val);
                        }}
                        gradientColorAction={(val) => {
                          props.action(index, 'backgroundGradientColor', val);
                        }}
                        gradientDegreeAction={(val) => {
                          props.action(index, 'backgroundGradientDegree', val);
                        }}
                        patternAction={(val) => {
                          props.action(index, 'backgroundPattern', val);
                        }}
                        showLabel
                        hideFieldset
                        hideVideo
      />

      <InputGroup>
        <TextInput title={fbt('Button Text (optional)', 'PricingTableProperties')}
                   value={item.buttonText}
                   action={(val) => props.action(index, 'buttonText', val)}
                   showLabel
        />
      </InputGroup>

      <InputGroup>
        <GenericSlider title={fbt('Button Size', 'PricingTableProperties')}
                       min="1" max="300"
                       value={item.buttonSize} action={(val) => props.action(index, 'buttonSize', val)}
                       showLabel
        />
      </InputGroup>

      <InputGroup>
        <GenericSlider title={fbt('Fixed Width (optional)', 'PricingTableProperties')}
                       min="0" max="100"
                       value={item.buttonWidth}
                       action={(val) => props.action(index, 'buttonWidth', val)}
                       showLabel
        />
      </InputGroup>

      <AdvancedColorPicker title={fbt('Button Color Style', 'PricingTableProperties')}
                           id={colorId}
                           value={item.buttonBackground}
                           action={(val) => props.action(index, 'buttonBackground', val)}
                           valueText={item.buttonColor}
                           actionText={(val) => props.action(index, 'buttonColor', val)}
      />

      {ElementColorClasses.includes(item.buttonBackground) && (
        <InputGroup>
          <CheckboxInput
            checked={item.buttonOutline}
            title={fbt('Outline only', 'ButtonsProperties')}
            action={() => props.action(index, 'buttonOutline', !item.buttonOutline)}
          />
        </InputGroup>
      )}

      <LinkGroup
        linkTypeId={linkTypeId}
        linkType={item.linkType}
        actionLinkType={(val) => props.action(index, 'linkType', val)}
        link={item.link}
        actionLink={(val) => props.action(index, 'link', val)}
        page={item.page}
        actionPage={(val) => props.action(index, 'page', val)}
        linkTarget={item.linkTarget}
        actionLinkTarget={(val) => props.action(index, 'linkTarget', val)}
        isNofollow={item.isNofollow}
        actionNofollow={val => props.action(index, 'isNofollow', val)}
      />
    </>
  );
};

export default connect()(PricingTableProperties);
