import { toast } from 'react-toastify';

import serviceHelper from '../../modules/serviceHelper';
import { ADD_SITE, REMOVE_SITE, SET_SITES } from '../actionTypes';
import { hideLoading, showLoading } from './modals';
import { apiAddTemplatePage } from './activeSite';
import logger from '../../modules/logger';

export const apiNewSite = (template, redirect) => (
  async (dispatch) => {
    dispatch(showLoading());
    const service = serviceHelper.getService('SiteService');

    const newSite = {
      ...template,
      fromTemplateId: template._id,
      _id: null,
    };

    // We create the site first
    const res = await service.store(newSite);

    logger.info('[API] new site stored', res);
    const item = res.data.item;

    dispatch(addSite(item));

    // Create pages
    for (const page of template.allPages) {
      page.site = item._id;

      // It's reloaded on redirect anyways
      await dispatch(apiAddTemplatePage(page, item._id, false));
    }

    dispatch(hideLoading());
    toast.info('New Site Added.');

    if (redirect) {
      redirect(item._id);
    }
  }
);

/* Get */
export const apiLoadUserSites = () => (
  async (dispatch) => {
    const siteService = serviceHelper.getService('SiteService');

    const res = await siteService.get();

    await dispatch(setSites(res.data.items));
  }
);

export const apiLoadSite = (siteId) => (
  async (dispatch) => {
    const siteService = serviceHelper.getService('SiteService');

    const res = await siteService.get({siteId});

    await dispatch(setSites(res.data.items));
  }
);

/* Delete */
export const apiRemoveSite = siteId => (
  async (dispatch) => {
    const siteService = serviceHelper.getService('SiteService');

    await siteService.delete(siteId);
  }
);


// Normal
export const setSites = sites => ({type: SET_SITES, payload: {sites}});
export const removeSite = siteId => ({type: REMOVE_SITE, payload: {siteId}});
export const addSite = (site) => ({type: ADD_SITE, payload: {site}});
