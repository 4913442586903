import serviceHelper from '../../modules/serviceHelper';
import jwt_decode from 'jwt-decode';
import axios from 'axios';

import { setToken, setUser } from './user';
import { setApiVersion } from './builder';
import logger from '../../modules/logger';

export const loadUserForToken = (token) => (
  (dispatch, getState) => {
    const pingService = serviceHelper.getService('PingService');
    pingService.setToken(token);

    pingService.get().then(res => {
      // console.log(res.data.user);
      dispatch(setUser(res.data.user));
      dispatch(setApiVersion(res.data.apiVersion));
    }).catch(err => {
      logger.info('Error logging in with Token', err);
      dispatch(setToken(null));
      window.localStorage.removeItem('jwtToken');
    });
  }
);

export const loginUserWithJwt = (jwtToken) => (
  (dispatch, getState) => {
    try {
      // Validate it and login (No verification on client side)
      const decoded = jwt_decode(jwtToken);
      const expireTimestamp = decoded.exp * 1000;

      // console.log(decoded);

      if (Date.now() > expireTimestamp) {
        logger.warn('Token Expired', Date.now(), expireTimestamp);
        throw new Error('Token expired');
      }

      dispatch(setToken(decoded.token));
      dispatch(loadUserForToken(decoded.token));
      axios.defaults.headers.token = decoded.token;
    } catch (error) {
      window.localStorage.removeItem('jwtToken');
      logger.warn('Error verifying JWT token', error);
      dispatch(setToken(null));
      document.cookie = 'jwtCookie= ;';
    }
  }
);
