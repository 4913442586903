import { combineReducers } from 'redux';
import activeSite from './activeSite';
import builder from './builder';
import modals from './modals';
import user from './user';
import sites from './sites';
import templates from './templates';
import settings from './settings';
import notifications from './notifications';
import viewerContext from './viewerContext';
import sessions from './sessions';

export default combineReducers({
  activeSite,
  builder,
  modals,
  user,
  sites,
  templates,
  settings,
  notifications,
  viewerContext,
  sessions,
});
