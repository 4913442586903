import React from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import Fieldset from '../FieldSet';
import ItemsFieldsetWrapper from '../Fieldsets/ItemsFieldsetWrapper';
import TextInput from '../Controls/TextInput';
import CheckboxInput from '../Controls/CheckboxInput';
import SelectInput from '../Controls/SelectInput';
import IconPicker from '../Controls/IconPicker';
import InputGroup from '../InputGroup';

const AccordionProperties = props => {
  const {model, element} = props;
  const {componentProperties} = element;

  return (
    <>
      <Fieldset title={fbt('Accordion Properties', 'AccordionProperties')} isOpen>
        <InputGroup style={{'--mt': '10px'}}>
          <SelectInput id="accordion-style"
                       title={fbt('Accordion Style', 'AccordionProperties')}
                       value={componentProperties.style || ''}
                       action={val => {
                         props.handlePropertyChange('style', val);
                       }}
                       options={{
                         '': fbt('Default', 'AccordionProperties'),
                       }}
                       showLabel
          />
        </InputGroup>
      </Fieldset>
      <ItemsFieldsetWrapper
        title={fbt('Items', 'AccordionProperties')}
        items={componentProperties.items}
        actionDispatch={props.dispatchItems}
        template={model.itemTemplate}
        isOpen
      >
        <Controls
          action={props.handleItemChange}/>
      </ItemsFieldsetWrapper>
    </>
  );
};

const Controls = (props) => {
  const item = props.item;
  const index = props.index;

  return (
    <>
      <InputGroup>
        <TextInput title={fbt('Title', 'AccordionProperties')}
                   value={item.title}
                   action={val => props.action(index, 'title', val)}
                   showLabel
                   required
        />
      </InputGroup>

      <InputGroup>
        <CheckboxInput title={fbt('Starts open', 'AccordionProperties')}
                       value={item.isOpen}
                       action={() => {
                         props.action(index, 'isOpen', !item.isOpen);
                       }}
                       showLabel
        />
      </InputGroup>

      <InputGroup>
        <IconPicker icon={item.icon} hasNone minimized
                    title={fbt('Accordion Icon (Optional)', 'AccordionProperties')}
                    action={icon => props.action(index, 'icon', icon)}
        />
      </InputGroup>
    </>
  );
};

export default connect()(AccordionProperties);
