import BaseService from './BaseService';
import axios from 'axios';

const paths = {
  get: '/site',
  post: '/site',
  patch: '/site',
  delete: '/site',
};

/**
 * Service for querying the Sites
 */
class SiteService extends BaseService {
  constructor() {
    super(paths);
    this.requireLogin = true;
  }

  download = siteId => {
    const url = '/site/download';

    return axios.post(url, {siteId});
  };

  checkSubdomain = subDomain => {
    const url = '/site/checkSubDomain';

    return axios.post(url, {subDomain});
  };

  publish = (subDomain, siteId, deployType = 'mame', options = {}) => {
    const url = '/site/publish';

    return axios.post(url, {subDomain, siteId, deployType, options});
  };

  publishAws = (siteId, options = {}) => {
    const url = '/site/publish';

    return axios.post(url, {siteId, deployType: 'aws', options});
  };

  updatePublished = (siteId) => {
    const url = '/site/updatePublished';

    return axios.post(url, {siteId});
  };

  addDomain = (siteId, domain, includeSubdomains) => {
    const url = '/site/addDomain';

    return axios.post(url, {siteId, domain, includeSubdomains});
  }
}

export default new SiteService();
