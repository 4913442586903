import React from 'react';

const CheckboxInput = (props) => {
  const classes = props.required ? 'required mame-primary' : 'mame-primary';

  const style = {
    ...props.style,
    '--d': 'grid',
    '--gtc': '25px auto',
    '--gg': '5px',
    '--ai': 'center'
  };

  return (
    <label htmlFor={props.id || null} style={style} title={props.tooltip || props.label || null}>
      <input type="checkbox"
             id={props.id || null}
             title={props.tooltip || props.label}
             className={classes}
             checked={props.checked || false}
             disabled={props.disabled || null}
             onChange={e => props.action(e.target.value)}
      />
      {props.label || props.title || props.children}
    </label>
  );
};

export default CheckboxInput;
