import React from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import elementHelper from '../../modules/elementHelper';
import ElementDiv from '../ElementControls/ElementDiv';
import componentHelper from '../../modules/componentHelper';
import { ElementColorClasses } from '../../modules/consts/Elements';

import ElementErrorRenderer from '../ElementControls/ElementErrorRenderer';
import DropZone from '../Sortable/DropZone';
import { setActiveElement } from '../../store/actions/builder';
import { isPreviewSelector } from '../../store/selectors';

const FormElement = props => {
  const element = props.element;

  const id = elementHelper.getId(element);
  const classes = elementHelper.getClasses(element, 'mame-form');
  const style = elementHelper.getStyle(element);

  const setActive = (e) => {
    // Make sure to only trigger grid
    if (!e.target.classList.contains('mame-form') && !e.target.classList.contains('form-caption')) {
      return;
    }

    props.dispatch(setActiveElement(element));
  };

  return (
    <ElementDiv {...props} id={id} classes={classes} style={style} action={setActive}>
      <DropZone element={element}
                items={element.elements}>
        <FormRenderer classes={classes}
                      style={style}
                      element={element}
                      elements={props.elements}
                      dispatch={props.dispatch}
                      addElement={props.addElement}
                      isPreview={props.isPreview}
                      setActive={setActive}
        />
      </DropZone>
    </ElementDiv>
  );
};

const FormRenderer = props => {
  const {element} = props;
  const {componentProperties} = element;

  const formId = elementHelper.getId(element, 'form-');

  const handleOnSubmit = e => {
    e.preventDefault();
  };

  const submitStyle = {};
  const formStyle = {};

  let submitClasses = null;
  let formClasses = null;

  if (componentProperties.submitBackground) {
    // Check if it's a class
    if (ElementColorClasses.includes(componentProperties.submitBackground)) {
      submitClasses = componentProperties.submitBackground;

      if (componentProperties.submitOutline) {
        submitClasses += ' outline';
      }
    } else {
      submitStyle['--bg'] = componentProperties.submitBackground;
      submitStyle['--c'] = componentProperties.submitColor || '#f1f1f1';
    }
  }

  if (props.isOverCurrent) {
    formStyle['--bgc'] = 'var(--form)';
    formClasses += ' mame-dropzone';
  }

  const CustomTag = componentProperties.titleTag || 'h3';

  const formAction = componentProperties.action === 'custom'
    ? componentProperties.customActionAddress
    : 'https://api.mame.app/v1/userform';

  return (
    <form id={formId}
          style={formStyle}
          ref={props.dropRef}
          className={formClasses}
          action={formAction}
          aria-label={componentProperties.title}
          method="post"
          onSubmit={handleOnSubmit}
          data-success-text={componentProperties.successText || fbt('Thank you for your message.', 'Element Form')}
          data-form-action={componentProperties.action}
          data-form={element._id}>
      {componentProperties.titleTag && (
        <CustomTag className="form-caption" onClick={props.setActive}>
          {componentProperties.title}
        </CustomTag>
      )}

      <div className="form-success" style={{
        '--d': 'none',
        '--bgc': 'var(--green)',
        '--c': 'var(--white)',
        '--p': '10px 20px',
      }}/>

      <div className="form-error" style={{
        '--d': 'none',
        '--bgc': 'var(--red)',
        '--c': 'var(--white)',
        '--p': '10px 20px',
      }}/>

      <div>
        {props.sortingItems.map((elementId, index) => {
          const subElement = props.elements.find(e => e._id === elementId);

          if (!subElement) {
            return (
              <ElementErrorRenderer key={elementId}
                                    id={elementId}
                                    parentType="element"
                                    parentId={element._id}
              />
            );
          }

          return componentHelper.getElementComponent(
            subElement,
            {
              parent: element,
              parentType: 'element',
              index,
              resortElement: props.resortOverwrite,
              resortEnd: props.endOverwrite,
            }
          );
        })}

        {!props.isPreview && (
          <div className="mame-new-element"
               onClick={e => props.addElement(e, props.dispatch)}
               title={fbt('Add another Field or Element', 'Element Form')}>
            <span className="icon icon-plus icon-2x"/>
          </div>
        )}

        {props.isOverCurrent && (
          <div style={{'--d': 'grid', '--ai': 'center', '--jc': 'center', '--h': '100px'}}>
            <fbt desc="Element Form">
              Move Element to Form
            </fbt>
          </div>
        )}
      </div>

      <input type="hidden" name="formId" defaultValue={element._id}/>

      <div>
        <input type="submit" value={componentProperties.submitText || ''}
               style={submitStyle} className={submitClasses}
        />
      </div>
    </form>
  );
};

const mapStateToProps = state => ({
  isPreview: isPreviewSelector(state),
  elements: state.activeSite.elements,
});

export default connect(mapStateToProps)(FormElement);
