export const DEBUG = 0;
export const INFO = 1;
export const WARN = 2;
export const ERROR = 3;

export const levelToString = (level) => {
  if (level === DEBUG) {
    return 'debug';
  }

  if (level === INFO) {
    return 'info';
  }

  if (level === WARN) {
    return 'warn';
  }

  if (level === ERROR) {
    return 'warn';
  }
}

/**
 * Simple Logger
 *
 * @type {{warn(): void, debug(): void, _out(*=, *=): void, level: (number), logToConsole: boolean, completeLog: *[], _log(*=, *=): void, errorLog: *[], error(): void, info(): void}}
 */
const logger = {
  completeLog: [],
  errorLog: [],

  /**
   * Default Log Level
   */
  level: process.env.NODE_ENV === 'development' ? DEBUG : WARN,

  /**
   * Log messages to console
   */
  logToConsole: true,

  debug() {
    this._log(arguments, DEBUG);
  },

  info() {
    this._log(arguments, INFO);
  },

  warn() {
    this._log(arguments, WARN);
  },

  error() {
    this._log(arguments, ERROR);
  },


  _log(argumentsObj, level = INFO) {
    const args = Array.prototype.slice.call(argumentsObj);

    // All Messages
    this.completeLog.push({
      date: new Date(),
      messages: args,
      level,
    });

    // Error log
    if (level > WARN) {
      this.errorLog.push({
        date: new Date(),
        messages: args,
        level,
      });
    }

    // Log to console
    if (this.logToConsole && level >= this.level) {
      this._out(args, levelToString(this.level));
    }
  },

  _out(args, type = 'info') {
    console[type](...args);
  },
};

export default logger;
