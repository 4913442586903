import React from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import Fieldset from '../FieldSet';
import AdvancedColorPicker from '../Controls/AdvancedColorPicker';
import LinkGroup from '../Controls/LinkGroup';
import IconPicker from '../Controls/IconPicker';
import GenericSlider from '../Controls/GenericSlider';
import BackgroundPicker from '../Controls/BackgroundPicker';
import HelpText from '../Controls/HelpText';
import TextInput from '../Controls/TextInput';
import InputGroup from '../InputGroup';

const IconFigureProperties = props => {
  const {element} = props;
  const {componentProperties} = element;

  return (
    <Fieldset title={fbt('Icon Figure Properties', 'IconFigureProperties')} isOpen>
      <HelpText style={{'--m': '10px 0'}}>
        <fbt desc="IconFigureProperties">
          Add and edit the content directly on the element.
        </fbt>
      </HelpText>

      <InputGroup>
        <IconPicker icon={componentProperties.icon}
                    title={fbt('Icon', 'IconFigureProperties')}
                    action={icon => props.handlePropertyChange('icon', icon)}
                    showLabel
        />
      </InputGroup>

      <InputGroup>
        <TextInput title={fbt('Icon Title', 'IconFigureProperties')}
                   value={componentProperties.iconTitle}
                   action={val => props.handlePropertyChange('iconTitle', val)}
                   showLabel
        />
      </InputGroup>


      <InputGroup>
        <GenericSlider title={fbt('Icon Size', 'IconFigureProperties')}
                       min="20" max="1500" step="10" showLabel
                       id="slider-icon-size"
                       value={componentProperties.iconSize}
                       action={val => props.handlePropertyChange('iconSize', val)}
        />
      </InputGroup>

        <AdvancedColorPicker value={componentProperties.iconColor}
                             title={fbt('Icon Color', 'IconFigureProperties')}
                             id="select-icon-color"
                             action={val => props.handlePropertyChange('iconColor', val)}
        />

      <BackgroundPicker title={fbt('Custom Background', 'IconFigureProperties')}
                        showLabel
                        hasNone
                        hideFieldset
                        hideVideo
                        type={componentProperties.backgroundType}
                        image={componentProperties.backgroundImage}
                        color={componentProperties.backgroundColor}
                        gradientColor={componentProperties.backgroundGradientColor}
                        gradientDegree={componentProperties.backgroundGradientDegree}
                        pattern={componentProperties.backgroundPattern}
                        typeAction={(val) => {
                          props.handlePropertyChange('backgroundType', val);
                        }}
                        colorAction={(val) => {
                          props.handlePropertyChange('backgroundColor', val);
                        }}
                        imageAction={(val) => {
                          props.handlePropertyChange('backgroundImage', val);
                        }}
                        gradientColorAction={(val) => {
                          props.handlePropertyChange('backgroundGradientColor', val);
                        }}
                        gradientDegreeAction={(val) => {
                          props.handlePropertyChange('backgroundGradientDegree', val);
                        }}
                        patternAction={(val) => {
                          props.handlePropertyChange('backgroundPattern', val);
                        }}
      />

      <LinkGroup
        linkType={componentProperties.linkType}
        actionLinkType={val => props.handlePropertyChange('linkType', val)}
        link={componentProperties.link}
        actionLink={val => props.handlePropertyChange('link', val)}
        page={componentProperties.page}
        actionPage={val => props.handlePropertyChange('page', val)}
        linkTarget={componentProperties.linkTarget}
        actionLinkTarget={val => props.handlePropertyChange('linkTarget', val)}
        isNofollow={componentProperties.isNofollow}
        actionNofollow={val => props.handlePropertyChange('isNofollow', val)}
      />
    </Fieldset>
  );
};

export default connect()(IconFigureProperties);
