import React, { useEffect, useState } from 'react';
import fbt from 'fbt';

import Fieldset from '../FieldSet';
import ActionIcon from '../../General/ActionIcon';
import DnDContainer from '../../Sortable/DnDContainer';
import DnDItem from '../../Sortable/DnDItem';
import { DragTypes } from '../../../modules/DragTypes';

const ItemsFieldsWrapper = props => {
  const [sortedItems, setSortedItems] = useState(props.items);

  useEffect(() => {
    setSortedItems(props.items);
  }, [props.items]);

  const dispatchItems = items => {
    props.actionDispatch(items);
  };

  const add = () => {
    const items = [...props.items];

    const newButton = JSON.parse(JSON.stringify(props.template));
    newButton.itemId = Date.now();

    items.push(newButton);

    dispatchItems(items);
  };

  const clone = (e, index) => {
    e.preventDefault();
    // TODO elements
    const items = JSON.parse(JSON.stringify(props.items));
    const clonedElement = {...items[index]};
    clonedElement.elements = [];
    items.push(clonedElement);
    dispatchItems(items);
  };

  const remove = (e, index) => {
    e.preventDefault();
    const items = JSON.parse(JSON.stringify(props.items));
    items.splice(index, 1);

    dispatchItems(items);
  };

  const storeSorting = newSorting => {
    dispatchItems(newSorting);
  };

  return (
    <Fieldset title={props.title || ''} isOpen={props.isOpen || false}>
      <DnDContainer items={sortedItems}
                    onMove={list => {
                      setSortedItems(list);
                    }}
                    group="items"
                    dragType={DragTypes.ITEM}
                    onEnd={storeSorting}
                    dragHandler
      >
        {sortedItems.map((item, index) => {
          return (
            <DnDItem key={item.itemId || index} index={index} dragHandler>
              <ItemDetails
                {...props}
                key={item.itemId || index}
                index={index}
                item={item}
                clone={clone}
                remove={remove}
              />
            </DnDItem>
          );
        })}
      </DnDContainer>
      <div style={{'--ta': 'right'}}>
        <button className="mame-primary" onClick={add}>
          <fbt desc="ItemsFieldsetWrapper">
            Add Item
          </fbt>
        </button>
      </div>
    </Fieldset>
  );
};

const ItemDetails = props => {
  const {item} = props;

  const contentWithProps = React.Children.map(props.children, child => {
    const addProps = {
      item,
      index: props.index,
    };

    return React.cloneElement(props.children, addProps);
  });

  const style = {};

  if (props.isDragging) {
    style['--bg'] = 'var(--grey)';
  }

  return (
    <details style={style}
             ref={props.dndPreviewRef}>
      <summary style={{'--d': 'flex', '--cur': 'pointer', '--ai': 'center'}}>
        <div style={{'--fg': 1}}>
          {item.title || item.identifier || item.text}
        </div>
        <div style={{'--ta': 'right'}}>
          <ActionIcon icon="hand-grab-o"
                      title={fbt('Change ordering (drag and drop)', 'ItemsFieldsetWrapper')}
                      cursor="move"
                      actionRef={props.dndDragRef}/>
          <ActionIcon icon="clone"
                      title={fbt('Duplicate Item', 'ItemsFieldsetWrapper')}
                      action={e => props.clone(e, props.index)}/>
          <ActionIcon icon="trash-o"
                      title={fbt('Remove Item', 'ItemsFieldsetWrapper')}
                      action={e => props.remove(e, props.index)}/>
        </div>
      </summary>
      <div>
        {contentWithProps}
      </div>
    </details>
  );
};

export default ItemsFieldsWrapper;
