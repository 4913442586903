import React, { useState } from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import TopMenu from '../components/Menu/TopMenu';
import SharedModals from '../components/Modals/SharedModals';

import { useParams } from 'react-router-dom';
import Loading from '../components/General/Loading';

const PreviewIframe = props => {
  const {siteId, route} = useParams();

  const [device, setDevice] = useState('xl');
  const [width, setWidth] = useState('100%');
  const [height, setHeight] = useState('100vh');
  const [marginTop, setMarginTop] = useState('0');

  const dimensions = {
    xs: {
      width: '375px',
      height: '812px',
    },
    small: {
      width: '812px',
      height: '375px',
    },
    medium: {
      width: '768px',
      height: '1024px',
    },
    large: {
      width: '1024px',
      height: '768px',
    },
    xl: {
      width: '100%',
      height: '100vh',
    },
  };

  const handleSizeClick = (e, device) => {
    e.preventDefault();
    e.stopPropagation();

    setDevice(device);
    setWidth(dimensions[device]['width']);
    setHeight(dimensions[device]['height']);

    if (device === 'xl') {
      setMarginTop('0');
      return;
    }

    setMarginTop('50px');
  };

  if (!props.site || !props.pages || !props.page || !props.sections || !props.elements) {
    return <Loading/>;
  }

  return (
    <>
      <TopMenu isPreview/>
      <div className="preview-iframe" style={{'--bg': '#f9f9f9', '--minh': '100vh', '--pt': marginTop}}>
        <iframe src={`/preview-content/${siteId}/${route}`} title="Site PreviewContent"
                style={{
                  '--d': 'block',
                  '--w': width,
                  '--minh': height,
                  '--b': 0,
                  '--m': `0 auto`,
                }}>
        </iframe>

        <div className="responsive-overlay" style={{
          '--pos': 'fixed',
          '--top': '200px',
          '--left': '0px',
          '--w': '35px',
          '--bg': '#fafafa',
          '--b': '1px solid #ccc',
          '--bl': 0,
          '--bb': 0,
          '--z': 999,
          '--ta': 'center',
          '--size': '130%',
          '--radius': '0 5px 5px 0',
          '--bs': '3px 4px 5px #ddd',
        }}>
          <button className={'action-icon-button ' + (device === 'xs' ? 'mame-primary' : 'transparent')}
                  style={{'--w': '100%', '--radius': '0 5px 0 0'}}
                  title={fbt('Extra Small (< 640px)', 'PreviewIframe')}
                  onClick={e => {
                    handleSizeClick(e, 'xs');
                  }}>
            <div className="icon icon-mobile"/>
          </button>

          <button className={'action-icon-button ' + (device === 'small' ? 'mame-primary' : 'transparent')}
                  style={{'--w': '100%', '--radius': '0'}}
                  title={fbt('Small (>= 640px)', 'PreviewIframe')}
                  onClick={e => {
                    handleSizeClick(e, 'small');
                  }}>
            <div className="icon icon-mobile icon-rotate-90deg"/>
          </button>

          <button className={'action-icon-button ' + (device === 'medium' ? 'mame-primary' : 'transparent')}
                  style={{'--w': '100%', '--radius': '0'}}
                  title={fbt('Medium (>= 768px)', 'PreviewIframe')}
                  onClick={e => {
                    handleSizeClick(e, 'medium');
                  }}>
            <div className="icon icon-tablet"/>
          </button>

          <button className={'action-icon-button ' + (device === 'large' ? 'mame-primary' : 'transparent')}
                  style={{'--w': '100%', '--radius': '0'}}
                  title={fbt('Large (>= 1024px)', 'PreviewIframe')}
                  onClick={e => {
                    handleSizeClick(e, 'large');
                  }}>
            <div className="icon icon-tablet icon-rotate-90deg"/>
          </button>

          <button className={'action-icon-button ' + (device === 'xl' ? 'mame-primary' : 'transparent')}
                  style={{'--w': '100%', '--radius': '0 0 5px 0', '--b': 0}}
                  title={fbt('Extra Large (>= 1280px)', 'PreviewIframe')}
                  onClick={e => {
                    handleSizeClick(e, 'xl');
                  }}>
            <div className="icon icon-desktop"/>
          </button>
        </div>
      </div>
      <SharedModals/>
    </>
  );
};

const mapStateToProps = state => ({
  page: state.activeSite.page,
  site: state.activeSite.site,
  sections: state.activeSite.sections,
  elements: state.activeSite.elements,
  pages: state.activeSite.pages,
  sites: state.sites.sites,
});

export default connect(mapStateToProps)(PreviewIframe);
