import React, { useState } from 'react';
import fbt from 'fbt';

import Fieldset from '../FieldSet';
import GenericSlider from '../Controls/GenericSlider';
import SelectInput from '../Controls/SelectInput';
import AdvancedColorPicker from '../Controls/AdvancedColorPicker';
import InputGroup from '../InputGroup';

const BorderFieldset = props => {
  const [isOpen, setOpen] = useState(false);

  const openIcon = `icon icon-${isOpen ? 'angle-down' : 'angle-right'}`;

  const handleBorderWidthChange = (index, value) => {
    // Lazy solution for immer
    const copy = JSON.parse(JSON.stringify(props.borderWidth));
    copy[index] = value;

    props.borderWidthAction(copy);
  };

  const applyBorderSizeToAll = val => {
    const copy = JSON.parse(JSON.stringify(props.borderWidth));

    for (let i = 0; i < 4; i++) {
      copy[i] = val;
    }

    props.borderWidthAction(copy);
  };

  return (
    <Fieldset title={fbt('Border and Shadow', 'BorderFieldset')}>
      <InputGroup style={{'--mt': '10px'}}>
        <SelectInput title={fbt('Border Type', 'BorderFieldset')}
                     value={props.borderType}
                     action={props.borderTypeAction}
                     options={{
                       '': fbt('None', 'BorderFieldset'),
                       'solid': fbt('Solid', 'BorderFieldset'),
                       'dotted': fbt('Dotted', 'BorderFieldset'),
                       'double': fbt('Double', 'BorderFieldset'),
                       'dashed': fbt('Dashed', 'BorderFieldset'),
                       'groove': fbt('Groove', 'BorderFieldset'),
                       'ridge': fbt('Ridge', 'BorderFieldset'),
                       'inset': fbt('Inset', 'BorderFieldset'),
                       'hidden': fbt('Hidden', 'BorderFieldset'),
                     }}
                     showLabel
        />
      </InputGroup>

      {props.borderType && (
        <InputGroup>
          <GenericSlider value={props.border || 0} min="0" max="100"
                         title={fbt('Border Size', 'BorderFieldset')}
                         showLabel action={props.borderAction}
          />
          <div style={{'--my': '10px'}}>
            <div
              role="button"
              style={{'--cur': 'pointer'}}
              onClick={() => {
                setOpen(!isOpen);
              }}>
              <span className={`icon icon-${openIcon}`} style={{'--mr': '10px'}}/>
              <fbt desc="BorderFieldset">
                Advanced Border Sizing
              </fbt>
            </div>

            {isOpen && props.borderWidth && (
              <InputGroup style={{'--mt': '10px'}}>
                <GenericSlider value={props.borderWidth[0]} min="0" max="100"
                               title={fbt('Border Top', 'BorderFieldset')}
                               icon="border_top"
                               applyToAll={applyBorderSizeToAll}
                               action={val => handleBorderWidthChange(0, val)}
                />

                <GenericSlider value={props.borderWidth[1]} min="0" max="100"
                               title={fbt('Border Right', 'BorderFieldset')}
                               icon="border_right"
                               applyToAll={applyBorderSizeToAll}
                               action={val => handleBorderWidthChange(1, val)}
                />

                <GenericSlider value={props.borderWidth[2]} min="0" max="100"
                               title={fbt('Border Bottom', 'BorderFieldset')}
                               icon="border_bottom"
                               applyToAll={applyBorderSizeToAll}
                               action={val => handleBorderWidthChange(2, val)}
                />

                <GenericSlider value={props.borderWidth[3]} min="0" max="100"
                               title={fbt('Border Left', 'BorderFieldset')}
                               icon="border_left"
                               applyToAll={applyBorderSizeToAll}
                               action={val => handleBorderWidthChange(3, val)}
                />
              </InputGroup>
            )}
          </div>
        </InputGroup>
      )}

      {props.borderType && <AdvancedColorPicker title={fbt('Border Color', 'BorderFieldset')}
                                                value={props.borderColor}
                                                action={props.borderColorAction}
      />}

      <InputGroup>
        <GenericSlider value={props.borderRadius}
                       title={fbt('Border Radius', 'BorderFieldset')}
                       action={props.borderRadiusAction}
                       min="0" max="100"
                       showLabel
        />
      </InputGroup>

      <InputGroup>
        <GenericSlider value={props.levitate}
                       title={fbt('Levitate', 'BorderFieldset')}
                       action={props.levitateAction}
                       min="0" max="20"
                       showLabel
        />
      </InputGroup>

      <InputGroup>
        <GenericSlider value={props.shadow}
                       title={fbt('Shadow', 'BorderFieldset')}
                       action={props.shadowAction}
                       min="0" max="30"
                       showLabel
        />
      </InputGroup>

      <InputGroup>
        <SelectInput title={fbt('Shadow Type', 'BorderFieldset')}
                     value={props.shadowType}
                     action={props.shadowTypeAction}
                     options={{
                       '': fbt('Default Shadow', 'BorderFieldset'),
                       'soft': fbt('Soft', 'BorderFieldset'),
                       'hard': fbt('Hard', 'BorderFieldset'),
                       'vert': fbt('Vertical', 'BorderFieldset'),
                       'inset': fbt('Inset', 'BorderFieldset'),
                     }}
                     showLabel
        />
      </InputGroup>
    </Fieldset>
  );
};

export default BorderFieldset;
