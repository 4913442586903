import * as services from './services/';

const serviceHelper = {
  /**
   * Get the Service
   *
   * @param name
   * @return BaseService|any
   */
  getService(name) {
    return services[name];
  },

  debounce(func, wait) {
    let timeout;
    return function (...args) {
      const context = this;

      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args);
      }, wait);
    };
  }
};

export default serviceHelper;
