import React, { useEffect, useState } from 'react';
import { updatePageProperty } from '../../store/actions/activePage';
import DnDContainer from '../Sortable/DnDContainer';
import DnDItem from '../Sortable/DnDItem';
import fbt from 'fbt';
import ActionIcon from '../General/ActionIcon';
import { removeSection } from '../../store/actions/activeSite';
import { setActiveElement, setActiveSection } from '../../store/actions/builder';
import { connect } from 'react-redux';

const PageSectionOverview = props => {
  const [sortingElements, setSortingElements] = useState(props.page.sections);

  const {page} = props;

  useEffect(() => {
    setSortingElements(page.sections);
  }, [page]);

  const storeSorting = newSorting => {
    props.dispatch(updatePageProperty(page, 'sections', null, newSorting));
  };

  return (
    <div>
      <DnDContainer items={sortingElements}
                    onMove={updatedList => {
                      setSortingElements(updatedList);
                    }}
                    group="page-sections"
                    onEnd={storeSorting}>
        {sortingElements.map((sectionId, index) => {
          const section = props.sections.find(s => s._id === sectionId);

          if (!section) {
            return (
              <SectionNotFoundRender
                {...props}
                key={sectionId}
                sectionId={sectionId}
              />
            );
          }

          const elements = props.elements.filter(e => section.elements.includes(e._id));

          return (
            <DnDItem key={sectionId} id={sectionId} index={index}>
              <SectionItemRender
                {...props}
                key={sectionId}
                section={section}
                elements={elements}
              />
            </DnDItem>
          );
        })}
      </DnDContainer>
    </div>
  );
};

const SectionNotFoundRender = props => {
  return (
    <div key={props.sectionId}
         style={{'--d': 'flex', '--cur': 'pointer', '--ai': 'center'}}>
      <div style={{'--fg': '1'}}>
        <fbt desc="PageProperties">
          Unknown section
        </fbt>
        ({props.sectionId})
      </div>
      <div>
        <ActionIcon icon="trash-o"
                    title={fbt('Remove', 'PageProperties')}
                    action={(e) => {
                      e.preventDefault();
                      props.dispatch(removeSection(props.sectionId, props.page._id));
                    }}/>
      </div>
    </div>
  );
};

const SectionItemRender = props => {
  const style = {
    '--b': '1px solid var(--section)',
    '--my': '5px',
    '--p': '5px',
    '--cur': 'pointer'
  };

  if (props.isDragging) {
    style['--bg'] = 'var(--section)';
  }

  return (
    <div key={props.section._id}
         ref={props.dndDragRef}
         style={style}>
      <div style={{'--d': 'flex', '--cur': 'pointer', '--ai': 'center'}}
           onClick={e => {
             e.preventDefault();
             props.dispatch(setActiveSection(props.section));
           }}>
        <div style={{'--fg': 1}}>
          <b>{props.section.identifier}</b>
        </div>
        <div>
          <ActionIcon icon="hand-grab-o"
                      title={fbt('Change Order (Drag and Drop)', 'PageProperties')}
                      cursor="move"/>
          <ActionIcon icon="trash-o" title={fbt('Remove', 'PageProperties')} action={(e) => {
            e.preventDefault();
            e.stopPropagation();
            props.dispatch(removeSection(props.section._id, props.page._id));
          }}/>
        </div>
      </div>
      <div>
        {props.elements.map(element => {
          return (
            <div
              key={element._id}
              style={{'--b': '1px solid var(--element)', '--my': '3px', '--p': '5px', '--cur': 'pointer'}}
              onClick={e => {
                e.preventDefault();
                props.dispatch(setActiveElement(element));
              }}>
              {element.identifier}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  sections: state.activeSite.sections,
  elements: state.activeSite.elements,
});

export default connect(mapStateToProps)(PageSectionOverview);
