import {
  CLOSE_ACTIVE_PROPERTY,
  SET_ACTIVE_ELEMENT,
  SET_ACTIVE_SECTION,
  SET_ACTIVE_TYPE,
  SET_API_VERSION,
  SET_CLIPBOARD_ELEMENT_STYLE,
  SET_CLIPBOARD_SECTION_STYLE,
  SET_PREVIEW,
  SET_RENDER,
  SET_SIDEBAR_VIEW,
} from '../actionTypes';

const initialState = {
  sidebarView: 'site',
  activeSection: null,
  activeElement: null,
  apiVersion: '',
  isPreview: false,
  isRender: false,
  clipboardSectionStyle: null,
  clipboardElementStyle: null,
};

const builderState = (state = initialState, action) => {
  switch (action.type) {
    // Set the active type
    case SET_ACTIVE_TYPE: {
      return {
        ...state, ...{
          activeType: action.payload.type,
        }
      };
    }

    case SET_SIDEBAR_VIEW: {
      return {
        ...state, ...{
          sidebarView: action.payload.view,
        }
      };
    }

    case SET_ACTIVE_ELEMENT: {
      if (state.activeElement === action.payload.element._id && state.sidebarView === 'element') {
        return state;
      }

      return {
        ...state, ...{
          activeElement: action.payload.element._id,
          activeType: 'element',
          sidebarView: 'element',
        }
      };
    }

    case SET_ACTIVE_SECTION: {
      if (state.activeSection === action.payload.section._id && state.sidebarView === 'section') {
        return state;
      }

      return {
        ...state, ...{
          activeSection: action.payload.section._id,
          activeType: 'section',
          sidebarView: 'section',
        }
      };
    }

    case CLOSE_ACTIVE_PROPERTY: {
      return {
        ...state, ...{
          activeSection: null,
          activeElement: null,
          activeType: null,
          sidebarView: 'site',
        }
      };
    }

    case SET_PREVIEW: {
      const {preview} = action.payload;

      return {
        ...state,
        isPreview: preview,
      };
    }

    case SET_RENDER: {
      const {render} = action.payload;

      return {
        ...state,
        isRender: render,
      };
    }

    case SET_API_VERSION: {
      const {apiVersion} = action.payload;

      return {
        ...state,
        apiVersion,
      };
    }

    case SET_CLIPBOARD_SECTION_STYLE: {
      const {clipboardSectionStyle} = action.payload;

      return {
        ...state,
        clipboardSectionStyle,
      };
    }

    case SET_CLIPBOARD_ELEMENT_STYLE: {
      const {clipboardElementStyle} = action.payload;

      return {
        ...state,
        clipboardElementStyle,
      };
    }

    default:
      return state;
  }
};

export default builderState;
