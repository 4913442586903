import React from 'react';
import ActionIcon from '../General/ActionIcon';
import fbt from 'fbt';

// Preview for Models and Templates, not existing sites
const SitePreview = props => {
  const defaultImg = '/img/section/no-preview.jpg';

  return (
    <figure className="preview site-preview accent"
            style={{'--levitate-hvr': 5, '--of': 'hidden'}}
            data-site={props.title}
            onClick={props.action}>
      {props.icon && (
        <div style={{'--h': '250px', '--d': 'flex', '--ai': 'center', '--jc': 'center'}}>
          <span className={`icon icon-${props.icon}`} style={{'--size': '800%'}}/>
        </div>
      )}

      {props.previewImg && (
        <div style={{'--of': 'hidden', '--h': '250px'}}>
          <img src={props.previewImg} onError={(e) => {
            e.target.onerror = null;
            e.target.src = defaultImg;
          }} alt="Preview"/>
        </div>
      )}
      <figcaption>
        <h4 style={{'--line-clamp': 1}}>{props.title}</h4>
      </figcaption>

      {!props.hideMenu && (
        <div className="preview-menu"
             onClick={(e) => {
               e.stopPropagation();
             }}
        >
          {props.makeScreenshot && (
            <>
              <ActionIcon icon="image"
                          title={fbt('Update Screenshot', 'SectionPreview')}
                          action={props.makeScreenshot}
              />
              <br/>
            </>
          )}
          {props.markAsFavorite && (
            <>
              <ActionIcon icon={props.favorite ? 'star' : 'star-o'}
                          title={props.favorite ? fbt('Unstar', 'SectionPreview') : fbt('Mark as Favorite', 'SectionPreview')}
                          action={props.markAsFavorite}/><br/>
            </>
          )}

          {props.handleDelete && (
            <>
              <ActionIcon icon="trash-o"
                          title={fbt('Delete Template', 'SectionPreview')}
                          action={props.handleDelete}/><br/>
              <ActionIcon icon="shopping-basket"
                          title={fbt('Offer Site as Template', 'SectionPreview')}
                          action={props.sell}/><br/>
            </>
          )}
        </div>
      )}
    </figure>
  );
};

export default SitePreview;
