import BreadcrumbProperties from '../../components/Properties/Elements/BreadcrumbProperties';
import { ElementVisible } from '../../modules/consts/Elements';

const BreadcrumbModel = {
  title: 'Breadcrumb',
  category: 'Basic',
  helpPage: '/documentation/elements/breadcrumb',
  component: 'BreadcrumbElement',
  propertyComponent: BreadcrumbProperties,
  hasChildElements: false,
  generalProperties: {
    hasVisibility: true,
    hasTextAlign: true,
    hasTextColor: false,
  },
  defaults: {
    demo: true,
    _id: null,
    identifier: 'New Breadcrumb',
    component: 'BreadcrumbElement',
    componentProperties: {
      hasLinks: true,
      hasHome: true,
      linkColor: '',
    },
    generalProperties: {
      visibility: [ElementVisible.sm, ElementVisible.md, ElementVisible.lg, ElementVisible.xl],
      published: true,
      margin: [0, 0, 0, 0],
      padding: [0, 0, 0, 0],
    },
  },
  itemTemplate: {
  },
};

export default BreadcrumbModel;
