import serviceHelper from '../../modules/serviceHelper';
import { REMOVE_SESSION, SET_SESSIONS } from '../actionTypes';

export const apiLoadUserSessions = () => (
  async (dispatch) => {
    const service = serviceHelper.getService('SessionService');

    const res = await service.get();

    await dispatch(setSessions(res.data.items));
  }
);

export const apiRemoveSession = sessionId => (
  async (dispatch) => {
    const service = serviceHelper.getService('SessionService');

    await service.delete(sessionId);
  }
);

// Normal actions
export const setSessions = sessions => ({type: SET_SESSIONS, payload: {sessions}});
export const removeSession = sessionId => ({type: REMOVE_SESSION, payload: {sessionId}});
