import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Mousetrap from 'mousetrap';
import fbt from 'fbt';

import { hideModal } from '../../store/actions/modals';

const Dialog = props => {
  const {closeAction, dispatch, name} = props;

  const handleClose = e => {
    if (!(e.target.classList.contains('modal-fade') || e.target.classList.contains('icon-times'))) {
      return;
    }

    // For resetting states etc.
    if (closeAction) {
      closeAction();
    }

    props.dispatch(hideModal(name));
  };

  // Shortcuts
  useEffect(() => {
    Mousetrap.bind('esc', () => {
      if (closeAction) {
        closeAction();
      }

      dispatch(hideModal(name));
    });

    return () => {
      Mousetrap.unbind('esc');
    };
  }, [name, dispatch, closeAction]);

  const className = ['modal'];

  if (props.size) {
    className.push(props.size);
  }

  return (
    <div className="modal-fade" onClick={handleClose} style={props.style || null}>
      <div className={className.join(' ')} style={{'--mt': '70px'}} role="dialog">
        <div className="modal-inner gradient-special">
          {!props.hideHeader && (
            <div className="modal-close" style={{'--p': '20px 15px 20px 15px', '--d': 'flex'}}>
              <div style={{'--ta': 'left', '--fg': '1'}}>
                <h5 style={{'--p': 0, '--m': 0, '--text-c': '#fff'}}>
                  {props.title || ''}
                </h5>
              </div>
              <span className="icon icon-times"
                    role="button"
                    style={{'--cur': 'pointer', '--c': '#fff'}}
                    title={fbt('Close', 'Dialog')}
                    onClick={handleClose}
              />
            </div>
          )}

          <div className="modal-body">
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect()(Dialog);
