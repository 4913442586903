import ButtonsProperties from '../../components/Properties/Elements/ButtonsProperties';
import { ElementVisible } from '../../modules/consts/Elements';

const ButtonsModel = {
  title: 'Button(s)',
  category: 'Basic',
  helpPage: '/documentation/elements/buttons',
  component: 'ButtonsElement',
  propertyComponent: ButtonsProperties,
  // Handled differently
  hasChildElements: false,
  generalProperties: {
    hasVisibility: true,
    hasTextAlign: true,
    hasTextColor: false,
    hasBackground: true,
  },
  defaults: {
    _id: null,
    demo: true,
    identifier: 'New Button(s)',
    component: 'ButtonsElement',
    componentProperties: {
      isGroup: false,
      items: [
        {
          itemId: 42,
          title: 'New Button',
          icon: '',
          linkType: '',
          page: '',
          link: '',
          linkTarget: '_self',
          isNofollow: false,
          background: 'primary',
          color: '#f1f1f1',
          outline: false,
          size: 100,
          width: 0,
        },
      ],
    },
    generalProperties: {
      visibility: [ElementVisible.sm, ElementVisible.md, ElementVisible.lg, ElementVisible.xl],
      published: true,
      margin: [0, 0, 0, 0],
      padding: [0, 0, 0, 0],
      borderRadius: 0,
      levitate: 0,
      customId: '',
      display: 'block',
    },
  },
  itemTemplate: {
    itemId: 0,
    title: 'New Button',
    icon: '',
    linkType: '',
    page: '',
    link: '',
    linkTarget: '_self',
    isNofollow: false,
    background: 'primary',
    color: '#f1f1f1',
    size: 100,
    width: 0,
    outline: false,
  }
};

export default ButtonsModel;
