import propertyHelper from './propertyHelper';

const sectionHelper = {
  getClasses(section) {
    const classes = ['mame-section'];

    if (section.generalProperties.customClasses) {
      classes.push(section.generalProperties.customClasses);
    }

    propertyHelper.setVisibility(classes, section.generalProperties);

    if (section.generalProperties.animation) {
      classes.push('animate');
      classes.push(`animate-${section.generalProperties.animation}`);
    }

    return classes.join(' ');
  },

  getStyle(section) {
    const p = section.generalProperties;
    let style = {};

    // General Style
    propertyHelper.setBackground(style, p);

    // Spacing
    propertyHelper.setSpacing(style, p.padding);
    propertyHelper.setSpacing(style, p.margin, false);

    // Border + Shadow
    propertyHelper.setBorder(style, p);
    propertyHelper.setBorderRadius(style, p);
    propertyHelper.setShadow(style, p);
    propertyHelper.setLevitate(style, p);

    // Dimensions
    propertyHelper.setDisplay(style, p);
    propertyHelper.setPosition(style, p);
    propertyHelper.setDimensions(style, p);

    // Effects
    propertyHelper.setHoverEffect(style, p);

    return style;
  },

  getDataAttributes(section) {
    const attr = {};

    if (section.generalProperties.animation) {
      attr['data-animation'] = section.generalProperties.animation;
    }

    return attr;
  },
};

export default sectionHelper;
