import React, { useState } from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import serviceHelper from '../../../modules/serviceHelper';
import logger from '../../../modules/logger';

const FileInput = (props) => {
  const [fileInput, setFileInput] = useState(null);

  const handleDrop = async e => {
    let dt = e.dataTransfer
    let files = dt.files

    if (!files || !files.length) {
      return;
    }

    await uploadFile(files);
  }

  const upload = async e => {

    const files = fileInput.files;

    if (!files || !files.length) {
      return;
    }

    await uploadFile(files);
  };

  const uploadFile = async files => {
    // Fake form for the upload
    const formData = new FormData();

    for (const file of files) {
      formData.append('attachments', file);
    }

    // We need to save the site for it (except on the places were we don't have one)
    formData.append('siteId', props.site.site ? props.site.site._id : '');
    formData.append('createThumbnail', props.createThumbnail ? 'true' : 'false');
    formData.append('convertWebp', props.convertWebp ? 'true' : 'false');

    if (props.resizeImage) {
      formData.append('resizeImage', 'true');
      formData.append('resizeWidth', props.resizeWidth);
      formData.append('resizeHeight', props.resizeHeight);
      formData.append('imageQuality', props.imageQuality);
      formData.append('preserveConstraints', props.preserveConstraints);
    }

    // This is not storing anything in the db, so we use it outside our API helpers
    const service = serviceHelper.getService('SectionService');

    // Hacky
    const method = props.task || 'uploadFiles';
    const result = await service[method](formData);

    logger.debug('File Upload result', result);

    if (!result.data) {
      logger.warn('No file upload result', result.data);
      return;
    }

    // Send the uploaded image back
    props.action(method === 'uploadFiles' ? result.data.items : result.data, result.data);
  }

  return (
    <div className="file-upload"
         style={{'--ai': 'center', '--jc': 'center', '--d': 'flex'}}>
      <span className="icon icon-photo icon-2x" style={{'--mx': '7px'}} aria-hidden />
      {props.text || fbt('Click to choose your file(s) or drop it here.', 'FileInput')}

      <label className="sr-only">
        <fbt desc="FileInput">
          Upload Image
        </fbt>
      </label>
      <input className="file-upload__input"
             id={props.id || null}
             type="file"
             accept={props.accept || null}
             onChange={upload}
             onDrop={handleDrop}
             ref={setFileInput}
      />
    </div>
  );
};

const mapStateToProps = state => ({site: state.activeSite});

export default connect(mapStateToProps)(FileInput);
