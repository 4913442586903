import React from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import Fieldset from '../FieldSet';
import AdvancedColorPicker from '../Controls/AdvancedColorPicker';
import GenericSlider from '../Controls/GenericSlider';
import SelectInput from '../Controls/SelectInput';
import InputGroup from '../InputGroup';

const SeparatorProperties = props => {
  const {element} = props;
  const {componentProperties} = element;

  return (
    <Fieldset title={fbt('Separator Properties', 'SeparatorProperties')} isOpen>
      <InputGroup style={{'--mt': '10px'}}>
        <GenericSlider min="1" max="30" title={fbt('Size', 'SeparatorProperties')}
                       value={componentProperties.height}
                       action={(val) => props.handlePropertyChange('height', val)}
                       showLabel
        />
      </InputGroup>

      <InputGroup>
        <SelectInput title={fbt('Style', 'SeparatorProperties')}
                     value={componentProperties.style}
                     id="separator-style" showLabel
                     action={(val) => {
                       props.handlePropertyChange('style', val);
                     }}
                     options={{
                       '': fbt('None', 'SeparatorProperties'),
                       'solid': fbt('Solid', 'SeparatorProperties'),
                       'dotted': fbt('Dotted', 'SeparatorProperties'),
                       'double': fbt('Double', 'SeparatorProperties'),
                       'dashed': fbt('Dashed', 'SeparatorProperties'),
                       'groove': fbt('Groove', 'SeparatorProperties'),
                       'ridge': fbt('Ridge', 'SeparatorProperties'),
                       'inset': fbt('Inset', 'SeparatorProperties'),
                       'hidden': fbt('Hidden', 'SeparatorProperties'),
                     }}
        />
      </InputGroup>

      <AdvancedColorPicker title={fbt('Color', 'SeparatorProperties')}
                           value={componentProperties.color}
                           action={(val) => props.handlePropertyChange('color', val)}
                           showLabel
      />
    </Fieldset>
  );
};

export default connect()(SeparatorProperties);
