import GalleryProperties from '../../components/Properties/Elements/GalleryProperties';
import { ElementVisible } from '../../modules/consts/Elements';

const GalleryModel = {
  title: 'Gallery',
  image: '/img/elements/gallery.webp',
  category: 'Media',
  helpPage: '/documentation/elements/gallery',
  component: 'GalleryElement',
  propertyComponent: GalleryProperties,
  hasChildElements: false,
  generalProperties: {
    hasVisibility: true,
    hasTextAlign: true,
    hasTextColor: true,
    hasBackground: true,
  },
  defaults: {
    demo: true,
    _id: null,
    identifier: 'New Gallery',
    component: 'GalleryElement',
    componentProperties: {
      style: '',
      thumbnailAutoGenerate: true,
      thumbnailWidth: '400',
      thumbnailHeight: '400',
      items: [
        {
          itemId: 42,
          title: 'First Image',
          alt: 'The Alps',
          thumbnail: 'https://source.unsplash.com/eluzJSfkNCk/400x300',
          image: 'https://source.unsplash.com/eluzJSfkNCk/1600x600',
          linkType: '',
          page: '',
          link: '',
          linkTarget: '_self',
          isNofollow: false,
        }
      ]
    },
    generalProperties: {
      visibility: [ElementVisible.sm, ElementVisible.md, ElementVisible.lg, ElementVisible.xl],
      published: true,
      margin: [0, 0, 0, 0],
      padding: [0, 0, 0, 0],
    },
  },
  itemTemplate: {
    itemId: 0,
    title: 'New Image',
    alt: '',
    thumbnail: '',
    image: '',
    linkType: '',
    page: '',
    link: '',
    linkTarget: '_self',
    isNofollow: false,
  },
};

export default GalleryModel;
