import BaseService from './BaseService';

const paths = {
  get: '/media',
  post: '/media',
  patch: '/media',
  delete: '/media',
};

/**
 * Service for querying the Media
 */
class MediaService extends BaseService {
  constructor() {
    super(paths);
    this.requireLogin = true;
  }
}

export default new MediaService();
