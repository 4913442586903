import React from 'react';
import fbt from 'fbt';

import SocialIcon from '../General/SocialIcon';
import propertyHelper from '../../modules/propertyHelper';

const ProfileFigure = props => {
  const {profile} = props;
  const avatar = profile.avatar_url ? propertyHelper.getImagePath(profile.avatar_url) : 'https://www.gravatar.com/avatar/noprofile/?s=120';

  return (
    <figure className="accent" style={{'--w': '100%'}}>
      <figcaption style={{'--ta': 'center'}}>
        {avatar && (
          <div style={{'--h': '200px', '--d': 'flex', '--ai': 'center', '--jc': 'center'}}>
            <a href={profile.html_url}>
              <img src={avatar} alt={profile.username}
                   style={{'--br': '50%', '--maxw': '60%', '--maxh': '180px'}}
              />
            </a>
          </div>
        )}

        <h4 style={{'--mb': 0}}>{profile.username}</h4>
        <div className="tag grey" style={{'--m': 0, '--p': '1px 8px', '--size': '80%'}}>
          {profile.account_subscription === 'free' ? fbt('Mame Free', 'UserProfile') : fbt('Mame Premium', 'UserProfile')}
        </div>

        <p style={{'--mt': '15px'}}>
          {profile.bio}
        </p>

        <div>
          <SocialIcon type="website" style={{'--c': 'var(--mame-primary)'}} data={profile.website}/>
          <SocialIcon type="facebook" style={{'--c': 'var(--mame-primary)'}} data={profile.facebook_id}/>
          <SocialIcon type="twitter" style={{'--c': 'var(--mame-primary)'}} data={profile.twitter_username}/>
          <SocialIcon type="github" style={{'--c': 'var(--mame-primary)'}} data={profile.github_username}/>
          <SocialIcon type="linkedin" style={{'--c': 'var(--mame-primary)'}} data={profile.linkedin_url}/>
          <SocialIcon type="unsplash" style={{'--c': 'var(--mame-primary)'}} data={profile.unsplash_username}/>
          <SocialIcon type="youtube" style={{'--c': 'var(--mame-primary)'}} data={profile.youtube_username}/>
        </div>
      </figcaption>
    </figure>
  );
};

export default ProfileFigure;
