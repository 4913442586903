import axios from 'axios';
import config from '../../config';
import logger from '../logger';

/**
 * Base class for services
 */
class BaseService {
  constructor(paths = {get: '/'}) {
    if (new.target === BaseService) {
      throw new TypeError('Cannot construct Abstract instances directly');
    }

    this.paths = paths;
    this.requireLogin = false;
    this.setupAxios();
  }

  /**
   * Setup axios defaults, is called to often atm
   */
  setupAxios = () => {
    axios.defaults.baseURL = config.endpoint;
    axios.defaults.headers['Content-Type'] = 'application/json';
    axios.defaults.headers.Accept = 'application/json';
  };

  /**
   * Set the API token
   *
   * @param token {string} - Token Key for the header
   */
  setToken = (token) => {
    axios.defaults.headers.token = token;
  };

  /**
   * Get the path (get, post etc.) from this.paths
   *
   * @param method {string} - Which method (get, post etc.)?
   * @returns {string} The path for the method
   *
   * @example
   *
   * // Returns for example /task
   * getPath('get');
   */
  getPath(method) {
    return this.paths[method];
  }

  /**
   * Basic Get request with axios
   *
   * @param filters {object} - Filters for the get request
   * @param config
   * @returns {Promise<AxiosResponse<T>>} The axios request
   *
   * @example
   *
   * // Returns the axios request
   * await get();
   */
  get = (filters = {}, config = {}) => {
    if (this.requireLogin && !axios.defaults.headers.token) {
      logger.warn('Storing items requires to be logged in.');
      return null;
    }

    let url = this.getPath('get');

    Object.keys(filters)
      .forEach((key, index) => {
        const glue = (index === 0) ? '?' : '&';
        url += `${glue + key}=${filters[key]}`;
      });

    return axios.get(url, config);
  };

  /**
   * Basic store request with the given item (POST or PATCH)
   *
   * @param item {object} - The item to store
   * @param hideModal {boolean} - Hide the axios modal
   * @param appInfo {boolean} - Send app info
   *
   * @returns {AxiosPromise} The axios request
   *
   * @example
   *
   * // Returns the axios request
   * await store(item);
   */
  store = (item, hideModal = false, sendAppInfo = true) => {
    let method = 'post';
    let url = this.getPath('post');

    if (!item) {
      return null;
    }

    if (item._id) {
      method = 'patch';
      url = this.getPath('patch');
    }

    if (this.requireLogin && !axios.defaults.headers.token) {
      logger.warn('Storing items requires to be logged in.');
      return null;
    }

    // Add application
    const appInfo = {
      app_version: config.version,
      app_platform: 'browser',
    };

    const data = {...appInfo, ...item};

    return axios({
      method,
      url,
      data,
      hideModal,
    });
  };

  /**
   * Upload a file
   * @param formData {Object} - The form data
   * @returns {Promise<AxiosResponse<T>>} The axios request
   */
  uploadFile = (formData) => {
    const url = '/upload/image';

    return axios.post(url, formData, {headers: {'Content-Type': 'multipart/form-data'}});
  };

  /**
   * Upload a file
   * @param formData {Object} - The form data
   * @returns {Promise<AxiosResponse<T>>} The axios request
   */
  uploadFiles = (formData) => {
    const url = '/upload/images';

    return axios.post(url, formData, {headers: {'Content-Type': 'multipart/form-data'}});
  };

  /**
   * Upload favicon
   * @param formData {Object} - The form data
   * @returns {Promise<AxiosResponse<T>>} The axios request
   */
  uploadFavicon = (formData) => {
    const url = '/upload/favicon';

    return axios.post(url, formData, {headers: {'Content-Type': 'multipart/form-data'}});
  };

  /**
   * Upload video
   *
   * @param formData
   * @return {Promise<AxiosResponse<any>>}
   */
  uploadVideo = (formData) => {
    const url = '/upload/video';

    return axios.post(url, formData, {headers: {'Content-Type': 'multipart/form-data'}});
  };


  /**
   * Delete request with the given item
   *
   * @param {string} _id - The mongoose id to delete
   * @returns {AxiosPromise} The axios request
   *
   * @example
   *
   * // Returns the axios request
   * await delete(_id);
   */
  delete = _id => axios({
    method: 'delete',
    url: this.getPath('delete'),
    data: JSON.stringify({_id}),
  });
}

export default BaseService;
