import { ElementVisible } from '../../modules/consts/Elements';
import MenuProperties from '../../components/Properties/Elements/MenuProperties';

const MenuModel = {
  title: 'Menu',
  category: 'Basic',
  helpPage: '/documentation/elements/menu',
  component: 'MenuElement',
  propertyComponent: MenuProperties,
  hasChildElements: false,
  generalProperties: {
    hasVisibility: true,
    hasTextAlign: true,
    hasTextColor: true,
    hasBackground: true,
  },
  defaults: {
    demo: true,
    _id: null,
    identifier: 'New Menu',
    component: 'MenuElement',
    componentProperties: {
      style: 'horizontal',
      caption: '',
      listStyle: 'disc',
      linkColor: '',
      spaceBetween: 10,
      spacer: '',
      positionStyle: '',
      generateMobileMenu: true,
      items: [
        {
          itemId: 42,
          title: 'Mame',
          linkType: 'external',
          page: '',
          link: 'https://mame.app',
          icon: '',
          linkTarget: '_blank',
          isNofollow: false,
          // Link or Button?
          displayAs: 'link',
          buttonColor: '',
          outline: false,
        },
        {
          itemId: 43,
          title: 'Blog',
          linkType: 'external',
          page: '',
          link: 'https://mame.app/blog',
          icon: '',
          linkTarget: '_blank',
          isNofollow: false,
          // Link or Button?
          displayAs: 'link',
          buttonColor: '',
          outline: false,
        },
        {
          itemId: 44,
          title: 'bonsai.css',
          linkType: 'external',
          page: '',
          link: 'https://bonsaicss.com',
          icon: '',
          linkTarget: '_blank',
          isNofollow: false,
          // Link or Button?
          displayAs: 'link',
          buttonColor: '',
          outline: false,
        }
      ],
    },
    generalProperties: {
      visibility: [ElementVisible.sm, ElementVisible.md, ElementVisible.lg, ElementVisible.xl],
      published: true,
      margin: [0, 0, 0, 0],
      padding: [0, 0, 0, 0],
    },
  },
  itemTemplate: {
    itemId: 0,
    title: 'New Menu Entry',
    linkType: '',
    page: '',
    link: '',
    icon: '',
    linkTarget: '_self',
    isNofollow: false,
    displayAs: 'link',
    buttonColor: '',
    outline: false,
  }
};

export default MenuModel;
