import { SET_SETTING, SET_SETTINGS } from '../actionTypes';

const initialState = {
  _id: null,
  language: 'en_US',
  theme: 'light',
  showTooltips: true,
  sidebarPlacement: 'right',
  showTour: true,
  showBreadcrumb: true,

  /**
   * @var string 'details'|'fieldset'
   */
  fieldsetType: 'details',
};

const settingsState = (state = initialState, action) => {
  switch (action.type) {
    case SET_SETTINGS: {
      const {settings} = action.payload;

      return {
        ...state,
        ...settings,
      };
    }

    case SET_SETTING: {
      const {key, value} = action.payload;

      return {
        ...state,
        ...{
          [key]: value,
        },
      };
    }

    default: {
      return state;
    }
  }
};

export default settingsState;
