import React from 'react';
import config from '../../config';

import fbt from 'fbt';

const SocialLoginButton = props => {
  const authUrl = `${config.baseUrl}/v1/login/${props.service.toLowerCase()}`;

  return (
    <a href={authUrl} className="button white"
       role="button"
       style={{'--w': '100%', '--d': 'grid', '--gtc': '30px 1fr', '--ta': 'center', '--mt': '15px'}}>
      <div aria-hidden>
        <span
          className={'icon icon-' + props.service.toLowerCase()}
        />
      </div>
      <div>
        <fbt desc="Social Login">
          Login with
          <fbt:param name="service">
            {props.service}
          </fbt:param>
        </fbt>
      </div>
    </a>
  );
};

export default SocialLoginButton;
