import ElementErrorRenderer from './ElementErrorRenderer';
import React from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import componentHelper from '../../modules/componentHelper';
import { showModal } from '../../store/actions/modals';
import { isPreviewSelector, isRenderSelector } from '../../store/selectors';

const FigureElementsRenderer = props => {
  const addElement = () => {
    const options = {to: props.element._id, toType: 'element'};
    props.dispatch(showModal('AddElementModal', options));
  };

  const style = {
    ...props.style,
  };

  let captionClasses = props.className || '';

  if (props.isOverCurrent) {
    style['--bgc'] = 'var(--form)';
    captionClasses += ' mame-dropzone';
  }

  const CustomTag = props.tag || 'figure';

  const noElements = !props.isPreview ? (
    <div onClick={props.addElement || addElement} className="fake-link" role="button">
      <fbt desc="Element Figure">
        No Elements - Start by adding one.
      </fbt>
    </div>
  ) : '';

  return (
    <CustomTag style={style}
               className={captionClasses || null}
               ref={props.dropRef || null}>

      {(!props.sortingItems || !props.sortingItems.length) && noElements}

      {props.sortingItems && props.sortingItems.map((elementId, index) => {
        const subElement = props.elements.find(e => e._id === elementId);

        if (!subElement) {
          return (
            <ElementErrorRenderer key={elementId}
                                  id={elementId}
                                  parentType={props.parentType || 'element'}
                                  parentId={props.element._id}
            />
          );
        }

        if (!subElement.generalProperties.published && props.isRender) {
          return null;
        }

        return (
          <React.Fragment key={elementId}>
            {componentHelper.getElementComponent(
              subElement,
              {
                parent: props.element,
                parentType: props.parentType || 'element',
                parentIndex: props.parentIndex,
                index,
                resortElement: props.resortOverwrite,
                resortEnd: props.endOverwrite,
              }
            )}
          </React.Fragment>
        );
      })}

      {props.isOverCurrent && (
        <div style={{'--d': 'grid', '--ai': 'center', '--jc': 'center', '--h': '100px'}}>
          {props.moveText || fbt('Move Element to Figure.', 'Element Figure')}
        </div>
      )}
    </CustomTag>
  );
};

const mapStateToProps = state => ({
  elements: state.activeSite.elements,
  isPreview: isPreviewSelector(state),
  isRender: isRenderSelector(state),
});

export default connect(mapStateToProps)(FigureElementsRenderer);
