import React from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';
import logger from '../../modules/logger';
import { setActiveElement, setActiveSection, setSidebarView } from '../../store/actions/builder';

const BuilderBreadcrumb = props => {
  if (!props.showBreadcrumb) {
    return null;
  }

  const titles = {
    'site': fbt('Site', 'BuilderBreadcrumb'),
    'page': fbt('Page', 'BuilderBreadcrumb'),
    'section': fbt('Section', 'BuilderBreadcrumb'),
    'element': fbt('Element', 'BuilderBreadcrumb'),
  };

  let path = [
    {
      id: props.site._id,
      text: props.site.title,
      action: () => {
        props.dispatch(setSidebarView('site'));
      },
      type: 'site'
    },
    {
      id: props.page._id,
      text: props.page.title,
      action: () => {
        props.dispatch(setSidebarView('page'));
      },
      type: 'page'
    },
  ];

  // Section
  if (props.builder.activeType === 'section') {
    const section = props.sections.find(s => s._id === props.builder.activeSection);

    if (section) {
      path.push({
        id: section._id,
        text: section.identifier,
        action: () => {
          props.dispatch(setActiveSection(section));
        },
        type: 'section',
      });
    }
  }

  const getElementParents = element => {
    const parentSection = props.sections.find(s => s.elements.includes(element._id));

    if (parentSection) {
      return [{
        id: parentSection._id,
        text: parentSection.identifier,
        action: () => {
          props.dispatch(setActiveSection(parentSection));
        },
        type: 'section',
      }];
    }

    // The complicated part
    let parentElement = props.elements.find(e => e.elements.includes(element._id));

    if (!parentElement) {
      // Check sub sub items
      parentElement = props.elements.find(e => e.componentProperties.items ?
        e.componentProperties.items.find(i => i.elements ? i.elements.includes(element._id) : false)
        : false
      );

      if (!parentElement) {
        logger.warn('[Breadcrumb] No parent found for Element.. Should not happen');
        return [];
      }
    }

    const grandParents = getElementParents(parentElement);

    return [...grandParents, ...[{
      id: parentElement._id,
      text: parentElement.identifier,
      action: () => {
        props.dispatch(setActiveElement(parentElement));
      },
      type: 'element',
    }]];
  };

  // Element
  if (props.builder.activeType === 'element') {
    const element = props.elements.find(e => e._id === props.builder.activeElement);

    if (element) {
      const parents = getElementParents(element);

      path = [...path, ...parents];
      path.push({
        id: element._id,
        text: element.identifier,
        action: () => {
          props.dispatch(setActiveElement(element));
        },
        type: 'element',
      });
    }
  }

  return (
    <div className={`builder-breadcrumb builder-breadcrumb-${props.sidebarPlacement === 'left' ? 'right' : 'left'}`}>
      {path.map(item => {
        return (
          <React.Fragment key={item.id}>
            <div onClick={item.action}
                 title={titles[item.type]}
                 className={`builder-breadcrumb__item builder-breadcrumb__item-${item.type}`}
            >
              <div style={{'--line-clamp': 1}}>
                {item.text}
              </div>
              <div className="builder-breadcrumb__item-arrow"/>
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

const mapStateToProps = state => ({
  builder: state.builder,
  page: state.activeSite.page,
  site: state.activeSite.site,
  sections: state.activeSite.sections,
  elements: state.activeSite.elements,
  sidebarPlacement: state.settings.sidebarPlacement,
  showBreadcrumb: state.settings.showBreadcrumb,
});

export default connect(mapStateToProps)(BuilderBreadcrumb);
