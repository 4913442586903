import GridProperties from '../../components/Properties/Elements/GridProperties';
import { ElementVisible } from '../../modules/consts/Elements';

const GridModel = {
  title: 'Grid',
  category: 'Basic',
  helpPage: '/documentation/elements/grid',
  component: 'GridElement',
  propertyComponent: GridProperties,
  hasChildElements: true,
  generalProperties: {
    hasVisibility: false,
    hasTextAlign: true,
    hasTextColor: true,
    hasBackground: true,
    hideDisplay: true,
  },
  defaults: {
    demo: true,
    _id: null,
    identifier: 'New Grid',
    component: 'GridElement',
    componentProperties: {
      gridTemplateColumns: 2,
      gridGap: 5,
      gridTemplateColumnWidth: '',
      gridTemplateColumnCustomWidth: '',
      justifyContent: 'space-between',
      alignItems: '',
      gridTemplateColumnsSm: 0,
      gridTemplateColumnCustomSm: '',
      gridTemplateColumnsMd: 0,
      gridTemplateColumnCustomMd: '',
      gridTemplateColumnsLg: 0,
      gridTemplateColumnCustomLg: '',
      gridTemplateColumnsXl: 0,
      gridTemplateColumnCustomXl: '',
    },
    generalProperties: {
      visibility: [ElementVisible.sm, ElementVisible.md, ElementVisible.lg, ElementVisible.xl],
      published: true,
      margin: [0, 0, 0, 0],
      padding: [20, 0, 20, 0],
    },
    elements: [],
  },
};

export default GridModel;
