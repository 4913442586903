import React from 'react';
import fbt from 'fbt';

import Fieldset from '../FieldSet';
import TextInput from '../Controls/TextInput';
import GenericSlider from '../Controls/GenericSlider';
import HelpText from '../Controls/HelpText';
import InputGroup from '../InputGroup';

const MapProperties = props => {
  const {element} = props;
  const {componentProperties} = element;

  return (
    <Fieldset title={fbt('Map Properties', 'MapProperties')} isOpen>
      <InputGroup style={{'--mt': '10px'}}>
        <TextInput title={fbt('Mapbox API Key', 'MapProperties')}
                   value={componentProperties.apiKey}
                   showLabel
                   action={val => props.handlePropertyChange('apiKey', val)}
        />

        <HelpText>
          <fbt desc="MapProperties">
            If you are hosting your website on Mame, you don't need an API Key.
            Otherwise sign up at <a href="https://mapbox.com" rel="noreferrer noopener" target="_blank">mapbox.com</a>,
            for your own API Key. They offer a generous free tier.
          </fbt>
        </HelpText>
      </InputGroup>

      <InputGroup>
        <TextInput title={fbt('Map Position Latitude', 'MapProperties')}
                   type="number"
                   value={componentProperties.lat}
                   action={val => props.handlePropertyChange('lat', val)}
                   required
                   showLabel
        />
        <HelpText>
          <fbt desc="MapProperties">
            You can get the longitude and latitude of an address with various free online services.
          </fbt>
        </HelpText>
      </InputGroup>

      <InputGroup>
        <TextInput title={fbt('Map Position Longitude', 'MapProperties')}
                   type="number"
                   value={componentProperties.long}
                   action={val => props.handlePropertyChange('long', val)}
                   required
                   showLabel
        />
      </InputGroup>


      <InputGroup>
        <GenericSlider title={fbt('Map Height in px', 'MapProperties')}
                       value={componentProperties.height}
                       action={val => props.handlePropertyChange('height', val)}
                       min="10" max="1000"
                       showLabel
        />
      </InputGroup>

      <InputGroup>
        <GenericSlider title={fbt('Default Zoom', 'MapProperties')}
                       value={componentProperties.zoom}
                       action={val => props.handlePropertyChange('zoom', val)}
                       min="1" max="20"
                       showLabel
        />
      </InputGroup>
    </Fieldset>
  );
};

export default MapProperties;
