import React from 'react';

const ResponsiveIcon = (props) => {
  const classes = `icon icon-${props.icon}`;
  const btnClass = props.active ? ' mame-primary' : ' white';

  return (
    <button className={btnClass} title={props.title}
            onClick={props.action}>
      <div className={classes} />
    </button>
  );
}

export default ResponsiveIcon;
