import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import Dialog from './Dialog';
import serviceHelper from '../../modules/serviceHelper';
import { setActiveSite } from '../../store/actions/activeSite';
import { hideModal, showModal } from '../../store/actions/modals';
import HelpText from '../Properties/Controls/HelpText';
import TextInput from '../Properties/Controls/TextInput';
import logger from '../../modules/logger';

const PublishModal = props => {
  const [view, setView] = useState('first');

  if (!props.isVisible) {
    // Make sure to reset it
    return null;
  }

  const handleDialogClose = () => {
    setView('first');
  };

  return (
    <Dialog title={fbt('Publish Your Website', 'PublishModal')} name="PublishModal" size="modal-xl"
            closeAction={handleDialogClose}>
      <div className="modal-content" style={{'--radius': '0 0 5px 5px'}}>
        {view === 'first' && <FirstPublish {...props} action={view => setView(view)}/>}
        {view === 'mame' && <SetupOnMame {...props} action={view => setView(view)}/>}
        {view === 'amazon' && <SetupOnAws {...props} action={view => setView(view)}/>}
        {view === 'mameCompleted' && <SetupOnMameComplete {...props} />}
        {view === 'awsCompleted' && <SetupOnAwsComplete {...props} />}
      </div>
    </Dialog>
  );
};

const FirstPublish = (props) => {
  return (
    <>
      <div style={{'--d': 'grid', '--gtc': '1fr 1fr 1fr', '--gg': '15px', '--p': '30px', '--ai': 'stretch'}}>
        <figure className="accent" style={{'--ta': 'center', '--levitate-hvr': '5', '--cur': 'pointer'}}
                onClick={() => {
                  props.action('mame');
                }}>
          <img src="/img/mame.webp"
               alt="Host on Mame"
               style={{'--maxh': '250px', '--m': '20px 0'}}
          />
          <figcaption>
            <h3>
              <fbt desc="PublishModal">
                Setup on Mame
              </fbt>
            </h3>
            <div className="tag grey">
              <fbt desc="PublishModal">
                Free
              </fbt>
            </div>
            <p>
              <fbt desc="PublishModal">
                Host your website on our high performance
                servers for free!*
              </fbt>
            </p>
            <small>
              <fbt desc="PublishModal">
                * Up to 200 MB Quota for Free Users.
              </fbt>
            </small>
          </figcaption>
        </figure>

        <figure className="accent" style={{'--ta': 'center', '--levitate-hvr': '5', '--cur': 'pointer'}}
                onClick={() => {
                  props.action('amazon');
                }}>
          <img src="/img/powered_by/aws.svg.png" alt="Host on AWS" style={{'--mt': '40px', '--p': '30px'}}/>
          <figcaption>
            <h3>
              <fbt desc="PublishModal">
                Deploy to Amazon Web Services (S3)
              </fbt>
            </h3>
            <div className="tag mame-primary">
              <fbt desc="PublishModal">
                Requires Mame Premium
              </fbt>
            </div>
            <p>
              <fbt desc="PublishModal">
                Host your website on Amazon Webservices (S3).
              </fbt>
            </p>
          </figcaption>
        </figure>

        <figure className="accent" style={{'--ta': 'center', '--levitate-hvr': '5', '--cur': 'pointer'}}>
          <img src="/img/powered_by/do.png" alt="Host on Digital Ocean"
               style={{'--mt': '10px', '--px': '30px', '--py': '-10px'}}/>
          <figcaption>
            <h3>
              <fbt desc="PublishModal">
                Deploy to DigitalOcean
              </fbt>
            </h3>
            <div className="tag mame-primary">
              <fbt desc="PublishModal">
                Requires Mame Premium
              </fbt>
            </div>
            <p>
              <fbt desc="PublishModal">
                Deploy your website to DigitalOcean.
              </fbt>
            </p>
          </figcaption>
        </figure>
      </div>
      <div style={{'--ta': 'center', '--pb': '20px'}}>
        <fbt desc="PublishModal">
          Missing one? Just drop us a line!
        </fbt>
        <br/>
        <fbt desc="PublishModal">
          P.S.: You can always download the website and deploy manually.
        </fbt>
      </div>
    </>
  );
};

const SetupOnMameComplete = (props) => {
  return (
    <div style={{'--p': '10px 20px'}}>
      <blockquote>
        <fbt desc="PublishModal">
          Your site is now being published, this may take a couple of minutes. You are going to get notified by mail
          once your site is ready.
        </fbt>
        <br/>
        <br/>
        <fbt desc="PublishModal">
          Afterwards it's going to be available at:
        </fbt>
        <br/>
        <a href={`https://${props.site.hosting.subDomain}.mame.app`}
           target="_blank"
           rel="noopener noreferrer">{props.site.hosting.subDomain}.mame.app</a>
      </blockquote>
      <p>
        <fbt desc="PublishModal">
          You can close this Dialog now.
        </fbt>
      </p>
    </div>
  );
};

const SetupOnAwsComplete = (props) => {
  return (
    <div style={{'--p': '10px 20px'}}>
      <p>
        <fbt desc="PublishModal">
          Your site is now being deployed to your AWS bucket, this may take some minutes.
        </fbt>
        <br/>
        <br/>
        <fbt desc="PublishModal">
          Once the deployment is done you will be notified via email.
        </fbt>
      </p>
      <p>
        <fbt desc="PublishModal">
          You can close this Dialog now.
        </fbt>
      </p>
    </div>
  );
};

const SetupOnAws = props => {
  const [id, setId] = useState('');
  const [secret, setSecret] = useState('');
  const [bucket, setBucket] = useState('');

  const service = serviceHelper.getService('SiteService');

  const openPremiumModal = e => {
    e.preventDefault();
    props.dispatch(showModal('PremiumModal'));
    props.dispatch(hideModal('PublishModal'));
  };

  const handleDeployClick = async e => {
    e.preventDefault();

    if (!id || !secret || !bucket) {
      return;
    }

    const result = await service.publishAws(props.site._id, {id, secret, bucket});
    logger.debug('AWS Publish result', result);

    props.dispatch(setActiveSite(result.data.item));
    props.action('awsCompleted');
  };

  if (props.user.account_subscription !== 'premium') {
    return (
      <div style={{'--p': '10px 20px'}}>
        <fbt desc="PublishModal">
          Deploying your website to Amazon Web Services requires Mame Premium.
        </fbt>
        <br/>
        <button className="mame-primary" onClick={openPremiumModal}>
          <fbt desc="PublishModal">
            Get Premium Now
          </fbt>
        </button>
      </div>
    );
  }

  return (
    <div style={{'--p': '10px 20px', '--d': 'grid', '--gtc': 'auto auto', '--ai': 'center'}}>
      <div>
        <h3>
          <fbt desc="PublishModal">
            Deploy website on Amazon Webservices (S3)
          </fbt>
        </h3>

        <HelpText>
          <fbt desc="PublishModal">
            In order to deploy your website to AWS, you first have to create an access key for Mame. Additionally
            we need the target S3 Bucket name. <a href="https://mame.app/documentation/publishing/deploy-to-aws"
                                                  target="_blank" rel="noreferrer noopener">Check the
            documentation</a> for a step-by-step guide.
          </fbt>
        </HelpText>

        <div>
          <TextInput
            id="aws-id"
            title={fbt('AWS Access Key ID', 'PublishModal')}
            value={id}
            action={setId}
            showLabel
          />
        </div>

        <div>
          <TextInput
            id="aws-secret"
            title={fbt('AWS Secret', 'PublishModal')}
            value={secret}
            action={setSecret}
            showLabel
          />
        </div>

        <div>
          <TextInput
            id="aws-bucket"
            title={fbt('AWS Bucket Name', 'PublishModal')}
            placeholder="mame-bucket-1234"
            value={bucket}
            action={setBucket}
            showLabel
          />
        </div>

        <button className="mame-primary"
                disabled={!(id && secret && bucket)}
                onClick={handleDeployClick}>
          <fbt desc="PublishModal">
            Deploy to S3
          </fbt>
        </button>
      </div>
      <img src="/img/illustrations/publish.webp" alt="Publish your Website"/>
    </div>
  );
};

const SetupOnMame = props => {
  const [subdomain, setSubdomain] = useState(props.site.hosting.subDomain);
  const [subdomainClassName, setSubdomainClassName] = useState(null);
  const [ready, setReady] = useState(false);

  const {site} = props;

  useEffect(() => {
    setSubdomain(site.hosting.subDomain);
  }, [site]);

  const service = serviceHelper.getService('SiteService');

  const checkSubDomain = async domain => {
    setSubdomainClassName('');
    setReady(false);

    if (domain.length < 3) {
      setSubdomainClassName('invalid');
      return;
    }

    if (domain.charAt(0) === '-' || domain.charAt(0) === '_') {
      setSubdomainClassName('invalid');
      return;
    }

    // TODO add debounce 300ms
    const result = await service.checkSubdomain(domain);

    if (result.data.available) {
      setSubdomainClassName('valid');
      setReady(true);
    } else {
      setSubdomainClassName('invalid');
    }
  };

  const handleSubdomainChange = value => {
    let val = value.toLowerCase();
    val = val.replace(/[^A-Za-z0-9-]/, '');
    setSubdomain(val);
    checkSubDomain(val);
  };

  const handleConfirmSubdomain = async () => {
    if (!ready) {
      return;
    }

    const result = await service.publish(subdomain, props.site._id, 'mame');

    props.dispatch(setActiveSite(result.data.item));
    props.action('mameCompleted');
  };

  return (
    <div style={{'--p': '10px 20px', '--d': 'grid', '--gtc': 'auto auto', '--ai': 'center'}}>
      <div>
        <h4>
          <fbt desc="PublishModal">
            Setting up hosting on our servers.
          </fbt>
        </h4>
        <p>
          <fbt desc="PublishModal">
            First you need to choose a subdomain under which the website is going to be available.
          </fbt>
          <br/>
          <fbt desc="PublishModal">
            You can always add top level domains (like example.com) later.
          </fbt>
        </p>

        <p>
          <fbt desc="PublishModal">
            Please make sure that your site does not contain anything illegal or unlicensed media as the website
            is going to be available publicly after you confirmed the subdomain.
          </fbt>
        </p>

        <br/>

        <div style={{'--mb': '30px'}}>
          <div>
            <label htmlFor="subdomain" className="sr-only">
              <fbt desc="PublishModal">
                Subdomain for the website
              </fbt>
            </label>
          </div>
          <div className="group">
            <TextInput id="subdomain"
                       placeholder="subdomain"
                       className={subdomainClassName}
                       value={subdomain}
                       action={handleSubdomainChange}
                       filter="[^A-Za-z0-9-]"
                       pattern="[A-Za-z0-9-]"
                       inputStyle={{'--m': '0', '--radius': '5px 0 0 5px'}}
            />
            <button className="mame-primary">.mame.app</button>
          </div>
          {subdomainClassName === 'invalid' && (
            <p style={{'--c': 'var(--red)'}}>
              <fbt desc="PublishModal">
                This subdomain is not available
              </fbt>
            </p>
          )}
          <p>
            <b>
              <fbt desc="PublishModal">
                Please note: The subdomain can't be changed later.
              </fbt>
            </b>
          </p>
          <button className="mame-primary"
                  disabled={!ready}
                  onClick={handleConfirmSubdomain}
                  style={{'--mt': '20px'}}
          >
            <fbt desc="PublishModal">
              Confirm Subdomain
            </fbt>
          </button>
        </div>
      </div>
      <img src="/img/illustrations/publish.webp" alt="Publish your Website"/>
    </div>
  );
};


const mapStateToProps = state => ({
  user: state.user.user,
  site: state.activeSite.site,
  isVisible: state.modals.PublishModal,
  payload: state.modals.payload.PublishModal,
});

export default connect(mapStateToProps)(PublishModal);
