import React from 'react';
import { connect } from 'react-redux';

import elementHelper from '../../modules/elementHelper';
import { ElementColorClasses } from '../../modules/consts/Elements';
import ElementDiv from '../ElementControls/ElementDiv';
import propertyHelper from '../../modules/propertyHelper';
import ElementLink from '../ElementControls/ElementLink';
import { setActiveElement } from '../../store/actions/builder';

const IconElement = props => {
  const {element} = props;
  const {componentProperties} = element;

  const id = elementHelper.getId(element);
  const elementClasses = elementHelper.getClasses(element, 'mame-icon');
  let classes = `icon icon-${componentProperties.icon}`;
  const style = elementHelper.getStyle(element);

  // Icon is always block
  delete style['--d'];
  delete style['--m'];
  delete style['--pos'];
  delete style['--right'];
  delete style['--top'];
  delete style['--left'];
  delete style['--bottom'];

  const elementStyle = {};

  propertyHelper.setDisplay(elementStyle, element.generalProperties);
  propertyHelper.setTextAlign(elementStyle, element.generalProperties);
  propertyHelper.setSpacing(elementStyle, element.generalProperties.margin, false);
  propertyHelper.setDimensions(elementStyle, element.generalProperties);
  propertyHelper.setPosition(elementStyle, element.generalProperties);

  const setActive = e => {
    e.preventDefault();
    props.dispatch(setActiveElement(element));
  };

  style['--size'] = componentProperties.size + '%';
  style['--d'] = 'block';

  if (componentProperties.color) {
    if (ElementColorClasses.includes(componentProperties.color)) {
      classes += ' ' + componentProperties.color;
      // For now use the variables
      style['--c'] = `var(--${componentProperties.color})`;
    } else {
      style['--c'] = componentProperties.color;
    }
  }

  const icon = (
    <div className={classes}
         style={style}
         title={componentProperties.title || null}
         aria-label={componentProperties.title || null}
         role="img"
    />
  );

  if (componentProperties.linkType) {
    return (
      <ElementDiv {...props} id={id} style={elementStyle} classes={elementClasses}>
        <ElementLink
          linkType={componentProperties.linkType}
          link={componentProperties.link}
          target={componentProperties.linkTarget}
          page={componentProperties.page}
          title={componentProperties.title || null}
          isNofollow={componentProperties.isNofollow}
          onClick={setActive}
        >
          {icon}
        </ElementLink>
      </ElementDiv>
    );
  }

  return (
    <ElementDiv {...props} id={id} style={elementStyle} classes={elementClasses}>
      {icon}
    </ElementDiv>
  );
};

export default connect()(IconElement);
