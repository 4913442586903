import React from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';
import Dropdown from '../General/Dropdown';
import ActionIconButton from '../General/ActionIconButton';
import { formatRelative } from 'date-fns';
import { clearNotifications, removeNotification } from '../../store/actions/notifications';

const NotificationMenu = props => {
  const {notifications} = props;

  const handleRemoveClick = (e, notification) => {
    e.preventDefault();

    props.dispatch(removeNotification(notification._id));
  };

  const handleClearAllClick = (e) => {
    e.preventDefault();

    props.dispatch(clearNotifications());
  };

  return (
    <Dropdown
      placement="bottom-end"
      width="250px"
      padding="0px"
      menu={
        <div style={{'--pos': 'relative'}}>
          <ActionIconButton icon="bell-o"
                            size={props.iconSize}
                            style={props.iconStyle}
                            title={fbt('Notifications', 'Notifications Header')}/>
          {!!notifications.length && (
            <div className="tag mame-primary"
                 style={{'--pos': 'absolute', 'top': '-5px', 'left': '16px', 'width': '15px', 'height': '15px'}}>
              {notifications.length}
            </div>
          )}
        </div>
      }
      content={<>
        <div className="dropdown-header" style={{'--bg': '#f7f7f7'}}>
          <h4 style={{'--m': 0, '--ta': 'center', '--p': '8px 0', '--bb': '1px dotted #ccc'}}>
            <fbt desc="Notifications header">
              Notifications
            </fbt>
          </h4>
        </div>

        {!notifications.length && (
          <p style={{'--p': '15px 15px 0'}}>
            <fbt desc="No Notifications">
              You have no notifications.
            </fbt>
          </p>
        )}

        {!!notifications.length && (
          notifications.map(item => {
            return <NotificationRenderer notification={item} key={item._id} handleRemoveClick={handleRemoveClick}/>;
          })
        )}

        {!!notifications.length && (
          <div style={{'--mt': '5px', '--ta': 'right', '--bt': '1px dotted #ccc'}}>
            <button className="mame-primary" style={{'--x': '80'}} onClick={handleClearAllClick}>
              <fbt desc="NotificationMenu">
                Clear all
              </fbt>
            </button>
          </div>
        )}
      </>}
    />
  );
};

const NotificationRenderer = props => {
  const {notification, handleRemoveClick} = props;

  return (
    <div className="notification" style={{'--p': '10px 15px', '--hvr-bg': 'var(--mame-grey)'}}>
      <div style={{'--d': 'flex', '--ai': 'center', '--jc': 'space-between'}}>
        <h4>{notification.title}</h4>

        <div className="icon icon-close"
             aria-label={fbt('Delete Notification', 'NotificationMenu')}
             title={fbt('Delete Notification', 'NotificationMenu')}
             style={{'--cur': 'pointer'}}
             onClick={(e) => handleRemoveClick(e, notification)}
        />
      </div>
      {notification.message}
      <div style={{'--ta': 'right', '--size': '10px'}}>
        {formatRelative(new Date(notification.created_at), new Date())}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  notifications: state.notifications.notifications,
});


export default connect(mapStateToProps)(NotificationMenu);
