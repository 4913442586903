import React from 'react';
import { connect } from 'react-redux';

import elementHelper from '../../modules/elementHelper';
import { ElementColorClasses } from '../../modules/consts/Elements';
import ElementDiv from '../ElementControls/ElementDiv';
import ElementLink from '../ElementControls/ElementLink';

const ButtonsElement = props => {
  const element = props.element;

  const id = elementHelper.getId(element);
  let classes = elementHelper.getClasses(element, 'mame-buttons');
  const style = elementHelper.getStyle(element);

  if (element.componentProperties.isGroup) {
    classes += ' group';
  }

  const handleOnClick = e => {
    e.preventDefault();
  };

  return (
    <ElementDiv {...props} id={id} classes={classes} style={style}>
      {element.componentProperties.items.map((button, index) => {
        const style = {};

        if (button.width) {
          style['--w'] = button.width + '%';
          style['--ta'] = 'center';
        }

        if (button.size) {
          style['--x'] = button.size;
        }

        let classes = 'button';

        if (button.background) {
          // Check if it's a class
          if (ElementColorClasses.includes(button.background)) {
            classes += ' ' + button.background;

            if (button.outline) {
              classes += ' outline';
              style['--c'] = button.background !== 'white' ? 'var(--element-bg)' : 'var(--form-text)';
            }
          } else {
            style['--bg'] = button.background;
            style['--c'] = button.color || '#f1f1f1';
          }
        }

        const icon = button.icon
          ? (<span className={`icon icon-${button.icon}`} style={{'--mx': '7px'}} aria-hidden/>)
          : '';

        // No link
        if (!button.linkType) {
          return (
            <button role="presentation"
                    key={button.itemId || index}
                    style={style}
                    className={classes}
                    onClick={handleOnClick}
            >
              {icon}
              {button.title}
            </button>
          );
        }

        return (
          <ElementLink
            key={button.itemId || index}
            linkType={button.linkType}
            link={button.link}
            target={button.linkTarget}
            page={button.page}
            isNofollow={button.isNofollow}
            style={style}
            className={classes}
            onClick={handleOnClick}
          >
            {icon}
            {button.title}
          </ElementLink>
        );
      })}
    </ElementDiv>
  );
};

export default connect()(ButtonsElement);
