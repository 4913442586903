import React from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import Fieldset from '../FieldSet';
import ItemsFieldsetWrapper from '../Fieldsets/ItemsFieldsetWrapper';
import TextInput from '../Controls/TextInput';
import CheckboxInput from '../Controls/CheckboxInput';
import HelpText from '../Controls/HelpText';
import InputGroup from '../InputGroup';

const SelectProperties = props => {
  const {model, element} = props;
  const {componentProperties} = element;

  return (
    <>
      <Fieldset title={fbt('Select Properties', 'SelectProperties')} isOpen>
        <InputGroup style={{'--mt': '10px'}}>
          <TextInput title={fbt('Label (Title)', 'SelectProperties')}
                     value={componentProperties.title}
                     id="input-input-title"
                     action={val => props.handlePropertyChange('title', val)}
                     showLabel
          />
        </InputGroup>

        <InputGroup>
          <CheckboxInput title={fbt('Show Label', 'SelectProperties')}
                         action={() => props.handlePropertyChange('showLabel', !componentProperties.showLabel)}
                         checked={componentProperties.showLabel}
                         id="check-show-label"
          />
        </InputGroup>

        <InputGroup>
          <TextInput title={fbt('Custom Name (for email placeholders / 3rd party API)', 'SelectProperties')}
                     value={componentProperties.customName}
                     action={val => props.handlePropertyChange('customName', val)}
                     id="input-custom-name"
                     filter="[^A-Za-z0-9_-]"
                     showLabel
          />
        </InputGroup>

        <InputGroup>
          <TextInput title={fbt('Default Value', 'SelectProperties')}
                     id="input-default-value"
                     value={componentProperties.defaultValue || ''}
                     action={val => props.handlePropertyChange('defaultValue', val)}
                     filter="[^A-Za-z0-9_-]"
                     showLabel
          />
        </InputGroup>

        <InputGroup>
          <CheckboxInput title={fbt('Required', 'SelectProperties')}
                         id="check-required"
                         checked={componentProperties.required}
                         action={() => props.handlePropertyChange('required', !componentProperties.required)}
          />
        </InputGroup>
      </Fieldset>

      <ItemsFieldsetWrapper
        title={fbt('Options', 'SelectProperties')}
        items={componentProperties.items}
        actionDispatch={props.dispatchItems}
        template={model.itemTemplate}
        isOpen
      >
        <Controls
          action={props.handleItemChange}/>
      </ItemsFieldsetWrapper>
    </>
  );
};

const Controls = props => {
  const item = props.item;
  const index = props.index;

  return (
    <>
      <InputGroup>
        <TextInput title={fbt('Text', 'SelectProperties')}
                   value={item.text}
                   action={(val) => props.action(index, 'text', val)}
                   showLabel
        />
        <HelpText>
          <fbt desc="SelectProperties">
            The text is shown to the user.
          </fbt>
        </HelpText>
      </InputGroup>

      <InputGroup>
        <TextInput title={fbt('Value', 'SelectProperties')}
                   action={(val) => props.action(index, 'value', val)}
                   value={item.value}
                   filter="[^A-Za-z0-9_-]"
                   showLabel
        />
        <HelpText>
          <fbt desc="SelectProperties">
            This is the actual value send in Mails and to APIs.
          </fbt>
        </HelpText>
      </InputGroup>
    </>
  );
};

export default connect()(SelectProperties);
