import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';

import fontsHelper from '../../modules/fontsHelper';
import CookieBanner from './CookieBanner';
import config from '../../config';
import { setActivePageCanonical, setPresetStyles } from '../../store/actions/activePage';
import { isRenderSelector } from '../../store/selectors';

const Site = props => {
  const {page, site, dispatch, isRender} = props;

  const getCanonical = () => {
    if (!page || !site) {
      return '';
    }

    if (page.meta && page.meta.canonicalUrl) {
      return page.meta.canonicalUrl;
    }

    // We need the base URL
    if (!site.meta.canonicalUrl) {
      return null;
    }

    if (!page.alias || page.alias === '/') {
      return site.meta.canonicalUrl;
    }

    const base = site.meta.canonicalUrl.replace(/\/+$/, '');

    if (page.alias.includes('/')) {
      if (page.alias.endsWith('/index')) {
        return `${base}/${page.alias.replace('/index', '')}/`;
      }

      return `${base}/${page.alias}/`;
    }

    if (page.alias === 'home') {
      return site.meta.canonicalUrl;
    }

    return `${site.meta.canonicalUrl}/${page.alias}.html`;
  };

  const canonicalUrl = getCanonical();

  useEffect(() => {
    // Set it in the state, so other components can use it
    dispatch(setActivePageCanonical(canonicalUrl));
  }, [page, site, dispatch, canonicalUrl]);

  useEffect(() => {
    if (!site || !page) {
      return;
    }

    const colors = [];

    const getColor = type => {
      if (page.colors && page.colors[type]) {
        return page.colors[type];
      }

      return site.colors[type];
    };

    // Too lazy for a loop
    colors.push(getColor('primaryColor'));
    colors.push(getColor('secondaryColor'));
    colors.push(getColor('white'));
    colors.push(getColor('grey'));
    colors.push(getColor('darkGrey'));
    colors.push(getColor('black'));
    colors.push(getColor('green'));
    colors.push(getColor('blue'));
    colors.push(getColor('orange'));
    colors.push(getColor('red'));
    colors.push(getColor('linkColor'));
    colors.push(getColor('linkHoverColor'));
    colors.push(getColor('successBackground'));
    colors.push(getColor('warningBackground'));

    dispatch(setPresetStyles([...new Set(colors)]));
  }, [page, site, dispatch]);

  if (!site) {
    return null;
  }

  const {colors, typography, settings, meta, integrations} = site;

  const getMeta = key => {
    if (page.meta && page.meta[key]) {
      return page.meta[key];
    }

    if (key === 'twitterCard' && !meta.twitterCard) {
      return 'summary';
    }

    if (key === 'twitterTitle' && page.title) {
      return page.title;
    }

    if (key === 'ogUrl' && canonicalUrl) {
      return canonicalUrl;
    }

    return meta[key];
  };

  const getTypography = key => {
    if (page.typography && page.typography[key]) {
      return page.typography[key];
    }

    return typography[key];
  };

  const getColor = key => {
    if (page.colors && page.colors[key]) {
      return page.colors[key];
    }

    return colors[key];
  };

  const primaryFont = fontsHelper.getFont(getTypography('primaryFont'), getTypography('primaryFamily'), getTypography('primaryUrl'), true);
  const secondaryFont = fontsHelper.getFont(getTypography('secondaryFont'), getTypography('secondaryFamily'), getTypography('secondaryUrl'));

  const favIconPath = config.baseUrl + '/' + settings.favIcon;

  const appleTouchIcons = ['57x57', '60x60', '72x72', '76x76', '76x76', '114x114', '120x120', '144x144', '152x152', '180x180'];

  let title = props.page.title;

  if (settings.titlePosition === '') {
    title += ' - ' + props.site.title;
  } else if (settings.titlePosition === 'before') {
    title = props.site.title + ' - ' + title;
  }

  const metaDescription = getMeta('description');
  const metaKeywords = getMeta('keywords');
  const language = getMeta('language');

  const metaOgTitle = getMeta('ogTitle');
  const metaOgImage = getMeta('ogImage');
  const metaOgType = getMeta('ogType');
  const metaOgUrl = getMeta('ogUrl');
  const metaOgSiteName = getMeta('ogSiteName');
  const metaOgDescription = getMeta('ogDescription');

  const metaTwitterCard = getMeta('twitterCard');
  const metaTwitterTitle = getMeta('twitterTitle');
  const metaTwitterImage = getMeta('twitterImage');
  const metaTwitterSite = getMeta('twitterSite');

  // TODO
  // props.dispatch(setActivePageTitle(title));

  const scriptSources = 'https://*.mame.app https://*.google-analytics.com https://*.googletagmanager.com https://*.googleapis.com ' +
    'https://*.gstatic.com https://*.google.com https://*.googleapis.com https://*.plausible.io https://*.unpkg.com https://*.disqus.com https://*.disquscdn.com';
  const cspContent = `default-src 'self'; script-src 'self' 'unsafe-inline' ${scriptSources}; style-src 'self' 'unsafe-inline' https://*.mame.app https://fonts.googleapis.com https://*.unpkg.com https://unpkg.com https://*.disquscdn.com; frame-src 'self' https://*.google.com https://www.googletagmanager.com https://www.youtube.com https://www.youtube-nocookie.com https://*.disquscdn.com https://*.disqus.com https://disqus.com; font-src 'self' https://fonts.gstatic.com https://*.mame.app; media-src 'self' https://*.googlevideo.com; connect-src *; prefetch-src 'self' https://*.disquscdn.com https://*.disqus.com https://disqus.com; img-src * data:`;

  // Custom JS
  const siteCustomJs = `try { ${settings.customCode.javascript} } catch (e) { console.error('Error in custom site JS', e); }`;

  let pageCustomJs = '';

  if (page.settings && page.settings.customCode && page.settings.customCode.javascript) {
    pageCustomJs = `try { ${props.page.settings.customCode.javascript} } catch (e) { console.error('Error in custom page JS', e); }`;
  }

  // Custom CSS
  let pageCustomCss = '';

  if (page.settings && page.settings.customCode && page.settings.customCode.css) {
    pageCustomCss = page.settings.customCode.css;
  }

  // Google Analytics code
  const analyticsJs = `window.dataLayer = window.dataLayer || [];function gtag() {dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${integrations ? integrations.analytics.uaCode : ''}', {'anonymize_ip': true});`;

  return (
    <>
      <Helmet>
        <html lang={language || 'en'}/>

        <title>{title}</title>

        {metaDescription && <meta name="description" content={metaDescription}/>}
        {metaKeywords && <meta name="keywords" content={metaKeywords}/>}

        {isRender && <link rel="manifest" href="manifest.json"/>}

        {canonicalUrl && <link rel="canonical" href={canonicalUrl}/>}

        {settings.csp && <meta httpEquiv="Content-Security-Policy" content={cspContent}/>}
        {settings.csp && <meta httpEquiv="X-Content-Security-Policy" content={cspContent}/>}
        {settings.csp && <meta httpEquiv="X-WebKit-CSP" content={cspContent}/>}
        {settings.referrerPolicy && <meta name="referrer" content="strict-origin-when-cross-origin"/>}

        {metaOgTitle && <meta property="og:title" content={metaOgTitle}/>}
        {metaOgImage && <meta property="og:image" content={metaOgImage}/>}
        {metaOgDescription && <meta property="og:description" content={metaOgDescription}/>}
        {metaOgUrl && <meta property="og:url" content={metaOgUrl}/>}
        {metaOgType && <meta property="og:type" content={metaOgType}/>}
        {metaOgSiteName && <meta property="og:site_name" content={metaOgSiteName}/>}

        {metaTwitterCard && <meta property="twitter:card" content={metaTwitterCard}/>}
        {metaTwitterTitle && <meta property="twitter:title" content={metaTwitterTitle}/>}
        {metaTwitterSite && <meta property="twitter:site" content={metaTwitterSite}/>}
        {metaTwitterImage && <meta property="twitter:image" content={metaTwitterImage}/>}

        {primaryFont.url && (
          <>
            <link rel="preload" href={primaryFont.url} as="style"/>
            <link href={primaryFont.url} rel="stylesheet"/>
          </>
        )}

        {secondaryFont.url && (
          <>
            <link rel="preload" href={secondaryFont.url} as="style"/>
            <link href={secondaryFont.url} rel="stylesheet"/>
          </>
        )}

        {settings.favIcon && <link rel="icon" href={favIconPath + 'favicon-96x96.png'} type="image/png"/>}
        {settings.favIcon && <link rel="icon" sizes="16x16" href={favIconPath + 'favicon-16x16.png'} type="image/png"/>}
        {settings.favIcon && <link rel="icon" sizes="32x32" href={favIconPath + 'favicon-32x32.png'} type="image/png"/>}
        {settings.favIcon &&
        <link rel="icon" sizes="192x192" href={favIconPath + 'android-icon-192x192.png'} type="image/png"/>}
        {settings.favIcon && appleTouchIcons.map(item => (
          <link key={item} rel="apple-touch-icon" sizes={item} href={favIconPath + 'apple-icon-' + item + '.png'}
                type="image/png"/>
        ))}

        {/* TODO Manifest */}

        <style>{`
        :root {
           --primary: ${getColor('primaryColor')};
           --secondary: ${getColor('secondaryColor')};
           --links: ${getColor('linkColor')};
           --white: ${getColor('white')};
           --grey: ${getColor('grey')};
           --dark-grey: ${getColor('darkGrey')};
           --black: ${getColor('black')};
           --green: ${getColor('green')};
           --blue: ${getColor('blue')};
           --orange: ${getColor('orange')};
           --red: ${getColor('red')};
           --background: ${getColor('background')};
           --background-body: ${getColor('background')};
        }

        html {
          font-size: ${getTypography('fontSize')}px;
          font-family: ${primaryFont.family};
          font-weight: ${getTypography('fontWeight')};
          line-height: ${getTypography('lineHeight')};
          color: ${getColor('color')};
          background: ${getColor('background')};
        }

        h1, h2, h3, h4, h5, h6 {
          font-family: ${secondaryFont.family || primaryFont.family};
        }

        a, .fake-link {
          color: ${getColor('linkColor')};
        }

        a:hover, .fake-link:hover {
          color: ${getColor('linkHoverColor')};
        }

        .success {
          background: ${getColor('successBackground')};
        }

        .warning {
          background: ${getColor('warningBackground')};
        }
        
        ${settings.customCode.css || ''}
        ${pageCustomCss}
      `}
        </style>

        {settings.customCode.javascript && (
          <script>
            {siteCustomJs}
          </script>
        )}

        {pageCustomJs && (
          <script>
            {pageCustomJs}
          </script>
        )}

        {(integrations && integrations.plausible.enabled && integrations.plausible.domain) && (
          <script async defer data-domain={integrations.plausible.domain}
                  src="https://plausible.io/js/plausible.js">
          </script>
        )}

        {(integrations && integrations.analytics.enabled && integrations.analytics.uaCode) && (
          <>
            <script async src={`https://www.googletagmanager.com/gtag/js?id=${integrations.analytics.uaCode}`}></script>
            <script>
              {analyticsJs}
            </script>
          </>
        )}
      </Helmet>

      {props.children}

      <CookieBanner/>
    </>
  );
};

const mapStateToProps = state => ({
  isRender: isRenderSelector(state),
  site: state.activeSite.site,
  page: state.activeSite.page,
});

export default connect(mapStateToProps)(Site);
