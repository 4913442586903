import produce from 'immer';
import { ADD_NOTIFICATION, CLEAR_NOTIFICATIONS, REMOVE_NOTIFICATION, SET_NOTIFICATIONS } from '../actionTypes';

const initialState = {
  notifications: [],
};

const notificationsState = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTIFICATIONS: {
      const {notifications} = action.payload;

      return {
        ...state,
        notifications,
      };
    }

    case ADD_NOTIFICATION: {
      const {notification} = action.payload;

      return produce(state, draft => {
        draft.notifications.push(notification);
      });
    }

    case REMOVE_NOTIFICATION: {
      const {notificationId} = action.payload;

      const notificationIndex = state.notifications.find(n => n._id === notificationId);

      return produce(state, draft => {
        draft.notifications.splice(notificationIndex, 1);
      });
    }

    case CLEAR_NOTIFICATIONS: {
      return produce(state, draft => {
        draft.notifications = [];
      });
    }

    default: {
      return state;
    }
  }
};

export default notificationsState;
