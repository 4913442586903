import PayPalDonateProperties from '../../components/Properties/Elements/PayPalDonateProperties';
import { ElementVisible } from '../../modules/consts/Elements';

const PayPalDonateModel = {
  title: 'PayPal Button',
  category: 'Actions',
  helpPage: '/documentation/elements/paypal-button',
  component: 'PayPalDonateElement',
  propertyComponent: PayPalDonateProperties,
  hasChildElements: false,
  generalProperties: {
    hasVisibility: true,
    hasTextAlign: true,
    hasTextColor: false,
    hasBackground: true,
    hideFont: true,
  },
  defaults: {
    demo: true,
    _id: null,
    identifier: 'New PayPal Button',
    component: 'PayPalDonateElement',
    componentProperties: {
      recipient: '',
      type: '_xclick',
      countryCode: 'en_US',
      itemName: '',
      itemNumber: '',
      currencyCode: 'USD',
      fixedAmount: 0,
      showCards: true,
    },
    generalProperties: {
      visibility: [ElementVisible.sm, ElementVisible.md, ElementVisible.lg, ElementVisible.xl],
      published: true,
      margin: [0, 0, 0, 0],
      padding: [0, 0, 0, 0],
    },
  },
};

export default PayPalDonateModel;
