import React from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import elementHelper from '../../modules/elementHelper';
import ElementDiv from '../ElementControls/ElementDiv';

const CheckboxElement = props => {
  const element = props.element;
  const {componentProperties} = element;

  const id = elementHelper.getId(element);
  const classes = elementHelper.getClasses(element, 'mame-checkbox');
  const style = elementHelper.getStyle(element);

  const fieldId = elementHelper.getId(element, 'input-');

  return (
    <ElementDiv {...props} id={id} classes={classes} style={style}>
      <label htmlFor={fieldId}>
        <input type="checkbox"
               value={fbt('Yes', 'Element Checkbox')}
               name={componentProperties.customName || fieldId}
               id={fieldId}
               checked={componentProperties.isChecked || null}
               required={componentProperties.required || null}
               data-invalid-text={componentProperties.invalidMessage || null}
               onChange={() => {}}
        />
        {componentProperties.title}
      </label>
    </ElementDiv>
  );
};

export default connect()(CheckboxElement);
