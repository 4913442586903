import BaseService from './BaseService';

const paths = {
  get: '/templates/page',
  post: '/templates/page',
  patch: '/templates/page',
  delete: '/templates/page',
};

/**
 * Service for querying the Sites
 */
class PageTemplateService extends BaseService {
  constructor() {
    super(paths);
    this.requireLogin = true;
  }
}

export default new PageTemplateService();
