import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import elementHelper from '../../modules/elementHelper';
import ElementDiv from '../ElementControls/ElementDiv';

const TypedTextElement = props => {
  const {element, isRender} = props;
  const {componentProperties} = element;

  useEffect(() => {
    if (isRender) {
      return;
    }

    window.setupTyped();
  }, [element, isRender]);

  const id = elementHelper.getId(element);
  const classes = elementHelper.getClasses(element, 'mame-typed-text');
  const style = elementHelper.getStyle(element);

  const CustomTag = componentProperties.displayAs || 'h3';

  return (
    <ElementDiv {...props} id={id} classes={classes} style={style}>
      <div className="typed"
           data-type-speed={componentProperties.typeSpeed || 80}
           data-loop={componentProperties.loop ? 'true' : 'false'}
           data-show-cursor={componentProperties.showCursor ? 'true' : 'false'}>
        <div className="typed__strings">
          {componentProperties.sentences.map((item, index) => {
            return (
              <CustomTag className="typed__item" key={index}>
                {item}
              </CustomTag>
            );
          })}
        </div>
        <CustomTag>
          <span className="typed__content"/>
        </CustomTag>
      </div>
    </ElementDiv>
  );
};

const mapStateToProps = state => ({
  isRender: state.builder.isRender,
});

export default connect(mapStateToProps)(TypedTextElement);
