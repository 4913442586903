import React from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import TextInput from '../Controls/TextInput';
import ItemsFieldsetWrapper from '../Fieldsets/ItemsFieldsetWrapper';
import Fieldset from '../FieldSet';
import SelectInput from '../Controls/SelectInput';
import IconPicker from '../Controls/IconPicker';
import InputGroup from '../InputGroup';

const TabsProperties = props => {
  const {model, element} = props;
  const {componentProperties} = element;

  return (
    <>
      <Fieldset title={fbt('Tab Properties', 'TabsProperties')} isOpen>
        <InputGroup style={{'--mt': '10px'}}>
          <SelectInput id="tab-style" title={fbt('Tab Style', 'TabsProperties')}
                       value={componentProperties.style}
                       action={val => {
                         props.handlePropertyChange('style', val);
                       }}
                       options={{
                         '': fbt('Default', 'TabsProperties'),
                         'vertical': fbt('Vertical', 'TabsProperties'),
                       }}
                       showLabel
          />
        </InputGroup>
      </Fieldset>

      <ItemsFieldsetWrapper
        title={fbt('Tabs', 'TabsProperties')}
        items={componentProperties.items}
        actionDispatch={props.dispatchItems}
        template={model.itemTemplate}
        isOpen
      >
        <Controls
          action={props.handleItemChange}/>
      </ItemsFieldsetWrapper>
    </>
  );
};

const Controls = props => {
  const item = props.item;
  const index = props.index;

  return (
    <>
      <InputGroup>
        <TextInput title={fbt('Tab Title', 'TabsProperties')}
                   value={item.title}
                   action={val => props.action(index, 'title', val)}
                   showLabel
                   required
        />
      </InputGroup>

      <InputGroup>
        <IconPicker icon={item.icon} hasNone minimized
                    title={fbt('Tab Icon (Optional)', 'TabsProperties')}
                    action={icon => props.action(index, 'icon', icon)}
                    showLabel
        />
      </InputGroup>
    </>
  );
};

export default connect()(TabsProperties);
