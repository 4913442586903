import React, { useEffect, useState } from 'react';
import { updateElementProperty } from '../../store/actions/activeSite';
import DnDContainer from '../Sortable/DnDContainer';
import DnDItem from '../Sortable/DnDItem';
import { ElementRendererProperties } from './SectionProperties';
import { connect } from 'react-redux';

const ElementChildrenOverview = props => {
  const [sortingElements, setSortingElements] = useState(props.element.elements);

  const {element} = props;

  useEffect(() => {
    setSortingElements(element.elements);
  }, [element]);

  const storeSorting = newSorting => {
    props.dispatch(updateElementProperty(element, null, 'elements', newSorting));
  };

  return (
    <DnDContainer items={sortingElements}
                  onMove={list => {
                    setSortingElements(list);
                  }}
                  group="element-elements"
                  onEnd={storeSorting}
    >
      {sortingElements.map((childId, index) => {
        const child = props.elements.find(e => e._id === childId);

        return (
          <DnDItem key={childId} id={childId} index={index}>
            <ElementRendererProperties
              {...props}
              key={childId}
              element={child}
              index={index}
              parentType="element"
              parentId={props.element._id}
            />
          </DnDItem>
        );
      })}
    </DnDContainer>
  );
};

const mapStateToProps = state => ({
  elements: state.activeSite.elements,
  activeElement: state.builder.activeElement,
  activeType: state.builder.activeType,
});

export default connect(mapStateToProps)(ElementChildrenOverview);
