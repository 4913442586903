import React, { useState } from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';
import { Link, useHistory } from 'react-router-dom';

import Dropdown from '../General/Dropdown';
import { showModal } from '../../store/actions/modals';
import { closeActiveProperty, setSidebarView } from '../../store/actions/builder';
import { isPreviewSelector } from '../../store/selectors';

const SiteMenu = props => {
  if (!props.site) {
    return null;
  }

  // Preview Menu
  if (props.isPreview) {
    return (
      <div style={{'--d': 'flex', '--minw': '250px', '--mr': '20px'}}>
        <div style={{'--d': 'flex', '--ai': 'center', '--fg': 1}}>
          <div style={{'--lh': 1}}>
            <small>
              <fbt desc="Site Top Menu">Currently Previewing</fbt>
            </small><br/>
            {props.page && <span><b>{props.page.title}</b> - </span>}
            <b>{props.site.title}</b>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Dropdown
      width="300px"
      menu={<MenuTitle site={props.site} page={props.page}/>}
      content={<Content {...props}/>}>
    </Dropdown>
  );
};

const MenuTitle = props => {
  return (
    <div style={{'--d': 'flex', '--minw': '250px', '--mr': '20px'}}>
      <div style={{'--d': 'flex', '--ai': 'center', '--fg': 1}}>
        <div style={{'--lh': 1}}>
          <small>
            <fbt desc="Site Top Menu">Currently Editing</fbt>
          </small><br/>
          {props.page && <span><b>{props.page.title}</b> - </span>}
          <b>{props.site.title}</b>
        </div>
        <div style={{'--fg': 1, '--ta': 'right'}}>
          <span className="icon icon-angle-down icon-2x"/>
        </div>
      </div>
    </div>
  );
};

const Content = props => {
  const [showMore, setShowMore] = useState(false);

  const history = useHistory();

  const handlePageRedirect = page => {
    props.dispatch(closeActiveProperty());
    props.dispatch(setSidebarView('page'));
    history.push(`/builder/${props.site._id}/${page.alias}`);
  };

  return (
    <>
      <div className="menu-entry fake-link" onClick={() => {
        props.dispatch(showModal('SaveTemplateModal', {type: 'site', item: props.site}));
      }}>
        <fbt desc="Site Top Menu">
          Save Site as Template
        </fbt>
      </div>
      <hr/>
      <strong>
        <fbt desc="Site Top Menu">
          Pages
        </fbt>
      </strong>
      <hr/>
      <div style={{'--line-clamp': showMore ? '0' : '8'}}>
        {props.pages && props.pages.map((page, index) => {
          return (
            <div className="menu-entry" key={index}>
            <span className="fake-link"
                  role="button"
                  onClick={() => {
                    handlePageRedirect(page);
                  }}>
              {page.title}
              </span>
            </div>
          );
        })}
      </div>
      {(props.pages.length > 9 && !showMore) && (
        <div className="menu-entry" role="button" onClick={() => setShowMore(true)} style={{'--cur': 'pointer'}}>
          <fbt desc="SiteMenu">
            Show all ..
          </fbt>
        </div>
      )}
      <hr/>
      <div className="menu-entry fake-link" onClick={() => {
        props.dispatch(showModal('AddPageModal'));
      }}>
        <fbt desc="Site Top Menu">
          Add Page
        </fbt>
      </div>
      <hr/>
      <div className="menu-entry">
        <Link to="/">
          <fbt desc="Site Top Menu">
            Return to your Dashboard
          </fbt>
        </Link>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  isPreview: isPreviewSelector(state),
  site: state.activeSite.site,
  page: state.activeSite.page,
  pages: state.activeSite.pages,
});

export default connect(mapStateToProps)(SiteMenu);
