import QuizProperties from '../../components/Properties/Elements/QuizProperties';
import { ElementVisible } from '../../modules/consts/Elements';

const QuizModel = {
  title: 'Quiz',
  image: '/img/elements/quiz.webp',
  category: 'Actions',
  helpPage: '/documentation/elements/quiz',
  component: 'QuizElement',
  propertyComponent: QuizProperties,
  hasChildElements: false,
  generalProperties: {
    hasVisibility: true,
    hasTextAlign: true,
    hasTextColor: false,
    hasBackground: true,
  },
  defaults: {
    demo: true,
    _id: null,
    identifier: 'New Quiz',
    component: 'QuizElement',
    componentProperties: {
      style: 'default',
      answerButtonLabel: 'Answer',
      textInputLabel: 'Please type in the answer',
      prevLabel: 'Previous',
      nextLabel: 'Next',
      resultText: 'Your Score',
      againButtonLabel: 'Try again',
      items: [{
        itemId: 42,
        title: 'What is the meaning of Mame?',
        type: 'single',
        answerText: '',
        answers: [
          {
            content: 'Mame are very tiny bonsai trees.',
            isCorrect: true,
          },
          {
            content: 'Mame is another word for Meme.',
            isCorrect: false,
          },
          {
            content: 'Mame is Japanese for Content Management.',
            isCorrect: false,
          },
          {
            content: 'Mame means all together in Swahili.',
            isCorrect: false,
          },
        ],
      }]
    },
    generalProperties: {
      visibility: [ElementVisible.sm, ElementVisible.md, ElementVisible.lg, ElementVisible.xl],
      published: true,
      margin: [0, 0, 0, 0],
      padding: [0, 0, 0, 0],
    },
  },
  itemTemplate: {
    itemId: 0,
    title: 'Question?',
    // Single, Multiple, Text
    type: 'single',
    answerText: '',
    answers: [
      {
        content: 'Answer A',
        isCorrect: true,
      },
      {
        content: 'Answer B',
        isCorrect: false,
      },
      {
        content: 'Answer C',
        isCorrect: false,
      },
    ]
  },
};

export default QuizModel;
