import BaseService from './BaseService';
import axios from 'axios';

const paths = {
  get: '/element',
  post: '/element',
  patch: '/element',
  delete: '/element',
  move: '/element/move',
};

/**
 * Service for querying the element
 */
class ElementService extends BaseService {
  constructor() {
    super(paths);
    this.requireLogin = true;
  }

  /**
   * Move an element
   * @param elementId
   * @param options
   * @param hideModal
   * @return {*}
   */
  move(elementId, options, hideModal = false) {
    const method = 'patch';
    const url = this.getPath('move');

    const data = {
      elementId,
      options,
    };

    return axios({
      method,
      url,
      data,
      hideModal,
    });
  }
}

export default new ElementService();
