import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import serviceHelper from '../modules/serviceHelper';
import ProfileFigure from '../components/Profile/ProfileFigure';
import { useParams } from 'react-router-dom';

const Profiles = props => {
  const {skip} = useParams();

  const [offset, setOffset] = useState(skip || 0);
  const [profiles, setProfiles] = useState([]);

  const profileService = serviceHelper.getService('ProfileService');

  useEffect(() => {
    setOffset(skip);
  }, [skip]);

  useEffect(() => {
    profileService
      .get({offset, limit: 30})
      .then(result => {
        setProfiles(result.data.items);
      });
  }, [offset, profileService, setProfiles]);

  return (
    <div className="wrapper" style={{'--gutter': '1vw'}}>
      <div style={{'--mt': '20px'}}>
        <h3>Mame Profiles</h3>

        <div style={{'--d': 'grid', '--gtc': '1fr 1fr 1fr', '--gg': '30px'}}>
          {profiles.map(item => {
            return (
              <ProfileFigure
                profile={item}
                key={item.id}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps)(Profiles);
