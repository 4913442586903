import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import config from '../../config';
import Dialog from './Dialog';
import { apiAddTemplateSection, apiCaptureSection, updateSectionProperty } from '../../store/actions/activeSite';
import SectionPreview from '../SectionControls/SectionPreview';
import ThreeColumnGrid from '../General/ThreeColumnGrid';
import ComingSoon from '../General/ComingSoon';
import { updatePageProperty } from '../../store/actions/activePage';
import { removeSectionTemplate, updateSectionTemplate } from '../../store/actions/templates';
import { confirmationDialog } from '../../store/actions/builder';
import { systemSectionTemplatesSelector, userSectionTemplatesSelector } from '../../store/selectors';
import { hideModal } from '../../store/actions/modals';
import logger from '../../modules/logger';
import HelpText from '../Properties/Controls/HelpText';
import FourColumnGrid from '../General/FourColumnGrid';

const AddSectionModal = props => {
  const {systemTemplates = [], userTemplates = [], sections = [], isVisible = false} = props;

  const [searchTerm, setSearchTerm] = useState('');
  const [filteredSystemTemplates, setFilteredSystemTemplates] = useState(systemTemplates);
  const [filteredUserTemplates, setFilteredUserTemplates] = useState(userTemplates);
  const [filteredSiteSections, setFilteredSiteSections] = useState(sections);

  useEffect(() => {
    setFilteredSystemTemplates(systemTemplates);
  }, [systemTemplates]);

  useEffect(() => {
    const orderBy = items => {
      return items.sort((a, b) => {
        return a.favorite === b.favorite ? 0 : a.favorite ? -1 : 1;
      });
    };

    // read only.. :-/
    setFilteredUserTemplates(orderBy(JSON.parse(JSON.stringify(userTemplates))));
  }, [userTemplates]);

  useEffect(() => {
    const orderBy = items => {
      return items.sort((a, b) => {
        return a.favorite === b.favorite ? 0 : a.favorite ? -1 : 1;
      });
    };

    // read only.. :-/
    setFilteredSiteSections(orderBy(JSON.parse(JSON.stringify(sections))));
  }, [sections]);

  if (!isVisible) {
    return null;
  }

  const handleSearch = searchTerm => {
    setSearchTerm(searchTerm);

    if (!searchTerm) {
      setFilteredSystemTemplates(systemTemplates);
      setFilteredUserTemplates(userTemplates);
      setFilteredSiteSections(sections);
      return;
    }

    const filteredSystem = systemTemplates.filter(t => t.title.toLowerCase().includes(searchTerm));
    setFilteredSystemTemplates(filteredSystem);

    // Templates
    const filteredUser = userTemplates.filter(t => t.title.toLowerCase().includes(searchTerm));
    setFilteredUserTemplates(filteredUser);

    // Sections
    const filteredSections = sections.filter(t => t.identifier.toLowerCase().includes(searchTerm));
    setFilteredSiteSections(filteredSections);
  };

  const handleAdd = (e, template) => {
    e.preventDefault();
    props.dispatch(apiAddTemplateSection(template, props.siteId, props.page._id, props.payload.after));
    props.dispatch(hideModal('AddSectionModal'));
  };

  const handleAddExisting = (e, section) => {
    e.preventDefault();

    const sections = [...props.page.sections];

    if (!props.payload.after) {
      sections.push(section._id);
    } else {
      const index = sections.findIndex(s => s === props.payload.after);
      sections.splice(index + 1, 0, section._id);
    }

    props.dispatch(updatePageProperty(props.page, 'sections', null, sections));
    props.dispatch(hideModal('AddSectionModal'));
  };

  const handleScreenshot = (e, section) => {
    e.preventDefault();
    props.dispatch(apiCaptureSection(section));
  };

  const handleDelete = (e, template) => {
    e.stopPropagation();
    props.dispatch(confirmationDialog(
      fbt('Do you really want to delete this Template?', 'AddSectionModal'),
      () => {
        props.dispatch(removeSectionTemplate(template._id));
      },
    ));
  };

  const handleSell = (e, template) => {
    e.stopPropagation();
    // TODO implement
    logger.info('TODO', template.title);
  };

  const handleFavoriteClick = (item, type = 'shared') => {
    if (type === 'shared') {
      props.dispatch(updateSectionProperty(item, null, 'favorite', !item.favorite));
      return;
    }

    // Immer readonly
    const copy = JSON.parse(JSON.stringify(item));
    copy.favorite = !copy.favorite;

    props.dispatch(updateSectionTemplate(copy));
  };

  return (
    <Dialog title={fbt('Add Section', 'AddSectionModal')} name="AddSectionModal" size="modal-xl">
      <Tabs forceRenderTabPanel>
        <TabList>
          <Tab>
            <span className="icon icon-books" style={{'--mr': '10px'}}/>
            <fbt desc="AddSectionModal">
              Default
            </fbt>
          </Tab>
          <Tab>
            <span className="icon icon-books" style={{'--mr': '10px'}}/>
            <fbt desc="AddSectionModal">
              This Site (Shared)
            </fbt>
          </Tab>
          <Tab>
            <span className="icon icon-shopping-basket" style={{'--mr': '10px'}}/>
            <fbt desc="AddSectionModal">
              Shop
            </fbt>
          </Tab>
          <Tab>
            <span className="icon icon-folder-open" style={{'--mr': '10px'}}/>
            <fbt desc="AddSectionModal">
              Your Templates
            </fbt>
          </Tab>
        </TabList>

        <div style={{'--bgc': 'var(--mame-white)', '--p': '15px 15px 0'}}>
          <input type="search"
                 value={searchTerm}
                 placeholder="Search Sections..."
                 style={{'--m': 0}}
                 onChange={(e) => handleSearch(e.target.value)}
          />
        </div>

        <div className="add-section modal-content" style={{'--radius': '0 0 5px 5px', '--p': '20px 10px'}}>
          <TabPanel>
            {/* Default bundled sections */}
            <div style={{'--ta': 'center'}}>
              <HelpText>
                <fbt desc="AddSectionModal">
                  These are some sample templates, you may want to create your own by starting with an empty section.
                </fbt>
              </HelpText>
            </div>
            <FourColumnGrid>
              {filteredSystemTemplates.map((item, index) => {
                return (
                  <SectionPreview key={index}
                                  title={item.title}
                                  image={item.image || 'unknown.png'}
                                  imageStyle={{'--h': '100%'}}
                                  icon={item.icon}
                                  action={e => handleAdd(e, item)}
                  />
                );
              })}
            </FourColumnGrid>
            <div style={{'--ta': 'center'}}>
              <fbt desc="AddSectionModal">
                Nothing that fits? Start with an Empty Section or check the shop.
              </fbt>
            </div>
          </TabPanel>
          <TabPanel>
            {/* Site sections */}
            <div style={{'--ta': 'center', '--mb': '10px'}}>
              <fbt desc="AddSectionModal">
                Insert a section you are using on another page of this website.
                The section is shared between the different pages (not a copy).
              </fbt>
              <br/>
              <fbt desc="AddSectionModal">
                Most common use cases are menus, headers and footers.
              </fbt>
              <br/>
            </div>
            <FourColumnGrid>
              {filteredSiteSections.map(item => {
                const previewImg = config.baseUrl + `/preview/section/${item._id}/preview.jpg`;

                return (
                  <SectionPreview
                    key={item._id}
                    title={item.identifier}
                    favorite={item.favorite}
                    markAsFavorite={() => {
                      handleFavoriteClick(item);
                    }}
                    image={previewImg}
                    makeScreenshot={(e) => handleScreenshot(e, item)}
                    action={(e) => handleAddExisting(e, item)}
                  />
                );
              })}
            </FourColumnGrid>
          </TabPanel>
          <TabPanel>
            <ComingSoon/>
          </TabPanel>
          <TabPanel>
            {/* Template sections */}
            {!userTemplates.length && (
              <div style={{'--ta': 'center'}}>
                <h4>
                  <fbt desc="AddSectionModal">
                    You don't have any section templates.
                  </fbt>
                </h4>
                <fbt desc="AddSectionModal">
                  You can add one by clicking on the "Save as Template" icon when you hover over an section.
                </fbt>
              </div>
            )}

            <ThreeColumnGrid>
              {filteredUserTemplates.map((item) => {
                const previewImg = config.baseUrl + `/preview/section-template/${item._id}/preview.jpg`;

                return (
                  <SectionPreview key={item._id}
                                  id={`t-${item._id}`}
                                  title={item.title}
                                  image={previewImg}
                                  favorite={item.favorite}
                                  markAsFavorite={() => {
                                    handleFavoriteClick(item, 'template');
                                  }}
                                  action={e => handleAdd(e, item)}
                                  delete={e => handleDelete(e, item)}
                                  sell={e => handleSell(e, item)}
                  />
                );
              })}
            </ThreeColumnGrid>
          </TabPanel>
        </div>
      </Tabs>
    </Dialog>
  );
};

const mapStateToProps = state => ({
  page: state.activeSite.page,
  siteId: state.activeSite.site._id,
  systemTemplates: systemSectionTemplatesSelector(state),
  userTemplates: userSectionTemplatesSelector(state),
  sections: state.activeSite.sections,
  isVisible: state.modals.AddSectionModal,
  payload: state.modals.payload.AddSectionModal,
});

export default connect(mapStateToProps)(AddSectionModal);
