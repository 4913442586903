import React from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import Fieldset from '../FieldSet';
import CheckboxInput from '../Controls/CheckboxInput';
import AdvancedColorPicker from '../Controls/AdvancedColorPicker';
import InputGroup from '../InputGroup';

const BreadcrumbProperties = props => {
  const {element} = props;
  const {componentProperties} = element;

  return (
    <Fieldset title={fbt('Breadcrumb Properties', 'BreadcrumbProperties')} isOpen>
      <InputGroup style={{'--mt': '10px'}}>
        <CheckboxInput title="Links" checked={componentProperties.hasLinks}
                       action={() => {
                         props.handlePropertyChange('hasLinks', !componentProperties.hasLinks);
                       }}
        />
      </InputGroup>

      <InputGroup>
        <CheckboxInput title={fbt('Include Home', 'BreadcrumbProperties')} checked={componentProperties.hasHome}
                       action={() => {
                         props.handlePropertyChange('hasHome', !componentProperties.hasHome);
                       }}
        />
      </InputGroup>

      <AdvancedColorPicker title={fbt('Item Color', 'BreadcrumbProperties')} value={componentProperties.linkColor}
                           actionReset={() => props.handlePropertyChange('linkColor', '')}
                           action={val => props.handlePropertyChange('linkColor', val)}/>
    </Fieldset>
  );
};

export default connect()(BreadcrumbProperties);
