import React from 'react';
import * as components from '../../components/Elements';
import ImagePreviewFrame from '../General/ImagePreviewFrame';

const ElementPreview = props => {
  const {model} = props;

  return (
    <div className="preview"
         onClick={props.action}
         data-title={model.title}
    >

      <ImagePreviewFrame>
        {model.icon && <span className={`icon icon-${model.icon}`} style={{'--size': '800%'}}/>}
        {model.image && <img src={model.image} alt={model.title} style={{'--maxh': '100%'}}/>}
        {(!model.image && !model.icon && model.component)
        && React.createElement(components[model.component], {
          element: model.defaults,
          model,
          sortingItems: [],
          dropRef: null,
          // We can't use isPreview here, because this is mapped to react state
          preview: true,
        })}
      </ImagePreviewFrame>

      <h5 style={{'--ta': 'center'}}>{model.title}</h5>
    </div>
  );
};

export default ElementPreview;
