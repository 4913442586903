import React from 'react';
import fbt from 'fbt';

import Fieldset from '../FieldSet';
import SelectInput from '../Controls/SelectInput';
import TextInput from '../Controls/TextInput';
import HelpText from '../Controls/HelpText';
import ImageComponentProperties from '../Fieldsets/ImageComponentProperties';
import InputGroup from '../InputGroup';

const TestimonialProperties = props => {
  const {element} = props;
  const {componentProperties} = element;

  return (
    <Fieldset title={fbt('Testimonial Properties', 'TestimonialProperties')} isOpen>
      <HelpText style={{'--m': '10px 0'}}>
        <fbt desc="TestimonialProperties">
          Edit the testimonial text directly on the element.
        </fbt>
      </HelpText>

      <InputGroup>
        <SelectInput id="select-testimonial-style"
                     title={fbt('Testimonial Style', 'TestimonialProperties')}
                     value={componentProperties.style || ''}
                     action={val => {
                       props.handlePropertyChange('style', val);
                     }}
                     options={{
                       '': fbt('Default', 'TestimonialProperties'),
                     }}
                     showLabel
        />
      </InputGroup>

      <InputGroup>
        <TextInput title={fbt('Name', 'TestimonialProperties')}
                   value={componentProperties.name}
                   id="input-testimonial-name"
                   action={val => {
                     props.handlePropertyChange('name', val);
                   }}
                   showLabel
        />
      </InputGroup>

      <InputGroup>
        <TextInput title={fbt('Sub Title', 'TestimonialProperties')}
                   value={componentProperties.subTitle}
                   id="input-testimonial-subtitle"
                   action={val => {
                     props.handlePropertyChange('subTitle', val);
                   }}
                   showLabel
        />
      </InputGroup>

      <ImageComponentProperties componentProperties={componentProperties}
                                handlePropertyChange={props.handlePropertyChange}
      />

      <InputGroup>
        <TextInput title={fbt('Image Alternate Text', 'TestimonialProperties')}
                   value={componentProperties.imageAlt}
                   id="input-testimonial-alt"
                   action={val => {
                     props.handlePropertyChange('imageAlt', val);
                   }}
                   showLabel
        />
      </InputGroup>
    </Fieldset>
  );
};

export default TestimonialProperties;
