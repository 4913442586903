import React from 'react';
import SelectInput from './SelectInput';
import GenericSlider from './GenericSlider';
import fbt from 'fbt';
import InputGroup from '../InputGroup';

const FilterSelect = props => {
    return (
      <>
        <InputGroup>
          <SelectInput
            title={fbt('Image Filter (Optional)', 'FilterSelect')}
            value={props.filterType}
            action={props.actionFilterType}
            options={{
              '': fbt('None', 'FilterSelect'),
              'blur': fbt('Blur', 'FilterSelect'),
              'grayscale': fbt('Grayscale', 'FilterSelect'),
              'brightness': fbt('Brightness', 'FilterSelect'),
              'contrast': fbt('Contrast', 'FilterSelect'),
              'invert': fbt('Invert', 'FilterSelect'),
              'saturate': fbt('Saturate', 'FilterSelect'),
              'Sepia': fbt('Sepia', 'FilterSelect'),
            }}
            showLabel
          />
        </InputGroup>

        {props.filterType && (
          <InputGroup>
            <GenericSlider
              title={fbt('Filter Amount', 'FilterSelect')}
              value={props.filterValue}
              action={props.actionFilterValue}
              min="0"
              max="200"
              step="1"
              showLabel
            />
          </InputGroup>
        )}
      </>
    );
  }
;

export default FilterSelect;
