import BaseService from './BaseService';
import axios from 'axios';
import logger from '../logger';

const paths = {
  post: '/capture',
  section: '/capture/section',
  template: '/capture/template',
  templatePage: '/capture/template/page',
  templateSite: '/capture/template/site',
};

/**
 * Service for taking screenshots (TODO clean up duplicate code)
 */
class CaptureService extends BaseService {
  constructor() {
    super(paths);
    this.requireLogin = true;
  }

  takeSectionScreenshot = (siteId, sectionId, sectionHtmlId, hideModal = false) => {
    const method = 'post';
    const url = this.getPath('section');

    const data = {siteId, sectionId, sectionHtmlId};

    return axios({
      method,
      url,
      data,
      hideModal,
    });
  };

  takeSectionTemplateScreenshot = (siteId, templateId, sectionId, sectionHtmlId, hideModal = false) => {
    const method = 'post';
    const url = this.getPath('template');

    if (this.requireLogin && !axios.defaults.headers.token) {
      logger.warn('Storing items requires to be logged in.. Need a job? :-)');
      return null;
    }

    const data = {siteId, templateId, sectionId, sectionHtmlId};

    return axios({
      method,
      url,
      data,
      hideModal,
    });
  };

  takePageTemplateScreenshot = (siteId, pageId, templateId, hideModal = false) => {
    const method = 'post';
    const url = this.getPath('templatePage');

    if (this.requireLogin && !axios.defaults.headers.token) {
      logger.warn('Storing items requires to be logged in.. Need a job? :-)');
      return null;
    }

    const data = {siteId, pageId, templateId};

    return axios({
      method,
      url,
      data,
      hideModal,
    });
  };

  takeSiteTemplateScreenshot = (siteId, templateId, hideModal = false) => {
    const method = 'post';
    const url = this.getPath('templateSite');

    if (this.requireLogin && !axios.defaults.headers.token) {
      logger.warn('Storing items requires to be logged in.. Need a job? :-)');
      return null;
    }

    const data = {siteId, templateId};

    return axios({
      method,
      url,
      data,
      hideModal,
    });
  };
}

export default new CaptureService();
