import React from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import componentHelper from '../../modules/componentHelper';
import elementHelper from '../../modules/elementHelper';
import ElementDiv from '../ElementControls/ElementDiv';
import ElementErrorRenderer from '../ElementControls/ElementErrorRenderer';
import DropZone from '../Sortable/DropZone';
import { setActiveElement } from '../../store/actions/builder';
import { showModal } from '../../store/actions/modals';
import { isRenderSelector } from '../../store/selectors';
import ActionIcon from '../General/ActionIcon';

const GridElement = props => {
  const {element} = props;
  const {componentProperties} = element;

  const id = elementHelper.getId(element);
  const classes = elementHelper.getClasses(element, 'mame-grid');
  const style = elementHelper.getStyle(element);

  const columnWidth = componentProperties.gridTemplateColumnWidth;

  const getGridColumns = (count, custom = '') => {
    let gridColumns = '';

    if (columnWidth === 'custom') {
      // Fallback to default
      return custom || componentProperties.gridTemplateColumnCustomWidth;
    }

    const gridColumnWidth = componentProperties.gridTemplateColumnWidth === 'auto' ? 'auto' : '1fr';

    for (let i = 0, max = count; i < max; i++) {
      gridColumns += ` ${gridColumnWidth}`;
    }

    return gridColumns;
  };

  // Grid settings
  style['--d'] = 'grid';
  style['--gtc'] = getGridColumns(componentProperties.gridTemplateColumns, componentProperties.gridTemplateColumnCustomWidth);

  if (componentProperties.gridTemplateColumnsSm) {
    style['--gtc-sm'] = getGridColumns(componentProperties.gridTemplateColumnsSm, componentProperties.gridTemplateColumnCustomSm);
  }

  if (componentProperties.gridTemplateColumnsMd) {
    style['--gtc-md'] = getGridColumns(componentProperties.gridTemplateColumnsMd, componentProperties.gridTemplateColumnCustomMd);
  }

  if (componentProperties.gridTemplateColumnsLg) {
    style['--gtc-lg'] = getGridColumns(componentProperties.gridTemplateColumnsLg, componentProperties.gridTemplateColumnCustomLg);
  }

  if (componentProperties.gridTemplateColumnsXl) {
    style['--gtc-xl'] = getGridColumns(componentProperties.gridTemplateColumnsXl, componentProperties.gridTemplateColumnCustomXl);
  }

  if (componentProperties.gridGap) {
    style['--gg'] = `${componentProperties.gridGap}px`;
  }

  style['--jc'] = componentProperties.justifyContent || 'space-between';

  if (componentProperties.alignItems) {
    style['--ai'] = componentProperties.alignItems;
  }

  const setActive = e => {
    // Make sure to only trigger grid
    if (!e.target.classList.contains('mame-grid')) {
      return;
    }

    props.dispatch(setActiveElement(element));
  };

  const showAddElement = e => {
    e.preventDefault();
    const options = {to: props.element._id, toType: 'element'};
    props.dispatch(showModal('AddElementModal', options));
  };

  if (!element.elements || !element.elements.length) {
    return (
      <ElementDiv {...props}
                  id={id}
                  classes="mame-grid-control"
                  controlClass="mame-element-control-left"
                  role="grid"
                  action={setActive}>
        {/* Just a hack for the Grid becoming active when clicking on the placeholder text*/}
        <DropZone element={element}
                  items={element.elements}>
          <GridEmptyRender classes={classes}
                           style={style}
                           dispatch={props.dispatch}
                           element={element}
                           isRender={props.isRender}
                           showAddElement={showAddElement}
          />
        </DropZone>
      </ElementDiv>
    );
  }

  return (
    <ElementDiv {...props}
                id={id}
                classes="mame-grid-control"
                controlClass="mame-element-control-left"
                role="grid"
                action={setActive}>
      <DropZone element={element}
                items={element.elements}>
        <GridRender classes={classes}
                    style={style}
                    element={element}
                    elements={props.elements}
                    dispatch={props.dispatch}
                    isRender={props.isRender}
                    showAddElement={showAddElement}
        />
      </DropZone>
    </ElementDiv>
  );
};

const GridRender = props => {
  const {element, showAddElement} = props;

  const style = {
    ...props.style
  };

  let classes = props.classes;

  if (props.isOverCurrent) {
    style['--bgc'] = 'var(--grid)';
    classes += ' mame-dropzone';
  }

  const maxItemsRow = element.componentProperties.gridTemplateColumnCustomXl ?
    element.componentProperties.gridTemplateColumnCustomXl.split(' ').length
    : (element.componentProperties.gridTemplateColumnsXl || element.componentProperties.gridTemplateColumns);

  const fillUp = maxItemsRow - props.sortingItems.length;

  return (
    <div className={classes} style={style} ref={props.dropRef} role="row">
      {props.sortingItems.map((elementId, index) => {
        const subElement = props.elements.find(e => e._id === elementId);

        if (!subElement) {
          return (
            <ElementErrorRenderer key={elementId}
                                  id={elementId}
                                  parentType="element"
                                  parentId={props.element._id}
            />
          );
        }

        if (props.isRender && !subElement.generalProperties.published) {
          return null;
        }

        return componentHelper.getElementComponent(
          subElement,
          {
            parent: props.element,
            parentType: 'element',
            index,
            resortElement: props.resortOverwrite,
            resortEnd: props.endOverwrite,
            role: 'gridcell',
          }
        );
      })}

      {(!props.isRender && fillUp > 0) && (
        <>
          {[...Array(fillUp)].map((x, i) =>
            <div key={i}
                 onClick={showAddElement}
                 style={{
                   '--d': 'flex',
                   '--jc': 'center',
                   '--ai': 'center',
                   '--as': 'stretch',
                   '--b': '1px dotted #ccc',
                   '--radius': '5px',
                 }}>
              <ActionIcon icon="plus" size="300" style={{'--c': 'var(--mame-icon)'}}/>
            </div>
          )}
        </>
      )}

      {props.isOverCurrent && (
        <div style={{'--d': 'grid', '--ai': 'center', '--jc': 'center', '--h': '100px'}} role="gridcell">
          <fbt desc="Element Grid">
            Move Element to Grid
          </fbt>
        </div>
      )}
    </div>
  );
};

const GridEmptyRender = props => {
  if (props.isRender) {
    return null;
  }

  const style = {
    ...props.style
  };

  let classes = props.classes;

  if (props.isOverCurrent) {
    style['--bgc'] = 'var(--grid)';
    classes += ' mame-dropzone';
  }

  style['--ai'] = 'stretch';

  return (
    <div className={classes} style={style} ref={props.dropRef} role="row">
      {/* Just a hack for the Grid becoming active when clicking on the placeholder text*/}
      <div className="mame-grid mame-grid-visible fake-link" onClick={props.showAddElement} role="gridcell"
           style={{'--d': 'flex', '--ai': 'center', '--jc': 'center'}}>
        <fbt desc="Element Grid">
          Empty Grid (No Elements)
        </fbt>
      </div>
      <div className="mame-grid mame-grid-visible" onClick={props.showAddElement} role="gridcell">
        <ActionIcon icon="plus" size="400" style={{'--c': 'var(--mame-icon)'}}/>
      </div>

      {props.isOverCurrent && (
        <div style={{'--d': 'grid', '--ai': 'center', '--jc': 'center', '--h': '100px'}} role="gridcell">
          <fbt desc="Element Grid">
            Move Element to Grid.
          </fbt>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  isRender: isRenderSelector(state),
  elements: state.activeSite.elements,
});

export default connect(mapStateToProps)(GridElement);
