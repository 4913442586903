const config = {
  baseUrl: process.env.NODE_ENV === 'development' ? 'http://localhost:4000' : 'https://api.mame.app',
  baseVersion: 'v1',
  version: '0.3.8',
  versionString: '0.3.8 Beta 1 (Public)',
  publicKey: `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAwcJEGBZiiLhVxNR+HeyA
G3OdGXte5a+aa3jWClZrxh2EPTjQfcqGPNpU5QlWOLJZAyGh3ENqeK6CwLQm6Bla
OsGazCiVopCYGZdajdpZoV21pPYEsRLgMNdC0tJs9n3SDompM7CW44p7kCpqi9YN
Y+ta5Uob8W4W+SdAIr5gPUH4avOOlW5uu2x73Nx91pve5mnPyajYJtWCi8WQEcnp
rlVSvsuppVq21s+zdbH1dWqHUnHadTkTKbHuEzQhyBUtzeHKEFuZxVQ2ChzX0utQ
lvZqTGGYMRpLiReDpj5/pqgqBv1qzC/mYaUxcs+VF+bIYE2MaJIE/mKmvUDQqPCd
YwIDAQAB
-----END PUBLIC KEY-----
`,
};

config.endpoint = `${config.baseUrl}/${config.baseVersion}`;

export default config;
