import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import fbt from 'fbt';

import Site from '../components/Site/Site';
import Section from '../components/Sections/Section';

const Render = props => {
  const {sectionId} = useParams();

  if (!props.site || !props.page || !props.sections || !props.elements) {
    return (
      <>
        <fbt desc="RenderSection">
          Loading ..
        </fbt>
      </>
    );
  }

  const section = props.sections.find(s => s._id === sectionId);

  return (
    <div id="site-content">
      <Site>
        <Section id={sectionId} section={section}/>
      </Site>
    </div>
  );
};

const mapStateToProps = state => ({
  page: state.activeSite.page,
  site: state.activeSite.site,
  sections: state.activeSite.sections,
  elements: state.activeSite.elements,
  pages: state.activeSite.pages,
  sites: state.sites.sites,
});

export default connect(mapStateToProps)(Render);
