import React from 'react';
import { connect } from 'react-redux';

import elementHelper from '../../modules/elementHelper';
import ElementDiv from '../ElementControls/ElementDiv';

const InputElement = props => {
  const element = props.element;
  const {componentProperties} = element;

  const id = elementHelper.getId(element);
  const classes = elementHelper.getClasses(element, 'mame-input');
  const style = elementHelper.getStyle(element);

  const fieldId = elementHelper.getId(element, 'input-');

  return (
    <ElementDiv {...props} id={id} classes={classes} style={style}>
      <label htmlFor={fieldId}>
        {componentProperties.showLabel && componentProperties.title}
        {!componentProperties.showLabel && <span className="sr-only">componentProperties.title</span>}

        <input type={componentProperties.inputType || 'text'}
               name={componentProperties.customName || fieldId}
               id={fieldId}
               autoComplete={componentProperties.autoComplete || null}
               required={componentProperties.required || null}
               pattern={componentProperties.pattern || null}
               data-invalid-text={componentProperties.invalidMessage || null}
               placeholder={componentProperties.placeholder || null}
               defaultValue={componentProperties.defaultValue || null}
        />
      </label>
    </ElementDiv>
  );
};

export default connect()(InputElement);
