import React, { useState } from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import Dialog from './Dialog';
import TextInput from '../Properties/Controls/TextInput';
import TextArea from '../Properties/Controls/TextArea';
import serviceHelper from '../../modules/serviceHelper';
import Alert from '../General/Alert';
import config from '../../config';

const ReportModal = (props) => {
  const [summary, setSummary] = useState('');
  const [report, setReport] = useState('');
  const [form, setForm] = useState('');

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  if (!props.isVisible) {
    // Make sure to reset it
    return null;
  }

  const handleSendReport = async e => {
    e.preventDefault();
    setError('');

    if (!form.reportValidity()) {
      setError(fbt('Please fill out all required fields.', 'ReportModal'));
      return;
    }

    const data = {
      summary,
      report,
    };

    const service = serviceHelper.getService('ReportService');

    await service.store(data);

    setSuccess(true);
  };

  return (
    <Dialog title={fbt('Feedback and Bug Reporting', 'ReportModal')} name="ReportModal" hideHeader>
      <div className="modal-content"
           style={{'--radius': '5px', '--p': '20px', '--d': 'grid', '--gtc': '1fr 1fr', '--ai': 'center'}}>
        <div>
          <h4 style={{'--m': 0}}>
            <fbt desc="ReportModal">
              Feedback and Bug Reporting
            </fbt>
          </h4>
          <div className="tag mame-primary">{config.versionString}</div>
          <p>
            <fbt desc="ReportModal">
              Thank you for making Mame better!
            </fbt>
          </p>

          {success && (
            <Alert variant="green" className="alert-success">
              <fbt desc="ReportModal">
                Thank you for your report! We'll come back to you as soon as possible.
              </fbt>
            </Alert>
          )}

          {error && (
            <Alert variant="red" className="alert-error">
              {error}
            </Alert>
          )}

          <form onSubmit={handleSendReport} ref={setForm} style={{'--mt': '15px'}}>
            <TextInput title={fbt('Summary *', 'ReportModal')} showLabel required
                       placeholder={fbt('Short summary of your report', 'ReportModal')}
                       value={summary}
                       action={val => setSummary(val)}
            />

            <TextArea title={fbt('Report *', 'ReportModal')} showLabel required
                      placeholder={fbt('Describe the issue or feedback. Where does it show up? How to replicate it?', 'ReportModal')}
                      rows="5"
                      value={report}
                      action={val => setReport(val)}
            />
          </form>

          <button className="mame-primary" onClick={handleSendReport}>
            <fbt desc="ReportModal">
              Send Report
            </fbt>
          </button>
        </div>
        <img src="/img/illustrations/bugfixing.webp" alt="Feedback and Reporting"/>
      </div>
    </Dialog>
  );
};


const mapStateToProps = state => ({
  isVisible: state.modals.ReportModal,
  payload: state.modals.payload.ReportModal,
});

export default connect(mapStateToProps)(ReportModal);
