import { ADD_SITE, SET_SITES, REMOVE_SITE } from '../actionTypes';
import produce from 'immer';

const initialState = {
  sites: [],
};

const sitesState = (state = initialState, action) => {
  switch (action.type) {
    case SET_SITES: {
      const {sites} = action.payload;

      return {
        ...state,
        sites
      };
    }

    case ADD_SITE: {
      const {site} = action.payload;

      return produce(state, draft => {
        draft.sites.push(site);
      });
    }

    case REMOVE_SITE: {
      const {siteId} = action.payload;

      // TODO redirect? (Should be only possible from dashboard but still)
      const siteIndex = state.sites.findIndex(s => s._id === siteId);

      return produce(state, draft => {
        draft.sites.splice(siteIndex, 1);
      });
    }

    default: {
      return state;
    }
  }
};

export default sitesState;
