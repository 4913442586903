import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// Main CSS File
import './App.min.css';

// Router
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import PrivateRoute from './components/Router/PrivateRoute';

// Drag and Drop
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// Translations
import { init } from 'fbt';
import translations from './translatedFbts.json';
import LOCALES from './i18n/locales';
import { setLocale } from './store/actions/viewerContext';

import Builder from './views/Builder';
import Login from './views/Login';
import Dashboard from './views/Dashboard';
import Profiles from './views/Profiles';
import Profile from './views/Profile';
import Render from './views/Render';
import RenderSection from './views/RenderSection';

import ErrorBoundary from './components/Error/ErrorBoundary';
import { loginUserWithJwt } from './store/actions/login';
import SiteLoader from './components/SiteControls/SiteLoader';
import Debug from './views/Debug';
import logger from './modules/logger';
import { apiLoadSettings } from './store/actions/settings';
import { apiLoadUserNotifications } from './store/actions/notifications';

import PreviewIframe from './views/PreviewIframe';
import PreviewContent from './views/PreviewContent';

const App = props => {
  // Facebook Translate
  init({
    translations, hooks: {
      // Fallback to en_US
      getViewerContext: () => props ? props.viewerContext : {GENDER: 3, locale: 'en_US'},
    },
  });

  const {language, dispatch, viewerContext, token} = props;

  useEffect(() => {
    if (!token) {
      return;
    }

    dispatch(apiLoadSettings());
    dispatch(apiLoadUserNotifications());
  }, [dispatch, token]);

  useEffect(() => {
    if (!language) {
      return;
    }

    logger.debug('Changing language to', language);

    if (language === viewerContext.locale) {
      logger.debug('Already using this language, skipping switch');
      return;
    }

    if (LOCALES[language]) {
      document.body.className = LOCALES[language].rtl ? 'rtl' : 'ltr';
    }

    dispatch(setLocale(language));
  }, [language, dispatch, viewerContext]);

  const checkLogin = () => {
    const jwtToken = window.localStorage.getItem('jwtToken');

    if (jwtToken) {
      props.dispatch(loginUserWithJwt(jwtToken));
      return;
    }

    const jwtCookie = document.cookie.split(';').find((item) => item.trim().startsWith('jwtCookie='));

    // Cookie login
    if (!jwtToken && jwtCookie) {
      logger.info('Using cookie checkLogin', jwtCookie);
      const token = jwtCookie.replace('jwtCookie=', '').trim();

      if (token) {
        props.dispatch(loginUserWithJwt(token));
      }
    }
  };

  if (!props.token) {
    // Check for local storage
    checkLogin();
  }

  return (
    <div className={`mame mame-theme-${props.theme}`}>
      {/* Routes */}
      <BrowserRouter>
        <ErrorBoundary>
          <PrivateRoute exact path="/">
            <Dashboard/>
          </PrivateRoute>
          <Route path="/login">
            <Login/>
          </Route>
          <Route path="/profiles">
            <Profiles/>
          </Route>
          <Route path="/profile/:username">
            <Profile/>
          </Route>
          <Switch>
            <Redirect exact from="/render/:siteId" to="/render/:siteId/home"/>
            <PrivateRoute path="/render/:siteId/:route+">
              <SiteLoader preview render>
                <DndProvider backend={HTML5Backend}>
                  <Render/>
                </DndProvider>
              </SiteLoader>
            </PrivateRoute>
            <PrivateRoute path="/render-section/:siteId/:sectionId">
              <SiteLoader preview render>
                <DndProvider backend={HTML5Backend}>
                  <RenderSection/>
                </DndProvider>
              </SiteLoader>
            </PrivateRoute>
            <Redirect exact from="/builder/:siteId" to="/builder/:siteId/home"/>
            <PrivateRoute path="/builder/:siteId/:route+">
              <SiteLoader>
                <DndProvider backend={HTML5Backend}>
                  <Builder/>
                </DndProvider>
              </SiteLoader>
            </PrivateRoute>
            <Redirect exact from="/preview/:siteId" to="/preview/:siteId/home"/>
            <PrivateRoute path="/preview/:siteId/:route+">
              <SiteLoader preview>
                <DndProvider backend={HTML5Backend}>
                  <PreviewIframe/>
                </DndProvider>
              </SiteLoader>
            </PrivateRoute>
            <Redirect exact from="/preview-content/:siteId" to="/preview-content/:siteId/home"/>
            <PrivateRoute path="/preview-content/:siteId/:route+">
              <SiteLoader preview>
                <DndProvider backend={HTML5Backend}>
                  <PreviewContent/>
                </DndProvider>
              </SiteLoader>
            </PrivateRoute>
          </Switch>
          <PrivateRoute path="/debug">
            <DndProvider backend={HTML5Backend}>
              <Debug/>
            </DndProvider>
          </PrivateRoute>
        </ErrorBoundary>
      </BrowserRouter>
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.user.token,
  viewerContext: state.viewerContext,
  language: state.settings.language,
  theme: state.settings.theme,
});

export default connect(mapStateToProps)(App);
