import React from 'react';
import { connect } from 'react-redux';

import elementHelper from '../../modules/elementHelper';
import ElementDiv from '../ElementControls/ElementDiv';

const SelectElement = props => {
  const element = props.element;
  const {componentProperties} = element;

  const id = elementHelper.getId(element);
  const classes = elementHelper.getClasses(element, 'mame-select');
  const style = elementHelper.getStyle(element);

  const fieldId = elementHelper.getId(element, 'input-');

  return (
    <ElementDiv {...props} id={id} classes={classes} style={style}>
      <label htmlFor={fieldId}>
        {componentProperties.showLabel && componentProperties.title}
        {!componentProperties.showLabel && <span className="sr-only">componentProperties.title</span>}

        <select name={componentProperties.customName || fieldId}
                id={fieldId}
                required={componentProperties.required || null}
                data-invalid-text={componentProperties.invalidMessage || null}
                defaultValue={componentProperties.defaultValue || null}
        >
          {componentProperties.items.map((item, index) => {
            return (
              <option key={index} value={item.value}>{item.text}</option>
            );
          })}
        </select>
      </label>
    </ElementDiv>
  );
};

export default connect()(SelectElement);
