import React, { useCallback, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useDrop } from 'react-dnd';
import mergeRefs from 'react-merge-refs';
import fbt from 'fbt';
import useContextMenu from 'react-use-context-menu';
import { Helmet } from 'react-helmet-async';
import { NativeTypes } from 'react-dnd-html5-backend';

import componentHelper from '../../modules/componentHelper';
import Container from '../Container/Container';
import sectionHelper from '../../modules/sectionHelper';
import SectionControlPopup from '../SectionControls/SectionControlPopoup';
import VideoBackground from '../General/VideoBackground';
import { DragTypes } from '../../modules/DragTypes';
import {
  isActiveSectionSelector,
  isPreviewSelector, isRenderSelector,
  selectElementsByIds,
  siteIdSelector
} from '../../store/selectors';
import ElementErrorRenderer from '../ElementControls/ElementErrorRenderer';
import { apiStoreElement, updateSectionProperty } from '../../store/actions/activeSite';
import { showModal } from '../../store/actions/modals';
import { setActiveSection } from '../../store/actions/builder';
import serviceHelper from '../../modules/serviceHelper';
import logger from '../../modules/logger';
import { ImageModel } from '../../models/Elements';

const Section = (props) => {
  const {section} = props;
  const {generalProperties} = section;

  // Storing the sorting temporary in here, than on End saving it
  const [sortingElements, setSortingElements] = useState(section.elements);
  const [isSortingUpdated, setSortingUpdated] = useState(false);

  useEffect(() => {
    setSortingElements(section.elements);
  }, [section.elements]);

  const sectionElements = useSelector(state => selectElementsByIds(state, section.elements));

  const handleHandleFileDrop = async (item) => {
    const files = item.files;

    if (!files || !files.length) {
      return;
    }

    // Fake form for the upload
    const formData = new FormData();

    for (const file of files) {
      formData.append('attachments', file);
    }

    formData.append('siteId', props.siteId || '');
    formData.append('convertWebp', 'true');

    const service = serviceHelper.getService('SectionService');
    const result = await service.uploadFiles(formData);

    if (!result.data) {
      // TODO notify user
      logger.warn('No file upload result', result.data);
      return;
    }

    // Add Image Element
    const newElement = {
      ...JSON.parse(JSON.stringify(ImageModel.defaults)),
      site: props.siteId,
      section: section._id,
    };

    newElement.identifier = 'New Image Upload';
    newElement.componentProperties.image = result.data.items[0];

    props.dispatch(apiStoreElement(newElement, {toType: 'section', to: section._id}));
  };

  // Drop to Section
  const handleDrop = (item, monitor) => {
    if (monitor.didDrop()) {
      return;
    }

    if (!item.files) {
      return {sectionId: section._id, type: 'section'};
    }

    // Handle drop of file
    handleHandleFileDrop(item);
  };

  const [{isOverCurrent}, drop] = useDrop({
    accept: [DragTypes.ELEMENT, NativeTypes.FILE],
    drop: handleDrop,
    collect: (monitor) => ({
      isOverCurrent: monitor.isOver({shallow: true}),
    }),
  });

  // Sorting sections
  const resortEnd = () => {
    if (!isSortingUpdated) {
      return;
    }

    props.dispatch(updateSectionProperty(section, null, 'elements', sortingElements));
    setSortingUpdated(false);
  };

  const resortElement = useCallback((dragIndex, dropIndex) => {
    const draggedElement = sortingElements[dragIndex];
    const copy = [...sortingElements];

    copy.splice(dragIndex, 1);
    copy.splice(dropIndex, 0, draggedElement);

    setSortingElements(copy);
    setSortingUpdated(true);
  }, [sortingElements]);

  // Context Menu
  const [
    bindMenu,
    bindMenuItems,
    useContextTrigger,
    {setVisible: setContextVisible}
  ] = useContextMenu();

  const [bindContextTrigger] = useContextTrigger({disable: props.isPreview, holdToDisplay: -1});

  const classes = sectionHelper.getClasses(section);
  const style = sectionHelper.getStyle(section);
  const dataAttributes = sectionHelper.getDataAttributes(section);

  const sectionId = generalProperties.customId || `s-${section._id}`;

  if (isOverCurrent) {
    style['--bgc'] = 'var(--section)';
  }

  if (props.isActive && !props.isPreview) {
    // style['--bg'] = 'repeating-linear-gradient(45deg, transparent, transparent 15px, var(--section) 5px, var(--section) 22px)';
    style['boxShadow'] = '0 0 0 2px var(--section)';
  }

  // Duplicate Code (TODO move to module)
  const showAddElement = (e) => {
    e.preventDefault();
    const options = {to: section._id, toType: 'section'};
    props.dispatch(showModal('AddElementModal', options));
  };

  const setActive = (e) => {
    if (!e.target.classList.contains('mame-section')) {
      return;
    }

    props.dispatch(setActiveSection(section));
  };

  let customColors = '';

  if (generalProperties.linkColor || generalProperties.primaryColor || generalProperties.secondaryColor) {
    const primaryColorText = generalProperties.primaryColor ? `--primary: ${generalProperties.primaryColor};` : '';
    const secondaryColorText = generalProperties.secondaryColor ? `--secondary: ${generalProperties.secondaryColor};` : '';
    const linkColorText = generalProperties.linkColor ? `--links: ${generalProperties.linkColor};` : '';

    const linkText = generalProperties.linkColor ? `
          #${sectionId} a, #${sectionId} .fake-link {
            color: ${generalProperties.linkColor};
          }

          #${sectionId} a:hover, #${sectionId} .fake-link:hover {
            color: ${generalProperties.linkHoverColor || generalProperties.linkColor}
          }
    ` : '';

    customColors = (
      <Helmet>
        <style>
          {`
          #${sectionId} {
           ${primaryColorText}
           ${secondaryColorText}
           ${linkColorText}
          }
          
          ${linkText}
         `}
        </style>
      </Helmet>
    );
  }

  const noElements = (
    <div style={{'--ta': 'center', '--p': '20px'}}>
      {customColors}
      <span onClick={showAddElement} className="fake-link">
        <fbt desc="Section has no elements">
          This section has no content, start by adding an element.
        </fbt>
      </span>
    </div>
  );

  if (!section.elements || !section.elements.length) {
    return (
      <div id={sectionId}
           className={classes}
           style={style}
           ref={mergeRefs([drop, props.dndPreviewRef])}
           {...dataAttributes}
           {...bindContextTrigger}
      >
        {customColors}
        <VideoBackground section={section}>
          <Container section={section}>
            {!props.isPreview && noElements}
          </Container>
        </VideoBackground>
        {!props.isPreview && (
          <SectionControlPopup
            section={section}
            handleRef={props.dndDragRef}
            bindMenu={bindMenu}
            bindMenuItems={bindMenuItems}
            setContextVisible={setContextVisible}
          />
        )}
      </div>
    );
  }

  return (
    <div id={sectionId}
         className={classes}
         style={style}
         ref={mergeRefs([drop, props.dndPreviewRef])}
         {...dataAttributes}
         {...bindContextTrigger}
         onClick={setActive}
    >
      <VideoBackground section={section}>
        <Container section={section}>
          {customColors}
          {sortingElements.map((elementId, index) => {
            const element = sectionElements.find(e => e._id === elementId);

            if (!element) {
              return (
                <ElementErrorRenderer
                  key={elementId}
                  id={elementId}
                  parentType="section"
                  parentId={section._id}
                />
              );
            }

            if (!element.generalProperties.published && props.isRender) {
              return null;
            }

            return componentHelper.getElementComponent(
              element,
              {
                parent: section,
                parentType: 'section',
                index,
                resortElement,
                resortEnd,
              }
            );
          })}
          {isOverCurrent && (
            <div style={{'--d': 'grid', '--ai': 'center', '--jc': 'center', '--h': '100px'}}>
              <fbt desc="Section">
                Move Element to Section
              </fbt>
            </div>
          )}
        </Container>
      </VideoBackground>
      {!props.isPreview && (
        <>
          <SectionControlPopup
            section={section}
            handleRef={props.dndDragRef}
            bindMenu={bindMenu}
            bindMenuItems={bindMenuItems}
            setContextVisible={setContextVisible}
          />

          <div className="mame-section-below"
               onClick={() => {
                 props.dispatch(showModal('AddSectionModal', {after: section._id}));
               }}>
            <span className="icon icon-move-down"/>
            <fbt desc="Add Section below button">
              Add new section below
            </fbt>
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  isActive: isActiveSectionSelector(state, props.section._id),
  isPreview: isPreviewSelector(state),
  isRender: isRenderSelector(state),
  siteId: siteIdSelector(state),
});

export default connect(mapStateToProps)(Section);
