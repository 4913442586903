import React from 'react';

const InputGroup = props => {
  const className = props.className ? `b-input-group ${props.className}` : 'b-input-group';

  return (
    <div className={className} style={props.style || null}>
      {props.children}
    </div>
  );
};

export default InputGroup;
