import React from 'react';
import { connect } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useHistory } from 'react-router-dom';
import fbt from 'fbt';

import Fieldset from './FieldSet';
import TextInput from './Controls/TextInput';
import { updatePageProperty } from '../../store/actions/activePage';
import CheckboxInput from './Controls/CheckboxInput';
import MetaFieldset from './Fieldsets/MetaFieldset';
import CustomizationFieldset from './Fieldsets/CustomizationFieldset';
import HelpText from './Controls/HelpText';
import logger from '../../modules/logger';
import TypographyFieldset from './Fieldsets/TypographyFieldset';
import ColorsFieldset from './Fieldsets/ColorsFieldset';
import PageSectionOverview from './PageSectionOverview';
import InputGroup from './InputGroup';

/**
 * Page Overrides
 *
 * - [x] Typography
 *   - [x] FontFamily
 *   - [x] SecondaryFont
 *   - [x] fontSize
 *   - [x] fontWeight
 *   - [x] lineHeight
 *
 * - [x] Colors
 *   - [x] Default Color
 *   - [x] Link Color
 *   - [x] Link Hover Color
 *   - [x] Primary Color
 *   - [x] Secondary Color
 *   - [x] Website Background
 *   - [x] Success Background
 *   - [x] Warning Background
 *
 * - [x] Meta
 *
 * - [x] custom Code
 *   - [x] JavaScript
 *   - [x] CSS
 *
 * @param props
 * @returns {JSX.Element|string}
 * @constructor
 */
const PageProperties = props => {
  const history = useHistory();

  const {page} = props;

  if (!page) {
    return null;
  }

  const handleChange = (category, key, value, subKey = null) => {
    props.dispatch(updatePageProperty(page, category, key, value, subKey));
  };

  const handleAliasChange = value => {
    if (!value) {
      return;
    }

    if (page.alias === props.page.alias) {
      logger.debug('CHANGING Current open alias, redirecting');
    }

    props.dispatch(updatePageProperty(page, 'alias', null, value, null));

    if (page.alias === props.page.alias) {
      history.push(`/builder/${props.siteId}/${value}`);
    }
  };

  return (
    <Tabs forceRenderTabPanel={true}>
      <TabList>
        <Tab>
          <fbt desc="PageProperties">General</fbt>
        </Tab>
        <Tab>
          <fbt desc="PageProperties">Overrides</fbt>
        </Tab>
        <Tab>
          <fbt desc="PageProperties">Sections</fbt>
        </Tab>
        <Tab>
          <fbt desc="PageProperties">Meta</fbt>
        </Tab>
      </TabList>
      <TabPanel>
        <Fieldset title={fbt('Page Settings', 'PageProperties')} isOpen>
          <InputGroup style={{'--mt': '10px'}}>
            <TextInput title={fbt('Page Title', 'PageProperties')}
                       value={page.title}
                       id="input-page-title"
                       blur={val => handleChange('title', null, val)}
                       required
                       showLabel
            />

            <HelpText>
              <fbt desc="PageProperties">
                The title of the page (Shown on the browser's window, search engines...)
              </fbt>
            </HelpText>
          </InputGroup>

          <InputGroup>
            <TextInput title={fbt('Alias (URL)', 'PageProperties')}
                       value={page.alias}
                       id="input-page-alias"
                       filter="[^a-z0-9/_-]"
                       blur={val => handleAliasChange(val)}
                       required
                       showLabel
            />
            <HelpText>
              <fbt desc="PageProperties">
                The alias sets the address under which the page can be found later.
                For example 'test', makes this page available at /test.html.
                Hint: If you use slashes it creates a sub folder.
              </fbt>
              <a href="https://mame.app/documentation/routing/" rel="noreferrer noopener" target="_blank"
                 style={{'--ml': '5px'}}>
                <fbt desc="SiteProperties">
                  Learn more
                </fbt>
              </a>
            </HelpText>
          </InputGroup>

          <InputGroup>
            <CheckboxInput title={fbt('Default Page (Homepage)', 'PageProperties')}
                           tooltip={fbt('The default page is going to be the index.html', 'PageProperties')}
                           checked={page.isHome}
                           id="input-page-is-home"
                           action={() => {
                             handleChange('isHome', null, !page.isHome);
                           }}
            />
          </InputGroup>
        </Fieldset>

        <CustomizationFieldset
          customCode={page.settings.customCode}
          action={(subKey, val) => handleChange('settings', 'customCode', val, subKey)}
          helpText={fbt('Here you may add CSS or JavaScript for this page only.', 'PageProperties')}
        />
      </TabPanel>
      <TabPanel>
        <HelpText style={{'--m': '10px 15px'}}>
          <fbt desc="PageProperties">
            Overrides of the global site settings.
          </fbt>
        </HelpText>

        <TypographyFieldset
          typography={page.typography}
          handlePropertyChange={handleChange}
        />

        <ColorsFieldset
          colors={page.colors}
          handlePropertyChange={handleChange}
          override
        />
      </TabPanel>
      <TabPanel style={{'--p': '0 15px'}}>
        <HelpText style={{'--m': '10px 0'}}>
          <fbt desc="PageProperties">
            Overview of this page's sections.
          </fbt>
        </HelpText>

        <PageSectionOverview
          {...props}
          handleChange={handleChange}
        />

      </TabPanel>
      <TabPanel>
        <HelpText style={{'--m': '10px 15px'}}>
          <fbt desc="PageProperties">
            Overrides of the global meta settings.
          </fbt>
        </HelpText>

        <Fieldset title={fbt('SEO Settings', 'PageProperties')} isOpen>
          <InputGroup style={{'--mt': '10px'}}>
            <TextInput title={fbt('Canonical URL for this page (Optional)', 'PageProperties')}
                       type="url"
                       value={page.meta.canonicalUrl}
                       placeholder="https://.../page-alias"
                       blur={val => handleChange('meta', 'canonicalUrl', val)}
                       showLabel
            />
            <HelpText>
              <fbt desc="PageProperties">
                With the canonical URL setting you can avoid duplicate URLs in search engines. Especially
                useful if you are using multiple domains. This URL overrides the global site setting, if
                nothing is set this value is autogenerated (if you have set a global canonical in site settings).
              </fbt>
            </HelpText>
          </InputGroup>

          <InputGroup>
            <CheckboxInput
              title={fbt('Exclude page from sitemap', 'PageProperties')}
              checked={page.settings.excludeFromSitemap}
              action={() => handleChange('settings', 'excludeFromSitemap', !page.settings.excludeFromSitemap)}
            />
          </InputGroup>

        </Fieldset>

        <MetaFieldset
          title={fbt('Page Meta Tags', 'PageProperties')}
          meta="page"
          action={(key, val) => handleChange('meta', key, val)}
          override
        />
      </TabPanel>
    </Tabs>
  );
};

const mapStateToProps = state => ({
  page: state.activeSite.page,
  siteId: state.activeSite.site._id,
});

export default connect(mapStateToProps)(PageProperties);
