import React, { useRef } from 'react';
import { connect } from 'react-redux';
import useContextMenu from 'react-use-context-menu';
import { useDrag, useDrop } from 'react-dnd';

import logger from '../../modules/logger';
import elementHelper from '../../modules/elementHelper';
import { moveElement } from '../../store/actions/activeSite';
import { DragTypes } from '../../modules/DragTypes';
import { isActiveElementSelector, isPreviewSelector } from '../../store/selectors';
import { setActiveElement } from '../../store/actions/builder';
import ElementControlPopup from './ElementControlPopup';

const ElementDiv = props => {
  const draggableRef = useRef(null);
  const previewRef = useRef(null);

  // We allow sorting just in the inner group
  const sortGroup = props.parentId;

  const [, drop] = useDrop({
    accept: DragTypes.ELEMENT,
    hover(item, monitor) {
      // logger.log('Hovering', item, monitor);

      if (!draggableRef.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = props.index;

      if (dragIndex === hoverIndex) {
        return;
      }

      if (item.group !== sortGroup) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = previewRef.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      props.resortElement(dragIndex, hoverIndex);
      item.index = hoverIndex;
    }
  });

  const [{isDragging}, drag, preview] = useDrag({
    type: DragTypes.ELEMENT,
    item: {
      id: props.element._id,
      index: props.index,
      group: sortGroup,
      parentId: props.parentId,
      parentType: props.parentType,
      caller: 'element',
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      logger.debug('dropResult', dropResult);

      if (!dropResult) {
        // We still need to trigger the resortEnd here, because maybe the user pulled it out of the container
        props.resortEnd(item.id, dropResult);
        return;
      }

      if (item.parentId === dropResult.id) {
        logger.debug('Dropped to same parent, should not happen');

        if (monitor.didDrop()) {
          props.resortEnd(item.id, dropResult);
        }

        // We just drop between different sections / elements here
        return;
      }

      // Just a move
      if (!dropResult.type) {
        props.resortEnd(item.id, dropResult);
        return;
      }

      // Drag and Drop!
      logger.debug('Moved Element to DIFFERENT parent');
      logger.debug(item.id, 'to', dropResult);
      logger.debug('parent', props.parentId);

      // Drop to other section
      if (dropResult.type === 'section' && props.parentId !== dropResult.sectionId) {
        props.dispatch(moveElement(item.id, {
          parentId: props.parentId,
          parentType: props.parentType,
          targetId: dropResult.sectionId,
          targetType: dropResult.type,
        }));

        return;
      }

      // Drop to other element (Like a grid)
      if (dropResult.type === 'element' && props.parentId !== dropResult.elementId) {
        props.dispatch(moveElement(item.id, {
          parentId: props.parentId,
          parentType: props.parentType,
          parentItemIndex: props.parentType,
          targetId: dropResult.elementId,
          targetType: dropResult.type,
        }));

        return;
      }

      if (dropResult.type === 'item') {
        // Special case
        props.dispatch(moveElement(item.id, {
          parentId: props.parentId,
          parentType: props.parentType,
          targetType: dropResult.type,
          targetId: dropResult.elementId,
          targetItemIndex: dropResult.index,
        }));

        return;
      }

      logger.debug('Dropped to same or undefined target');
      props.resortEnd(item.id, dropResult);
    }
  });

  const handleOnClick = e => {
    if (!props.element._id) {
      // Prevent demo elements to propagate
      return;
    }

    if (props.isPreview) {
      // Links etc. should work on preview
      return;
    }

    if (props.action) {
      return props.action(e);
    }

    // We need to prevent the default for links etc.
    if (!e.target.classList.contains('icon-question')) {
      e.preventDefault();
    }

    props.dispatch(setActiveElement(props.element));
  };

  // Context Menu
  const [
    bindMenu,
    bindMenuItems,
    useContextTrigger,
    {setVisible: setContextVisible}
  ] = useContextMenu();

  let disableContext = props.isPreview || props.preview;

  const [bindContextTrigger] = useContextTrigger({disable: disableContext, holdToDisplay: -1});

  if (!props.element) {
    return <div/>;
  }

  const dataAttributes = elementHelper.getDataAttributes(props.element);
  const ElementTag = props.tag || 'div';
  const isPreview = props.preview || props.isPreview;

  drag(draggableRef);
  preview(previewRef);
  drop(previewRef);

  const elementStyle = {
    ...props.style,
  };

  if (isDragging) {
    // Styling while drag
    elementStyle['--bg'] = 'var(--element)';
  }

  if (props.isActive && !props.isPreview) {
    elementStyle['boxShadow'] = '0 0 0 2px var(--element)';
  }

  return (
    <ElementTag id={props.id || null}
                ref={props.dndPreviewRef || previewRef}
                className={props.classes}
                style={elementStyle}
                aria-label={props.ariaLabel || null}
                role={props.role || null}
                {...dataAttributes}
                {...bindContextTrigger}
                onClick={handleOnClick}
    >
      {props.children}

      {!isPreview && (
        <ElementControlPopup
          elementId={props.element._id}
          model={props.model}
          controlClass={props.controlClass}
          parentType={props.parentType}
          parentIndex={props.parentIndex}
          parentId={props.parentId}
          addElement={props.addElement}
          bindMenu={bindMenu}
          bindMenuItems={bindMenuItems}
          setContextVisible={setContextVisible}
          dragRef={props.dndDragRef || draggableRef}
        />
      )}
    </ElementTag>
  );
};

const mapStateToProps = (state, props) => ({
  isPreview: isPreviewSelector(state),
  isActive: isActiveElementSelector(state, props.element._id),
});

export default connect(mapStateToProps)(ElementDiv);
