import React, { useState } from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';
import { toast } from 'react-toastify';

import {
  userPageTemplatesSelector,
  userSectionTemplatesSelector,
  userSiteTemplatesSelector
} from '../../store/selectors';
import {
  apiAddPageTemplate,
  apiAddSectionTemplate,
  apiAddSiteTemplate,
  apiUpdatePageTemplate,
  apiUpdateSectionTemplate
} from '../../store/actions/templates';
import { hideModal } from '../../store/actions/modals';
import Dialog from './Dialog';
import ThreeColumnGrid from '../General/ThreeColumnGrid';
import config from '../../config';
import SectionPreview from '../SectionControls/SectionPreview';
import TextInput from '../Properties/Controls/TextInput';
import logger from '../../modules/logger';
import HelpText from '../Properties/Controls/HelpText';

const SaveTemplateModal = props => {
  const [storeType, setStoreType] = useState(null);
  const newFunc = {
    page: apiAddPageTemplate,
    section: apiAddSectionTemplate,
    site: apiAddSiteTemplate,
  };

  const updateFunc = {
    page: apiUpdatePageTemplate,
    section: apiUpdateSectionTemplate,
  };

  if (!props.isVisible || !props.payload.type) {
    // Make sure to reset it
    return null;
  }

  // Type of the Modal, [site, page, section]
  const {type} = props.payload;
  const title = type.charAt(0).toUpperCase() + type.slice(1);

  // Site, Page, Section
  const item = {...props.payload.item};

  const storeNewTemplate = () => {
    props.dispatch(newFunc[type](item));
    setStoreType(null);
    toast(`${item.title} ${fbt('Template stored.', 'SaveTemplateModal')}`);
    props.dispatch(hideModal('SaveTemplateModal'));
  };

  const updateTemplate = template => {
    logger.debug('Update template', template);

    props.dispatch(updateFunc[type](item, template));
    setStoreType(null);
    toast(`${item.title} ${fbt('Template updated.', 'SaveTemplateModal')}`);
    props.dispatch(hideModal('SaveTemplateModal'));
  };

  return (
    <Dialog title={fbt('Save ' + fbt.param('title', title) + ' as Template', 'SaveTemplateModal')}
            name="SaveTemplateModal"
            closeAction={() => {
              setStoreType(null);
            }}
    >
      <div className="modal-content" style={{'--radius': '0 0 5px 5px', '--p': '20px'}}>
        {!storeType && <FirstPage action={(type) => setStoreType(type)}/>}
        {storeType === 'new' && (
          <NewTemplate
            title={item.title}
            titleAction={(val) => {
              item.title = val;
            }}
            storeAction={() => {
              storeNewTemplate();
            }}
          />
        )}

        {storeType === 'existing' && (
          <ExistingTemplate
            {...props}
            action={updateTemplate}
            actionPrev={() => {
              setStoreType(null);
            }}
            type={type}
          />
        )}
      </div>
    </Dialog>
  );
};

const FirstPage = props => {
  return (
    <div style={{'--d': 'grid', '--gtc': '1fr 1fr', '--gg': '20px'}}>
      <figure className="accent"
              style={{'--ta': 'center', '--levitate-hvr': 5, '--cur': 'pointer'}}
              onClick={() => props.action('new')}>
        <figcaption style={{'--p': '25px'}}>
          <span className="icon icon-plus icon-4x"/>
          <h4>
            <fbt desc="SaveTemplateModal">
              New Template
            </fbt>
          </h4>
        </figcaption>
      </figure>

      <figure className="accent" style={{'--ta': 'center', '--levitate-hvr': 5, '--cur': 'pointer'}}
              onClick={() => props.action('existing')}>
        <figcaption style={{'--p': '25px'}}>
          <span className="icon icon-folder-open icon-4x"/>
          <h4>
            <fbt desc="SaveTemplateModal">
              Existing Template
            </fbt>
          </h4>
        </figcaption>
      </figure>
    </div>
  );
};

const NewTemplate = props => {
  return (
    <div>
      <TextInput title={fbt('Template Title', 'SaveTemplateModal')}
                 showLabel
                 id="input-template-title"
                 placeholder={fbt('For example "Header with Image"', 'SaveTemplateModal')}
                 value={props.title || ''}
                 action={props.titleAction}
                 onReturnKey={props.storeAction}
      />
      <HelpText>
        <fbt desc="SaveTemplateModal">
          Choose a nice remarkable title, to easily find it later.
        </fbt>
      </HelpText>
      <div style={{'--ta': 'right'}}>
        <button className="mame-primary" onClick={props.storeAction}>
          <fbt desc="SaveTemplateModal">
            Save Template
          </fbt>
        </button>
      </div>
    </div>
  );
};

const ExistingTemplate = props => {
  let templates = [];

  if (props.type === 'section') {
    templates = props.userSectionTemplates;
  } else if (props.type === 'page') {
    templates = props.userPageTemplates;
  } else if (props.type === 'site') {
    templates = props.userSiteTemplates;
  }

  return (
    <div>
      {!templates.length && (
        <div style={{'--ta': 'center'}}>
          <fbt desc="SaveTemplateModal">
            You don't have any templates
          </fbt>
          <br/>
          <button className="mame-primary" onClick={props.actionPrev}>
            <fbt desc="SaveTemplateModal">
              Back
            </fbt>
          </button>
        </div>
      )}

      <ThreeColumnGrid>
        {templates.map(item => {
          const previewImg = config.baseUrl + `/preview/${props.type}-template/${item._id}/preview.jpg`;

          return (
            <SectionPreview
              key={item._id}
              id={`t-${item._id}`}
              title={item.title}
              image={previewImg}
              action={() => props.action(item)}
            />
          );
        })}
      </ThreeColumnGrid>

      {templates.length && (
        <div style={{'--mt': '15px'}}>
          <button className="mame-primary" onClick={props.actionPrev}>
            <fbt desc="SaveTemplateModal">
              Back
            </fbt>
          </button>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  userSiteTemplates: userSiteTemplatesSelector(state),
  userPageTemplates: userPageTemplatesSelector(state),
  userSectionTemplates: userSectionTemplatesSelector(state),
  isVisible: state.modals.SaveTemplateModal,
  payload: state.modals.payload.SaveTemplateModal,
});

export default connect(mapStateToProps)(SaveTemplateModal);

