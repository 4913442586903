import React from 'react';
import { connect, useSelector } from 'react-redux';
import fbt from 'fbt';

import ActionIcon from '../General/ActionIcon';
import {
  closeActiveProperty,
  setActiveElement,
  setClipboardElementStyle,
} from '../../store/actions/builder';
import { apiDuplicateElement, removeElement, updateElementProperties } from '../../store/actions/activeSite';
import { selectElementById } from '../../store/selectors';

const ElementControlPopup = props => {
  const {bindMenu, bindMenuItems, setContextVisible, model, parentType, parentId, parentIndex} = props;
  const element = useSelector(state => selectElementById(state, props.elementId));

  const stop = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleEditClick = e => {
    stop(e);
    props.dispatch(setActiveElement(element));
    setContextVisible(false);
  };

  const handleDuplicateClick = e => {
    stop(e);
    // Where am I?
    const options = {to: parentId, toType: parentType, index: parentIndex};
    props.dispatch(apiDuplicateElement(element, options));
    setContextVisible(false);
  };

  const handleRemoveClick = e => {
    stop(e);
    props.dispatch(closeActiveProperty());
    props.dispatch(removeElement(element._id, {
      parentType,
      parentId,
    }));
    setContextVisible(false);
  };

  const handleCopyStyleClick = e => {
    stop(e);

    // Only GeneralPRoperties here
    props.dispatch(setClipboardElementStyle(JSON.stringify(element.generalProperties)));
    setContextVisible(false);
  };

  const handlePasteStyleClick = e => {
    stop(e);

    const generalProperties = JSON.parse(props.clipboardStyle);

    // Make sure to not copy any ids
    generalProperties.customId = '';

    // TODO ? Remove Properties not used on this element (model definition)
    props.dispatch(updateElementProperties(element, generalProperties));

    setContextVisible(false);
  };

  const handleAddClick = e => {
    props.addElement(e, props.dispatch);
  };

  // Classes and icons
  const controlClass = ['mame-element-control'];

  if (props.controlClass) {
    controlClass.push(props.controlClass);
  }

  const moveIcon = parentType === 'section' ? 'section-elements-move' : 'element-children-move';

  return (
    <>
      <div className={controlClass.join(' ')} role="navigation">
        {model.hasChildElements && (
          <ActionIcon icon="plus"
                      title={fbt('Add Element', 'Element')}
                      action={handleAddClick}
          />
        )}
        <ActionIcon icon="pencil"
                    title={fbt('Edit Element', 'Element')}
                    action={handleEditClick}
        />

        <span className={`icon icon-hand-grab-o ${moveIcon}`}
              title={fbt('Move Element', 'Element')}
              style={{'--cur': 'move', '--px': '7px'}}
              ref={props.dragRef}
              role="button"
        />

        <ActionIcon icon="clone" title={fbt('Duplicate Element', 'Element')}
                    action={handleDuplicateClick}/>

        <ActionIcon icon="object-ungroup" title={fbt('Copy Style', 'ElementControlPopup')}
                    action={handleCopyStyleClick}/>

        {props.clipboardStyle && (
          <ActionIcon icon="magic" title={fbt('Paste Style', 'ElementControlPopup')}
                      action={handlePasteStyleClick}/>
        )}

        <ActionIcon icon="trash-o" title={fbt('Remove Element', 'Element')}
                    action={handleRemoveClick}/>

        <a href={`https://mame.app${model.helpPage || '/documentation/elements'}`}
           rel="noreferrer noopener" target="_blank" style={{'--c': '#262626'}}
        >
          <ActionIcon icon="question"
                      title={fbt('Element Help', 'Element')}
          />
        </a>
      </div>

      <nav {...bindMenu} className="context-menu context-menu-element">
        {/* TODO TEST*/}
        {model.hasChildElements && (
          <div {...bindMenuItems} onClick={e => {
            props.addElement(e, props.dispatch);
          }}>
            <ActionIcon icon="plus" style={{'--w': '35px', '--d': 'inline-block'}}/>
            <fbt desc="Context Menu Add Element">
              Add Element
            </fbt>
          </div>
        )}

        <div {...bindMenuItems} onClick={handleDuplicateClick}>
          <ActionIcon icon="clone" style={{'--w': '35px', '--d': 'inline-block'}}/>
          <fbt desc="Context Menu Duplicate Element">
            Duplicate Element
          </fbt>
        </div>

        <div {...bindMenuItems} onClick={handleCopyStyleClick}>
          <ActionIcon icon="object-ungroup" style={{'--w': '35px', '--d': 'inline-block'}}/>
          <fbt desc="ElementControlPopup">
            Copy Style
          </fbt>
        </div>

        {props.clipboardStyle && (
          <div {...bindMenuItems} onClick={handlePasteStyleClick}>
            <ActionIcon icon="magic" style={{'--w': '35px', '--d': 'inline-block'}}/>
            <fbt desc="ElementControlPopup">
              Paste Style
            </fbt>
          </div>
        )}

        <div {...bindMenuItems} onClick={handleRemoveClick}>
          <ActionIcon icon="trash-o" style={{'--w': '35px', '--d': 'inline-block'}}/>
          <fbt desc="Context Menu Remove Element">
            Remove Element
          </fbt>
        </div>

        <div {...bindMenuItems} onClick={() => {
          const link = `https://mame.app${model.helpPage || '/documentation/elements'}`;
          window.open(link, '_blank');
          setContextVisible(false);
        }}>
          <a href={`https://mame.app${model.helpPage || '/documentation/elements'}`}
             rel="noreferrer noopener" target="_blank" style={{'--c': '#262626'}}
          >
            <ActionIcon icon="question" style={{'--w': '35px', '--d': 'inline-block'}}/>
            <fbt desc="Context Menu Help">
              Element Help
            </fbt>
          </a>
        </div>

      </nav>
    </>
  );
};

const mapStateToProps = (state, ownProps) => ({
  clipboardStyle: state.builder.clipboardElementStyle,
});

export default connect(mapStateToProps)(ElementControlPopup);
