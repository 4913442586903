import React from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import Fieldset from '../FieldSet';
import TextInput from '../Controls/TextInput';
import GenericSlider from '../Controls/GenericSlider';
import AdvancedColorPicker from '../Controls/AdvancedColorPicker';
import IconPicker from '../Controls/IconPicker';
import LinkGroup from '../Controls/LinkGroup';
import InputGroup from '../InputGroup';

const IconProperties = props => {
  const {element} = props;
  const {componentProperties} = element;

  return (
    <Fieldset title={fbt('Icon Properties', 'IconProperties')} isOpen>
      <InputGroup>
        <IconPicker icon={componentProperties.icon}
                    action={icon => props.handlePropertyChange('icon', icon)}
                    title={fbt('Icon', 'IconFigureProperties')}
                    showLabel
        />
      </InputGroup>

      <InputGroup>
        <GenericSlider title={fbt('Icon Size', 'IconProperties')}
                       min="20" max="1500" step="10" showLabel
                       value={componentProperties.size}
                       action={val => props.handlePropertyChange('size', val)}
        />
      </InputGroup>

      <AdvancedColorPicker value={componentProperties.color}
                           title={fbt('Icon Color', 'IconProperties')}
                           action={val => props.handlePropertyChange('color', val)}
      />

      <InputGroup>
        <TextInput title={fbt('Icon Title', 'IconProperties')}
                   placeholder={fbt('A Plane', 'IconProperties')}
                   value={componentProperties.title}
                   action={val => props.handlePropertyChange('title', val)}
                   required
                   showLabel
        />
      </InputGroup>

      <LinkGroup
        linkType={componentProperties.linkType}
        actionLinkType={val => props.handlePropertyChange('linkType', val)}
        link={componentProperties.link}
        actionLink={val => props.handlePropertyChange('link', val)}
        page={componentProperties.page}
        actionPage={val => props.handlePropertyChange('page', val)}
        linkTarget={componentProperties.linkTarget}
        actionLinkTarget={val => props.handlePropertyChange('linkTarget', val)}
        isNofollow={componentProperties.isNofollow}
        actionNofollow={val => props.handlePropertyChange('isNofollow', val)}
      />
    </Fieldset>
  );
};

export default connect()(IconProperties);

