import React from 'react';
import fbt from 'fbt';

import Fieldset from '../FieldSet';
import CheckboxInput from '../Controls/CheckboxInput';
import TextInput from '../Controls/TextInput';
import InputGroup from '../InputGroup';

const CountdownProperties = props => {
  const {element} = props;
  const {componentProperties} = element;

  return (
    <Fieldset title={fbt('Countdown Properties', 'CountdownProperties')} isOpen>
      <InputGroup style={{'--mt': '10px'}}>
        <TextInput type="datetime-local"
                   title={fbt('Countdown Target', 'CountdownProperties')}
                   value={componentProperties.targetDateTime}
                   action={(val) => props.handlePropertyChange('targetDateTime', val)}
                   showLabel
        />
      </InputGroup>

      <InputGroup>
        <TextInput title={fbt('Label for Days', 'CountdownProperties')}
                   value={componentProperties.dayLabel}
                   action={(val) => props.handlePropertyChange('dayLabel', val)}
                   showLabel
        />
      </InputGroup>

      <InputGroup>
        <TextInput title={fbt('Label for Hours', 'CountdownProperties')}
                   value={componentProperties.hourLabel}
                   action={(val) => props.handlePropertyChange('hourLabel', val)}
                   showLabel
        />
      </InputGroup>

      <InputGroup>
        <TextInput title={fbt('Label for Minutes', 'CountdownProperties')}
                   value={componentProperties.minuteLabel}
                   action={(val) => props.handlePropertyChange('minuteLabel', val)}
                   showLabel
        />
      </InputGroup>

      <InputGroup>
        <TextInput title={fbt('Label for Seconds', 'CountdownProperties')}
                   value={componentProperties.secondLabel}
                   action={(val) => props.handlePropertyChange('secondLabel', val)}
                   showLabel
        />
      </InputGroup>

      <InputGroup>
        <TextInput title={fbt('Separator (Spaces by default)', 'CountdownProperties')}
                   value={componentProperties.separator}
                   action={(val) => props.handlePropertyChange('separator', val)}
                   showLabel
        />
      </InputGroup>

      <InputGroup>
        <CheckboxInput title={fbt('Show Hours', 'CountdownProperties')}
                       checked={componentProperties.showHours}
                       action={() => props.handlePropertyChange('showHours', !componentProperties.showHours)}
        />
      </InputGroup>
      <InputGroup>
        <CheckboxInput title={fbt('Show Minutes', 'CountdownProperties')}
                       checked={componentProperties.showMinutes}
                       action={() => props.handlePropertyChange('showMinutes', !componentProperties.showMinutes)}
        />
      </InputGroup>
      <InputGroup>
        <CheckboxInput title={fbt('Show Seconds', 'CountdownProperties')}
                       checked={componentProperties.showSeconds}
                       action={() => props.handlePropertyChange('showSeconds', !componentProperties.showSeconds)}
        />
      </InputGroup>
    </Fieldset>
  );
};

export default CountdownProperties;
