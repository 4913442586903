import React, { useState } from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import elementHelper from '../../modules/elementHelper';
import ElementDiv from '../ElementControls/ElementDiv';
import propertyHelper from '../../modules/propertyHelper';
import ElementLink from '../ElementControls/ElementLink';

const LogoElement = props => {
  const [dimensions, setDimensions] = useState({width: 0, height: 0});
  const {element, settings, meta} = props;
  const {componentProperties = {}} = element;

  const id = elementHelper.getId(element);
  const classes = elementHelper.getClasses(element, 'mame-logo');
  const style = elementHelper.getStyle(element);

  const textAlign = element.generalProperties.textAlign;

  if (componentProperties.filterType) {
    if (componentProperties.filterType === 'blur') {
      style['--fr'] = `blur(${componentProperties.filterValue}px)`;
    } else {
      style['--fr'] = `${componentProperties.filterType}(${componentProperties.filterValue}%)`;
    }
  }

  if (componentProperties.autoDimensions) {
    style['maxWidth'] = '100%';
    style['height'] = 'auto';
  }

  const getImageDimensions = e => {
    if (!componentProperties.autoDimensions) {
      return;
    }

    setDimensions({width: e.target.offsetWidth, height: e.target.offsetHeight});
  };

  const logo = settings.logo ? (
    <img src={propertyHelper.getImagePath(settings.logo)}
         alt={settings.logoText}
         className="mame-logo-img"
         style={style}
         loading={componentProperties.lazy ? 'lazy' : null}
         onLoad={getImageDimensions}
         width={componentProperties.autoDimensions ? (dimensions.width || null) : null}
         height={componentProperties.autoDimensions ? (dimensions.height || null) : null}
    />
  ) : (<h5 className="mame-logo-text" style={style}>{settings.logoText}</h5>);

  let structuredData = null;

  if (settings.logo && meta.canonicalUrl) {
    structuredData = {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      url: meta.canonicalUrl,
      logo: propertyHelper.getImagePath(settings.logo),
    };
  }

  if (componentProperties.linkToHomepage) {
    return (
      <ElementDiv {...props} id={id} style={{'--ta': textAlign}} classes={classes}>
        <ElementLink
          linkType="internal"
          page="home"
          target="_self"
          title={fbt('Homepage', 'Element Logo')}
        >
          {logo}
        </ElementLink>
        {structuredData && (
          <script type="application/ld+json" data-rh="true">
            {JSON.stringify(structuredData)}
          </script>
        )}
      </ElementDiv>
    );
  }

  return (
    <ElementDiv {...props} id={id} style={{'--ta': textAlign}} classes={classes}>
      {logo}
      {structuredData && (
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      )}
    </ElementDiv>
  );
};

const mapStateToProps = state => ({
  settings: state.activeSite.site.settings,
  meta: state.activeSite.site.meta,
});

export default connect(mapStateToProps)(LogoElement);
