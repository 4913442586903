import React from 'react';
import fbt from 'fbt';

const Loading = (props) => {
  return (
    <div className="modal-fade" onClick={props.onClick || null}>
      <div style={{
        '--pos': 'fixed',
        '--h': '300px',
        '--top': '50%',
        '--left': '50%',
        'transform': 'translate(-50%, -50%)',
      }}>
        <div className="loading"
             style={{'--p': '50px', '--br': '10px', '--ta': 'center', '--mt': '50px'}}>
          <div>
            <div className="box1"/>
            <div className="box2"/>
            <div className="box3"/>
          </div>
        </div>
        <div style={{'--ta': 'center'}}>
          <h3 style={{'--c': '#fff'}}>
            <fbt desc="Loading Dialog">
              Loading..
            </fbt>
          </h3>
        </div>
      </div>
    </div>
  );
};

export default Loading;
