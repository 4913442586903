import React from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import { removeElement } from '../../store/actions/activeSite';

const ElementErrorRenderer = props => {
  const remove = (e) => {
    e.preventDefault();
    e.stopPropagation();

    props.dispatch(removeElement(props.id, {
      parentType: props.parentType,
      parentId: props.parentId,
    }));
  };

  return (
    <div role="gridcell">
      <fbt desc="Element Error">
        Oops, Element not found!
      </fbt>
      <button onClick={remove}>
        <fbt desc="Element Error">
          Remove Element
        </fbt>
      </button>
    </div>
  );
};

export default connect()(ElementErrorRenderer);
