import axios from 'axios';

import BaseService from './BaseService';

const paths = {
  post: '/premium',
};

/**
 * Service for getting Premium
 */
class PremiumService extends BaseService {
  constructor() {
    super(paths);
    this.requireLogin = true;
  }

  subscribeStripe = (plan) => {
    const method = 'post';
    const url = `${this.getPath('post')}/stripe`;

    return axios({
      method,
      url,
      data: {
        plan
      },
    });
  };
}

export default new PremiumService();
