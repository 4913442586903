import YoutubeProperties from '../../components/Properties/Elements/YoutubeProperties';
import { ElementVisible } from '../../modules/consts/Elements';

const YoutubeModel = {
  title: 'Youtube',
  category: 'Media',
  helpPage: '/documentation/elements/youtube',
  image: '/img/elements/youtube.webp',
  component: 'YoutubeElement',
  propertyComponent: YoutubeProperties,
  hasChildElements: false,
  generalProperties: {
    hasVisibility: true,
    hasTextAlign: false,
    hasTextColor: false,
    hasBackground: false,
  },
  defaults: {
    demo: true,
    _id: null,
    identifier: 'New Youtube Video',
    component: 'YoutubeElement',
    componentProperties: {
      videoId: 'YCFbTTuKbyg',
      autoPlay: false,
      controls: true,
      fullScreen: true,
      loop: false,
      aspectRatio: '9 / 16',
    },
    generalProperties: {
      visibility: [ElementVisible.sm, ElementVisible.md, ElementVisible.lg, ElementVisible.xl],
      published: true,
      margin: [0, 0, 0, 0],
      padding: [0, 0, 0, 0],
    },
  },
};

export default YoutubeModel;
