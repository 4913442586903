import React from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import Fieldset from '../FieldSet';
import TextInput from '../Controls/TextInput';
import CheckboxInput from '../Controls/CheckboxInput';
import SelectInput from '../Controls/SelectInput';
import HelpText from '../Controls/HelpText';
import InputGroup from '../InputGroup';

const InputProperties = props => {
  const {element} = props;
  const {componentProperties} = element;

  return (
    <Fieldset title={fbt('Input Properties', 'InputProperties')} isOpen>
      <InputGroup style={{'--mt': '10px'}}>
        <TextInput title={fbt('Label (Title)', 'InputProperties')}
                   value={componentProperties.title}
                   showLabel
                   id="input-input-title"
                   action={val => props.handlePropertyChange('title', val)}
        />
      </InputGroup>

      <InputGroup>
        <CheckboxInput title={fbt('Show Label', 'InputProperties')}
                       checked={componentProperties.showLabel}
                       id="check-show-label"
                       action={() => props.handlePropertyChange('showLabel', !componentProperties.showLabel)}
        />
      </InputGroup>

      <InputGroup>
        <TextInput title={fbt('Custom Name (for email placeholders / 3rd party API)', 'InputProperties')}
                   showLabel
                   id="input-custom-name"
                   filter="[^A-Za-z0-9_-]"
                   value={componentProperties.customName}
                   action={val => props.handlePropertyChange('customName', val)}
        />
      </InputGroup>

      <InputGroup>
        <SelectInput title={fbt('Input Type', 'InputProperties')}
                     value={componentProperties.inputType}
                     id="input-element-type"
                     action={val => props.handlePropertyChange('inputType', val)}
                     options={{
                       '': fbt('Text (Default)', 'InputProperties'),
                       'number': fbt('Number Input', 'InputProperties'),
                       'email': fbt('Email address', 'InputProperties'),
                       'password': fbt('Password', 'InputProperties'),
                       'tel': fbt('Phone', 'InputProperties'),
                       'time': fbt('Time', 'InputProperties'),
                       'url': fbt('URL', 'InputProperties'),
                       'date': fbt('Date', 'InputProperties'),
                     }}
                     showLabel
        />
      </InputGroup>

      <InputGroup>
        <TextInput title={fbt('Placeholder', 'InputProperties')}
                   value={componentProperties.placeholder || ''}
                   id="input-placeholder"
                   action={val => props.handlePropertyChange('placeholder', val)}
                   showLabel
        />
      </InputGroup>

      <InputGroup>
        <TextInput title={fbt('Default Value', 'InputProperties')}
                   value={componentProperties.defaultValue || ''}
                   id="input-default-value"
                   action={val => props.handlePropertyChange('defaultValue', val)}
                   showLabel
        />
      </InputGroup>

      <InputGroup>
        <SelectInput title={fbt('Autocomplete', 'InputProperties')}
                     value={componentProperties.autoComplete} showLabel
                     id="select-auto-complete"
                     action={val => props.handlePropertyChange('autoComplete', val)}
                     options={{
                       '': fbt('None (Default)', 'InputProperties'),
                       'email': fbt('Email', 'InputProperties'),
                       'name': fbt('Full name', 'InputProperties'),
                       'new-password': fbt('New Password', 'InputProperties'),
                       'current-password': fbt('Current Password', 'InputProperties'),
                       'organization': fbt('Organization', 'InputProperties'),
                       'street-address': fbt('Street Address', 'InputProperties'),
                       'address-line1': fbt('Address Line 1', 'InputProperties'),
                       'address-line2': fbt('Address Line 2', 'InputProperties'),
                       'country': fbt('Country', 'InputProperties'),
                       'postal-code': fbt('Postal Code / Zip', 'InputProperties'),
                       'bday': fbt('Birthday', 'InputProperties'),
                       'sex': fbt('Sex', 'InputProperties'),
                       'tel': fbt('Telephone Number', 'InputProperties'),
                       'url': fbt('URL', 'InputProperties'),
                     }}
        />
        <HelpText>
          <fbt desc="InputProperties">
            The autocomplete property helps the browser to fill out fields for the user.
          </fbt>
        </HelpText>
      </InputGroup>

      <InputGroup>
        <CheckboxInput title={fbt('Required', 'InputProperties')}
                       id="check-required"
                       checked={componentProperties.required}
                       action={() => props.handlePropertyChange('required', !componentProperties.required)}
        />
      </InputGroup>

      {componentProperties.required && (
        <InputGroup>
          <TextInput title={fbt('Custom Validation Pattern', 'InputProperties')}
                     placeholder="[A-Za-z0-9_-]"
                     value={componentProperties.pattern}
                     showLabel
                     action={val => props.handlePropertyChange('pattern', val)}
          />
        </InputGroup>
      )}
    </Fieldset>
  );
};

export default connect()(InputProperties);
