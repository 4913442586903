import React from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import TextInput from '../Controls/TextInput';
import GenericSlider from '../Controls/GenericSlider';
import AdvancedColorPicker from '../Controls/AdvancedColorPicker';
import Fieldset from '../FieldSet';
import CheckboxInput from '../Controls/CheckboxInput';
import LinkGroup from '../Controls/LinkGroup';
import ItemsFieldsetWrapper from '../Fieldsets/ItemsFieldsetWrapper';
import { ElementColorClasses } from '../../../modules/consts/Elements';
import IconPicker from '../Controls/IconPicker';
import InputGroup from '../InputGroup';

const ButtonsProperties = (props) => {
  const {element, model} = props;
  const {componentProperties} = element;

  return (
    <div>
      <Fieldset title={fbt('Settings', 'ButtonsProperties')} isOpen>
        <InputGroup style={{'--m': '10px 0'}}>
          <CheckboxInput title={fbt('Display as Group (No Spacing between...)', 'ButtonsProperties')}
                         checked={componentProperties.isGroup}
                         action={() => props.handlePropertyChange('isGroup', !componentProperties.isGroup)}
          />
        </InputGroup>
      </Fieldset>

      <ItemsFieldsetWrapper
        title={fbt('Buttons', 'ButtonsProperties')}
        items={componentProperties.items}
        actionDispatch={props.dispatchItems}
        template={model.itemTemplate}
        isOpen
      >
        <Controls
          action={props.handleItemChange}/>
      </ItemsFieldsetWrapper>
    </div>
  );
};

const Controls = props => {
  const item = props.item;
  const index = props.index;

  return (
    <>
      <InputGroup>
        <TextInput title={fbt('Button Text', 'ButtonsProperties')}
                   value={item.title}
                   blur={val => props.action(index, 'title', val)}
                   showLabel
        />
      </InputGroup>

      <InputGroup>

        <IconPicker icon={item.icon}
                    title={fbt('Button Icon (Optional)', 'ButtonsProperties')}
                    action={icon => props.action(index, 'icon', icon)}
                    hasNone minimized showLabel
        />
      </InputGroup>

      <LinkGroup
        linkType={item.linkType}
        actionLinkType={val => props.action(index, 'linkType', val)}
        link={item.link}
        actionLink={val => props.action(index, 'link', val)}
        page={item.page}
        actionPage={val => props.action(index, 'page', val)}
        linkTarget={item.linkTarget}
        actionLinkTarget={val => props.action(index, 'linkTarget', val)}
        isNofollow={item.isNofollow}
        actionNofollow={val => props.action(index, 'isNofollow', val)}
      />

      <InputGroup>
        <GenericSlider title={fbt('Button Size', 'ButtonsProperties')}
                       min="1" max="300"
                       value={item.size}
                       action={val => props.action(index, 'size', val)}
                       showLabel
        />
      </InputGroup>

      <InputGroup>
        <GenericSlider title={fbt('Fixed Width (Optional)', 'ButtonsProperties')}
                       min="0" max="100"
                       value={item.width}
                       action={val => props.action(index, 'width', val)}
                       showLabel
        />
      </InputGroup>

      <AdvancedColorPicker
        title={fbt('Button Color Style', 'ButtonsProperties')}
        value={item.background}
        action={val => props.action(index, 'background', val)}
        valueText={item.color}
        actionText={val => props.action(index, 'color', val)}
      />

      {ElementColorClasses.includes(item.background) && (
        <InputGroup>
          <CheckboxInput
            checked={item.outline}
            title={fbt('Outline only', 'ButtonsProperties')}
            action={() => props.action(index, 'outline', !item.outline)}
          />
        </InputGroup>
      )}
    </>
  );
};

export default connect()(ButtonsProperties);

