import React from 'react';
import SettingsModal from './SettingsModal';
import ShortcutModal from './ShortcutModal';
import PremiumModal from './PremiumModal';
import ReportModal from './ReportModal';
import ConfirmDialog from './ConfirmModal';
import PublishModal from './PublishModal';
import LoadingModal from './LoadingModal';

const SharedModals = props => {
  return (
    <>
      <ConfirmDialog/>
      <ReportModal/>
      <SettingsModal/>
      <ShortcutModal/>
      <PremiumModal/>
      <PublishModal/>
      <LoadingModal/>
    </>
  );
};

export default SharedModals;
