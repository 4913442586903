import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import fbt from 'fbt';

import { apiLoadUserSites } from '../../store/actions/sites';
import { apiLoadPageTemplates, apiLoadSectionTemplates } from '../../store/actions/templates';
import {
  apiLoadSiteElements,
  apiLoadSitePages,
  apiLoadSiteSections,
  setActiveSite
} from '../../store/actions/activeSite';
import { setActivePage } from '../../store/actions/activePage';
import { clearCurrentActiveProperty, setPreview, setRender } from '../../store/actions/builder';
import logger from '../../modules/logger';

const SiteLoader = props => {
  const {siteId, route} = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const {sites, sections, pages, page, preview, render} = props;

  // Load the site, the element and the section (If not there yet) - TODO caching
  useEffect(() => {
    dispatch(apiLoadUserSites());
    dispatch(apiLoadSectionTemplates());
    dispatch(apiLoadPageTemplates());
  }, [dispatch]);

  useEffect(() => {
    if (!sites || !sites.length) {
      return;
    }

    const currentSite = sites.find(s => s._id === siteId);

    if (!currentSite) {
      // If site not found redirect to dashboard
      logger.warn('Site with ', siteId, ' not found');
      history.push(`/`);
      return;
    }

    dispatch(setPreview(preview || false));
    dispatch(setRender(render || false));
    dispatch(setActiveSite(currentSite));
  }, [dispatch, sites, siteId, preview, render, history]);

  useEffect(() => {
    // Make sure to start from scratch
    dispatch(clearCurrentActiveProperty());

    // Load elements
    dispatch(apiLoadSitePages(siteId));
    dispatch(apiLoadSiteSections(siteId));
    dispatch(apiLoadSiteElements(siteId));
  }, [dispatch, siteId]);


  useEffect(() => {
    if (!pages || !pages.length) {
      return;
    }

    let foundPage;

    if (route === 'home') {
      // Home is an alias to the homepage
      foundPage = pages.find(p => p.isHome);
    } else if (route) {
      foundPage = pages.find(p => p.alias === route);
    } else {
      foundPage = pages.find(p => p.isHome);
    }

    if (!foundPage) {
      logger.warn('Page not found', route, foundPage);
      toast.warn(fbt('Page ' + fbt.param('pagename', route) + ' not found. Redirected to homepage.', 'SiteLoader'));

      if (!preview) {
        history.push(`/builder/${siteId}`);
      } else {
        history.push(`/preview/${siteId}`);
      }

      return;
    }

    if (!page || page._id !== foundPage._id) {
      dispatch(setActivePage(foundPage));
    }
  }, [dispatch, pages, page, preview, route, history, siteId]);

  useEffect(() => {
    if (!render) {
      window.delayTriggerAnimatedElements();
    }
  }, [sections, page, render]);

  return (
    <>
      {props.children}
    </>
  );
};

const mapStateToProps = state => ({
  sites: state.sites.sites,
  sections: state.activeSite.sections,
  page: state.activeSite.page,
  pages: state.activeSite.pages,
});

export default connect(mapStateToProps)(SiteLoader);
