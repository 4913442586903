import CheckboxProperties from '../../components/Properties/Elements/CheckboxProperties';
import { ElementVisible } from '../../modules/consts/Elements';

const CheckboxModel = {
  title: 'Checkbox',
  category: 'Form',
  helpPage: '/documentation/elements/form',
  component: 'CheckboxElement',
  propertyComponent: CheckboxProperties,
  hasChildElements: false,
  generalProperties: {
    hasVisibility: true,
    hasTextAlign: true,
    hasTextColor: false,
    hasBackground: false,
  },
  defaults: {
    demo: true,
    _id: null,
    identifier: 'New Checkbox',
    component: 'CheckboxElement',
    componentProperties: {
      title: 'New Checkbox',
      customName: '',
      isChecked: false,
      required: false,
      invalidMessage: '',
    },
    generalProperties: {
      visibility: [ElementVisible.sm, ElementVisible.md, ElementVisible.lg, ElementVisible.xl],
      published: true,
      margin: [0, 0, 0, 0],
      padding: [0, 0, 0, 0],
    },
  },
};

export default CheckboxModel;
