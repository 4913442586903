import produce from 'immer';
import { REMOVE_SESSION, SET_SESSIONS } from '../actionTypes';

const initialState = {
  sessions: [],
};

const sessionsState = (state = initialState, action) => {
  switch (action.type) {
    case SET_SESSIONS: {
      const {sessions} = action.payload;

      return {
        ...state,
        sessions,
      };
    }

    case REMOVE_SESSION: {
      const {sessionId} = action.payload;

      const index = state.sessions.find(e => e._id === sessionId);

      return produce(state, draft => {
        draft.sessions.splice(index, 1);
      });
    }

    default: {
      return state;
    }
  }
};

export default sessionsState;
