import axios from 'axios';
import BaseService from './BaseService';
import config from '../../config';

const paths = {
  login: '/login',
  logout: '/logout',
  authStatus: '/auth/status',
  register: '/register',
  recover: '/register/start-recovery',
};

class LoginService extends BaseService {
  constructor() {
    super(paths);
    this.requireLogin = false;
  }

  login = credentials => {
    const data = {
      ...credentials,
      app_version: config.version,
      app_platform: 'Browser',
    };

    return axios.post(this.getPath('login'), data);
  };

  logout = token => axios.post(this.getPath('logout'), token);

  /**
   * Register a user
   *
   * @param credentials {object} - {username, email, password}
   * @return {Promise<AxiosResponse<any>>}
   */
  register = (credentials) => {
    return axios.post(this.getPath('register'), credentials);
  };

  /**
   * Send reset email for the given email
   *
   * @param {Object} data - The email
   * @returns {Promise<AxiosResponse<T>>} Axios request
   */
  recoverPassword = data => {
    return axios.post(this.getPath('recover'), data);
  };
}

export default new LoginService();
