import React from 'react';
import fbt from 'fbt';

import Fieldset from '../FieldSet';
import HelpText from '../Controls/HelpText';
import TextInput from '../Controls/TextInput';

const TransformFieldset = props => {
  return (
    <Fieldset title={fbt('Transform', 'TransformFieldset')} isOpen={props.isOpen || false}>
      <TextInput title={fbt('Transform', 'TransformFieldset')}
                 placeholder={fbt('The Transform CSS Property', 'TransformFieldset')}
                 value={props.transform}
                 action={props.actionTransform}
      />

      <HelpText>
        <fbt desc="TransformFieldset">
          Transforms are useful to distort an element and create nice effects, put in the value directly.
        </fbt>
        <br/>
        <fbt desc="TransformFieldset">
          For example: perspective(1400px) rotateY(-30deg)
        </fbt>
        <br/>
        <fbt desc="TransformFieldset">
          Best works with images and similar content.
        </fbt>
      </HelpText>
    </Fieldset>
  );
};

export default TransformFieldset;
