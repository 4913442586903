import React from 'react';
import fbt from 'fbt';

const ComingSoon = (props) => {

  return (
    <>
      <img src="/img/general/coming-soon.png" alt={fbt('Coming soon', 'Coming Soon')}/>
      <h3 style={{'--ta': 'center'}}>
        <fbt desc="Coming Soon">
          Coming soon
        </fbt>
      </h3>
    </>
  );
};

export default ComingSoon;
