import React, { useState } from 'react';
import { connect } from 'react-redux';

import elementHelper from '../../modules/elementHelper';
import ElementDiv from '../ElementControls/ElementDiv';
import propertyHelper from '../../modules/propertyHelper';
import HtmlEdit from '../Properties/Controls/HtmlEdit';
import { updateElementProperty } from '../../store/actions/activeSite';

const TestimonialElement = props => {
  const [dimensions, setDimensions] = useState({width: 0, height: 0});

  const {element} = props;
  const {componentProperties} = element;

  const id = elementHelper.getId(element);
  const classes = elementHelper.getClasses(element, 'mame-testimonial');
  const style = elementHelper.getStyle(element);

  style['--maxw'] = '400px';

  const storeHtml = html => {
    props.dispatch(updateElementProperty(
      element,
      'componentProperties',
      'html',
      html,
    ));
  };

  const getImageDimensions = e => {
    if (!componentProperties.autoDimensions) {
      return;
    }

    setDimensions({width: e.target.offsetWidth, height: e.target.offsetHeight});
  };

  const imageStyle = {'--br': '50%', '--maxh': '80px'};

  if (componentProperties.filterType) {
    if (componentProperties.filterType === 'blur') {
      imageStyle['--fr'] = `blur(${componentProperties.filterValue}px)`;
    } else {
      imageStyle['--fr'] = `${componentProperties.filterType}(${componentProperties.filterValue}%)`;
    }
  }

  if (componentProperties.autoDimensions) {
    style['maxWidth'] = '100%';
  }

  return (
    <ElementDiv {...props} id={id} classes={classes} style={style}>
      <div className="testimonial__head" style={{'--ta': 'center'}}>
        <img src={propertyHelper.getImagePath(componentProperties.image)}
             alt={componentProperties.alt}
             style={imageStyle}
             loading={componentProperties.lazy ? 'lazy' : null}
             onLoad={getImageDimensions}
             width={componentProperties.autoDimensions ? (dimensions.width || null) : null}
             height={componentProperties.autoDimensions ? (dimensions.height || null) : null}
        />
      </div>
      <div className="testimonial__body" style={{'--pos': 'relative'}}>
        <span className="icon icon-quote-left"
              aria-hidden
              style={{
                '--pos': 'absolute',
                '--left': '0',
                '--top': '30%',
                '--t': 'translateY(-50%)',
                '--c': 'var(--grey)',
                '--size': '500%',
                '--z': '0',
              }}/>
        <HtmlEdit
          tag="div"
          style={{'--p': '10px', '--z': '1', '--pos': 'relative'}}
          action={html => storeHtml(html)}
          html={componentProperties.html}
        />
      </div>
      <div className="testimonial__footer">
        <b>{componentProperties.name}</b>
        {componentProperties.subTitle && (
          <>
            <br/>
            <small>{componentProperties.subTitle}</small>
          </>
        )}
      </div>
    </ElementDiv>
  );
};

export default connect()(TestimonialElement);
