import React from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import ItemsFieldsetWrapper from '../Fieldsets/ItemsFieldsetWrapper';
import TextInput from '../Controls/TextInput';
import SelectInput from '../Controls/SelectInput';
import CheckboxInput from '../Controls/CheckboxInput';
import Fieldset from '../FieldSet';
import InputGroup from '../InputGroup';

const QuizProperties = props => {
  const {model, element} = props;
  const {componentProperties} = element;

  return (
    <>
      <Fieldset title={fbt('Quiz Properties', 'QuizProperties')} isOpen>
        <InputGroup style={{'--mt': '10px'}}>
          <TextInput title={fbt('Next Button Label', 'QuizProperties')}
                     value={componentProperties.nextLabel}
                     action={val => props.handlePropertyChange('nextLabel', val)}
                     showLabel
                     required
          />
        </InputGroup>

        <InputGroup>
          <TextInput title={fbt('Previous Button Label', 'QuizProperties')}
                     value={componentProperties.prevLabel}
                     action={val => props.handlePropertyChange('prevLabel', val)}
                     showLabel
                     required
          />
        </InputGroup>

        <InputGroup>
          <TextInput title={fbt('Text Answer Input Label', 'QuizProperties')}
                     value={componentProperties.textInputLabel}
                     action={val => props.handlePropertyChange('textInputLabel', val)}
                     showLabel
                     required
          />
        </InputGroup>

        <InputGroup>
          <TextInput title={fbt('Answer Button Label', 'QuizProperties')}
                     value={componentProperties.answerButtonLabel}
                     action={val => props.handlePropertyChange('answerButtonLabel', val)}
                     showLabel
                     required
          />
        </InputGroup>

        <InputGroup>
          <TextInput title={fbt('Result Summary Label', 'QuizProperties')}
                     value={componentProperties.resultText}
                     action={val => props.handlePropertyChange('resultText', val)}
                     showLabel
                     required
          />
        </InputGroup>

        <InputGroup>
          <TextInput title={fbt('Try Again Button Label', 'QuizProperties')}
                     value={componentProperties.againButtonLabel}
                     action={val => props.handlePropertyChange('againButtonLabel', val)}
                     showLabel
                     required
          />
        </InputGroup>
      </Fieldset>

      <ItemsFieldsetWrapper
        title={fbt('Questions', 'QuizProperties')}
        items={componentProperties.items}
        actionDispatch={props.dispatchItems}
        template={model.itemTemplate}
        isOpen
      >
        <Controls
          action={props.handleItemChange}/>
      </ItemsFieldsetWrapper>
    </>
  );
};

const Controls = props => {
  const item = props.item;
  const index = props.index;

  const handleAnswerChange = (answerIndex, key, value) => {
    const answers = JSON.parse(JSON.stringify(item.answers));
    answers[answerIndex][key] = value;
    props.action(index, 'answers', answers);
  };

  const handleAddAnswer = e => {
    const answers = JSON.parse(JSON.stringify(item.answers));
    answers.push({
      content: 'New Answer',
      isCorrect: false,
    });

    props.action(index, 'answers', answers);
  };

  const handleDeleteAnswer = answerIndex => {
    const answers = JSON.parse(JSON.stringify(item.answers));
    answers.splice(answerIndex, 1);

    props.action(index, 'answers', answers);
  };

  return (
    <>
      <InputGroup>
        <TextInput title={fbt('Question', 'QuizProperties')}
                   value={item.title}
                   action={val => props.action(index, 'title', val)}
                   showLabel
        />
      </InputGroup>

      <InputGroup>
        <SelectInput title={fbt('Question Type', 'QuizProperties')}
                     value={item.type}
                     action={val => props.action(index, 'type', val)}
                     options={{
                       'single': 'Single Choice',
                       'multiple': 'Multiple Choice',
                       'text': 'Text Input',
                     }}
                     showLabel
        />
      </InputGroup>

      {item.type !== 'text' && item.answers.map((item, index) => {
        return (
          <Fieldset title={fbt('Answer', 'QuizProperties') + ` #${index + 1}`} key={index} isOpen>
            <InputGroup>
              <TextInput title={fbt('Answer', 'QuizProperties')}
                         value={item.content}
                         action={val => handleAnswerChange(index, 'content', val)}
              />
            </InputGroup>

            <InputGroup>
              <CheckboxInput title={fbt('Correct answer', 'QuizProperties')}
                             checked={item.isCorrect}
                             action={() => handleAnswerChange(index, 'isCorrect', !item.isCorrect)}
              />
            </InputGroup>

            <button className="mame-white" style={{'--x': '80'}} onClick={() => handleDeleteAnswer(index)}>
              Delete Answer
            </button>
          </Fieldset>
        );
      })}

      {item.type !== 'text' && (
        <button className="mame-primary" onClick={handleAddAnswer}>
          <fbt desc="QuizProperties">
            Add Answer
          </fbt>
        </button>
      )}

      {item.type === 'text' && (
        <InputGroup>
          <TextInput title={fbt('Answer', 'QuizProperties')}
                     value={item.answerText}
                     action={val => props.action(index, 'answerText', val)}
                     showLabel
          />
        </InputGroup>
      )}
    </>
  );
};

export default connect()(QuizProperties);
