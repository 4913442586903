import {
  ADD_PAGE_TEMPLATE,
  ADD_SECTION_TEMPLATE,
  ADD_SITE_TEMPLATE,
  REMOVE_PAGE_TEMPLATE,
  REMOVE_SECTION_TEMPLATE,
  REMOVE_SITE_TEMPLATE,
  SET_PAGE_TEMPLATES,
  SET_SECTION_TEMPLATES, SET_SITE_TEMPLATES,
  UPDATE_PAGE_TEMPLATE,
  UPDATE_SECTION_TEMPLATE,
  UPDATE_SITE_TEMPLATE
} from '../actionTypes';
import produce from 'immer';

const initialState = {
  sectionTemplates: [],
  pageTemplates: [],
  siteTemplates: [],
};

const templatesState = (state = initialState, action) => {
  switch (action.type) {
    /* Page */
    case SET_PAGE_TEMPLATES: {
      const {pageTemplates} = action.payload;

      return {
        ...state,
        pageTemplates,
      };
    }

    case ADD_PAGE_TEMPLATE: {
      const {template} = action.payload;

      return produce(state, draft => {
        draft.pageTemplates.push(template);
      });
    }

    case UPDATE_PAGE_TEMPLATE: {
      const {template} = action.payload;

      const templateIndex = state.pageTemplates.findIndex(t => t._id === template._id);

      return produce(state, draft => {
        draft.pageTemplates[templateIndex] = template;
      });
    }

    case REMOVE_PAGE_TEMPLATE: {
      const {templateId} = action.payload;

      const index = state.pageTemplates.findIndex(t => t._id === templateId);

      return produce(state, draft => {
        draft.pageTemplates.splice(index, 1);
      });
    }

    /* Section */
    case SET_SECTION_TEMPLATES: {
      const {sectionTemplates} = action.payload;

      return {
        ...state,
        sectionTemplates,
      };
    }

    case ADD_SECTION_TEMPLATE: {
      const {template} = action.payload;

      return produce(state, draft => {
        draft.sectionTemplates.push(template);
      });
    }

    case UPDATE_SECTION_TEMPLATE: {
      const {template} = action.payload;

      const templateIndex = state.sectionTemplates.findIndex(t => t._id === template._id);

      return produce(state, draft => {
        draft.sectionTemplates[templateIndex] = template;
      });
    }

    case REMOVE_SECTION_TEMPLATE: {
      const {templateId} = action.payload;

      const index = state.sectionTemplates.findIndex(t => t._id === templateId);

      return produce(state, draft => {
        draft.sectionTemplates.splice(index, 1);
      });
    }

    /* Sites */
    case SET_SITE_TEMPLATES: {
      const {siteTemplates} = action.payload;

      return {
        ...state,
        siteTemplates,
      };
    }

    case ADD_SITE_TEMPLATE: {
      const {template} = action.payload;

      return produce(state, draft => {
        draft.siteTemplates.push(template);
      });
    }

    case UPDATE_SITE_TEMPLATE: {
      const {template} = action.payload;

      const templateIndex = state.siteTemplates.findIndex(t => t._id === template._id);

      return produce(state, draft => {
        draft.siteTemplates[templateIndex] = template;
      });
    }

    case REMOVE_SITE_TEMPLATE: {
      const {templateId} = action.payload;

      const index = state.siteTemplates.findIndex(t => t._id === templateId);

      return produce(state, draft => {
        draft.siteTemplates.splice(index, 1);
      });
    }

    default: {
      return state;
    }
  }
};

export default templatesState;
