import React from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import { ElementColorClasses } from '../../modules/consts/Elements';
import { isPreviewSelector } from '../../store/selectors';
import HtmlEdit from '../Properties/Controls/HtmlEdit';
import { updateSiteProperty } from '../../store/actions/activeSite';

const CookieBanner = (props) => {
  const {cookie} = props;

  if (!cookie.enabled) {
    return null;
  }

  if (!cookie.devEnabled && !props.isPreview) {
    return null;
  }

  const isBar = cookie.displayAs === 'bar';
  const classes = 'cookie-banner ' + (isBar ? 'cookie-banner--bar' : 'cookie-banner--inline');

  const style = {
    '--pos': 'fixed',
    '--left': isBar ? '0' : '40vw',
    '--d': 'grid',
    '--gtc': isBar ? '1fr 1fr' : '1fr',
    '--ai': 'center',
    '--jc': 'space-between',
    '--px': '10px',
    '--z': '999',
  };

  if (cookie.position === 'top') {
    style['--top'] = '0px';
    style['--radius'] = isBar ? '' : '0 0 5px 5px';
  } else {
    style['--bottom'] = '0px';
    style['--radius'] = isBar ? '' : '5px 5px 0 0';
  }

  if (ElementColorClasses.includes(cookie.backgroundColor)) {
    style['--bgc'] = `var(--${cookie.backgroundColor})`;
  } else {
    style['--bg'] = cookie.backgroundColor;
    style['--c'] = cookie.textColor || '#333'; // Make sure it stays visible
  }

  if (!isBar) {
    style['--p'] = '15px';
  }

  const setActive = () => {
    if (props.isPreview) {
      return;
    }
  };

  const handleHtmlChange = html => {
    props.dispatch(updateSiteProperty('settings', 'cookie', html, 'text'));
  };

  return (
    <div className={classes} style={style} onClick={setActive}>
      <div>
        <HtmlEdit html={cookie.text}
                  action={handleHtmlChange}
        />

        {cookie.url && (
          <a href={cookie.url} target="_blank" rel="noopener noreferrer"
             style={{'--ml': '5px'}}>
            <fbt desc="Cookie Banner">
              More Information
            </fbt>
          </a>
        )}
      </div>
      <div style={{'--ta': isBar ? 'right' : 'center'}}>
        <button id="cookie-banner__btn-reject" className="white">{cookie.rejectText}</button>
        <button id="cookie-banner__btn-accept" className="primary">{cookie.agreeText}</button>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  cookie: state.activeSite.site.settings.cookie,
  isPreview: isPreviewSelector(state),
});

export default connect(mapStateToProps)(CookieBanner);
