import React from 'react';
// eslint-disable-next-line no-unused-vars
import fbt from 'fbt';
import InputGroup from '../InputGroup';

const PatternPicker = props => {
  const pattern = [
    // Light
    'dot-grid.webp',
    'topography.webp',
    'webb.webp',
    'double-bubble-outline.webp',
    'y-so-serious-white.webp',
    'spikes.webp',

    // Objects
    'email-pattern.webp',
    'doodles.webp',
    'trees.webp',

    // Usual ones
    'pattern1.webp',
    'pattern2.webp',
    'pattern3.webp',
    'pattern5.webp',
    'pattern6.webp',
    'pattern7.webp',
    'pattern9.webp',
    'pattern10.webp',
    'pattern11.webp',
    'pattern12.webp',

    // Walls
    'brickwall.webp',
    'small_steps.webp',

    // Complex
    'pentagon-grey.webp',
    'pentagon-blue.webp',
    'just-waves.webp',

    // Dark ones
    'pattern4.webp',
    'congruent_outline.webp',
    'oriental-tiles.webp',
    'pattern8.webp',
  ];

  return (
    <InputGroup style={{'--mt': '10px'}}>
      <label style={{'--mb': '8px', '--d': 'block'}}>
        <fbt desc="PatternPicker">
          Choose a pattern
        </fbt>
      </label>
      <div>
        {pattern.map(img => {
          const path = `/img/pattern/${img}`;

          const style = {'--w': '40px', '--h': '40px', '--mr': '7px', '--cur': 'pointer', '--scale-hvr': '150%'};

          if (img === props.value) {
            style['--b'] = '2px solid var(--mame-primary)';
          }

          return (
            <img src={path}
                 alt="Pattern"
                 key={img}
                 style={style}
                 role="button"
                 onClick={() => props.action(img)}
            />
          );
        })}
      </div>
    </InputGroup>
  );
};

export default PatternPicker;
