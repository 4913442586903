import React, { useState } from 'react';
import fbt from 'fbt';

import FileInput from './FileInput';
import CheckboxInput from './CheckboxInput';
import TextInput from './TextInput';
import GenericSlider from './GenericSlider';
import propertyHelper from '../../../modules/propertyHelper';
import InputGroup from '../InputGroup';

const ImageUpload = (props) => {
  const [resizeImage, setResizeImage] = useState(props.hasOwnProperty('defaultResize') ? props.defaultResize : true);
  const [convertWebp, setConvertWebp] = useState(props.hasOwnProperty('webpConvert') ? props.webpConvert : true);
  const [resizeWidth, setResizeWidth] = useState(props.resizeWidth || 1280);
  const [resizeHeight, setResizeHeight] = useState(props.resizeHeight || 1280);
  const [imageQuality, setImageQuality] = useState(80);
  const [preserveConstraints, setPreserveConstraints] = useState(true);

  const handleUpload = (files, data = null) => {
    if (props.task) {
      props.action(files, data);
      return;
    }

    if (!files || !files.length) {
      return;
    }

    // Just the first image
    props.action(files[0], data);
  };

  return (
    <>
      {props.image && (
        <div>
          <img src={propertyHelper.getImagePath(props.image)}
               alt="Preview" className="image-preview" aria-hidden/>
        </div>

      )}
      {props.showLabel && (
        <label>
          {props.title}
        </label>
      )}

      <InputGroup>
        <FileInput id={props.id}
                   task={props.task || ''}
                   accept="image/*"
                   text={props.text}
                   resizeImage={resizeImage}
                   convertWebp={convertWebp}
                   resizeWidth={resizeWidth}
                   resizeHeight={resizeHeight}
                   imageQuality={imageQuality}
                   preserveConstraints={preserveConstraints}
                   createThumbnail={props.createThumbnail || false}
                   action={(url, data) => {
                     handleUpload(url, data);
                   }}
        />
      </InputGroup>

      {!props.disableWebp && (
        <InputGroup>
          <CheckboxInput
            title={fbt('Convert to modern image format (webp)', 'ImageUpload')}
            checked={convertWebp}
            action={() => {
              setConvertWebp(!convertWebp);
            }}
          />
        </InputGroup>
      )}

      {!props.disableResize && (
        <InputGroup>
          <CheckboxInput
            title={fbt('Resize and optimize image', 'ImageUpload')}
            checked={resizeImage}
            action={() => {
              setResizeImage(!resizeImage);
            }}
          />
        </InputGroup>
      )}

      {!props.disableResize && resizeImage && <ResizeSettings
        resizeWidth={resizeWidth}
        resizeHeight={resizeHeight}
        imageQuality={imageQuality}
        preserveConstraints={preserveConstraints}
        resizeWidthAction={val => setResizeWidth(val)}
        resizeHeightAction={val => setResizeHeight(val)}
        imageQualityAction={val => setImageQuality(val)}
        preserveConstraintsAction={() => setPreserveConstraints(!preserveConstraints)}
      />}
    </>
  );
};

const ResizeSettings = props => {
  return (
    <>
      <InputGroup>
        <GenericSlider title={fbt('Image Quality (for supported formats)', 'ImageUpload')}
                       min="1" max="100" showLabel
                       value={props.imageQuality} action={props.imageQualityAction}
        />
      </InputGroup>

      <InputGroup>
        <TextInput type="number" title={fbt('Maximum width in px', 'ImageUpload')}
                   min="5" max="2000" showLabel
                   value={props.resizeWidth} action={props.resizeWidthAction}
        />
      </InputGroup>

      <InputGroup>
        <TextInput type="number" title={fbt('Maximum height in px', 'ImageUpload')} min="5" max="2000" showLabel
                   value={props.resizeHeight} action={props.resizeHeightAction}
        />
      </InputGroup>

      <InputGroup>
        <CheckboxInput title={fbt('Preserve constraints (prevent image stretching)', 'ImageUpload')}
                       checked={props.preserveConstraints}
                       action={props.preserveConstraintsAction}
        />
      </InputGroup>
    </>
  );
};

export default ImageUpload;
