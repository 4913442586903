import React from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import Fieldset from '../FieldSet';
import CheckboxInput from '../Controls/CheckboxInput';
import SelectInput from '../Controls/SelectInput';
import BackgroundPicker from '../Controls/BackgroundPicker';
import ImageComponentProperties from '../Fieldsets/ImageComponentProperties';
import InputGroup from '../InputGroup';

const ImageOverlayProperties = props => {
  const {element} = props;
  const {componentProperties} = element;

  return (
    <Fieldset title={fbt('Image with Overlay Properties', 'ImageOverlayProperties')} isOpen>
      <ImageComponentProperties componentProperties={componentProperties}
                                handlePropertyChange={props.handlePropertyChange}
      />

      <h4 style={{'--mt': '15px'}}>
        <fbt desc="ImageOverlayProperties">
          Overlay Settings
        </fbt>
      </h4>

      <InputGroup>
        <SelectInput title={fbt('Trigger Overlay on', 'ImageOverlayProperties')}
                     value={componentProperties.overlayOn} showLabel
                     action={val => props.handlePropertyChange('overlayOn', val)}
                     options={{
                       'hover': fbt('Mouse Over', 'ImageOverlayProperties'),
                       // 'click': fbt('Mouse Click', 'ImageOverlayProperties'),
                     }}
        />
      </InputGroup>

      <InputGroup>
        <CheckboxInput title={fbt('Always show Overlay', 'ImageOverlayProperties')}
                       checked={componentProperties.overlayShowAlways}
                       action={() => {
                         props.handlePropertyChange('overlayShowAlways', !componentProperties.overlayShowAlways);
                       }}
        />
      </InputGroup>

      <BackgroundPicker title={fbt('Overlay Background', 'SlideshowProperties')}
                        type={componentProperties.overlayBackgroundType}
                        image={componentProperties.overlayBackgroundImage}
                        color={componentProperties.overlayBackgroundColor}
                        gradientColor={componentProperties.overlayBackgroundGradientColor}
                        gradientDegree={componentProperties.overlayBackgroundGradientDegree}
                        pattern={componentProperties.overlayBackgroundPattern}
                        typeAction={val => {
                          props.handlePropertyChange('overlayBackgroundType', val);
                        }}
                        colorAction={val => {
                          props.handlePropertyChange('overlayBackgroundColor', val);
                        }}
                        imageAction={val => {
                          props.handlePropertyChange('overlayBackgroundImage', val);
                        }}
                        gradientColorAction={val => {
                          props.handlePropertyChange('overlayBackgroundGradientColor', val);
                        }}
                        gradientDegreeAction={val => {
                          props.handlePropertyChange('overlayBackgroundGradientDegree', val);
                        }}
                        patternAction={val => {
                          props.handlePropertyChange('overlayBackgroundPattern', val);
                        }}
                        hideFieldset
                        hideVideo
                        showLabel
      />
    </Fieldset>
  );
};

export default connect()(ImageOverlayProperties);
