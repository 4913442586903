import React from 'react';
import { connect } from 'react-redux';

import elementHelper from '../../modules/elementHelper';
import ElementDiv from '../ElementControls/ElementDiv';
import HtmlEdit from '../Properties/Controls/HtmlEdit';
import { ElementColorClasses } from '../../modules/consts/Elements';
import propertyHelper from '../../modules/propertyHelper';
import { updateElementItemProperty } from '../../store/actions/activeSite';
import { isPreviewSelector } from '../../store/selectors';
import ElementLink from '../ElementControls/ElementLink';

const PricingTableElement = props => {
  const {element} = props;
  const {componentProperties} = element;

  const id = elementHelper.getId(element);
  const classes = elementHelper.getClasses(element, 'mame-pricing-table');
  const style = elementHelper.getStyle(element);

  const handleOnClick = e => {
    if (!props.isPreview) {
      e.preventDefault();
    }
  };

  const storeHtml = (index, html) => {
    props.dispatch(updateElementItemProperty(
      element,
      index,
      'html',
      html
    ));
  };

  return (
    <ElementDiv {...props} id={id} classes={classes}
                style={{'--d': 'flex', '--jc': 'center', '--ai': 'center', '--fw': 'wrap', '--gg': '20px'}}>
      {componentProperties.items.map((item, index) => {
        const elementStyle = {...style};

        propertyHelper.setBackground(elementStyle, item);

        // TODO fix Duplicate stupid code, make heler class.. Anchor is missing :/
        const buttonStyle = {};

        if (item.buttonWidth) {
          buttonStyle['--w'] = item.buttonWidth + '%';
          buttonStyle['--ta'] = 'center';
        }

        if (item.buttonSize) {
          buttonStyle['--x'] = item.buttonSize;
        }

        let buttonClasses = 'button';

        if (item.buttonBackground) {
          // Check if it's a class
          if (ElementColorClasses.includes(item.buttonBackground)) {
            buttonClasses += ' ' + item.buttonBackground;

            if (item.buttonOutline) {
              buttonClasses += ' outline';
            }
          } else {
            buttonStyle['--bg'] = item.buttonBackground;
            buttonStyle['--c'] = item.buttonColor || '#f1f1f1';
          }
        }

        const button = item.buttonText ? (
          <button className={buttonClasses}
                  style={buttonStyle}
          >
            {item.buttonText}
          </button>
        ) : null;

        return (
          <figure className="accent" style={elementStyle} key={index}>
            <figcaption>
              <HtmlEdit
                tag="div"
                style={{'--p': '5px'}}
                action={html => storeHtml(index, html)}
                html={item.html}/>

              {item.linkType && (
                <ElementLink
                  linkType={item.linkType}
                  link={item.link}
                  target={item.linkTarget}
                  page={item.page}
                  isNofollow={item.isNofollow}
                  onClick={handleOnClick}
                >
                  {button}
                </ElementLink>
              )}

              {!item.linkType && button}
            </figcaption>
          </figure>
        );
      })}
    </ElementDiv>
  );
};

const mapStateToProps = state => ({
  isPreview: isPreviewSelector(state),
});

export default connect(mapStateToProps)(PricingTableElement);
