import {
  SET_ACTIVE_PAGE,
  SET_ACTIVE_PAGE_CANONICAL,
  SET_ACTIVE_PAGE_TITLE,
  SET_PRESET_STYLES,
  UPDATE_PAGE_PROPERTY
} from '../actionTypes';
import serviceHelper from '../../modules/serviceHelper';
import { addPage } from './activeSite';

/* Post */
export const apiStorePage = (page) => (
  async (dispatch) => {
    const service = serviceHelper.getService('PageService');

    const res = await service.store(page);

    const item = res.data.item;

    if (!page._id) {
      dispatch(addPage(item));
    }
  }
);

/* Normal dispatchers */
export const setActivePage = page => ({type: SET_ACTIVE_PAGE, payload: {page}});
export const setActivePageCanonical = canonical => ({type: SET_ACTIVE_PAGE_CANONICAL, payload: {canonical}});
export const setActivePageTitle = title => ({type: SET_ACTIVE_PAGE_TITLE, payload: {title}});
export const setPresetStyles = colors => ({type: SET_PRESET_STYLES, payload: {colors}});

export const updatePageProperty = (page, category, key, value, subKey) => ({
  type: UPDATE_PAGE_PROPERTY,
  payload: {page, category, key, value, subKey},
});
