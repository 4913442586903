import React, { useState } from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';
import { Link } from 'react-router-dom';
import useContextMenu from 'react-use-context-menu';

import config from '../../config';
import serviceHelper from '../../modules/serviceHelper';
import ActionIcon from '../General/ActionIcon';
import { confirmationDialog } from '../../store/actions/builder';
import { removeSite } from '../../store/actions/sites';
import logger from '../../modules/logger';

const SiteFigure = props => {
  const [imageElement, setImageElement] = useState(null);
  const {site} = props;

  const previewImg = config.baseUrl + `/preview/site/${site._id}/preview.jpg`;
  const defaultImg = '/img/section/no-preview.jpg';

  // Context Menu
  const [
    bindMenu,
    bindMenuItems,
    useContextTrigger,
    {setVisible: setContextVisible}
  ] = useContextMenu();

  const [bindContextTrigger] = useContextTrigger({disable: props.isPreview, holdToDisplay: -1});

  const handleCaptureSite = e => {
    e.preventDefault();
    e.stopPropagation();
    setContextVisible(false);

    // Async this is costly
    const service = serviceHelper.getService('CaptureService');

    service.store({siteId: site._id})
      .then((res) => {
        imageElement.src = `${previewImg}?t=${Date.now()}`;
      }).catch((err) => {
      logger.warn('Error taking screenshot', err);
    });
  };

  const handleRemoveSite = e => {
    e.preventDefault();
    e.stopPropagation();
    setContextVisible(false);

    props.dispatch(confirmationDialog(
      fbt('Do you really want to delete this site? Forms are going to stop working too!', 'Site Deletion Warning'),
      () => {
        props.dispatch(removeSite(site._id));
      },
    ));
  };

  // const handleSaveTemplate = (e) => {
  //   e.preventDefault();
  //   props.dispatch(showModal('SaveTemplateModal', {item: site, type: 'site'}));
  // };

  const handleSellStore = e => {
    e.preventDefault();
    e.stopPropagation();
    setContextVisible(false);

    logger.info('TODO Store');
  };

  // const handleShareSite = (e) => {
  //   e.preventDefault();
  //   console.log('TODO');
  // };

  return (
    <Link to={'/builder/' + site._id} key={site._id}>
      <figure
        className="preview site-preview accent"
        // style={{'--levitate-hvr': 5}}
        {...bindContextTrigger}
        style={{'--of': 'hidden'}}
      >
        <img src={previewImg}
             onError={e => {
               e.target.onerror = null;
               e.target.src = defaultImg;
               handleCaptureSite(e);
             }}
             alt="Preview"
             ref={setImageElement}
        />
        <figcaption style={{'--ta': 'center'}}>
          <h4 style={{'--line-clamp': 1}}>{site.title}</h4>
        </figcaption>

        <div className="preview-menu" onClick={(e) => {
          e.preventDefault();
        }}>
          <ActionIcon icon="image"
                      title={fbt('Refresh Screenshot (Takes some seconds)', 'Site Item Context Menu')}
                      action={handleCaptureSite}
          />
          <br/>
          <ActionIcon icon="trash-o"
                      title={fbt('Remove Site', 'Site Item Context Menu')}
                      action={handleRemoveSite}/><br/>
          {/*<ActionIcon icon="save" title="Save as Template" action={handleSaveTemplate}/><br/>*/}
          {/*<ActionIcon icon="share-square-o" title="Share Preview" action={handleShareSite}/><br/>*/}
          <ActionIcon icon="shopping-basket"
                      title={fbt('Offer as a template on the Shop', 'Site Item Context Menu')}
                      action={handleSellStore}/><br/>
        </div>

        <nav {...bindMenu} className="context-menu context-menu-dashboard">
          <div {...bindMenuItems} onClick={handleCaptureSite}>
            <ActionIcon icon="image" style={{'--w': '35px', '--d': 'inline-block'}}/>
            <fbt desc="Site Item Context Menu">
              Refresh Screenshot
            </fbt>
          </div>

          <div {...bindMenuItems} onClick={handleRemoveSite}>
            <ActionIcon icon="trash-o" style={{'--w': '35px', '--d': 'inline-block'}}/>
            <fbt desc="Site Item Context Menu">
              Remove Site
            </fbt>
          </div>

          <div {...bindMenuItems} onClick={handleSellStore}>
            <ActionIcon icon="shopping-basket" style={{'--w': '35px', '--d': 'inline-block'}}/>
            <fbt desc="Site Item Context Menu">
              Offer on Store
            </fbt>
          </div>
        </nav>
      </figure>
    </Link>
  );
};

export default connect()(SiteFigure);
