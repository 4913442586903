import FigureProperties from '../../components/Properties/Elements/FigureProperties';
import { ElementVisible } from '../../modules/consts/Elements';

const FigureModel = {
  title: 'Figure',
  category: 'Basic',
  helpPage: '/documentation/elements/figure',
  component: 'FigureElement',
  propertyComponent: FigureProperties,
  hasChildElements: true,
  generalProperties: {
    hasVisibility: true,
    hasTextAlign: true,
    hasTextColor: false,
    hasBackground: false,
  },
  defaults: {
    demo: true,
    _id: null,
    identifier: 'New Figure',
    component: 'FigureElement',
    componentProperties: {
      linkType: '',
      page: '',
      link: '',
      linkTarget: '_self',
      isNofollow: false,
      backgroundType: '',
      backgroundColor: '#ffffff',
      backgroundGradientColor: 'f1f1f1',
      backgroundGradientDegree: 0,
      backgroundPattern: 'pattern1.webp',
    },
    generalProperties: {
      visibility: [ElementVisible.sm, ElementVisible.md, ElementVisible.lg, ElementVisible.xl],
      published: true,
      margin: [0, 0, 0, 0],
      padding: [15, 15, 15, 15],
    },
  },
};

export default FigureModel;
