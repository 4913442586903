import React from 'react';
import Select from 'react-select';

const SelectInput = props => {
  let className = props.className || '';

  className += className ? ' builder-select' : 'builder-select';

  if (props.required) {
    className += 'required';
  }

  const id = props.id || null;

  // React Select requires another data scheme.. TODO optimize and allow new way
  let options = props.newOptions || [];

  // New version
  if (!props.newOptions) {
    Object.keys(props.options).forEach(key => {
      options.push({value: key, label: props.options[key].toString()});
    });
  }

  const value = options.find(i => i.value === props.value);

  const isSearchable = props.hasOwnProperty('disableSearch') ? false : options.length > 8;

  return (
    <>
      <label htmlFor={id} style={props.style || null}>
        {props.showLabel && props.title}
        {!props.showLabel && <span className="sr-only">props.title</span>}
        <Select options={options}
                value={value}
                hasValue
                inputId={id}
                className={className}
                onChange={result => {
                  props.action(result.value);
                }}
                isSearchable={isSearchable}
        />
      </label>
    </>
  );
};

export default SelectInput;
