import React from 'react';
import fbt from 'fbt';

import ResponsiveIcon from './ResponsiveIcon';
import { ElementVisible } from '../../../modules/consts/Elements';
import HelpText from './HelpText';
import InputGroup from '../InputGroup';

const VisibilitySelection = (props) => {
  let visibilities = props.values;

  // Default is we show all
  if (!props.values || !props.values.length) {
    visibilities = [ElementVisible.sm, ElementVisible.md, ElementVisible.lg, ElementVisible.xl];
  }

  return (
    <InputGroup>
      <div className="group">
        <ResponsiveIcon icon="mobile"
                        title={fbt('Visible on small devices (>= 640px)', 'VisibilitySection')}
                        action={() => props.action(ElementVisible.sm)}
                        active={visibilities.includes(ElementVisible.sm)}/>
        <ResponsiveIcon icon="tablet"
                        title={fbt('Visible on medium devices (>= 768px)', 'VisibilitySection')}
                        action={() => props.action(ElementVisible.md)}
                        active={visibilities.includes(ElementVisible.md)}/>
        <ResponsiveIcon icon="tablet icon-rotate-90deg"
                        title={fbt('Visible on large devices (>= 1024px)', 'VisibilitySection')}
                        action={() => props.action(ElementVisible.lg)}
                        active={visibilities.includes(ElementVisible.lg)}/>
        <ResponsiveIcon icon="desktop"
                        title={fbt('Visible on extra large devices (>= 1280px)', 'VisibilitySection')}
                        action={() => props.action(ElementVisible.xl)}
                        active={visibilities.includes(ElementVisible.xl)}/>
      </div>
      {props.type === 'section' && (
        <HelpText>
          <fbt desc="VisibilitySection">
            On which devices should the section be shown.
          </fbt>
        </HelpText>
      )}

      {props.type === 'element' && (
        <HelpText>
          <fbt desc="VisibilitySection">
            On which devices should the element be shown.
          </fbt>
        </HelpText>
      )}
    </InputGroup>
  );
};

export default VisibilitySelection;
