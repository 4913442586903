import React from 'react';
import fbt from 'fbt';

import Fieldset from '../FieldSet';
import SelectInput from '../Controls/SelectInput';
import InputGroup from '../InputGroup';

const DisplayFieldset = (props) => {
  return (
    <Fieldset title={fbt('Display', 'DisplayFieldset')} isOpen={props.isOpen || false}>
      {!props.hideDisplay && (
        <InputGroup style={{'--mt': '8px'}}>
          <SelectInput value={props.display}
                       title={fbt('Display', 'DisplayFieldset')}
                       options={{
                         '': fbt('Default (Usually Block)', 'DisplayFieldset'),
                         'block': fbt('Block', 'DisplayFieldset'),
                         'inline': fbt('Inline', 'DisplayFieldset'),
                         'inline-block': fbt('Inline Block', 'DisplayFieldset'),
                         'flex': fbt('Flex', 'DisplayFieldset'),
                         'inline-flex': fbt('Inline Flex', 'DisplayFieldset'),
                         'grid': fbt('Grid', 'DisplayFieldset'),
                         'inline-grid': fbt('Inline Grid', 'DisplayFieldset'),
                         'table': fbt('Table', 'DisplayFieldset'),
                         'table-row': fbt('Table Row', 'DisplayFieldset'),
                         'none': fbt('Hidden', 'DisplayFieldset'),
                       }}
                       action={val => props.actionDisplay(val)}
          />
        </InputGroup>
      )}

      {/* TODO only show for the options that support that*/}
      <InputGroup style={{'--mt': '8px'}}>
        <SelectInput value={props.alignItems}
                     title={props.titleAlignItems || fbt('Align Items (depending on display option)', 'DisplayFieldset')}
                     options={{
                       '': fbt('Default', 'DisplayFieldset'),
                       'stretch': fbt('Stretch', 'DisplayFieldset'),
                       'center': fbt('Center', 'DisplayFieldset'),
                       'flex-start': fbt('Start', 'DisplayFieldset'),
                       'flex-end': fbt('End', 'DisplayFieldset'),
                       'baseline': fbt('Baseline', 'DisplayFieldset'),
                     }}
                     action={val => props.actionAlignItems(val)}
                     showLabel
        />
      </InputGroup>

      <InputGroup>
        <SelectInput value={props.justifyContent}
                     title={props.titleJustifyContent || fbt('Justify Content (depending on display option)', 'DisplayFieldset')}
                     options={{
                       '': fbt('Default', 'DisplayFieldset'),
                       'center': fbt('Center', 'DisplayFieldset'),
                       'flex-start': fbt('Flex Start', 'DisplayFieldset'),
                       'flex-end': fbt('Flex End', 'DisplayFieldset'),
                       'space-between': fbt('Space Between', 'DisplayFieldset'),
                       'space-around': fbt('Space Around', 'DisplayFieldset'),
                     }}
                     action={val => props.actionJustifyContent(val)}
                     showLabel
        />
      </InputGroup>
    </Fieldset>
  );
};

export default DisplayFieldset;
