import React from 'react';
import fbt from 'fbt';

import Fieldset from '../FieldSet';
import HelpText from '../Controls/HelpText';
import FontSelect from '../Controls/FontSelect';
import GenericSlider from '../Controls/GenericSlider';
import InputGroup from '../InputGroup';

const TypographyFieldset = props => {
  return (
    <Fieldset title={fbt('Typography', 'TypographyFieldset')} isOpen={props.isOpen || false}>
      <HelpText style={{'--m': '10px 0'}}>
        <fbt desc="TypographyFieldset">
          Google and Web Fonts may increase the load time of your website.
        </fbt>
      </HelpText>

      <FontSelect title={fbt('Primary Font', 'TypographyFieldset')}
                  value={props.typography.primaryFont}
                  action={val => props.handlePropertyChange('typography', 'primaryFont', val)}
                  family={props.typography.primaryFamily}
                  familyAction={val => props.handlePropertyChange('typography', 'primaryFamily', val)}
                  url={props.typography.primaryUrl}
                  urlAction={val => props.handlePropertyChange('typography', 'primaryUrl', val)}
                  hasCustom
      />
      <FontSelect title={fbt('Secondary Font (Optional)', 'TypographyFieldset')}
                  value={props.typography.secondaryFont}
                  action={val => props.handlePropertyChange('typography', 'secondaryFont', val)}
                  family={props.typography.secondaryFamily}
                  familyAction={val => props.handlePropertyChange('typography', 'secondaryFamily', val)}
                  url={props.typography.secondaryUrl}
                  urlAction={val => props.handlePropertyChange('typography', 'secondaryUrl', val)}
                  secondary
                  hasCustom
      />

      <InputGroup>
        <GenericSlider title={fbt('Default Font Size in px', 'TypographyFieldset')}
                       className="b-typography__font-size"
                       value={props.typography.fontSize}
                       action={val => props.handlePropertyChange('typography', 'fontSize', parseInt(val))}
                       min="8" max="30"
                       showLabel
        />
      </InputGroup>

      <InputGroup>
        <GenericSlider title={fbt('Font Weight (Thin, Regular, Bold..)', 'TypographyFieldset')}
                       className="b-typography__font-weight"
                       value={props.typography.fontWeight}
                       action={val => props.handlePropertyChange('typography', 'fontWeight', parseInt(val))}
                       min="100" max="900" step="100"
                       showLabel
        />
      </InputGroup>

      <InputGroup>
        <GenericSlider title={fbt('Line Height', 'TypographyFieldset')}
                       className="b-typography__line-height"
                       value={props.typography.lineHeight}
                       action={val => props.handlePropertyChange('typography', 'lineHeight', val)}
                       min="0.5" max="3.0" step="0.1"
                       showLabel
        />
      </InputGroup>
    </Fieldset>
  );
};

export default TypographyFieldset;
