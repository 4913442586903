import PricingTableProperties from '../../components/Properties/Elements/PricingTableProperties';
import { ElementVisible } from '../../modules/consts/Elements';

const PricingTableModel = {
  title: 'Pricing Table',
  image: '/img/elements/pricing-table.webp',
  helpPage: '/documentation/elements/pricing-table',
  category: 'Basic',
  component: 'PricingTableElement',
  propertyComponent: PricingTableProperties,
  hasChildElements: false,
  generalProperties: {
    hasVisibility: true,
    hasTextAlign: true,
    hasTextColor: true,
    hasBackground: false,
  },
  defaults: {
    demo: true,
    _id: null,
    identifier: 'New Pricing Table',
    component: 'PricingTableElement',
    componentProperties: {
      items: [{
        itemId: 42,
        identifier: 'Standard',
        html: '<h3>Standard</h3><h1><b>49</b> <small>€</small></h1><p>Annual Payment</p><ul><li>Access to Everything</li><li>Personal Support</li><li>Commercial Projects</li><li>Lorem Ipsum dolor</li><li>..</li></ul>',
        buttonText: 'Subscribe',
        buttonBackground: 'primary',
        buttonOutline: false,
        buttonColor: '#f1f1f1',
        buttonSize: 100,
        buttonWidth: 0,
        linkType: '',
        link: '',
        isNofollow: false,
        page: '',
        linkTarget: '_self',
        backgroundType: 'color',
        backgroundColor: '#ffffff',
        backgroundGradientColor: 'f1f1f1',
        backgroundGradientDegree: 0,
        backgroundPattern: 'pattern1.webp',
      }],
    },
    generalProperties: {
      visibility: [ElementVisible.sm, ElementVisible.md, ElementVisible.lg, ElementVisible.xl],
      published: true,
      margin: [0, 0, 0, 0],
      padding: [25, 30, 25, 30],
      hover: 5,
      hoverType: 'shadow',
    },
  },
  itemTemplate: {
    itemId: 0,
    identifier: 'New Pricing Table',
    html: '<h3>New</h3><h1><b>99</b> <small>€</small></h1><p>Annual Payment</p><ul><li>Access to Everything</li><li>Personal Support</li><li>Commercial Projects</li><li>Lorem Ipsum dolor</li><li>..</li></ul>',
    buttonText: 'Subscribe',
    buttonBackground: 'primary',
    buttonOutline: false,
    buttonColor: '#f1f1f1',
    buttonSize: 100,
    buttonWidth: 0,
    linkType: '',
    link: '',
    page: '',
    linkTarget: '_self',
    isNofollow: false,
    backgroundType: 'color',
    backgroundColor: '#ffffff',
    backgroundGradientColor: 'f1f1f1',
    backgroundGradientDegree: 0,
    backgroundPattern: 'pattern1.webp',
  },
};

export default PricingTableModel;
