import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import elementHelper from '../../modules/elementHelper';
import { ElementColorClasses } from '../../modules/consts/Elements';
import ElementDiv from '../ElementControls/ElementDiv';
import { isPreviewSelector } from '../../store/selectors';
import ElementLink from '../ElementControls/ElementLink';

const MenuElement = props => {
  const element = props.element;

  const id = elementHelper.getId(element);
  let classes = elementHelper.getClasses(element, 'mame-menu');
  const style = elementHelper.getStyle(element);

  const {componentProperties} = element;
  const isVertical = componentProperties.style === 'vertical';

  useEffect(() => {
    window.setupMenus();
  }, [componentProperties]);

  const ulStyle = {
    '--list': componentProperties.listStyle,
    '--p': isVertical || componentProperties.listStyle === 'none' ? 0 : '0 28px',
  };

  if (isVertical) {
    ulStyle['--m'] = 0;
  }

  let ulClasses = '';

  const CaptionTag = `${componentProperties.captionSize || 'h4'}`;

  const handleOnClick = (e) => {
    if (!props.isPreview) {
      e.preventDefault();
    }
  };

  let mobileMenu = null;

  if (componentProperties.generateMobileMenu) {
    ulClasses += ' hidden-sm';

    mobileMenu = (
      <nav className="mobile-menu hidden-md hidden-lg hidden-xl" style={{'--p': '10px'}}
           aria-label={fbt('Mobile Menu', 'MenuElement')}>
        <span className="mobile-menu__button icon icon-align-justify" style={{'--size': '150%'}}/>
        <div className="mobile-menu__overlay" style={{
          '--pos': 'fixed',
          '--top': '0',
          '--left': '0',
          '--bg': 'rgba(255,255,255,0.9)',
          '--w': '100%',
          '--h': '100vh',
          '--z': '999999',
          '--ta': 'left',
          'display': 'none',
        }}
        >
          <div className="mobile-menu__close"
               style={{
                 '--pos': 'fixed',
                 '--top': '5px',
                 '--right': '10px',
                 '--cur': 'pointer',
               }} title={fbt('Close', 'MenuElement')} role="button">
            <span className="icon icon-close" aria-hidden/>
          </div>

          <ul>
            {componentProperties.items.map((item, index) => {
              const icon = item.icon
                ? (<span className={`icon icon-${item.icon}`} style={{'--mx': '7px'}} aria-hidden/>)
                : '';

              return (
                <li className="mobile-menu__entry"
                    key={item.itemId || index}
                    style={{'--py': '8px'}}>
                  <ElementLink
                    linkType={item.linkType}
                    link={item.link}
                    target={item.linkTarget}
                    page={item.page}
                    isNofollow={item.isNofollow}
                    onClick={handleOnClick}
                  >
                    {icon}
                    {item.title}
                  </ElementLink>
                </li>
              );
            })}
          </ul>
        </div>
      </nav>
    );
  }

  return (
    <ElementDiv {...props} id={id} style={style} classes={classes} tag="nav" role={null}>
      {componentProperties.caption && <CaptionTag>{componentProperties.caption}</CaptionTag>}
      <ul style={ulStyle} className={ulClasses}>
        {componentProperties.items.map((item, index) => {
          const itemStyle = {};

          if (isVertical) {
            itemStyle['display'] = 'inline-block';
          }

          if (componentProperties.spaceBetween && isVertical) {
            if (index === 0) {
              itemStyle['--mr'] = componentProperties.spaceBetween + 'px';
            } else {
              itemStyle['--mx'] = componentProperties.spaceBetween + 'px';
            }
          }

          if (componentProperties.spaceBetween && !isVertical) {
            if (index === 0) {
              itemStyle['--mb'] = componentProperties.spaceBetween + 'px';
            } else {
              itemStyle['--my'] = componentProperties.spaceBetween + 'px';
            }
          }

          const linkStyle = {};

          if (componentProperties.linkColor) {
            if (ElementColorClasses.includes(componentProperties.linkColor)) {
              linkStyle['--c'] = `var(--${componentProperties.linkColor})`;
            } else {
              linkStyle['--c'] = componentProperties.linkColor;
            }
          }

          const classes = [];

          if (item.displayAs === 'button') {
            classes.push('button');

            if (item.buttonColor) {
              classes.push(item.buttonColor);
            }

            if (item.outline) {
              classes.push('outline');
              linkStyle['--c'] = item.buttonColor.background !== 'white' ? 'var(--element-bg)' : 'var(--form-text)';
            }
          }

          const icon = item.icon
            ? (<span className={`icon icon-${item.icon}`} style={{'--mx': '7px'}} aria-hidden/>)
            : '';

          if (!item.linkType) {
            return (
              <React.Fragment key={index}>
                <li style={itemStyle}>
                  <span
                    style={linkStyle}
                    className={classes.join(' ')}>
                    {icon}
                    {item.title}
                  </span>
                </li>
                {componentProperties.spacer && (
                  <span className="menu-spacer" style={{'--lcd': 'none'}}>{componentProperties.spacer}</span>
                )}
              </React.Fragment>
            );
          }

          return (
            <React.Fragment key={index}>
              <li style={itemStyle}>
                <ElementLink
                  linkType={item.linkType}
                  link={item.link}
                  target={item.linkTarget}
                  page={item.page}
                  className={classes.join(' ')}
                  style={linkStyle}
                  isNofollow={item.isNofollow}
                  onClick={handleOnClick}
                >
                  {icon}
                  {item.title}
                </ElementLink>
              </li>
              {componentProperties.spacer && <span className="menu-spacer">{componentProperties.spacer}</span>}
            </React.Fragment>
          );
        })}
      </ul>

      {mobileMenu}
    </ElementDiv>
  );
};

const mapStateToProps = state => ({
  isPreview: isPreviewSelector(state),
});

export default connect(mapStateToProps)(MenuElement);
