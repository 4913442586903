import React from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import Dialog from './Dialog';

const ShortcutModal = props => {
  if (!props.isVisible) {
    // Make sure to reset it
    return null;
  }

  return (
    <Dialog title={fbt('Shortcuts', 'ShortcutModal')} name="ShortcutModal">
      <div className="modal-content" style={{'--radius': '0 0 5px 5px', '--p': '20px'}}>
        <h4>
          <fbt desc="ShortcutModal">
            General Shortcuts
          </fbt>
        </h4>
        <div style={{'--d': 'flex', '--ai': 'center'}}>
          <div className="tag mame-primary">
            Esc
          </div>
          <div>
            <fbt desc="ShortcutModal">
              Closes Dialogs and cancels operations
            </fbt>
          </div>
        </div>

        <h4 style={{'--mt': '20px'}}>
          <fbt desc="ShortcutModal">
            Shortcuts in the Builder
          </fbt>
        </h4>

        <div style={{'--d': 'flex', '--ai': 'center'}}>
          <div className="tag mame-primary">
            <fbt desc="ShortcutModal">
              Ctrl
            </fbt>
          </div>
          <div className="tag mame-primary">
            <fbt desc="ShortcutModal">
              Alt
            </fbt>
          </div>
          <div className="tag mame-primary">
            P
          </div>
          <div>
            <fbt desc="ShortcutModal">
              Adds a new page to the open site
            </fbt>
          </div>
        </div>

        <div style={{'--d': 'flex', '--ai': 'center'}}>
          <div className="tag mame-primary">
            <fbt desc="ShortcutModal">
              Ctrl
            </fbt>
          </div>
          <div className="tag mame-primary">
            <fbt desc="ShortcutModal">
              Alt
            </fbt>
          </div>
          <div className="tag mame-primary">
            S
          </div>
          <div>
            <fbt desc="ShortcutModal">
              Adds a new section to the open page
            </fbt>
          </div>
        </div>
      </div>
    </Dialog>
  );
};


const mapStateToProps = state => ({
  isVisible: state.modals.ShortcutModal,
  payload: state.modals.payload.ShortcutModal,
});

export default connect(mapStateToProps)(ShortcutModal);
