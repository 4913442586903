import React from 'react';
import fbt from 'fbt';

import ColorPicker from './ColorPicker';
import GenericSlider from './GenericSlider';
import InputGroup from '../InputGroup';

const GradientGenerator = props => {
  return (
    <>
      <InputGroup style={{'--mt': '10px'}}>
        <ColorPicker title={fbt('Base Color', 'GradientGenerator')}
                     value={props.colorValue}
                     action={props.colorAction}
                     showLabel
        />
      </InputGroup>
      <InputGroup>
        <ColorPicker title={fbt('Gradient Color', 'GradientGenerator')}
                     value={props.gradientColor}
                     action={props.gradientColorAction}
                     showLabel
        />
      </InputGroup>
      <InputGroup>
        <GenericSlider title={fbt('Gradient Degree', 'GradientGenerator')}
                       value={props.gradientDegree}
                       action={props.gradientDegreeAction}
                       min="0" max="360"
                       showLabel
        />
      </InputGroup>
    </>
  );
};

export default GradientGenerator;
