import React from 'react';
import HtmlEditor from '../Editor/HtmlEditor';

const TextElement = props => {
  // Just a wrapper component.. (stupid actually)

  return (
    <HtmlEditor
      {...props}
    />
  )
}

export default TextElement;
