// Builder
export const SET_ACTIVE_ELEMENT = 'SET_ACTIVE_ELEMENT';
export const SET_ACTIVE_SECTION = 'SET_ACTIVE_SECTION';
export const SET_ACTIVE_TYPE = 'SET_ACTIVE_TYPE';
export const CLOSE_ACTIVE_PROPERTY = 'CLOSE_ACTIVE_PROPERTY';
export const SET_PREVIEW = 'SET_PREVIEW';
export const SET_RENDER = 'SET_RENDER';
export const SET_API_VERSION = 'SET_API_VERSION';
export const SET_SIDEBAR_VIEW = 'SET_SIDEBAR_VIEW';
export const SET_CLIPBOARD_SECTION_STYLE = 'SET_CLIPBOARD_SECTION_STYLE';
export const SET_CLIPBOARD_ELEMENT_STYLE = 'SET_CLIPBOARD_ELEMENT_STYLE';

// Modals
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDE_LOADING = 'HIDE_LOADING';

// Site
export const SET_ACTIVE_SITE = 'SET_SITE';
export const SET_ACTIVE_PAGE = 'SET_ACTIVE_PAGE';
export const SET_ACTIVE_SITE_PAGES = 'SET_ACTIVE_SITE_PAGES';
export const SET_ACTIVE_SITE_SECTIONS = 'SET_ACTIVE_SITE_SECTIONS';
export const SET_ACTIVE_SITE_ELEMENTS = 'SET_ACTIVE_SITE_ELEMENTS';
export const UPDATE_SITE_PROPERTY = 'UPDATE_SITE_PROPERTY';
export const SET_ACTIVE_PAGE_CANONICAL = 'SET_ACTIVE_PAGE_CANONICAL';
export const SET_ACTIVE_PAGE_TITLE = 'SET_ACTIVE_PAGE_TITLE';
export const SET_PRESET_STYLES = 'SET_PRESET_STYLES';

// Activities
export const SET_ACTIVE_SITE_ACTIVITIES = 'SET_ACTIVE_SITE_ACTIVITIES';
export const ADD_ACTIVE_SITE_ACTIVITIES = 'ADD_ACTIVE_SITE_ACTIVITIES';

// Pages
export const ADD_PAGE = 'ADD_PAGE';
export const UPDATE_PAGE_PROPERTY = 'UPDATE_PAGE_PROPERTY';
export const REMOVE_PAGE = 'REMOVE_PAGE';

// Section
export const ADD_SECTION = 'ADD_SECTION';
export const UPDATE_SECTION_PROPERTY = 'UPDATE_SECTION_PROPERTY';
export const UPDATE_SECTION_PROPERTIES = 'UPDATE_SECTION_PROPERTIES';
export const REMOVE_SECTION = 'REMOVE_SECTION';

// Element
export const UPDATE_ELEMENT_HTML = 'UPDATE_ELEMENT_HTML';
export const UPDATE_ELEMENT_PROPERTY = 'UPDATE_ELEMENT_PROPERTY';
export const UPDATE_ELEMENT_PROPERTIES = 'UPDATE_ELEMENT_PROPERTIES';
export const ADD_ELEMENT = 'ADD_ELEMENT';
export const REMOVE_ELEMENT = 'REMOVE_ELEMENT';
export const MOVE_ELEMENT = 'MOVE_ELEMENT';
export const UPDATE_ELEMENT_ITEM_PROPERTY = 'UPDATE_ELEMENT_ITEM_PROPERTY';

// User
export const SET_USER = 'SET_USER';
export const SET_TOKEN = 'SET_TOKEN';
export const LOGOUT_USER = 'LOGOUT_USER';
export const UPDATE_USER_PROPERTY = 'UPDATE_USER_PROPERTY';

// Sites
export const SET_SITES = 'SET_SITES';
export const ADD_SITE = 'ADD_SITE';
export const REMOVE_SITE = 'REMOVE_SITE';

// Templates
export const SET_SECTION_TEMPLATES = 'SET_SECTION_TEMPLATES';
export const ADD_SECTION_TEMPLATE = 'ADD_SECTION_TEMPLATE';
export const UPDATE_SECTION_TEMPLATE = 'UPDATE_SECTION_TEMPLATE';
export const REMOVE_SECTION_TEMPLATE = 'REMOVE_SECTION_TEMPLATE';

export const SET_PAGE_TEMPLATES = 'SET_PAGE_TEMPLATES';
export const ADD_PAGE_TEMPLATE = 'ADD_PAGE_TEMPLATE';
export const UPDATE_PAGE_TEMPLATE = 'UPDATE_PAGE_TEMPLATE';
export const REMOVE_PAGE_TEMPLATE = 'REMOVE_PAGE_TEMPLATE';

export const SET_SITE_TEMPLATES = 'SET_SITE_TEMPLATES';
export const ADD_SITE_TEMPLATE = 'ADD_SITE_TEMPLATE';
export const UPDATE_SITE_TEMPLATE = 'UPDATE_SITE_TEMPLATE';
export const REMOVE_SITE_TEMPLATE = 'REMOVE_SITE_TEMPLATE';

// Media
export const SET_SITE_MEDIA = 'SET_SITE_MEDIA';

// Settings
export const SET_SETTINGS = 'SET_SETTINGS';
export const SET_SETTING = 'SET_SETTING';

// Notifications
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';

// Viewer Context
export const SET_LOCALE = 'SET_LOCALE';

// Sessions
export const SET_SESSIONS = 'SET_SESSIONS';
export const REMOVE_SESSION = 'REMOVE_SESSION';
