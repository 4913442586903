import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import elementHelper from '../../modules/elementHelper';
import ElementDiv from '../ElementControls/ElementDiv';

const QuizElement = props => {
  const {element, isRender} = props;
  const {componentProperties} = element;
  const {items} = componentProperties;

  useEffect(() => {
    if (isRender) {
      return;
    }

    window.setupQuiz();
  }, [element, isRender]);

  const id = elementHelper.getId(element);
  const classes = elementHelper.getClasses(element, 'mame-quiz');
  const style = elementHelper.getStyle(element);

  return (
    <ElementDiv {...props} id={id} classes={classes} style={style}>
      {items.map((item, index) => {
        const itemStyle = {
          '--ta': 'center',
        };

        if (index) {
          itemStyle['display'] = 'none';
        }

        return (
          <div className="quiz__question"
               key={index}
               style={itemStyle}
               data-index={index}
               data-kind={item.type}
               data-answer={item.answerText || null}
          >
            <h3 className="quiz__question-title">{item.title}</h3>

            {item.type !== 'text' && item.answers.map((answer, index) => {
              return (
                <div className="quiz__answer" key={index}>
                  <button className={`quiz__answer-${item.type === 'single' ? 'button' : 'option'} white`}
                          style={{'--w': '100%'}}
                          data-is-correct={answer.isCorrect || null}
                  >
                    {answer.content}
                  </button>
                </div>
              );
            })}

            {item.type === 'text' && (
              <div className="quiz__answer">
                <label>{componentProperties.textInputLabel}</label>
                <input type="text" style={{'--w': '100%'}} className="quiz__answer-input"/>
                <div className="quiz__answer-text" />
              </div>
            )}

            <div style={{'--ta': 'right'}}>
              <button className="quiz__previous white" style={{'display': 'none'}}>
                {componentProperties.prevLabel}
              </button>

              <button className="quiz__next primary">
                {componentProperties.nextLabel}
              </button>

              {item.type !== 'single' && (
                <button className="quiz__answer-button primary">
                  {componentProperties.answerButtonLabel}
                </button>
              )}
            </div>

          </div>
        );
      })}

      <div className="quiz__summary" style={{'display': 'none', '--ta': 'center'}}>
        <h3>{componentProperties.resultText}</h3>
        <div className="quiz__summary-score" />

        <button className="primary quiz__summary-again">
          {componentProperties.againButtonLabel}
        </button>
      </div>
    </ElementDiv>
  );
};

const mapStateToProps = state => ({
  isRender: state.builder.isRender,
});

export default connect(mapStateToProps)(QuizElement);
