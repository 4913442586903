import React from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import Fieldset from '../FieldSet';
import SelectInput from '../Controls/SelectInput';
import TextInput from '../Controls/TextInput';
import HelpText from '../Controls/HelpText';
import InputGroup from '../InputGroup';

const DisqusProperties = props => {
  const {element} = props;
  const {componentProperties} = element;

  return (
    <>
      <Fieldset title={fbt('Disqus Properties', 'DisqusProperties')} isOpen>
        <HelpText style={{'--mt': '10px'}}>
          <fbt desc="DisqusProperties">
            Disqus is a global comment system you can use to allow comments on your website. Register at
            <a href="https://disqus.com" rel="noreferrer noopener">disqus.com</a> and create a new site in Disqus.
            Please use the Element only once per page.
          </fbt>
        </HelpText>

        <InputGroup>
          <TextInput title={fbt('Disqus Site Shortname', 'DisqusProperties')}
                     tooltip={fbt('Important: Set the shortname, e.g. the EXAMPLE part in the Disqus URL https://EXAMPLE.disqus.com', 'DisqusProperties')}
                     value={componentProperties.shortName}
                     blur={val => props.handlePropertyChange('shortName', val)}
                     showLabel
                     required
          />
        </InputGroup>

        <InputGroup>
          <SelectInput id="disqus-select-page-identifier-type"
                       title={fbt('Page Identifier', 'DisqusProperties')}
                       value={componentProperties.pageIdentifierType || ''}
                       action={val => {
                         props.handlePropertyChange('pageIdentifierType', val);
                       }}
                       options={{
                         '': fbt('Auto', 'DisqusProperties'),
                         'custom': fbt('Custom', 'DisqusProperties'),
                       }}
                       showLabel
          />
        </InputGroup>

        {componentProperties.pageIdentifierType === 'custom' && (
          <InputGroup>
            <TextInput title={fbt('Unique Page Identifier', 'DisqusProperties')}
                       value={componentProperties.pageIdentifier}
                       action={val => props.handlePropertyChange('pageIdentifier', val)}
                       showLabel
            />
          </InputGroup>
        )}

        <InputGroup>
          <SelectInput id="disqus-select-page-url-type"
                       title={fbt('Page URL', 'DisqusProperties')}
                       value={componentProperties.pageUrlType || ''}
                       action={val => {
                         props.handlePropertyChange('pageUrlType', val);
                       }}
                       options={{
                         '': fbt('Auto', 'DisqusProperties'),
                         'custom': fbt('Custom', 'DisqusProperties'),
                       }}
                       showLabel
          />
        </InputGroup>

        {componentProperties.pageUrlType === 'custom' && (
          <InputGroup>
            <TextInput title={fbt('Custom Page URL', 'DisqusProperties')}
                       value={componentProperties.pageUrl}
                       action={val => props.handlePropertyChange('pageUrl', val)}
                       showLabel
            />
          </InputGroup>
        )}
      </Fieldset>
    </>
  );
};

export default connect()(DisqusProperties);
