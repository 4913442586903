import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import Dialog from './Dialog';
import ComingSoon from '../General/ComingSoon';
import ImageUpload from '../Properties/Controls/ImageUpload';
import { apiLoadMedia } from '../../store/actions/media';
import propertyHelper from '../../modules/propertyHelper';
import ImagePreviewFrame from '../General/ImagePreviewFrame';
import TextInput from '../Properties/Controls/TextInput';
import { hideModal } from '../../store/actions/modals';
import logger from '../../modules/logger';
import FourColumnGrid from '../General/FourColumnGrid';


const MediaManagerModal = props => {
  const [selectedIndex, setSelectedIndex] = useState(props.payload ? props.payload.selectedIndex : 0);
  const [mediaSearch, setMediaSearch] = useState('');
  const [filteredMedia, setFilteredMedia] = useState(props.media);
  const [imageUrl, setImageUrl] = useState('');

  const {isVisible, site, media, dispatch, payload} = props;

  useEffect(() => {
    if (!isVisible || !site) {
      return;
    }

    dispatch(apiLoadMedia(site._id));
  }, [isVisible, site, dispatch]);

  useEffect(() => {
    setFilteredMedia(media);
  }, [media]);

  useEffect(() => {
    setImageUrl('');

    if (!payload || !payload.image) {
      return;
    }

    if (payload.image.startsWith('http')) {
      setSelectedIndex(3);
      setImageUrl(payload.image);
    }
  }, [payload]);


  if (!isVisible) {
    return null;
  }

  const mediaPath = `uploads/images/${site._id}/`;

  const handleUpload = (file, data = null) => {
    logger.debug('Uploading file', file);

    if (!props.payload.action) {
      logger.warn('Action for file upload is missing aborting');
      return;
    }

    payload.action(file, data);
    dispatch(hideModal('MediaManagerModal'));
  };

  const searchMedia = (term) => {
    setMediaSearch(term);

    if (!term) {
      setFilteredMedia(media);
      return;
    }

    setFilteredMedia(media.filter(m => m.toLowerCase().includes(term.toLowerCase())));
  };

  return (
    <Dialog title={fbt('Media Manager', 'MediaManagerModal')} name="MediaManagerModal" size="modal-xl">
      <Tabs forceRenderTabPanel
            selectedIndex={selectedIndex}
            onSelect={index => setSelectedIndex(index)}
      >
        <TabList>
          <Tab>
            <span className="icon icon-cloud-upload" style={{'--mr': '10px'}}/>
            <fbt desc="MediaManagerModal">
              Upload
            </fbt>
          </Tab>
          <Tab>
            <span className="icon icon-books" style={{'--mr': '10px'}}/>
            <fbt desc="MediaManagerModal">
              Your Images
            </fbt>
          </Tab>
          <Tab>
            <span className="icon icon-unsplash" style={{'--mr': '10px'}}/>
            <fbt desc="MediaManagerModal">
              Unsplash
            </fbt>
          </Tab>
          <Tab>
            <span className="icon icon-link" style={{'--mr': '10px'}}/>
            <fbt desc="MediaManagerModal">
              By URL
            </fbt>
          </Tab>
        </TabList>
        <div className="modal-content" style={{'--radius': '0 0 5px 5px', '--p': '20px 10px'}}>
          <TabPanel>
            <div style={{'--d': 'grid', '--gtc': 'auto', '--ai': 'center', '--jc': 'center'}}>
              <div style={{'--w': '500px'}}>
                <p>
                  <fbt desc="MediaManagerModal">
                    Only select images that you have confirmed you can use in your intended context according to the
                    license details.
                    <a href="https://mame.app/media-licensing.html" rel="noreferrer noopener" target="_blank">More
                      information</a>.
                  </fbt>
                </p>

                <ImageUpload
                  action={handleUpload}
                  createThumbnail={props.payload.createThumbnail || false}
                />
              </div>
            </div>
          </TabPanel>

          <TabPanel>
            <div style={{'--bgc': 'var(--mame-white)', '--p': '0 5px 10px'}}>
              <input type="search"
                     value={mediaSearch}
                     placeholder={fbt('Search Images...', 'MediaManagerModal')}
                     style={{'--m': 0}}
                     onChange={e => searchMedia(e.target.value)}
              />
            </div>
            <FourColumnGrid>
              {filteredMedia.map((item, index) => {
                const imagePath = mediaPath + item;

                return (
                  <div key={index}>
                    <ImagePreviewFrame action={() => {
                      handleUpload(imagePath);
                    }}>
                      <img src={propertyHelper.getImagePath(imagePath)} alt={item}/>
                    </ImagePreviewFrame>
                    {item}
                  </div>
                );
              })}
            </FourColumnGrid>
          </TabPanel>

          <TabPanel>
            <ComingSoon/>
          </TabPanel>

          <TabPanel>
            <div style={{'--d': 'grid', '--gtc': 'auto', '--ai': 'center', '--jc': 'center'}}>
              <div style={{'--w': '500px'}}>
                <TextInput title={fbt('URL of the image..', 'MediaManagerModal')}
                           id="input-media-url"
                           value={imageUrl}
                           action={val => {
                             setImageUrl(val);
                           }}
                           handleReturn={() => handleUpload(imageUrl)}
                />

                <div>
                  <small>
                    <fbt desc="MediaManagerModal">
                      Only select images that you have confirmed you can use in your intended context according to the
                      license details.
                      <a href="https://mame.app/media-licensing.html" rel="noreferrer noopener" target="_blank">More
                        information</a>.
                    </fbt>
                  </small>
                </div>

                <button id="btn-media-url-image"
                        className="mame-primary"
                        onClick={() => handleUpload(imageUrl)}>
                  <fbt desc="MediaManagerModal">
                    Use Image
                  </fbt>
                </button>
              </div>
            </div>
          </TabPanel>

        </div>
      </Tabs>

    </Dialog>
  );
};

const mapStateToProps = state => ({
  site: state.activeSite.site,
  media: state.activeSite.media,
  isVisible: state.modals.MediaManagerModal,
  payload: state.modals.payload.MediaManagerModal,
});

export default connect(mapStateToProps)(MediaManagerModal);
