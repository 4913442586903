import React, { useCallback, useState, useEffect } from 'react';
import { DragTypes } from '../../modules/DragTypes';

const DnDContainer = props => {
  const [items, setItems] = useState(props.items);
  const [isSortingUpdating, setSortingUpdated] = useState(false);

  const {dragHandler = false} = props;
  const {group = 'dnd'} = props;
  const {dragType = DragTypes.ELEMENT} = props;

  useEffect(() => {
    setItems(props.items);
  }, [props.items]);

  const moveItem = useCallback(
    (dragIndex, dropIndex) => {
      const draggedElement = items[dragIndex];
      const copy = [...items];

      copy.splice(dragIndex, 1);
      copy.splice(dropIndex, 0, draggedElement);

      setItems(copy);
      setSortingUpdated(true);

      // Emitter
      if (props.onMove) {
        props.onMove(copy);
      }
    },
    [items, props]
  );

  const moveEnd = (index, dropResult) => {
    if (!isSortingUpdating) {
      return;
    }

    if (props.onEnd) {
      props.onEnd(items);
    }

    setSortingUpdated(false);
  };

  const childrenWithProps = React.Children.map(props.children, child => {
    const addProps = {
      moveItem,
      moveEnd,
      group,
      dragType,
      dragHandler,
    };

    return React.cloneElement(child, addProps);
  });

  return (
    <React.Fragment>
      {childrenWithProps}
    </React.Fragment>
  );
};

export default DnDContainer;
