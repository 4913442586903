import React from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import Fieldset from '../FieldSet';
import LinkGroup from '../Controls/LinkGroup';
import BackgroundPicker from '../Controls/BackgroundPicker';
import HelpText from '../Controls/HelpText';
import ImageComponentProperties from '../Fieldsets/ImageComponentProperties';

const ImageFigureProperties = props => {
  const {element} = props;
  const {componentProperties} = element;

  return (
    <div>
      <Fieldset title={fbt('Image Figure Properties', 'ImageFigureProperties')}
                id="fieldset-image-figure"
                isOpen>
        <HelpText style={{'--m': '10px 0'}}>
          <fbt desc="ImageFigureProperties">
            Add Text and other elements directly on the element...
          </fbt>
        </HelpText>

        <ImageComponentProperties componentProperties={componentProperties}
                                  handlePropertyChange={props.handlePropertyChange}
        />

        <BackgroundPicker title={fbt('Background', 'ImageFigureProperties')}
                          showLabel
                          hasNone
                          hideFieldset
                          hideVideo
                          type={componentProperties.backgroundType}
                          image={componentProperties.backgroundImage}
                          color={componentProperties.backgroundColor}
                          gradientColor={componentProperties.backgroundGradientColor}
                          gradientDegree={componentProperties.backgroundGradientDegree}
                          pattern={componentProperties.backgroundPattern}
                          typeAction={(val) => {
                            props.handlePropertyChange('backgroundType', val);
                          }}
                          colorAction={(val) => {
                            props.handlePropertyChange('backgroundColor', val);
                          }}
                          imageAction={(val) => {
                            props.handlePropertyChange('backgroundImage', val);
                          }}
                          gradientColorAction={(val) => {
                            props.handlePropertyChange('backgroundGradientColor', val);
                          }}
                          gradientDegreeAction={(val) => {
                            props.handlePropertyChange('backgroundGradientDegree', val);
                          }}
                          patternAction={(val) => {
                            props.handlePropertyChange('backgroundPattern', val);
                          }}
        />

        <LinkGroup
          linkTypeId="image-figure-link-type"
          linkType={componentProperties.linkType}
          actionLinkType={val => props.handlePropertyChange('linkType', val)}
          link={componentProperties.link}
          actionLink={val => props.handlePropertyChange('link', val)}
          page={componentProperties.page}
          actionPage={val => props.handlePropertyChange('page', val)}
          linkTarget={componentProperties.linkTarget}
          actionLinkTarget={val => props.handlePropertyChange('linkTarget', val)}
          isNofollow={componentProperties.isNofollow}
          actionNofollow={val => props.handlePropertyChange('isNofollow', val)}
        />

      </Fieldset>
    </div>
  );
};

export default connect()(ImageFigureProperties);

