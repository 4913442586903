import React from 'react';
import TextInput from './TextInput';

/**
 * @deprecated Use TextInput instead
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const LinkInput = (props) => {
  return (
    <TextInput type="url" placeholder="https://.." {...props} />
  );
};

export default LinkInput;
