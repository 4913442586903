import React from 'react';

const ThreeColumnGrid = props => {
  // Maybe make some of these props
  return (
    <div
      style={{
        '--d': 'grid',
        '--gtc': '1fr 1fr 1fr',
        '--gg': '15px',
        '--jc': 'space-between',
        '--p': '10px 5px',
        '--ta': 'center',
      }}>
      {props.children}
    </div>
  );
};

export default ThreeColumnGrid;
