import serviceHelper from '../../modules/serviceHelper';
import {
  ADD_ACTIVE_SITE_ACTIVITIES,
  ADD_ELEMENT,
  ADD_PAGE,
  ADD_SECTION,
  MOVE_ELEMENT,
  REMOVE_ELEMENT,
  REMOVE_PAGE,
  REMOVE_SECTION,
  SET_ACTIVE_SITE,
  SET_ACTIVE_SITE_ACTIVITIES,
  SET_ACTIVE_SITE_ELEMENTS,
  SET_ACTIVE_SITE_PAGES,
  SET_ACTIVE_SITE_SECTIONS,
  UPDATE_ELEMENT_HTML,
  UPDATE_ELEMENT_PROPERTY,
  UPDATE_ELEMENT_ITEM_PROPERTY,
  UPDATE_ELEMENT_PROPERTIES,
  UPDATE_SECTION_PROPERTY,
  UPDATE_SECTION_PROPERTIES,
  UPDATE_SITE_PROPERTY,
} from '../actionTypes';
import { toast } from 'react-toastify';
import { hideLoading, showLoading } from './modals';
import { sectionToTemplate } from './templates';
import logger from '../../modules/logger';

// API
/*
 * Storing
 */
export const apiStoreElement = (element, options) => (
  async dispatch => {
    const service = serviceHelper.getService('ElementService');

    const res = await service.store(element);

    if (!res || !res.data) {
      return;
    }

    const item = res.data.item;

    if (!element._id) {
      dispatch(addElement(item, options));
    }

    if (element.addElements && element.addElements.length) {
      for (const child of element.addElements) {
        child.element = item._id;
        child.site = element.site;

        const result = await service.store(child);

        dispatch(addElement(result.data.item, {toType: 'element', to: item._id}));
      }
    }
  }
);

export const apiStoreSection = section => (
  async dispatch => {
    const service = serviceHelper.getService('SectionService');

    // We have to wait for the _id
    const res = await service.store(section);

    const item = res.data.item;

    // Add it to the rendering store (Else we have to reload the site)
    if (!section._id) {
      dispatch(addSection(item));
    }
  }
);

export const apiStoreSite = site => (
  async dispatch => {
    const service = serviceHelper.getService('SiteService');

    const res = await service.store(site);

    if (!site._id) {
      dispatch(setActiveSite(res.data.item));
    }
  }
);

export const apiAddTemplatePage = (template, siteId, updateAlias = true) => (
  async dispatch => {
    dispatch(showLoading());
    const service = serviceHelper.getService('PageService');

    const newPage = {
      ...template,
      fromTemplateId: template._id,
      _id: null,
      site: siteId,
    };

    if (updateAlias) {
      newPage.alias = 'new-page';
    }

    const res = await service.store(newPage);
    const page = res.data.item;

    await dispatch(addPage(page));

    for (const section of template.allSections) {
      await dispatch(apiAddTemplateSection(section, siteId, page._id));
    }

    dispatch(hideLoading());
    toast.info('New Page added. Make sure to adjust the alias!');
  }
);

export const apiAddTemplateSection = (template, siteId, pageId, after = null) => (
  async dispatch => {
    const service = serviceHelper.getService('SectionService');
    const elementService = serviceHelper.getService('ElementService');

    // Store section, elements and save
    const newSection = {
      ...template,
      fromTemplateId: template._id,
      _id: null,
      elements: [],
      site: siteId,
      page: pageId,
      after,
    };

    const res = await service.store(newSection);
    const section = res.data.item;

    // Store the section in the store
    dispatch(addSection(section, pageId, after));

    // Store Section Elements
    const recursiveElements = async (index, to, toType = 'section', tab = 0) => {
      const element = template.allElements.find(a => a.index === index);
      const copy = {...element, site: siteId, elements: []};

      if (toType === 'section') {
        copy.section = to;
      } else if (toType === 'element') {
        copy.element = to;
      } else if (toType === 'item') {
        copy.createOptions = {
          to: to,
          toType: 'item',
          index: tab,
        };
      } else {
        logger.warn('NOT IMPLEMENTED', to, toType);
        throw Error(`Adding ${toType} is not implemented`);
      }

      // Store it, we need the id for the next elements
      const result = await elementService.store(copy);
      const newElement = result.data.item;

      dispatch(addElement(newElement, {to, toType, index: tab}));

      if (element.elementElements) {
        for (const child of element.elementElements) {
          await recursiveElements(child, newElement._id, 'element');
        }
      }

      if (element.elementItems) {
        for (const child of element.elementItems) {
          await recursiveElements(child.element, newElement._id, 'item', child.tab);
        }
      }
    };

    for (let index of template.sectionElements) {
      await recursiveElements(index, section._id, 'section');
    }
  }
);

export const apiUpdatePublishedSite = site => (
  async dispatch => {
    const service = serviceHelper.getService('SiteService');

    const res = await service.updatePublished(site._id);

    const item = res.data.item;

    dispatch(setActiveSite(item));
  }
);

export const apiCaptureSection = section => (
  async () => {
    const service = serviceHelper.getService('CaptureService');

    const sectionId = section._id;
    const htmlId = section.generalProperties.customId || `s-${section._id}`;

    service.takeSectionScreenshot(section.site, sectionId, htmlId);
    // toast('Screenshot is being updated (This may take some seconds).');
  }
);

export const apiMoveElement = (elementId, options) => (
  async () => {
    // Faster with own endpoint instead of storing two things
    const elementService = serviceHelper.getService('ElementService');

    await elementService.move(elementId, options);
  }
);

/*
 * Duplication
 */
export const apiDuplicateElement = (element, options) => (
  async (dispatch, getState) => {
    const service = serviceHelper.getService('ElementService');

    // const duplicateElement = {
    //   ...element,
    //   _id: null,
    //   __v: null,
    //   elements: [],
    //   createOptions: options,
    // };

    // const duplicateElement = Object.assign({}, element, {
    //   _id: null,
    //   __v: null,
    //   elements: [],
    //   createOptions: options,
    // })

    // Stupid frozen hack, Object.assign / Spread Function copies not deep enough
    const duplicateElement = JSON.parse(JSON.stringify(element));

    duplicateElement._id = null;
    duplicateElement.__v = null;
    duplicateElement.elements = [];
    duplicateElement.createOptions = options;

    if (duplicateElement.componentProperties.items
      && duplicateElement.componentProperties.items.length
      && duplicateElement.componentProperties.items[0].hasOwnProperty('elements')
    ) {
      duplicateElement.componentProperties.items.forEach(item => {
        item.elements = [];
      });
    }

    if (options.toType === 'section') {
      duplicateElement.section = options.to;
    } else if (options.toType === 'element') {
      duplicateElement.element = options.to;
    }

    const res = await service.store(duplicateElement);
    const parentId = res.data.item._id;

    dispatch(addElement(res.data.item, options));

    const recursiveElements = async (elementId, to, toType = 'element', index = -1) => {
      const target = getState().activeSite.elements.find(e => e._id === elementId);
      const elOptions = {to: to, toType: toType, index};

      const copy = {
        ...target,
        _id: null,
        __v: null,
        site: getState().activeSite.site._id,
        createOptions: elOptions,
        elements: [],
      };

      if (toType === 'element') {
        copy.element = to;
      } else if (toType === 'item') {
        // Options are already set
      } else {
        logger.warn('NOT IMPLEMENTED', to, toType);
        throw Error(`Duplicate ${toType} is not implemented`);
      }

      const res = await service.store(copy);
      const item = res.data.item;

      dispatch(addElement(item, elOptions));

      for (const child of target.elements) {
        await recursiveElements(child, item._id, 'element');
      }
    };

    for (const subId of element.elements) {
      await recursiveElements(subId, parentId, 'element');
    }

    // Items.. like in Tabs, slideshows :-/
    if (duplicateElement.componentProperties.items
      && duplicateElement.componentProperties.items.length
      && duplicateElement.componentProperties.items[0].hasOwnProperty('elements')
    ) {
      let index = 0;

      for (const subItem of element.componentProperties.items) {
        for (const subId of subItem.elements) {
          await recursiveElements(subId, parentId, 'item', index);
        }

        index++;
      }
    }
  }
);

export const apiDuplicateSection = (section, siteId, pageId) => (
  async (dispatch, getState) => {
    dispatch(showLoading());
    const template = sectionToTemplate(getState().activeSite.elements, section);
    await dispatch(apiAddTemplateSection(template, siteId, pageId));
    dispatch(hideLoading());
    toast.info('Section duplicated.');
  }
);

/*
 * Deleting
 */

export const apiRemoveElement = elementId => (
  async () => {
    const service = serviceHelper.getService('ElementService');

    // We have to wait for the _id
    await service.delete(elementId);
  }
);


export const apiRemoveSection = (sectionId, pageId = '') => (
  async () => {
    /**
     * @type {SectionService}
     */
    const service = serviceHelper.getService('SectionService');

    // We have to wait for the _id
    await service.delete(sectionId, pageId);
  }
);

export const apiRemovePage = (pageId) => (
  async () => {
    const service = serviceHelper.getService('PageService');

    // We have to wait for the _id
    await service.delete(pageId);
  }
);

/*
 * Loading / GET
 */
export const apiLoadSitePages = (siteId) => (
  async dispatch => {
    const service = serviceHelper.getService('PageService');

    const res = await service.get({siteId});

    await dispatch(setActiveSitePages(res.data.items));
  }
);

export const apiLoadSiteSections = (siteId) => (
  async dispatch => {
    const siteService = serviceHelper.getService('SectionService');

    const res = await siteService.get({siteId});

    await dispatch(setActiveSiteSections(res.data.items));
  }
);

export const apiLoadSiteElements = siteId => (
  async dispatch => {
    const service = serviceHelper.getService('ElementService');

    const res = await service.get({siteId});

    await dispatch(setActiveSiteElements(res.data.items));
  }
);

export const apiLoadLastSiteActivities = (siteId, offset = 0, limit = 25) => (
  async dispatch => {
    const service = serviceHelper.getService('ActivityService');

    const res = await service.getSiteActivities(siteId, offset, limit);

    await dispatch(setActiveSiteActivities(res.data.items));
  }
);

/* Normal non-async functions */
export const setActiveSite = site => ({type: SET_ACTIVE_SITE, payload: {site}});
export const setActiveSitePages = pages => ({type: SET_ACTIVE_SITE_PAGES, payload: {pages}});
export const setActiveSiteSections = sections => ({type: SET_ACTIVE_SITE_SECTIONS, payload: {sections}});
export const setActiveSiteElements = elements => ({type: SET_ACTIVE_SITE_ELEMENTS, payload: {elements}});

// Activities
export const setActiveSiteActivities = activities => ({type: SET_ACTIVE_SITE_ACTIVITIES, payload: {activities}});
export const addActiveSiteActivities = activities => ({type: ADD_ACTIVE_SITE_ACTIVITIES, payload: {activities}});

// Site
export const updateSiteProperty = (category, key, value, subKey) => ({
  type: UPDATE_SITE_PROPERTY,
  payload: {category, key, value, subKey},
});

// Pages
export const addPage = page => ({type: ADD_PAGE, payload: {page}});
export const removePage = pageId => ({type: REMOVE_PAGE, payload: {pageId}});

// Sections
export const addSection = (section, pageId = null, after = null) => ({
  type: ADD_SECTION,
  payload: {section, pageId, after}
});
export const removeSection = (sectionId, pageId = null) => ({type: REMOVE_SECTION, payload: {sectionId, pageId}});

export const updateSectionProperty = (section, category, key, value, index) => ({
  type: UPDATE_SECTION_PROPERTY,
  payload: {section, category, key, value, index},
});

export const updateSectionProperties = (section, category, properties) => ({
  type: UPDATE_SECTION_PROPERTIES,
  payload: {section, category, properties},
});

// Elements
export const updateElementHtml = (element, html) => ({type: UPDATE_ELEMENT_HTML, payload: {element, html}});
export const updateElementProperty = (element, category, key, value, index) => ({
  type: UPDATE_ELEMENT_PROPERTY,
  payload: {element, category, key, value, index}
});
export const updateElementItemProperty = (element, index, key, value) => ({
  type: UPDATE_ELEMENT_ITEM_PROPERTY,
  payload: {element, index, key, value},
});

export const updateElementProperties = (element, properties) => ({
  type: UPDATE_ELEMENT_PROPERTIES,
  payload: {element, properties},
});

export const addElement = (element, options) => ({type: ADD_ELEMENT, payload: {element, options}});
export const removeElement = (elementId, options = {}) => ({type: REMOVE_ELEMENT, payload: {elementId, options}});

export const moveElement = (elementId, options) => ({type: MOVE_ELEMENT, payload: {elementId, options}});
