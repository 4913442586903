import React, { useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import TopMenu from '../components/Menu/TopMenu';
import DnDContainer from '../components/Sortable/DnDContainer';
import DnDItem from '../components/Sortable/DnDItem';
import { showLoading } from '../store/actions/modals';
import SharedModals from '../components/Modals/SharedModals';
import logger from '../modules/logger';

const Debug = props => {
  logger.debug('Accessing the Debug page. (Just some content for the log file)');

  const [items, setItems] = useState([
    {
      _id: '1',
      text: 'Item 1',
    },
    {
      _id: '2',
      text: 'Item 2',
    },
    {
      _id: '3',
      text: 'Item 3',
    }
  ]);

  const triggerToast = (type = 'default') => {
    if (type === 'default') {
      toast('Hello World, this is a nice message. Please Subscribe!');
    }

    if (type === 'info') {
      toast.info('A info message.');
    }

    if (type === 'success') {
      toast.success('A success message.');
    }

    if (type === 'warning') {
      toast.warning('A warning message.');
    }

    if (type === 'link') {
      toast(<>A message with <a href="https://mame.app">Link</a>. Please Subscribe!</>);
    }
  };

  // const completeLog = logger.completeLog.join('<br />');
  return (
    <>
      <TopMenu/>
      <div className="wrapper" style={{'--mt': '20px', '--gutter': '3vw'}}>
        <h3>Oh snap you found the top secret page ;)</h3>

        <h4>Buttons</h4>
        <button>Default</button>
        <button className="mame-primary">Mame Primary</button>
        <button className="blue">Blue</button>
        <button className="green">Green</button>
        <button className="orange">Orange</button>
        <button className="grey">Grey</button>
        <button className="dark-grey">Dark Grey</button>
        <button className="black">Black</button>
        <button className="transparent">Transparent</button>

        <hr/>

        <h4>Toast tests</h4>

        <button onClick={() => {
          triggerToast('default');
        }}>Normal Toast
        </button>
        <button onClick={() => {
          triggerToast('info');
        }}>Info Toast
        </button>
        <button onClick={() => {
          triggerToast('success');
        }}>Success Toast
        </button>
        <button onClick={() => {
          triggerToast('warning');
        }}>Warning Toast
        </button>

        <button onClick={() => {
          triggerToast('link');
        }}>Link Toast
        </button>

        <hr/>
        <h4>Loading Tests</h4>

        <button onClick={() => {
          props.dispatch(showLoading());
        }}>Show Loading
        </button>

        <hr/>

        <h4>DND Vertical Tests</h4>
        <DnDContainer
          items={items}
          onMove={newSorting => {
            setItems(newSorting);
            logger.warn('[DEBUG] Moved', newSorting);
          }}
          onEnd={newSorting => {
            logger.warn('[DEBUG] End', newSorting);
            // setItems(newSorting);
          }}
        >
          {items.map((item, index) => {
            return (
              <DnDItem key={item._id} id={item._id} index={index}>
                <TestRenderer item={item}/>
              </DnDItem>
            );
          })}
        </DnDContainer>

        <h4>DND Horizontal Tests</h4>
        <div style={{
          '--d': 'grid',
          '--gtc': '1fr 1fr 1fr',
          '--gg': '10px',
        }}>
          <DnDContainer
            items={items}
            onMove={newSorting => {
              setItems(newSorting);
              logger.warn('[DEBUG] Moved', newSorting);
            }}
            onEnd={newSorting => {
              logger.warn('[DEBUG] End', newSorting);
              // setItems(newSorting);
            }}
          >
            {items.map((item, index) => {
              return (
                <DnDItem key={item._id} id={item._id} index={index}>
                  <TestRenderer item={item}/>
                </DnDItem>
              );
            })}
          </DnDContainer>
        </div>

        <hr/>

        <div>
          <h3>App Log</h3>
          {logger.completeLog.map((item, index) => {
            return (
              <div key={index}>
                {item.date.toString()}
                -
                {item.messages.join(' ')}
              </div>
            );
          })}
        </div>
      </div>
      <SharedModals/>
    </>
  );
};

const TestRenderer = props => {
  return (
    <div ref={props.dndDragRef} style={{'border': '1px solid blue', '--my': '5px', '--h': '100px'}}>
      {props.item.text}
    </div>
  );
};


export default connect()(Debug);
