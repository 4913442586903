import IconFigureProperties from '../../components/Properties/Elements/IconFigureProperties';
import { ElementVisible } from '../../modules/consts/Elements';

const IconFigureModel = {
  title: 'Icon Figure',
  category: 'Media',
  helpPage: '/documentation/elements/icon-figure',
  component: 'IconFigureElement',
  propertyComponent: IconFigureProperties,
  hasChildElements: true,
  generalProperties: {
    hasVisibility: true,
    hasTextAlign: true,
    hasTextColor: true,
    // In the Item
    hasBackground: false,
  },
  defaults: {
    demo: true,
    _id: null,
    identifier: 'New Icon Figure',
    component: 'IconFigureElement',
    componentProperties: {
      icon: 'paperplane',
      iconTitle: '',
      iconColor: 'primary',
      iconSize: 600,
      linkType: '',
      page: '',
      link: '',
      linkTarget: '_self',
      isNofollow: false,
      backgroundType: 'color',
      backgroundColor: '#ffffff',
      backgroundGradientColor: 'f1f1f1',
      backgroundGradientDegree: 0,
      backgroundPattern: 'pattern1.webp',
    },
    generalProperties: {
      visibility: [ElementVisible.sm, ElementVisible.md, ElementVisible.lg, ElementVisible.xl],
      published: true,
      margin: [0, 0, 0, 0],
      padding: [15, 15, 15, 15],
    },
    elements: [],
  },
};

export default IconFigureModel;
