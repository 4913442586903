import React, { useEffect, useState } from 'react';
import fbt from 'fbt';

import logger from '../../../modules/logger';
import ActionIconButton from '../../General/ActionIconButton';

const TextInput = props => {
  const [value, setValue] = useState(props.value === undefined ? '' : props.value);
  const [inValid, setInvalid] = useState(false);

  let classes = props.className || '';

  let inputStyle = props.inputStyle || {};

  if (props.required) {
    classes = classes ? classes += ' required' : 'required';
    inputStyle = inValid
      ? {'--b': '1px solid var(--mame-warning)'} :
      {'--b': '1px solid var(--mame-border)'};
  }

  useEffect(() => {
    if (props.value === undefined && (props.action || props.blur)) {
      logger.debug('field', props.title.toString(), 'needs a value');

      if (props.type === 'number') {
        setValue(0);
      } else {
        setValue('');
      }
    } else {
      setValue(props.value);
    }

    setInvalid(false);

    if (props.required && !props.value) {
      setInvalid(true);
    }
  }, [props.value, props.required, props.action, props.blur, props.title, props.type]);

  const handleChange = e => {
    let val = e.target.value;

    if (props.filter) {
      const re = new RegExp(props.filter);

      val = val.replace(re, '');
    }

    if (props.type === 'number') {
      val = parseFloat(val);

      // Fix NaN
      if (!val) {
        val = 0;
      }
    }

    // Simple Validation
    if (props.required && !val) {
      setInvalid(true);
    } else {
      setInvalid(false);
    }

    setValue(val);

    if (props.action) {
      props.action(val);
    }
  };

  const blur = () => {
    if (props.blur && props.value !== value) {
      props.blur(value);
    }
  };

  const handleReturn = e => {
    if (e.key === 'Enter' && props.onReturnKey) {
      e.preventDefault();
      e.stopPropagation();
      props.onReturnKey(value);
      return;
    }

    if (e.key === 'Enter' && props.blur) {
      props.blur(value);
    }
  };

  return (
    <label style={props.style || {'--d': 'block', '--pos': 'relative'}}>
      {props.showLabel && props.title}
      {!props.showLabel && props.title && <span className="sr-only">{props.title}</span>}
      <input id={props.id || null}
             type={props.type || 'text'}
             disabled={props.disabled || null}
             placeholder={props.placeholder || props.title || null}
             title={props.tooltip || (props.showLabel ? null : props.title) || null}
             className={classes || null}
             autoComplete={props.autoComplete || null}
             value={value}
             required={props.required || null}
             style={inputStyle}
             pattern={props.pattern || null}
             minLength={props.minLength || null}
             onMouseDown={props.handleMouseDown}
             onBlur={blur}
             onKeyDown={handleReturn}
             step={props.step || null}
             min={props.min || null}
             max={props.max || null}
             onChange={handleChange}
      />
      {props.removeAction && (
        <ActionIconButton icon="trash-o"
                          action={props.removeAction}
                          title={fbt('Remove', 'TextInput')}
                          style={{'--pos': 'absolute', '--right': '10px', '--top': '31px'}}
        />
      )}
    </label>
  );
};

export default TextInput;
