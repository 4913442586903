import ImageProperties from '../../components/Properties/Elements/ImageProperties';
import { ElementVisible } from '../../modules/consts/Elements';

const ImageModel = {
  title: 'Image',
  category: 'Media',
  helpPage: '/documentation/elements/image',
  component: 'ImageElement',
  propertyComponent: ImageProperties,
  hasChildElements: false,
  generalProperties: {
    hasVisibility: true,
    hasTextAlign: true,
    hasTextColor: false,
    hasBackground: true,
    hideFont: true,
  },
  defaults: {
    demo: true,
    _id: null,
    identifier: 'New Image',
    component: 'ImageElement',
    componentProperties: {
      image: '/img/mame.webp',
      alt: 'Mame Builder',
      lazy: true,
      autoDimensions: true,
      filterType: '',
      filterValue: 0,
      linkType: '',
      page: '',
      link: '',
      linkTarget: '_self',
      isNofollow: false,
    },
    generalProperties: {
      visibility: [ElementVisible.sm, ElementVisible.md, ElementVisible.lg, ElementVisible.xl],
      published: true,
      margin: [0, 0, 0, 0],
      padding: [0, 0, 0, 0],
      textAlign: 'center',
      display: 'inline-block',
    },
  },
};

export default ImageModel;
