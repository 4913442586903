import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';
import { formatRelative } from 'date-fns';

import userHelper from '../../modules/userHelper';
import { apiLoadLastSiteActivities } from '../../store/actions/activeSite';
import { siteIdSelector } from '../../store/selectors';

const Activities = props => {
  const {dispatch, user, activities, siteId} = props;

  const avatar = userHelper.getUserAvatarUrl(user);
  const username = user.username;

  useEffect(() => {
    if (!activities || !activities.length) {
      dispatch(apiLoadLastSiteActivities(siteId));
    }
  }, [activities, dispatch, siteId]);

  if (!props.activities.length) {
    return (
      <div>
        <fbt desc="Activities Side Bar">
          No activities.
        </fbt>
      </div>
    );
  }

  return (
    <div style={{'--p': '10px 15px'}}>
      {props.activities.map(item => {
        return (
          <div key={item._id} style={{'--d': 'grid', '--gtc': '60px 1fr', '--ai': 'center', '--jc': 'center'}}>
            <div>
              <img src={avatar} alt={username} style={{'--maxw': '50px'}}/>
            </div>
            <div>
              <div>
                {username} {item.verb} {item.target_type}
              </div>
              <div>
                <small>
                  {formatRelative(new Date(item.created_at), new Date())}
                </small>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const mapStateToProps = state => ({
  siteId: siteIdSelector(state),
  activities: state.activeSite.activities,
  user: state.user.user,
});

export default connect(mapStateToProps)(Activities);
