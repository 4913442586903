import React from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import elementHelper from '../../modules/elementHelper';
import ElementDiv from '../ElementControls/ElementDiv';
import { setActiveElement } from '../../store/actions/builder';
import DropZone from '../Sortable/DropZone';
import ElementErrorRenderer from '../ElementControls/ElementErrorRenderer';
import componentHelper from '../../modules/componentHelper';
import { showModal } from '../../store/actions/modals';
import { isRenderSelector } from '../../store/selectors';
import ActionIcon from '../General/ActionIcon';

const ColumnElement = props => {
  const element = props.element;
  const {componentProperties} = element;

  const id = elementHelper.getId(element);
  const classes = elementHelper.getClasses(element, 'mame-columns');
  const style = elementHelper.getStyle(element);

  style['--cc'] = componentProperties.columns;
  style['--cg'] = componentProperties.gap + 'px';

  if (componentProperties.columnsSm) {
    style['--cc-sm'] = componentProperties.columnsSm;
  }

  if (componentProperties.columnsMd) {
    style['--cc-md'] = componentProperties.columnsMd;
  }

  if (componentProperties.columnsLg) {
    style['--cc-lg'] = componentProperties.columnsLg;
  }

  if (componentProperties.columnsXl) {
    style['--cc-xl'] = componentProperties.columnsXl;
  }

  const setActive = e => {
    // Make sure to only trigger grid
    if (!e.target.classList.contains('mame-columns')) {
      return;
    }

    props.dispatch(setActiveElement(element));
  };

  if (!element.elements || !element.elements.length) {
    return (
      <ElementDiv {...props}
                  id={id}
                  classes="mame-column-control"
                  controlClass="mame-element-control-left"
                  role="grid"
                  action={setActive}>
        <DropZone element={element}
                  items={element.elements}>
          <ColumnEmptyRenderer
            classes={classes}
            style={style}
            dispatch={props.dispatch}
            element={element}
            isRender={props.isRender}
          />
        </DropZone>
      </ElementDiv>
    );
  }

  return (
    <ElementDiv {...props} id={id} classes="mame-column-control"
                controlClass="mame-element-control-left"
                role="grid"
                action={setActive}>
      <DropZone element={element}
                items={element.elements}>
        <ColumnRender
          classes={classes}
          style={style}
          element={element}
          elements={props.elements}
          dispatch={props.dispatch}
        />
      </DropZone>
    </ElementDiv>
  );
};

const ColumnEmptyRenderer = props => {
  if (props.isRender) {
    return null;
  }

  const showAddElement = e => {
    e.preventDefault();
    const options = {to: props.element._id, toType: 'element'};
    props.dispatch(showModal('AddElementModal', options));
  };

  const style = {
    ...props.style,
    '--p': '10px',
  };

  let classes = props.classes;

  if (props.isOverCurrent) {
    style['--bgc'] = 'var(--grid)';
    classes += ' mame-dropzone';
  }

  style['--ai'] = 'stretch';

  return (
    <>
      <div className={classes} style={style} ref={props.dropRef} role="row">
        <div className="mame-grid-visible fake-link" onClick={showAddElement} role="gridcell"
             style={{'--p': '32px 0'}}>
          <fbt desc="Column Element">
            Empty Columns (No Elements)
          </fbt>
        </div>
        <div className="mame-grid-visible" onClick={showAddElement} role="gridcell">
          <ActionIcon icon="plus" size="400" style={{'--c': 'var(--mame-icon)'}}/>
        </div>

        {props.isOverCurrent && (
          <div style={{'--d': 'grid', '--ai': 'center', '--jc': 'center', '--h': '100px'}} role="gridcell">
            <fbt desc="Column Element">
              Move Element to Columns
            </fbt>
          </div>
        )}
      </div>

    </>
  );
};


const ColumnRender = props => {
  const style = {
    ...props.style
  };

  let classes = props.classes;

  if (props.isOverCurrent) {
    style['--bgc'] = 'var(--grid)';
    classes += ' mame-dropzone';
  }

  return (
    <div className={classes} style={style} ref={props.dropRef} role="row">
      {props.sortingItems.map((elementId, index) => {
        const subElement = props.elements.find(e => e._id === elementId);

        if (!subElement) {
          return (
            <ElementErrorRenderer key={elementId}
                                  id={elementId}
                                  parentType="element"
                                  parentId={props.element._id}
            />
          );
        }

        return componentHelper.getElementComponent(
          subElement,
          {
            parent: props.element,
            parentType: 'element',
            index,
            resortElement: props.resortOverwrite,
            resortEnd: props.endOverwrite,
            role: 'gridcell',
          }
        );
      })}

      {props.isOverCurrent && (
        <div style={{'--d': 'grid', '--ai': 'center', '--jc': 'center', '--h': '100px'}} role="gridcell">
          <fbt desc="Column Element">
            Move Element to Columns
          </fbt>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  isRender: isRenderSelector(state),
  elements: state.activeSite.elements,
});


export default connect(mapStateToProps)(ColumnElement);
