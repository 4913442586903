import React from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import { removeSection } from '../../store/actions/activeSite';

const SectionErrorRenderer = props => {
  const remove = (e) => {
    e.preventDefault();
    e.stopPropagation();

    props.dispatch(removeSection(props.id, props.page._id));
  };

  return (
    <div id={`s-${props.id}`}>
      <fbt desc="SiteProperties">
        Oops, Section not found! <button onClick={remove}>Remove Section</button>
      </fbt>
    </div>
  );
};

const mapStateToProps = state => ({
  page: state.activeSite.page,
});

export default connect(mapStateToProps)(SectionErrorRenderer);
