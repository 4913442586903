import React from 'react';
import fbt from 'fbt';

import SelectInput from './SelectInput';
import TextInput from './TextInput';
import HelpText from './HelpText';
import InputGroup from '../InputGroup';

const FontSelect = props => {
  const options = {
    '': fbt('None (inherit)', 'FontSelect'),
    'Arial': 'Arial',
    'Courier': 'Courier',
    'Helvetica': 'Helvetica',
    'Roboto': 'Roboto (Google Font)',
    'Open Sans': 'Open Sans (Google Font)',
    'Lato': 'Lato (Google Font)',
    'Source Sans Pro': 'Source Sans Pro (Google Font)',
    'Inter': 'Inter (Google Font)',
    'Merriweather': 'Merriweather (Google Font)',
  };

  if (props.hasCustom) {
    options['custom'] = fbt('Custom', 'FontSelect');
  }

  return (
    <>
      <InputGroup>
        <SelectInput title={props.title}
                     value={props.value}
                     action={val => props.action(val)}
                     options={options}
                     showLabel
        />
      </InputGroup>

      {props.value === 'custom' && (
        <>
          <HelpText style={{'--mb': '10px'}}>
            <fbt desc="FontSelect">
              Set the font family property manually. If you want to use a web font (like Google Fonts) just add the URL
              of the stylesheet.
            </fbt>
          </HelpText>

          <InputGroup>
            <TextInput title={fbt('Font Family', 'FontSelect')}
                       placeholder="'Open Sans', sans-serif"
                       value={props.family}
                       action={props.familyAction}
                       showLabel
            />
          </InputGroup>

          <InputGroup>
            <TextInput type="url"
                       title={fbt('Font Stylesheet URL (Optional)', 'FontSelect')}
                       placeholder="https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap"
                       value={props.url}
                       action={val => props.urlAction(val)}
                       showLabel
            />
          </InputGroup>
        </>
      )}
    </>
  );
};

export default FontSelect;
