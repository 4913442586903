import BaseService from './BaseService';

const paths = {
  get: '/pages',
  post: '/pages',
  patch: '/pages',
  delete: '/pages',
};

/**
 * Service for querying the Sites
 */
class PageService extends BaseService {
  constructor() {
    super(paths);
    this.requireLogin = true;
  }
}

export default new PageService();
