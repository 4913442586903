import React from 'react';
import fbt from 'fbt';

import Fieldset from '../FieldSet';
import TextInput from '../Controls/TextInput';
import CheckboxInput from '../Controls/CheckboxInput';
import SelectInput from '../Controls/SelectInput';
import HelpText from '../Controls/HelpText';
import MediaPicker from '../Controls/MediaPicker';
import InputGroup from '../InputGroup';

const VideoProperties = props => {
  const {element} = props;
  const {componentProperties} = element;

  return (
    <Fieldset title={fbt('Video Properties', 'VideoProperties')} isOpen>
      <InputGroup style={{'--mt': '10px'}}>
        <TextInput title={fbt('Video URL', 'VideoProperties')}
                   value={componentProperties.url}
                   action={(val) => props.handlePropertyChange('url', val)}
                   showLabel
        />
      </InputGroup>

      <InputGroup>
        <CheckboxInput checked={componentProperties.loop}
                       label={fbt('Play in loop', 'VideoProperties')}
                       action={() => props.handlePropertyChange('loop', !componentProperties.loop)}
        />
      </InputGroup>

      <InputGroup>
        <CheckboxInput checked={componentProperties.autoPlay}
                       label={fbt('Autoplay', 'VideoProperties')}
                       action={() => props.handlePropertyChange('autoPlay', !componentProperties.autoPlay)}
        />
      </InputGroup>

      <InputGroup>
        <CheckboxInput checked={componentProperties.controls}
                       label={fbt('Show Controls', 'VideoProperties')}
                       action={() => props.handlePropertyChange('controls', !componentProperties.controls)}
        />
      </InputGroup>

      <InputGroup>
        <CheckboxInput checked={componentProperties.muted}
                       label={fbt('Muted', 'VideoProperties')}
                       action={() => props.handlePropertyChange('muted', !componentProperties.muted)}
        />
      </InputGroup>

      <MediaPicker image={componentProperties.poster}
                   title={fbt('Preview Image (Poster)', 'VideoProperties')}
                   action={val => props.handlePropertyChange('poster', val)}
                   showLabel
      />

      <InputGroup>
        <SelectInput title={fbt('Preload Video', 'VideoProperties')}
                     value={componentProperties.preload} showLabel
                     action={val => props.handlePropertyChange('preload', val)}
                     options={{
                       'auto': fbt('Auto', 'ImageOverlayProperties'),
                       'metadata': fbt('Metadata', 'ImageOverlayProperties'),
                       'none': fbt('None', 'ImageOverlayProperties'),
                     }}
        />

        <HelpText>
          <fbt desc="VideoProperties">
            If you use preload none, make sure to disable autoplay and show controls. You may also want to add a preview
            image.
          </fbt>
        </HelpText>
      </InputGroup>
    </Fieldset>
  );
};


export default VideoProperties;
