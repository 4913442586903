import React from 'react';
import fbt from 'fbt';

import Fieldset from '../FieldSet';
import CodeInput from '../Controls/CodeInput';
import HelpText from '../Controls/HelpText';

const CustomizationFieldset = props => {
  const customCode = props.customCode;

  return (
    <Fieldset title={fbt('Custom CSS and JavaScript', 'CustomizationFieldset')} isOpen={props.isOpen || false}>
      <HelpText style={{'--m': '10px 0'}}>
        {props.helpText || fbt('Here you may set global custom CSS and JavaScript, which is appended to every page. Be careful as this may break your site.', 'CustomizationFieldset')}
      </HelpText>

      <CodeInput title={fbt('Custom CSS', 'CustomizationFieldset')}
                 language="css"
                 placeholder=".mame-rocks { text-decoration: underline; }"
                 value={customCode.css}
                 action={val => props.action('css', val)}
                 showLabel
      />
      <CodeInput title={fbt('Custom JavaScript', 'CustomizationFieldset')}
                 language="javascript"
                 placeholder="alert('Easy XSS Injection');"
                 value={customCode.javascript}
                 action={val => props.action('javascript', val)}
                 showLabel
      />
    </Fieldset>
  );
};

export default CustomizationFieldset;
