import { connect } from 'react-redux';
import Loading from '../General/Loading';
import { hideLoading } from '../../store/actions/modals';

const LoadingModal = props => {
  if (!props.isVisible) {
    // Make sure to reset it
    return null;
  }

  return (
    <Loading onClick={() => {
      props.dispatch(hideLoading());
    }}/>
  );
};

const mapStateToProps = state => ({
  isVisible: state.modals.LoadingModal,
});

export default connect(mapStateToProps)(LoadingModal);
