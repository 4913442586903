import React from 'react';
import { connect } from 'react-redux';

const HelpText = props => {
  const style = {
    ...props.style,
    '--size': '90%',
    '--op': '0.7',
  }

  if (!props.settings.showTooltips) {
    return null;
  }

  return (
    <p style={style}>
      {props.children}
    </p>
  );
};

const mapStateToProps = state => ({
  settings: state.settings,
});

export default connect(mapStateToProps)(HelpText);
