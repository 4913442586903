import React from 'react';
import fbt from 'fbt';

import Fieldset from '../FieldSet';
import SelectInput from '../Controls/SelectInput';
import TextInput from '../Controls/TextInput';
import HelpText from '../Controls/HelpText';
import InputGroup from '../InputGroup';

const PayPalDonateProperties = props => {
  const {element} = props;
  const {componentProperties} = element;

  return (
    <Fieldset title={fbt('PayPal Button Properties', 'PayPalDonateProperties')} isOpen>
      <InputGroup style={{'--mt': '10px'}}>
        <SelectInput title={fbt('Button Type', 'PayPalDonateProperties')}
                     id="select-paypal-button-type"
                     value={componentProperties.type}
                     action={val => props.handlePropertyChange('type', val)}
                     options={{
                       '_cart': fbt('Shopping Cart Button', 'PayPalDonateProperties'),
                       '_xclick': fbt('Buy Now Button', 'PayPalDonateProperties'),
                       '_donations': fbt('Donate Button', 'PayPalDonateProperties'),
                     }}
                     showLabel
        />
      </InputGroup>

      <InputGroup>
        <TextInput title={fbt('Recipient Email Address (Business)', 'PayPalDonateProperties')}
                   id="input-paypal-recipient" type="email"
                   value={componentProperties.recipient}
                   action={val => props.handlePropertyChange('recipient', val)}
                   showLabel
                   required
        />
      </InputGroup>

      <InputGroup>
        <TextInput title={fbt('Item Name', 'PayPalDonateProperties')}
                   placeholder="Hot Sauce-12oz. Bottle"
                   id="input-paypal-item-name"
                   value={componentProperties.itemName}
                   action={val => props.handlePropertyChange('itemName', val)}
                   showLabel
                   required
        />
      </InputGroup>

      <InputGroup>
        <TextInput title={fbt('Fixed Amount', 'PayPalDonateProperties')}
                   id="input-paypal-amount"
                   type="number"
                   value={componentProperties.fixedAmount}
                   action={val => props.handlePropertyChange('fixedAmount', val)}
                   showLabel
        />
        <HelpText>
          <fbt desc="PayPalDonateProperties">
            For donations you may want to keep the fixed amount zero, for all other button types
            you need to add one.
          </fbt>
        </HelpText>
      </InputGroup>

      <InputGroup>
        <TextInput title={fbt('Country Code', 'PayPalDonateProperties')} showLabel
                   id="input-paypal-country-code"
                   value={componentProperties.countryCode}
                   action={val => props.handlePropertyChange('countryCode', val)}
                   required
        />
      </InputGroup>

      <InputGroup>
        <TextInput title={fbt('Currency Code', 'PayPalDonateProperties')} showLabel
                   id="input-paypal-currency-code"
                   value={componentProperties.currencyCode}
                   action={val => props.handlePropertyChange('currencyCode', val)}
                   required
        />
      </InputGroup>
    </Fieldset>
  );
};

export default PayPalDonateProperties;
