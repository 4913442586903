import React, { useState } from 'react';
import Dialog from './Dialog';
import fbt from 'fbt';
import { connect } from 'react-redux';

import CheckboxInput from '../Properties/Controls/CheckboxInput';
import Alert from '../General/Alert';
import serviceHelper from '../../modules/serviceHelper';
import config from '../../config';

const PremiumModal = props => {
  const [view, setView] = useState('first');
  const [plan, setPlan] = useState('');

  if (!props.isVisible) {
    // Make sure to reset it
    return null;
  }

  const handleDialogClose = () => {
    setView('first');
  };

  const handleFirstSite = async planType => {
    setPlan(planType);

    const service = serviceHelper.getService('PremiumService');
    const result = await service.subscribeStripe(plan);

    // We need to redirect over the API for Stripe
    window.open(`${config.baseUrl}/v1/premium/stripe-redirect/${result.data.sessionId}`);

    // Show the success dialog
    setView('second');
  };

  return (
    <Dialog title={fbt('Get Mame Premium', 'PremiumModal')} name="PremiumModal" size="modal-xl"
            closeAction={handleDialogClose}>
      <div className="accent" style={{'--radius': '0 0 5px 5px', '--bg': '#f1f1f1'}}>
        {view === 'first' && <FirstPage {...props} action={handleFirstSite}/>}
        {view === 'second' && <SecondPage {...props}/>}
      </div>
    </Dialog>
  );
};

const SecondPage = props => {
  return (
    <div style={{'--ta': 'center'}}>
      <p>
        <fbt desc="PremiumModal">
          Please continue your subscription in the other browser tab or window.
        </fbt>
      </p>
      <p>
        <fbt desc="PremiumModal">
          Afterwards you can close this dialog.
        </fbt>
      </p>
    </div>
  );
};

const FirstPage = props => {
  const [acceptRefund, setAcceptRefund] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [plan, setPlan] = useState('pro_monthly');

  const handleSubscribeClick = e => {
    setHasError(false);
    e.preventDefault();

    if (!acceptRefund || !acceptTerms) {
      setHasError(true);
      return;
    }

    props.action(plan);
  };

  return (
    <div>
      <div style={{'--ta': 'center', '--pt': '20px'}}>
        <h4>
          <fbt desc="PremiumModal">
            Unlock all Features and Support Mame
          </fbt>
        </h4>
        <p>
          <fbt desc="PremiumModal">
            We offer a 30 day money back guarantee.
          </fbt>
        </p>
      </div>

      {hasError && (
        <Alert action={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setHasError(false);
        }}>
          <fbt desc="PremiumModal">
            Please accept the refund policy and terms of service.
          </fbt>
        </Alert>
      )}

      <div style={{
        '--d': 'grid',
        '--gg': '20px',
        '--gtc': '1fr 1fr 1fr 1fr',
        '--p': '20px 25px',
      }}>
        <figure className="mame-pricing-table accent"
                onClick={() => setPlan('standard_monthly')}
                style={{
                  '--p': '25px 30px',
                  '--levitate-hvr': '5',
                  '--cur': 'pointer',
                  '--shadow': plan === 'standard_monthly' ? '15' : '1',
                }}>
          <h3>Standard</h3>
          <div style={{'--h': '100px'}}>
            <h1>
              <small style={{'--mr': '10px'}}>$</small>
              <b>2.99</b>
            </h1>
          </div>
          <p>
            <fbt desc="PremiumModal">
              Billed <b>Monthly</b>
            </fbt>
          </p>
          <ul>
            <li>Access to Everything</li>
            <li>Basic Support</li>
            <li>Up to 8 Sites</li>
            <li>500 MB Quota each</li>
            <li>Deploy to AWS, DO..</li>
          </ul>

          <button className="mame-primary">
            <fbt desc="PremiumModal">
              Select
            </fbt>
          </button>
        </figure>

        <figure className="mame-pricing-table accent"
                onClick={() => setPlan('standard_annually')}
                style={{
                  '--p': '25px 30px',
                  '--levitate-hvr': '5',
                  '--cur': 'pointer',
                  '--shadow': plan === 'standard_annually' ? '15' : '1',
                }}>
          <h3>Standard</h3>
          <div style={{'--h': '100px'}}>
            <h1 style={{'--lh': '1', '--m': '10px 0 0 0 '}}>
              <small style={{'--mr': '10px'}}>$</small>
              <b>30.00</b>
            </h1>
            <div className="tag blue" style={{'--lh': '1.5', '--m': '5px 0 10px', '--w': '80px'}}>
              <fbt desc="PremiumModal">
                Save 20 %
              </fbt>
            </div>
          </div>
          <p>
            <fbt desc="PremiumModal">
              Billed <b>Annually</b>
            </fbt>
          </p>
          <ul>
            <li>Access to Everything</li>
            <li>Basic Support</li>
            <li>Up to 8 Sites</li>
            <li>500 MB Quota each</li>
            <li>Deploy to AWS, DO..</li>
          </ul>

          <button className="mame-primary">
            <fbt desc="PremiumModal">
              Select
            </fbt>
          </button>
        </figure>

        <figure className="mame-pricing-table accent gray"
                onClick={() => setPlan('pro_monthly')}
                style={{
                  '--bgc': '#f8f8f8',
                  '--grad': '207deg',
                  '--grad-color': '#9013fe',
                  '--p': '25px 30px',
                  '--levitate-hvr': '5',
                  '--cur': 'pointer',
                  '--shadow': plan === 'pro_monthly' ? '15' : '1',
                }}>
          <h3><b>Pro</b></h3>
          <div style={{'--h': '100px'}}>
            <h1>
              <small style={{'--mr': '10px'}}>$</small>
              <b>9.99</b>
            </h1>
          </div>
          <p>
            <fbt desc="PremiumModal">
              Billed <b>Monthly</b>
            </fbt>
          </p>
          <ul>
            <li>Access to Everything</li>
            <li>Premium Support</li>
            <li>Up to 30 Sites</li>
            <li>1000 MB Quota each</li>
            <li>Deploy to AWS, DO..</li>
          </ul>

          <button className="mame-primary">
            <fbt desc="PremiumModal">
              Select
            </fbt>
          </button>
        </figure>

        <figure className="mame-pricing-table accent gray"
                onClick={() => setPlan('pro_annually')}
                style={{
                  '--bgc': '#f8f8f8',
                  '--grad': '207deg',
                  '--grad-color': '#9013fe',
                  '--p': '25px 30px',
                  '--levitate-hvr': '5',
                  '--cur': 'pointer',
                  '--shadow': plan === 'pro_annually' ? '15' : '1',
                }}>
          <h3><b>Pro</b></h3>
          <div style={{'--h': '100px'}}>
            <h1 style={{'--lh': '1', '--m': '10px 0 0 0 '}}>
              <small style={{'--mr': '10px'}}>$</small>
              <b>99.99</b>
            </h1>
            <div className="tag blue" style={{'--lh': '1.5', '--m': '5px 0 10px', '--w': '80px'}}>
              <fbt desc="PremiumModal">
                Save 20 %
              </fbt>
            </div>
          </div>
          <p>
            <fbt desc="PremiumModal">
              Billed <b>Annually</b>
            </fbt>
          </p>
          <ul>
            <li>Access to Everything</li>
            <li>Premium Support</li>
            <li>Up to 30 Sites</li>
            <li>1000 MB Quota each</li>
            <li>Deploy to AWS, DO..</li>
          </ul>

          <button className="mame-primary">
            <fbt desc="PremiumModal">
              Select
            </fbt>
          </button>
        </figure>
      </div>
      <div style={{'--p': '10px 30px', '--d': 'grid', '--gtc': '1fr 1fr', '--ai': 'center'}}>
        <div>
          <CheckboxInput checked={acceptRefund}
                         action={() => setAcceptRefund(!acceptRefund)}>
            <fbt desc="PremiumModal">
              I accept the <a href="https://mame.app/refund.html" target="_blank" rel="noopener noreferrer">Refund
              Policy</a>
            </fbt>
          </CheckboxInput>

          <CheckboxInput checked={acceptTerms}
                         action={() => setAcceptTerms(!acceptTerms)}>
            <fbt desc="PremiumModal">
              I accept the <a href="https://mame.app/eula.html" target="_blank" rel="noopener noreferrer">Terms of
              Service</a> and <a href="https://mame.app/privacy-policy.html" target="_blank" rel="noopener noreferrer">Privacy
              Policy</a>
            </fbt>
          </CheckboxInput>
        </div>

        <div>
          <b>Pay with</b>
          <div>
            <img src="/img/stripe.webp" alt={fbt('Pay with Stripe', 'PremiumModal')} style={{'--h': '60px'}}/>
          </div>
        </div>

      </div>

      <div style={{'--p': '10px 30px'}}>
        <button className="mame-primary" onClick={handleSubscribeClick}>
          <fbt desc="PremiumModal">
            Start Mame Premium
          </fbt>
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  isVisible: state.modals.PremiumModal,
  payload: state.modals.payload.PremiumModal,
});

export default connect(mapStateToProps)(PremiumModal);
