import React from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import Fieldset from '../FieldSet';
import LinkGroup from '../Controls/LinkGroup';
import ImageComponentProperties from '../Fieldsets/ImageComponentProperties';

const ImageProperties = props => {
  const {element} = props;
  const {componentProperties} = element;

  return (
    <Fieldset title={fbt('Image Properties', 'ImageProperties')} isOpen>
      <ImageComponentProperties componentProperties={componentProperties}
                                handlePropertyChange={props.handlePropertyChange}
      />

      <LinkGroup
        linkTypeId="image-link-type"
        linkType={componentProperties.linkType}
        actionLinkType={val => props.handlePropertyChange('linkType', val)}
        link={componentProperties.link}
        actionLink={val => props.handlePropertyChange('link', val)}
        page={componentProperties.page}
        actionPage={val => props.handlePropertyChange('page', val)}
        linkTarget={componentProperties.linkTarget}
        actionLinkTarget={val => props.handlePropertyChange('linkTarget', val)}
        isNofollow={componentProperties.isNofollow}
        actionNofollow={val => props.handlePropertyChange('isNofollow', val)}
      />
    </Fieldset>
  );
};

export default connect()(ImageProperties);

