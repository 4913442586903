import FormProperties from '../../components/Properties/Elements/FormProperties';
import { ElementVisible } from '../../modules/consts/Elements';

const FormModel = {
  title: 'Form',
  image: '/img/elements/form.webp',
  category: 'Form',
  helpPage: '/documentation/elements/form',
  component: 'FormElement',
  propertyComponent: FormProperties,
  hasChildElements: true,
  setCategoryTab: 'Form',
  generalProperties: {
    hasVisibility: true,
    hasTextAlign: true,
    hasTextColor: true,
    hasBackground: true,
  },
  defaults: {
    demo: true,
    _id: null,
    identifier: 'New Form',
    component: 'FormElement',
    componentProperties: {
      title: 'Contact',
      titleTag: 'h3',
      submitText: 'Send',
      submitBackground: 'primary',
      submitColor: '#f1f1f1',
      submitOutline: false,
      successText: 'Thank you for your message.',
      action: 'email',
      // Custom
      customActionAddress: '',
      // API
      apiUrl: '',
      apiHeaders: '{}',
      apiAdditional: '{}',
      // Email
      emailAddress: '',
      bccAddress: '',
      replyTo: '{{email}}',
      subject: 'New message from {{name}}',
      emailTemplate: 'Hello,\n\nYou got a new message from {{name}}:\n\n{{form_data}}\n\nBest wishes,\nYour Mame Team'
    },
    generalProperties: {
      visibility: [ElementVisible.sm, ElementVisible.md, ElementVisible.lg, ElementVisible.xl],
      published: true,
      margin: [0, 0, 0, 0],
      padding: [25, 0, 25, 0],
    },
    elements: [],
    addElements: [
      {
        _id: null,
        identifier: 'Name Input',
        component: 'InputElement',
        componentProperties: {
          title: 'Your Name',
          showLabel: true,
          customName: 'name',
          inputType: '',
          defaultValue: '',
          placeholder: '',
          autoComplete: '',
          required: false,
          invalidMessage: '',
          pattern: '',
        },
        generalProperties: {
          visibility: [ElementVisible.sm, ElementVisible.md, ElementVisible.lg, ElementVisible.xl],
          published: true,
        },
      }
    ]
  },
};

export default FormModel;
