import BaseService from './BaseService';
import axios from 'axios';

const paths = {
  post: '/report',
  reportAppError: '/report/error',
};

/**
 * Service for querying the Sites
 */
class ReportService extends BaseService {
  constructor() {
    super(paths);
    this.requireLogin = true;
  }

  async reportAppError(data, hideModal = false) {
    const method = 'post';
    const url = this.getPath('reportAppError');

    return axios({
      method,
      url,
      data,
      hideModal,
    });
  }
}

export default new ReportService();
