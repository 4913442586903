import axios from 'axios';

import BaseService from './BaseService';

const paths = {
  get: '/activities',
  post: '/activities',
  patch: '/activities',
  delete: '/activities',
};

/**
 * Service for querying the Activities
 */
class ActivityService extends BaseService {
  constructor() {
    super(paths);
    this.requireLogin = true;
  }

  getSiteActivities = (siteId, offset = 0, limit = 50) => {
    const url = `/activities/site/${siteId}?offset=${offset}&limit=${limit}`;

    return axios.get(url);
  }
}

export default new ActivityService();
