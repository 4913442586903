import React from 'react';
import { connect } from 'react-redux';

import elementHelper from '../../modules/elementHelper';
import ElementDiv from '../ElementControls/ElementDiv';
import { ElementColorClasses } from '../../modules/consts/Elements';
import propertyHelper from '../../modules/propertyHelper';
import DropZone from '../Sortable/DropZone';
import FigureElementsRenderer from '../ElementControls/FigureElementsRenderer';
import { setActiveElement } from '../../store/actions/builder';
import ElementLink from '../ElementControls/ElementLink';

const IconFigureElement = props => {
  const element = props.element;
  const {componentProperties} = element;

  const id = elementHelper.getId(element);
  const classes = elementHelper.getClasses(element, 'accent mame-icon-figure');
  const style = elementHelper.getStyle(element);

  propertyHelper.setBackground(style, element.componentProperties);

  const setActive = (e) => {
    if (!e.target.classList.contains('mame-icon-figure')) {
      return;
    }

    props.dispatch(setActiveElement(element));
  };

  const size = componentProperties.iconSize || 600;
  const padding = `${size * 0.05 | 1}px 0`;

  const iconStyle = {
    '--size': size + '%',
    '--ta': 'center',
    '--p': padding,
  };

  let iconClasses = '';

  if (componentProperties.iconColor) {
    if (ElementColorClasses.includes(componentProperties.iconColor)) {
      iconClasses += ' ' + componentProperties.iconColor;

      // For now use the variables
      iconStyle['--c'] = `var(--${componentProperties.iconColor})`;
    } else {
      iconStyle['--c'] = componentProperties.iconColor;
    }
  }

  if (!componentProperties.backgroundType) {
    // Overwrite the default #fff one
    style['--bgc'] = 'none';
  }

  const icon = (
    <div className={`mame-icon-figure__icon icon icon-${componentProperties.icon} ${iconClasses}`}
         style={iconStyle}
         onClick={e => {
           e.preventDefault();
           props.dispatch(setActiveElement(element));
         }}
         title={componentProperties.iconTitle || null}
         aria-label={componentProperties.iconTitle || null}
         role="img"
    />
  );

  return (
    <ElementDiv {...props}
                id={id}
                classes={classes}
                tag="figure"
                action={setActive}
                style={style}>
      {componentProperties.linkType && (
        <ElementLink
          linkType={componentProperties.linkType}
          link={componentProperties.link}
          target={componentProperties.linkTarget}
          page={componentProperties.page}
          title={componentProperties.iconTitle || null}
          isNofollow={componentProperties.isNofollow}
          onClick={e => {
            e.preventDefault();
            props.dispatch(setActiveElement(element));
          }}
        >
          {icon}
        </ElementLink>
      )}

      {!componentProperties.linkType && icon}

      <DropZone element={element}
                items={element.elements}>
        <FigureElementsRenderer
          className="mame-icon-figure__body"
          tag="div"
          element={element}
        />
      </DropZone>
    </ElementDiv>
  );
};

export default connect()(IconFigureElement);
