import React from 'react';
import fbt from 'fbt';

import Fieldset from '../FieldSet';
import ColorPicker from './ColorPicker';
import GradientGenerator from './GradientGenerator';
import PatternPicker from './PatternPicker';
import FileInput from './FileInput';
import MediaPicker from './MediaPicker';
import propertyHelper from '../../../modules/propertyHelper';
import InputGroup from '../InputGroup';

const BackgroundPicker = props => {
  const changeBackgroundType = type => {
    props.typeAction(type);
  };

  const picker = (
    <>
      <div className="group">
        <PickerButton variant=""
                      label={fbt('None', 'BackgroundPicker')}
                      activeVariant={props.type}
                      action={changeBackgroundType}
        />

        <PickerButton variant="image"
                      label={fbt('Image', 'BackgroundPicker')}
                      activeVariant={props.type}
                      action={changeBackgroundType}
        />

        {!props.hideVideo && (
          <PickerButton variant="video"
                        label={fbt('Video', 'BackgroundPicker')}
                        activeVariant={props.type}
                        action={changeBackgroundType}
          />
        )}

        <PickerButton variant="color"
                      label={fbt('Color', 'BackgroundPicker')}
                      activeVariant={props.type}
                      action={changeBackgroundType}
        />
        <PickerButton variant="gradient"
                      label={fbt('Gradient', 'BackgroundPicker')}
                      activeVariant={props.type}
                      action={changeBackgroundType}
        />
        <PickerButton variant="pattern"
                      activeVariant={props.type}
                      label={fbt('Pattern', 'BackgroundPicker')}
                      action={changeBackgroundType}
        />
      </div>

      <div>
        <ImageControls {...props} />
      </div>
    </>
  );

  if (props.hideFieldset) {
    const labelClass = props.showLabel ? '' : 'sr-only';

    return (
      <>
        <label className={labelClass}>{props.title}</label>
        <br/>
        {picker}
      </>
    );
  }

  return (
    <Fieldset title={props.title} isOpen={props.isOpen || false}>
      {picker}
    </Fieldset>
  );
};

const ImageControls = (props) => {
  switch (props.type) {
    case 'image': {
      return (
        <>
          <MediaPicker
            title={fbt('Select Image', 'BackgroundPicker')}
            {...props}
            action={props.imageAction}
          />
        </>
      );
    }

    case 'video': {
      return (
        <InputGroup style={{'--mt': '10px'}}>
          {props.video && <video
            width="100%"
            src={propertyHelper.getImagePath(props.video)}
            autoPlay={props.videoAutoPlay}
            loop={props.videoLoop}
            muted={true}
            controls={false}
          />}

          <FileInput id="background-video-upload"
                     task="uploadVideo"
                     action={props.videoAction}
          />
        </InputGroup>
      );
    }

    case 'color': {
      return (
        <InputGroup style={{'--mt': '8px'}}>
          <ColorPicker value={props.color}
                       title={fbt('Choose a color', 'BackgroundPicker')}
                       action={val => props.colorAction(val)}
                       showLabel
          />
        </InputGroup>
      );
    }


    case 'unsplash': {
      return (
        <InputGroup>
          {fbt('Coming soon', 'BackgroundPicker')}
        </InputGroup>
      );
    }

    case 'gradient': {
      return (
        <GradientGenerator colorValue={props.color}
                           {...props}
        />
      );
    }

    case 'pattern': {
      return (
        <PatternPicker value={props.pattern} action={props.patternAction}/>
      );
    }

    default:
      return '';
  }
};

const PickerButton = props => {
  const className = props.variant === props.activeVariant ? 'mame-primary' : 'mame-white';

  return (
    <button className={className}
            style={{'--x': props.buttonSize || 80, '--px': '10px'}}
            onClick={() => props.action(props.variant)}
    >
      {props.label}
    </button>
  );
};


export default BackgroundPicker;
