import React, { useEffect, useState } from 'react';
import fbt from 'fbt';

// Redux / Form
import { connect } from 'react-redux';

// Tabs
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

// Fieldsets and Properties
import Spacing from './Controls/Spacing';
import VisibilitySelection from './Controls/VisibilitySelection';
import { ElementVisible } from '../../modules/consts/Elements';
import TextInput from './Controls/TextInput';
import ContainerProperties from './Controls/ContainerProperties';
import CheckboxInput from './Controls/CheckboxInput';
import Fieldset from './FieldSet';
import BackgroundPicker from './Controls/BackgroundPicker';
import BorderFieldset from './Fieldsets/BorderFieldset';
import HoverFieldset from './Fieldsets/HoverFieldset';
import DisplayFieldset from './Fieldsets/DisplayFieldset';
import AnimationFieldset from './Fieldsets/AnimationFieldset';
import ActionIcon from '../General/ActionIcon';
import DnDContainer from '../Sortable/DnDContainer';
import DnDItem from '../Sortable/DnDItem';
import { removeElement, updateSectionProperty } from '../../store/actions/activeSite';
import { setActiveElement } from '../../store/actions/builder';
import HelpText from './Controls/HelpText';
import PositionFieldset from './Fieldsets/PositionFieldset';
import ColorPicker from './Controls/ColorPicker';
import InputWithUnitPicker from './Controls/InputWithUnitPicker';
import InputGroup from './InputGroup';

/**
 * TODO Implement Section properties
 *
 * - [x] background
 * - [] background-img / background-repeat / background-cover
 * - [x] containerMaxWidth
 * - [x] containerHeight
 * - [x] containerType
 * - [x] margin
 * - [x] padding
 * - [x] visibility??
 * - [x] name
 * - [x] customClasses
 * - [x] customId
 * - [x] published
 * - [x] elements (At least show them)
 *
 * @param props
 * @returns {JSX.Element|string}
 * @constructor
 */
const SectionProperties = props => {
  if (!props.activeSection) {
    return (
      <div style={{'--ta': 'center'}}>
        <h3>
          <fbt desc="SectionProperties">
            No section selected
          </fbt>
        </h3>
        <p>
          <fbt desc="SectionProperties">
            You can select a section by clicking on one or by using the edit icon.
          </fbt>
        </p>
      </div>
    );
  }

  const section = props.sections.find(s => s._id === props.activeSection);

  if (!section) {
    return (
      <div>
        <fbt desc="SectionProperties">Section not found</fbt>
      </div>
    );
  }

  const handleSpacingChange = (index, value, padding = true) => {
    const key = padding ? 'padding' : 'margin';
    props.dispatch(updateSectionProperty(section, 'generalProperties', key, parseInt(value), index));
  };

  const handleVisibilityChange = index => {
    const allVisibilities = [ElementVisible.sm, ElementVisible.md, ElementVisible.lg, ElementVisible.xl];

    const newVis = [...section.generalProperties.visibility || allVisibilities];

    if (newVis.includes(index)) {
      newVis.splice(newVis.indexOf(index), 1);
    } else {
      newVis.push(index);
    }

    props.dispatch(updateSectionProperty(section, 'generalProperties', 'visibility', newVis));
  };

  const handleTextChange = (key, value, category = 'generalProperties') => {
    props.dispatch(updateSectionProperty(section, category, key, value));
  };

  const handleVideoUpload = (data, key = 'backgroundVideo') => {
    if (!data || !data.items) {
      return;
    }

    // This shouldn't be hardcoded, but whatever
    handleTextChange(key, data.items[0]);
  };

  const handleAnimationChange = value => {
    handleTextChange('animation', value);

    // TODO Just a hack for now, should be triggered on callback
    setTimeout(window.triggerAnimatedElements, 300);
  };

  return (
    <div className="section-editor">
      <Tabs forceRenderTabPanel={true}>
        <TabList>
          <Tab>
            <fbt desc="SectionProperties">General</fbt>
          </Tab>
          <Tab>
            <fbt desc="SectionProperties">Style</fbt>
          </Tab>
          <Tab>
            <fbt desc="SectionProperties">Advanced</fbt>
          </Tab>
          <Tab>
            <fbt desc="SectionProperties">Elements</fbt>
          </Tab>
        </TabList>
        <TabPanel>
          <Fieldset title={fbt('Section Identifier', 'SectionProperties')} isOpen>
            <InputGroup style={{'--mt': '8px'}}>
              <TextInput title={fbt('Section Identifier', 'SectionProperties')}
                         id="section-identifier"
                         tooltip={fbt('Internal identifier to easier find the section', 'SectionProperties')}
                         value={section.identifier}
                         action={val => handleTextChange('identifier', val, null)}
                         inputStyle={{'--m': 0}}
              />
            </InputGroup>
          </Fieldset>

          <Fieldset title={fbt('Visibility', 'SectionProperties')} isOpen>
            <VisibilitySelection
              type="section"
              action={handleVisibilityChange}
              values={section.generalProperties.visibility}
            />

            <InputGroup>
              <CheckboxInput label={fbt('Published', 'SectionProperties')}
                             tooltip={fbt('Unpublished sections are not included in the published site or in the download.', 'SectionProperties')}
                             checked={section.generalProperties.published}
                             id="section-published"
                             action={() => handleTextChange('published', !section.generalProperties.published)}
              />
            </InputGroup>
          </Fieldset>

          <BackgroundPicker title={fbt('Section Background', 'SectionProperties')}
                            hasAdvancedImage
                            isOpen
                            type={section.generalProperties.backgroundType}
                            image={section.generalProperties.backgroundImage}
                            imageSize={section.generalProperties.backgroundImageSize}
                            imagePosition={section.generalProperties.backgroundImagePosition}
                            imageRepeat={section.generalProperties.backgroundImageRepeat}
                            color={section.generalProperties.backgroundColor}
                            gradientColor={section.generalProperties.backgroundGradientColor}
                            gradientDegree={section.generalProperties.backgroundGradientDegree}
                            pattern={section.generalProperties.backgroundPattern}
                            video={section.generalProperties.backgroundVideo}
                            videoAutoPlay={section.generalProperties.backgroundVideoAutoPlay}
                            videoLoop={section.generalProperties.backgroundVideoLoop}
                            typeAction={(val) => {
                              handleTextChange('backgroundType', val);
                            }}
                            colorAction={(val) => {
                              handleTextChange('backgroundColor', val);
                            }}
                            imageAction={(val) => {
                              handleTextChange('backgroundImage', val);
                            }}
                            imageSizeAction={val => handleTextChange('backgroundImageSize', val)}
                            imagePositionAction={val => handleTextChange('backgroundImagePosition', val)}
                            imageRepeatAction={val => handleTextChange('backgroundImageRepeat', val)}
                            gradientColorAction={(val) => {
                              handleTextChange('backgroundGradientColor', val);
                            }}
                            gradientDegreeAction={(val) => {
                              handleTextChange('backgroundGradientDegree', val);
                            }}
                            patternAction={(val) => {
                              handleTextChange('backgroundPattern', val);
                            }}
                            videoAction={(val) => {
                              handleVideoUpload(val, 'backgroundVideo');
                            }}
                            videoLoopAction={(val) => {
                              handleTextChange('backgroundVideoLoop', val);
                            }}
                            videoAutoPlayAction={(val) => {
                              handleTextChange('backgroundVideoAutoPlay', val);
                            }}
          />
          <AnimationFieldset animation={section.generalProperties.animation}
                             animationAction={handleAnimationChange}
          />
        </TabPanel>
        <TabPanel>
          <BorderFieldset
            borderType={section.generalProperties.borderType}
            border={section.generalProperties.border}
            borderColor={section.generalProperties.borderColor}
            borderWidth={section.generalProperties.borderWidth}
            levitate={section.generalProperties.levitate}
            borderRadius={section.generalProperties.borderRadius}
            shadow={section.generalProperties.shadow}
            shadowType={section.generalProperties.shadowType}
            borderTypeAction={(val) => handleTextChange('borderType', val)}
            borderAction={(val) => handleTextChange('border', val)}
            borderColorAction={(val) => handleTextChange('borderColor', val)}
            borderWidthAction={val => handleTextChange('borderWidth', val)}
            levitateAction={(val) => handleTextChange('levitate', val)}
            borderRadiusAction={(val) => handleTextChange('borderRadius', val)}
            shadowAction={(val) => handleTextChange('shadow', val)}
            shadowTypeAction={(val) => handleTextChange('shadowType', val)}
          />

          <Fieldset title={fbt('Spacing (Padding and Margin)', 'SectionProperties')}>
            <InputGroup style={{'--mt': '10px'}}>
              <Spacing min="0" max="300"
                       action={(index, value) => handleSpacingChange(index, value)}
                       values={section.generalProperties.padding}
                       category="Padding"
              />
            </InputGroup>

            <InputGroup>
              <Spacing min="-300" max="300"
                       action={(index, value) => handleSpacingChange(index, value, false)}
                       values={section.generalProperties.margin}
                       category="Margin"
              />
            </InputGroup>
          </Fieldset>

          <Fieldset title={fbt('Color Overrides for this section', 'SectionProperties')}>
            <InputGroup style={{'--mt': '10px'}}>
              <ColorPicker title={fbt('Primary Color (Override)', 'ElementProperties')}
                           value={section.generalProperties.primaryColor}
                           action={val => handleTextChange('primaryColor', val)}
                           showLabel
                           allowReset
              />
            </InputGroup>

            <InputGroup>
              <ColorPicker title={fbt('Secondary Color (Override)', 'ElementProperties')}
                           value={section.generalProperties.secondaryColor}
                           action={val => handleTextChange('secondaryColor', val)}
                           showLabel
                           allowReset
              />
            </InputGroup>

            <InputGroup>
              <ColorPicker title={fbt('Link Color (Override)', 'ElementProperties')}
                           value={section.generalProperties.linkColor}
                           action={val => handleTextChange('linkColor', val)}
                           showLabel
                           allowReset
              />
            </InputGroup>

            <InputGroup>
              <ColorPicker title={fbt('Link Hover Color (Override)', 'ElementProperties')}
                           value={section.generalProperties.linkHoverColor}
                           action={val => handleTextChange('linkHoverColor', val)}
                           showLabel
                           allowReset
              />
            </InputGroup>
          </Fieldset>

          <HoverFieldset
            hover={section.generalProperties.hover}
            hoverType={section.generalProperties.hoverType}
            hoverColor={section.generalProperties.hoverColor}
            hoverAction={val => handleTextChange('hover', val)}
            hoverTypeAction={val => handleTextChange('hoverType', val)}
            hoverColorAction={val => handleTextChange('hoverColor', val)}
          />
        </TabPanel>
        <TabPanel>
          <Fieldset title={fbt('Container Settings', 'SectionProperties')}>
            <HelpText style={{'--mt': '10px'}}>
              <fbt desc="SectionProperties">
                Containers deal with the maximum width of the content and provide equality for content on the whole
                website.
                In most cases a fixed width one is what you are looking for, but depending on the content you may want a
                fluid
                containers. <a href="https://mame.app/documentation/container" target="_blank"
                               rel="noreferrer noopener">Learn
                More</a>
              </fbt>
            </HelpText>

            <ContainerProperties
              type={section.sectionProperties.containerType}
              typeAction={val => handleTextChange('containerType', val, 'sectionProperties')}
              customMaxWidth={section.sectionProperties.containerCustomMaxWidth}
              customMaxWidthAction={val => handleTextChange('containerCustomMaxWidth', val, 'sectionProperties')}
            />
          </Fieldset>

          <Fieldset title={fbt('Customize HTML', 'SectionProperties')}>

            <InputGroup style={{'--mt': '8px'}}>
              <TextInput title={fbt('Custom Id', 'SectionProperties')}
                         value={section.generalProperties.customId}
                         blur={val => handleTextChange('customId', val)}
                         placeholder="great-section"
                         filter="[^A-Za-z0-9_-]"
                         showLabel
              />
            </InputGroup>
            <InputGroup>
              <TextInput title={fbt('Custom Classes', 'SectionProperties')}
                         value={section.generalProperties.customClasses}
                         blur={val => handleTextChange('customClasses', val)}
                         placeholder="my-class my-other-class"
                         filter="[^A-Za-z0-9 _-]"
                         showLabel
              />
            </InputGroup>
          </Fieldset>

          <Fieldset title={fbt('Fixed Dimensions', 'SectionProperties')}>
            <InputGroup style={{'--mt': '10px'}}>
              <InputWithUnitPicker
                title={fbt('Custom Width', 'ElementProperties')}
                value={section.generalProperties.width}
                action={val => handleTextChange('width', val)}
                isWidth
                showLabel
              />
            </InputGroup>

            <InputGroup>
              <InputWithUnitPicker
                title={fbt('Custom Height', 'ElementProperties')}
                value={section.generalProperties.height}
                action={val => handleTextChange('height', val)}
                isHeight
                showLabel
              />
            </InputGroup>

            <InputGroup>
              <InputWithUnitPicker
                title={fbt('Maximum Width', 'ElementProperties')}
                value={section.generalProperties.maxWidth || ''}
                action={val => handleTextChange('maxWidth', val)}
                isWidth
                showLabel
              />
            </InputGroup>

            <InputGroup>
              <InputWithUnitPicker
                title={fbt('Maximum Height', 'ElementProperties')}
                value={section.generalProperties.maxHeight || ''}
                action={val => handleTextChange('maxHeight', val)}
                isHeight
                showLabel
              />
            </InputGroup>
          </Fieldset>

          <DisplayFieldset
            display={section.generalProperties.display}
            actionDisplay={val => handleTextChange('display', val)}
            alignItems={section.generalProperties.alignItems}
            actionAlignItems={val => handleTextChange('alignItems', val)}
            justifyContent={section.generalProperties.justifyContent}
            actionJustifyContent={val => handleTextChange('justifyContent', val)}
          />

          <PositionFieldset
            generalProperties={section.generalProperties}
            action={handleTextChange}
          />
        </TabPanel>
        <TabPanel style={{'--p': '0 15px'}}>
          <HelpText style={{'--m': '10px 0'}}>
            <fbt desc="SectionProperties">
              A quick overview of the elements in this section.
            </fbt>
          </HelpText>
          <SectionElements {...props} section={section}/>
        </TabPanel>
      </Tabs>
    </div>
  );
};

const SectionElements = props => {
  const [sortingElements, setSortingElements] = useState(props.section.elements);

  const {section} = props;

  useEffect(() => {
    setSortingElements(section.elements);
  }, [section.elements]);

  const style = {
    '--b': '1px solid var(--section)',
    '--my': '5px',
    '--p': '5px',
  };

  const storeSorting = newSorting => {
    props.dispatch(updateSectionProperty(props.section, null, 'elements', newSorting));
  };

  if (!sortingElements.length) {
    return (
      <div style={style}>
        <fbt desc="SectionProperties">
          This section has no elements.
        </fbt>
      </div>
    );
  }

  return (
    <div style={style}>
      <DnDContainer items={sortingElements}
                    onMove={updatedList => {
                      setSortingElements(updatedList);
                    }}
                    group="section-elements"
                    onEnd={storeSorting}>
        {sortingElements.map((elementId, index) => {
          const element = props.elements.find(e => e._id === elementId);

          return (
            <DnDItem key={elementId} id={elementId} index={index}>
              <ElementRendererProperties {...props}
                                         key={elementId}
                                         index={index}
                                         element={element}
                                         parentType="section"
                                         parentId={props.section._id}
              />
            </DnDItem>
          );
        })}
      </DnDContainer>
    </div>
  );
};

export const ElementRendererProperties = props => {
  if (!props.element) {
    // Simple Protection for now
    return null;
  }

  const style = {
    '--b': '1px solid var(--element)',
    '--my': '3px',
    '--p': '5px',
    '--cur': 'pointer'
  };

  const childStyle = props.element.component === 'GridElement' ? {
    '--d': 'grid',
    '--gtc': '1fr 1fr',
    '--gg': '3px',
  } : {};

  if (props.isDragging) {
    style['--bg'] = 'var(--element)';
  }

  return (
    <div
      ref={props.dndDragRef}
      style={style}
      onClick={e => {
        e.preventDefault();
        props.dispatch(setActiveElement(props.element));
      }}>
      <div style={{'--d': 'flex', '--ai': 'center'}}>
        <div style={{'--fg': 1}}>
          <b>{props.element.identifier}</b>
        </div>
        <div>
          {!props.subElement && (
            <span className={`icon icon-hand-grab-o`}
                  title={fbt('Move Element (Drag & Drop)', 'SectionProperties')}
                  style={{'--cur': 'move', '--px': '7px'}}
            />
          )}

          <ActionIcon icon="trash-o" title={fbt('Remove', 'SectionProperties')}
                      action={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        props.dispatch(removeElement(
                          props.element._id,
                          {parentType: props.parentType, parentId: props.parentId}
                        ));
                      }}/>
        </div>
      </div>
      <div style={childStyle}>
        {props.element.elements.map(childId => {
          const child = props.elements.find(e => e._id === childId);

          return (
            <ElementRendererProperties
              {...props}
              key={childId}
              element={child}
              parentType="element"
              parentId={props.element._id}
              subElement={true}
            />
          );
        })}
      </div>
    </div>
  );
};


const mapStateToProps = state => ({
  sections: state.activeSite.sections,
  elements: state.activeSite.elements,
  activeSection: state.builder.activeSection,
  activeType: state.builder.activeType,
});

export default connect(mapStateToProps)(SectionProperties);
