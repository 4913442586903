import React from 'react';
// eslint-disable-next-line no-unused-vars
import fbt from 'fbt';

import serviceHelper from '../../modules/serviceHelper';
import logger from '../../modules/logger';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {error: null, errorInfo: null};
  }

  logErrorToApi(error, errorInfo) {
    const data = {
      error: error.toString(),
      errorInfo: JSON.stringify(errorInfo),
      log: JSON.stringify(logger.completeLog),
      componentStack: errorInfo ? errorInfo.componentStack : '',
      where: window.location.href,
    };

    const service = serviceHelper.getService('ReportService');
    service.reportAppError(data);
  }

  componentDidCatch(error, errorInfo) {
    logger.warn('ErrorBoundary Error', error, errorInfo);

    // You can also log the error to an error reporting service
    this.logErrorToApi(error, errorInfo);

    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div className="error-renderer" style={{'--d': 'flex', '--minh': '100vh', '--ai': 'center'}}>
          <div className="wrapper" style={{'--wrapper': '900px'}}>
            <div style={{'--d': 'grid', '--gtc': 'auto auto', '--ai': 'center'}}>
              <div>
                <h3>
                  <fbt desc="Error Boundary">
                    Sorry, something went wrong.
                  </fbt>
                </h3>

                <p>
                  <fbt desc="Error Boundary">
                    Oh snap, you found it! As Mame is currently in Beta Bugs may happen.
                  </fbt>
                    <br />
                  <fbt desc="Error Boundary">
                    Please report it and we are going to fix it asap.
                  </fbt>
                </p>

                <p className="debug-output">
                  <b>
                    <fbt desc="Error Boundary">
                      Error Info
                    </fbt>
                  </b>:
                  <br/>
                  {this.state.error && this.state.error.toString()}
                </p>

                <div style={{'--d': 'grid', '--gtc': '1fr 1fr'}}>
                  <span className="fake-link" onClick={() => {
                    window.location.reload();
                  }}>
                    <fbt desc="Error Boundary">
                      Try refreshing the page
                    </fbt>
                  </span>
                  <a href="https://mame-pagebuilder.atlassian.net/servicedesk/customer/portals"
                     target="_blank" rel="noreferrer noopener">
                    <fbt desc="Error Boundary">
                      Contact the Support Team
                    </fbt>
                  </a>
                </div>
              </div>
              <div>
                <img src="/img/illustrations/bugfixing.webp" alt="You found it"/>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
