import React, { useState } from 'react';
import { connect } from 'react-redux';

import elementHelper from '../../modules/elementHelper';
import ElementDiv from '../ElementControls/ElementDiv';
import propertyHelper from '../../modules/propertyHelper';
import DropZone from '../Sortable/DropZone';
import FigureElementsRenderer from '../ElementControls/FigureElementsRenderer';
import { setActiveElement } from '../../store/actions/builder';
import ElementLink from '../ElementControls/ElementLink';
import { isPreviewSelector } from '../../store/selectors';

const ImageFigureElement = props => {
  const [dimensions, setDimensions] = useState({width: 0, height: 0});

  const {element} = props;
  const {componentProperties} = element;

  const id = elementHelper.getId(element);
  const classes = elementHelper.getClasses(element, 'mame-image-figure accent');
  const style = elementHelper.getStyle(element);

  // Padding is for the body element
  delete style['--p'];

  // For border radius, looks way better..
  if (props.isPreview) {
    style['--of'] = 'hidden';
  }

  // Body Style padding
  const bodyStyle = {};
  propertyHelper.setSpacing(bodyStyle, element.generalProperties.padding);

  // Background, no idea why it's here
  propertyHelper.setBackground(style, element.componentProperties);

  // Set the Background
  const setActive = (e) => {
    if (!e.target.classList.contains('mame-image-figure')) {
      return;
    }

    props.dispatch(setActiveElement(element));
  };

  const getImageDimensions = e => {
    if (!componentProperties.autoDimensions) {
      return;
    }

    setDimensions({width: e.target.offsetWidth, height: e.target.offsetHeight});
  };

  const imageStyle = {};

  if (componentProperties.filterType) {
    if (componentProperties.filterType === 'blur') {
      imageStyle['--fr'] = `blur(${componentProperties.filterValue}px)`;
    } else {
      imageStyle['--fr'] = `${componentProperties.filterType}(${componentProperties.filterValue}%)`;
    }
  }

  if (componentProperties.autoDimensions) {
    imageStyle['maxWidth'] = '100%';
    imageStyle['height'] = 'auto';
  }

  if (!componentProperties.alt) {
    console.log('[Mame] Image Figure is missing alt attribute, please set one!');
  }

  const image = (
    <img src={propertyHelper.getImagePath(componentProperties.image)}
         alt={componentProperties.alt}
         style={imageStyle}
         className="mame-image-figure__image"
         onClick={() => props.dispatch(setActiveElement(element))}
         loading={componentProperties.lazy ? 'lazy' : null}
         onLoad={getImageDimensions}
         width={componentProperties.autoDimensions ? (dimensions.width || null) : null}
         height={componentProperties.autoDimensions ? (dimensions.height || null) : null}
    />
  );

  return (
    <ElementDiv {...props} id={id} style={style} classes={classes}
                tag="figure" action={setActive}>
      {componentProperties.linkType && (
        <ElementLink
          linkType={componentProperties.linkType}
          link={componentProperties.link}
          target={componentProperties.linkTarget}
          page={componentProperties.page}
          title={componentProperties.iconTitle || null}
          isNofollow={componentProperties.isNofollow}
          onClick={e => {
            e.preventDefault();
            props.dispatch(setActiveElement(element));
          }}
        >
          {image}
        </ElementLink>
      )}

      {!componentProperties.linkType && image}

      <DropZone element={element}
                items={element.elements}>
        <FigureElementsRenderer
          tag="div"
          className="mame-image-figure__body"
          element={element}
          style={bodyStyle}
        />
      </DropZone>

    </ElementDiv>
  );
};

const mapStateToProps = state => ({
  isPreview: isPreviewSelector(state),
});

export default connect(mapStateToProps)(ImageFigureElement);
