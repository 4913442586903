import { LOGOUT_USER, SET_TOKEN, SET_USER, UPDATE_USER_PROPERTY } from '../actionTypes';
import produce from 'immer';

const initialState = {
  user: null,
  token: null,
};

const userState = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER: {
      const {user} = action.payload;

      return {...state, user};
    }

    case SET_TOKEN: {
      const {token} = action.payload;

      return {...state, token};
    }

    case LOGOUT_USER: {
      // We need to delete the window storage
      window.localStorage.removeItem('jwtToken');

      return initialState;
    }

    case UPDATE_USER_PROPERTY: {
      const {category, key, value} = action.payload;

      return produce(state, draft => {
        if (category) {
          draft.user[category][key] = value;
        } else {
          draft.user[key] = value;
        }
      });
    }

    default: {
      return state;
    }
  }
}

export default userState;
