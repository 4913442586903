import React from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import { updateUserProperty } from '../../store/actions/user';
import TextInput from '../Properties/Controls/TextInput';

const UserInformation = props => {
  const handleChange = (key, value, category = 'personalInformation') => {
    props.dispatch(updateUserProperty(category, key, value));
  };

  const user = props.user;

  return (
    <div>
      <p>
        <fbt desc="UserInformation">
          Your personal information are used for billing purposes on the store and for Mame premium invoices.
          We do not share or sell your information without your permission.
        </fbt>
        <a href="https://mame.app/privacy-policy.html" rel="noopener noreferrer" target="_blank"
           style={{'--ml': '5px'}}>
          <fbt desc="UserInformation">
            Privacy Policy
          </fbt>
        </a>
      </p>

      <div className="form-horizontal">
        <TextInput title={fbt('First Name', 'UserInformation')}
                   showLabel required
                   value={user.personalInformation.firstName}
                   action={val => handleChange('firstName', val)}
        />
        <TextInput title={fbt('Last Name', 'UserInformation')}
                   showLabel required
                   value={user.personalInformation.lastName}
                   action={val => handleChange('lastName', val)}
        />
        <TextInput title={fbt('Company Name', 'UserInformation')}
                   showLabel
                   value={user.personalInformation.companyName}
                   action={val => handleChange('companyName', val)}
        />
        {/*TODO SELECT*/}
        <TextInput title={fbt('Country', 'UserInformation')}
                   value={user.personalInformation.country}
                   action={val => handleChange('country', val)}
                   showLabel
        />
        <TextInput title={fbt('Address', 'UserInformation')}
                   value={user.personalInformation.address}
                   action={val => handleChange('address', val)}
                   showLabel
        />
        <TextInput title={fbt('Address (Additional Line)', 'UserInformation')}
                   value={user.personalInformation.address2}
                   action={val => handleChange('address2', val)}
                   showLabel
        />
        <TextInput title={fbt('City', 'UserInformation')}
                   value={user.personalInformation.city}
                   action={val => handleChange('city', val)}
                   showLabel
        />
        <TextInput title={fbt('State / Region', 'UserInformation')}
                   value={user.personalInformation.state}
                   action={val => handleChange('state', val)}
                   showLabel
        />
        <TextInput title={fbt('Postal Code (ZIP)', 'UserInformation')}
                   value={user.personalInformation.zip}
                   action={val => handleChange('zip', val)}
                   showLabel
        />
        <TextInput title={fbt('European VAT Id', 'UserInformation')}
                   placeholder="DE123141231"
                   value={user.personalInformation.vatId}
                   action={val => handleChange('vatId', val)}
                   showLabel
        />
        <TextInput title={fbt('Company No.', 'UserInformation')}
                   value={user.personalInformation.companyNo}
                   action={val => handleChange('companyNo', val)}
                   showLabel
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.user.user,
});

export default connect(mapStateToProps)(UserInformation);

