import React from 'react';
import fbt from 'fbt';

const SocialIcon = (props) => {
  if (!props.data) {
    return null;
  }

  const types = {
    website: {
      icon: 'home',
      title: fbt('Website', 'Social Icon'),
      url: props.data,
    },
    facebook: {
      icon: 'facebook',
      title: fbt('FaceBook', 'Social Icon'),
      url: 'https://facebook.com/' + props.data,
    },
    twitter: {
      icon: 'twitter',
      title: fbt('Twitter', 'Social Icon'),
      url: 'https://twitter.com/' + props.data,
    },
    github: {
      icon: 'github',
      title: fbt('GitHub', 'Social Icon'),
      url: 'https://github.com/' + props.data,
    },
    unsplash: {
      icon: 'unsplash',
      title: fbt('Unsplash', 'Social Icon'),
      url: 'https://unsplash.com/@' + props.data,
    },
    youtube: {
      icon: 'youtube-play',
      title: fbt('Youtube', 'Social Icon'),
      url: 'https://youtube.com/channel/' + props.data,
    },
    linkedin: {
      icon: 'linkedin-square',
      title: fbt('LinkedIn', 'Social Icon'),
      url: 'https://linkedin.com/in/' + props.data,
    },
  };

  const item = types[props.type];

  let iconClass = `icon icon-${item.icon}`;

  const style = {
    ...props.style,
    '--px': '7px',
    '--size': `${props.size || 100}%`,
  };

  return (
    <a href={item.url || '#'} target="_blank"
       rel="noopener noreferrer nofollow">
      <span className={iconClass}
            style={style}
            title={item.title || ''}/>
    </a>
  );
};

export default SocialIcon;
