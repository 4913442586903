import React from 'react';
import fbt from 'fbt';

import Fieldset from '../FieldSet';
import TextInput from '../Controls/TextInput';
import CheckboxInput from '../Controls/CheckboxInput';
import SelectInput from '../Controls/SelectInput';
import InputGroup from '../InputGroup';

const YoutubeProperties = props => {
  const {element} = props;
  const {componentProperties} = element;

  return (
    <Fieldset title={fbt('Youtube Properties', 'YoutubeProperties')} isOpen>
      <InputGroup style={{'-mt': '10px'}}>
        <TextInput title={fbt('Youtube Video Id', 'YoutubeProperties')}
                   placeholder=" "
                   value={componentProperties.videoId}
                   action={val => {
                     props.handlePropertyChange('videoId', val);
                   }}
                   showLabel
        />
      </InputGroup>

      <InputGroup>
        <CheckboxInput checked={componentProperties.autoPlay}
                       label={fbt('Autoplay', 'YoutubeProperties')}
                       action={() => props.handlePropertyChange('autoPlay', !componentProperties.autoPlay)}
        />
      </InputGroup>

      <InputGroup>
        <CheckboxInput checked={componentProperties.controls}
                       label={fbt('Show Controls', 'YoutubeProperties')}
                       action={() => props.handlePropertyChange('controls', !componentProperties.controls)}
        />
      </InputGroup>

      <InputGroup>
        <CheckboxInput checked={componentProperties.fullScreen}
                       label={fbt('Allow Fullscreen', 'YoutubeProperties')}
                       action={() => props.handlePropertyChange('fullScreen', !componentProperties.fullScreen)}
        />
      </InputGroup>

      <InputGroup>
        <CheckboxInput checked={componentProperties.loop}
                       label={fbt('Play in loop', 'YoutubeProperties')}
                       action={() => props.handlePropertyChange('loop', !componentProperties.loop)}
        />
      </InputGroup>

      <InputGroup>
        <SelectInput title={fbt('Aspect Ratio', 'YoutubeProperties')}
                     style={{'--mt': '10px', '--d': 'block'}}
                     value={componentProperties.aspectRatio}
                     action={val => props.handlePropertyChange('aspectRatio', val)}
                     options={{
                       '': fbt('Please select', 'YoutubeProperties'),
                       '9 / 16': fbt('16 by 9 (Default)', 'YoutubeProperties'),
                       '3 / 4': fbt('4 by 3', 'YoutubeProperties'),
                     }}
                     showLabel
        />
      </InputGroup>
    </Fieldset>
  );
};

export default YoutubeProperties;
