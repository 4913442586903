import BaseService from './BaseService';

const paths = {
  get: '/user',
  post: '/user',
  patch: '/user',
};

/**
 * Service for querying the Sites
 */
class UserService extends BaseService {
  constructor() {
    super(paths);
    this.requireLogin = true;
  }
}

export default new UserService();
