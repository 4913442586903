import React from 'react';
import { connect, useSelector } from 'react-redux';
import fbt from 'fbt';

import ActionIcon from '../General/ActionIcon';
import { apiDuplicateSection, removeSection, updateSectionProperties } from '../../store/actions/activeSite';
import { addSectionTemplate, apiLoadSectionTemplates, sectionToTemplate } from '../../store/actions/templates';
import serviceHelper from '../../modules/serviceHelper';
import { closeActiveProperty, setActiveSection, setClipboardSectionStyle } from '../../store/actions/builder';
import { showModal } from '../../store/actions/modals';
import { selectElementsByIds } from '../../store/selectors';

const SectionControlPopup = props => {
  const {section, bindMenu, bindMenuItems, setContextVisible} = props;

  const sectionElements = useSelector(state => selectElementsByIds(state, section.elements));


  const setActive = () => {
    props.dispatch(setActiveSection(section));
  };

  const handleAddElementClick = () => {
    const options = {to: section._id, toType: 'section'};
    props.dispatch(showModal('AddElementModal', options));
    setContextVisible(false);
  };

  const handleRemoveClick = () => {
    // TODO only if it is open
    props.dispatch(closeActiveProperty());
    props.dispatch(removeSection(section._id, props.pageId));
    setContextVisible(false);
  };

  const handleSaveTemplateClick = () => {
    props.dispatch(showModal('SaveTemplateModal', {item: section, type: 'section'}));
    setContextVisible(false);
  };

  const handleSystemTemplateClick = async () => {
    const template = sectionToTemplate(sectionElements, section);

    template.kind = 'system';
    template.title = section.identifier;
    template.image = `/img/section/${section.identifier}.webp`;

    const service = serviceHelper.getService('SectionTemplateService');
    const res = await service.store(template);

    props.dispatch(addSectionTemplate(res.data.item));
    setContextVisible(false);
  };

  const handleUpdateSystemTemplateClick = async () => {
    console.log('Updating system template');

    if (!section.fromTemplateId) {
      console.warn('No Template Id');
      return;
    }

    const template = sectionToTemplate(sectionElements, section);

    template.kind = 'system';
    template._id = section.fromTemplateId;

    const service = serviceHelper.getService('SectionTemplateService');
    await service.store(template);

    props.dispatch(apiLoadSectionTemplates());
    setContextVisible(false);
  };

  const handleDuplicateClick = async e => {
    e.preventDefault();
    props.dispatch(apiDuplicateSection(section, props.siteId, props.pageId));
    setContextVisible(false);
  };

  const handleCopyStyleClick = () => {
    const style = {
      sectionProperties: section.sectionProperties,
      generalProperties: section.generalProperties,
    };

    props.dispatch(setClipboardSectionStyle(JSON.stringify(style)));
    setContextVisible(false);
  };

  const handlePasteStyleClick = () => {
    if (!props.clipboardStyle) {
      return;
    }

    const style = JSON.parse(props.clipboardStyle);

    // Make sure to not copy any ids
    style.generalProperties.customId = '';

    // Handle State change
    props.dispatch(updateSectionProperties(section, 'generalProperties', style.generalProperties));
    props.dispatch(updateSectionProperties(section, 'sectionProperties', style.sectionProperties));
    setContextVisible(false);
  };

  return (
    <>
      <div className="mame-control-popup mame-section-control-popup" role="navigation">
        <ActionIcon icon="plus" title={fbt('Add Element', 'Add Element')} action={handleAddElementClick}/>
        <ActionIcon icon="hand-grab-o section-move" title={fbt('Move Section', 'Move Section')}
                    cursor="move" actionRef={props.handleRef}/>
        <ActionIcon icon="pencil" title={fbt('Edit Section', 'Edit Section')} action={setActive}/>
        {process.env.NODE_ENV === 'development' && (
          <>
            <ActionIcon icon="cloud-download" style={{'--c': 'darkred'}}
                        title={fbt('Save as System Template', 'SectionControlPopup')}
                        action={handleSystemTemplateClick}/>
            <ActionIcon icon="repeat" style={{'--c': 'darkred'}}
                        title={fbt('Update System Template', 'SectionControlPopup')}
                        action={handleUpdateSystemTemplateClick}/>
          </>
        )}
        <ActionIcon icon="save" title={fbt('Save as Template', 'Save as Template')} action={handleSaveTemplateClick}/>
        <ActionIcon icon="clone" title={fbt('Duplicate Section', 'Duplicate Section')} action={handleDuplicateClick}/>
        <ActionIcon icon="object-ungroup" title={fbt('Copy Style', 'Copy Style')} action={handleCopyStyleClick}/>
        {props.clipboardStyle && (
          <ActionIcon icon="magic" title={fbt('Paste Style', 'Paste Style')} action={handlePasteStyleClick}/>
        )}
        <ActionIcon icon="trash-o" title={fbt('Remove Section', 'Remove Section')} action={handleRemoveClick}/>
      </div>
      <nav {...bindMenu} className="context-menu context-menu-section">
        <div {...bindMenuItems} onClick={handleAddElementClick}>
          <ActionIcon icon="plus" style={{'--w': '35px', '--d': 'inline-block'}}/>
          <fbt desc="Add Element">
            Add Element
          </fbt>
        </div>

        {process.env.NODE_ENV === 'development' && (
          <>
            <div {...bindMenuItems} onClick={handleSaveTemplateClick} style={{'--c': 'darkred'}}>
              <ActionIcon icon="cloud-download" style={{'--w': '35px', '--d': 'inline-block'}}/>
              <fbt desc="SectionControlPopup">
                Save as System Template
              </fbt>
            </div>
            <div {...bindMenuItems} onClick={handleUpdateSystemTemplateClick} style={{'--c': 'darkred'}}>
              <ActionIcon icon="repeat" style={{'--w': '35px', '--d': 'inline-block'}}/>
              <fbt desc="SectionControlPopup">
                Update System Template
              </fbt>
            </div>
          </>
        )}

        <div {...bindMenuItems} onClick={handleCopyStyleClick}>
          <ActionIcon icon="object-ungroup" style={{'--w': '35px', '--d': 'inline-block'}}/>
          <fbt desc="SectionControlPopup">
            Copy Style
          </fbt>
        </div>

        {props.clipboardStyle && (
          <div {...bindMenuItems} onClick={handlePasteStyleClick}>
            <ActionIcon icon="magic" style={{'--w': '35px', '--d': 'inline-block'}}/>
            <fbt desc="SectionControlPopup">
              Paste Style
            </fbt>
          </div>
        )}

        <div {...bindMenuItems} onClick={handleSaveTemplateClick}>
          <ActionIcon icon="save" style={{'--w': '35px', '--d': 'inline-block'}}/>
          <fbt desc="Save as Template">
            Save as Template
          </fbt>
        </div>

        <div {...bindMenuItems} onClick={handleDuplicateClick}>
          <ActionIcon icon="clone" style={{'--w': '35px', '--d': 'inline-block'}}/>
          <fbt desc="Duplicate Section">
            Duplicate Section
          </fbt>
        </div>

        <div {...bindMenuItems} onClick={handleRemoveClick}>
          <ActionIcon icon="trash-o" style={{'--w': '35px', '--d': 'inline-block'}}/>
          <fbt desc="Remove Section">
            Remove Section
          </fbt>
        </div>
      </nav>
    </>
  );
};

const mapStateToProps = state => ({
  siteId: state.activeSite.site._id,
  pageId: state.activeSite.page._id,
  clipboardStyle: state.builder.clipboardSectionStyle,
});

export default connect(mapStateToProps)(SectionControlPopup);
