import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import elementHelper from '../../modules/elementHelper';
import ElementDiv from '../ElementControls/ElementDiv';

const CountdownElement = props => {
  const element = props.element;
  const {componentProperties} = element;

  useEffect(() => {
    window.setupCountdown();
  }, [componentProperties]);

  const id = elementHelper.getId(element);
  const classes = elementHelper.getClasses(element, 'mame-countdown');
  const style = elementHelper.getStyle(element);


  const parseDate = (date) => {
    let b = date.split(/\D+/);
    return new Date(b[0], --b[1], b[2], b[3], b[4], b[5] || 0, b[6] || 0);
  };

  const targetTime = parseDate(componentProperties.targetDateTime);

  return (
    <ElementDiv {...props} id={id} classes={classes} style={style}>
      <div role="timer"
           data-countdown={targetTime.getTime()}
           data-day-label={componentProperties.dayLabel}
           data-hour-label={componentProperties.hourLabel}
           data-minute-label={componentProperties.minuteLabel}
           data-second-label={componentProperties.secondLabel}
           data-separator={componentProperties.separator}
           data-show-hours={componentProperties.showHours || null}
           data-show-minutes={componentProperties.showMinutes || null}
           data-show-seconds={componentProperties.showSeconds || null}
      >
      </div>
    </ElementDiv>
  );
};

export default connect()(CountdownElement);
