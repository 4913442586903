import React from 'react';
import { connect } from 'react-redux';

import elementHelper from '../../modules/elementHelper';
import ElementDiv from '../ElementControls/ElementDiv';

const YoutubeElement = props => {
  const element = props.element;
  const {componentProperties} = element;

  const id = elementHelper.getId(element);
  const classes = elementHelper.getClasses(element, 'mame-youtube');
  const style = elementHelper.getStyle(element);

  style['--aspect-ratio'] = componentProperties.aspectRatio || '9 / 16';
  style['--pb'] = 'calc(var(--aspect-ratio, .5625) * 100%)';

  let params = '';

  // Simple Hack
  let hasFirstParameter = false;

  if (componentProperties.autoPlay) {
    params += 'autoplay=1';
    hasFirstParameter = true;
  }

  if (!componentProperties.controls) {
    params += hasFirstParameter ? '&controls=0' : 'controls=0';
    hasFirstParameter = true;
  }

  if (componentProperties.loop) {
    params += hasFirstParameter ? '&loop=0' : 'loop=0';
    hasFirstParameter = true;
  }

  let videoUrl = `https://www.youtube-nocookie.com/embed/${componentProperties.videoId}${params ? `?${params}` : ''}`;

  return (
    <ElementDiv {...props} id={id} classes={classes} style={style}>
      {props.isRender && (
        <lazy-youtube videoid={componentProperties.videoId}
                      style={{'--pos': 'absolute', '--top': 0, '--left': 0, '--w': '100%', '--h': '100%'}}
                      params={params}
        />
      )}

      {!props.isRender && (
        <iframe type="text/html"
                style={{'--pos': 'absolute', '--top': 0, '--left': 0, '--w': '100%', '--h': '100%'}}
                title={id}
                src={videoUrl}
                frameBorder="0"
                allowFullScreen={componentProperties.fullScreen || null}
        />
      )}
    </ElementDiv>
  );
};

const mapStateToProps = state => ({
  isRender: state.builder.isRender,
});

export default connect(mapStateToProps)(YoutubeElement);
