import React from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import SelectInput from './SelectInput';

/**
 * Picker for Anchor
 */
const AnchorSectionPicker = props => {
  const options = {'': fbt('Please Select', 'AnchorSectionPicker')};

  props.pageSections.forEach(sectionId => {
    const section = props.sections.find(s => s._id === sectionId);

    const key = section.generalProperties.customId ? `#${section.generalProperties.customId}` : `#s-${sectionId}`;

    options[key] = section.identifier;
  });

  return (
    <SelectInput title={props.title}
                 options={options}
                 value={props.value}
                 action={props.action}
                 showLabel
    />
  );
};

const mapStateToProps = state => ({
  pageSections: state.activeSite.page.sections,
  sections: state.activeSite.sections,
});

export default connect(mapStateToProps)(AnchorSectionPicker);
