import React from 'react';
import { connect } from 'react-redux';

import elementHelper from '../../modules/elementHelper';
import ElementDiv from '../ElementControls/ElementDiv';

const TextareaElement = props => {
  const element = props.element;
  const {componentProperties} = element;

  const id = elementHelper.getId(element);
  const classes = elementHelper.getClasses(element, 'mame-textarea');
  const style = elementHelper.getStyle(element);

  const fieldId = elementHelper.getId(element, 'input-');

  return (
    <ElementDiv {...props} id={id} classes={classes} style={style}>
      <label htmlFor={fieldId}>
        {componentProperties.showLabel && componentProperties.title}
        {!componentProperties.showLabel && <span className="sr-only">componentProperties.title</span>}

        <textarea
          name={componentProperties.customName || fieldId}
          id={fieldId}
          rows={componentProperties.rows || 5}
          required={componentProperties.required || null}
          data-invalid-text={componentProperties.invalidMessage || null}
          placeholder={componentProperties.placeholder || null}
          defaultValue={componentProperties.defaultValue || null}
        />
      </label>
    </ElementDiv>
  );
};

export default connect()(TextareaElement);
