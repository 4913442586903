import React from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import elementHelper from '../../modules/elementHelper';
import ElementDiv from '../ElementControls/ElementDiv';
import DropZone from '../Sortable/DropZone';
import FigureElementsRenderer from '../ElementControls/FigureElementsRenderer';
import { showModal } from '../../store/actions/modals';
import { setActiveElement } from '../../store/actions/builder';

const AccordionElement = props => {
  const element = props.element;
  const {componentProperties} = element;

  const id = elementHelper.getId(element);
  const classes = elementHelper.getClasses(element, 'mame-accordion');
  const style = elementHelper.getStyle(element);

  const addElement = (e, index) => {
    e.preventDefault();

    const options = {
      to: element._id,
      toType: 'item',
      index: index,
    };

    props.dispatch(showModal('AddElementModal', options));
  };

  const setActive = (e) => {
    if (e.target.tagName === 'SUMMARY') {
      props.dispatch(setActiveElement(element));
    }
  };

  return (
    <ElementDiv {...props} id={id} classes={classes}
                style={style} action={setActive} role="tree">
      {componentProperties.items.map((item, index) => {
        return (
          <details key={index} open={item.isOpen || null}
                   role="treeitem"
                   aria-expanded={item.isOpen}>
            <summary>
              {item.title}
              {item.icon && (
                <span className={`icon icon-${item.icon}`} style={{'--ft': 'right'}} aria-hidden/>
              )}
            </summary>
            <DropZone element={element}
                      type="item"
                      index={index}
                      items={item.elements}>
              <FigureElementsRenderer
                tag="div"
                element={element}
                addElement={e => addElement(e, index)}
                parentType="item"
                parentIndex={index}
                moveText={fbt('Move Element to Accordion.', 'Element Accordion')}
              />
            </DropZone>
          </details>
        );
      })}
    </ElementDiv>
  );
};

const mapStateToProps = state => ({
  elements: state.activeSite.elements,
});

export default connect(mapStateToProps)(AccordionElement);
