import SlideshowProperties from '../../components/Properties/Elements/SlideshowProperties';
import { ElementVisible } from '../../modules/consts/Elements';

const SlideshowModel = {
  title: 'Slideshow',
  image: '/img/elements/slideshow.webp',
  helpPage: '/documentation/elements/slideshow',
  category: 'Media',
  component: 'SlideshowElement',
  propertyComponent: SlideshowProperties,
  hasChildElements: false,
  generalProperties: {
    hasVisibility: true,
    hasTextAlign: true,
    hasTextColor: false,
    hasBackground: true,
  },
  defaults: {
    demo: true,
    _id: null,
    identifier: 'New Slideshow',
    component: 'SlideshowElement',
    componentProperties: {
      interval: 5,
      autoPlay: true,
      animation: 'animate-slide-left-in',
      navigationVisible: true,
      controlsVisible: true,
      items: [
        {
          itemId: 42,
          identifier: 'Slide 1',
          backgroundType: 'image',
          backgroundImage: 'http://source.unsplash.com/QdAAasrZhdk/800x600',
          backgroundPattern: '',
          backgroundColor: '',
          backgroundGradientColor: '#f1f1f1',
          backgroundGradientDegree: 0,
          backgroundVideo: '',
          backgroundVideoLoop: true,
          backgroundVideoAutoPlay: true,
          elements: [],
        },
        {
          itemId: 43,
          identifier: 'Slide 2',
          backgroundType: 'image',
          backgroundImage: 'http://source.unsplash.com/l5Tzv1alcps/800x600',
          backgroundPattern: '',
          backgroundColor: '',
          backgroundGradientColor: '#f1f1f1',
          backgroundGradientDegree: 0,
          backgroundVideo: '',
          backgroundVideoLoop: true,
          backgroundVideoAutoPlay: true,
          elements: [],
        },
      ]
    },
    generalProperties: {
      visibility: [ElementVisible.sm, ElementVisible.md, ElementVisible.lg, ElementVisible.xl],
      published: true,
      height: '60vh',
      margin: [0, 0, 0, 0],
      padding: [0, 0, 0, 0],
    },
  },
  itemTemplate: {
    itemId: 0,
    identifier: 'New Slide',
    backgroundType: '',
    backgroundImage: '',
    backgroundPattern: '',
    backgroundColor: '',
    backgroundGradientColor: '#f1f1f1',
    backgroundGradientDegree: 0,
    backgroundVideo: '',
    backgroundVideoLoop: true,
    backgroundVideoAutoPlay: true,
    elements: [],
  },
};

export default SlideshowModel;
