import React from 'react';
import fbt from 'fbt';
import MediaPicker from '../Controls/MediaPicker';
import TextInput from '../Controls/TextInput';
import CheckboxInput from '../Controls/CheckboxInput';
import HelpText from '../Controls/HelpText';
import FilterSelect from '../Controls/FilterSelect';
import InputGroup from '../InputGroup';

const ImageComponentProperties = props => {
  const {componentProperties, handlePropertyChange} = props;

  return (
    <>
      <InputGroup>
        <MediaPicker image={componentProperties.image}
                     action={val => handlePropertyChange('image', val)}
        />
      </InputGroup>

      <InputGroup>
        <TextInput title={fbt('Image Alternate Text', 'ImageOverlayProperties')}
                   className="b-image-properties__alt"
                   value={componentProperties.alt}
                   blur={val => handlePropertyChange('alt', val)}
                   required
                   showLabel
        />
      </InputGroup>

      <InputGroup>
        <CheckboxInput title={fbt('Add Width and Height automatically', 'ImageProperties')}
                       checked={componentProperties.autoDimensions}
                       action={() => {
                         handlePropertyChange('autoDimensions', !componentProperties.autoDimensions);
                       }}
        />
      </InputGroup>

      <InputGroup>
        <CheckboxInput title={fbt('Lazy Loading', 'ImageProperties')}
                       checked={componentProperties.lazy}
                       action={() => {
                         handlePropertyChange('lazy', !componentProperties.lazy);
                       }}
        />
        <HelpText>
          <fbt desc="ImageProperties">
            Image lazy loading is useful for images outside the initial viewport, allowing to save up resources
            until the visitor scrolls to it.
          </fbt>
        </HelpText>
      </InputGroup>

      <FilterSelect
        filterType={componentProperties.filterType}
        actionFilterType={val => handlePropertyChange('filterType', val)}
        filterValue={componentProperties.filterValue || 0}
        actionFilterValue={val => handlePropertyChange('filterValue', val)}
      />
    </>
  );
};

export default ImageComponentProperties;
