// TODO maybe move to Selector, too many state dependencies
const menuHelper = {
  getLink({linkType = '', link = '', page = '', siteId = '', isPreview = false, isRender = false} = {}) {
    if (linkType === 'internal') {
      return this.getPageLink(page, siteId, isPreview, isRender);
    }

    if (linkType === 'anchor') {
      return link;
    }

    if (!linkType) {
      return '';
    }

    // Default external
    return link;
  },

  getPageLink(pageAlias, siteId, isPreview = false, isRender = false) {
    // TODO Router
    let base = isPreview ? '/preview-content' : '/builder';

    if (isRender) {
      if (!pageAlias) {
        // If no menu entry chosen
        return '/';
      }

      if (pageAlias.includes('/')) {
        if (pageAlias.endsWith('/index')) {
          // We need to get rid of it for the routing
          return `/${pageAlias.replace('/index', '')}/`;
        }

        // Sub folder
        return `/${pageAlias}/`;
      }

      if (pageAlias === 'home') {
        return `/`;
      }

      return `/${pageAlias}.html`;
    }

    return `${base}/${siteId}/${pageAlias}`;
  }
};

export default menuHelper;
