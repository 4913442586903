import { LOGOUT_USER, SET_TOKEN, SET_USER, UPDATE_USER_PROPERTY } from '../actionTypes';
import serviceHelper from '../../modules/serviceHelper';

export const apiStoreUser = user => (
  async dispatch => {
    const service = serviceHelper.getService('UserService');

    const res = await service.store(user);

    // We need to update it because of possible API changes in the user
    dispatch(setUser(res.data.item));
  }
);

// Normal actions
export const setUser = user => ({type: SET_USER, payload: {user}});
export const setToken = token => ({type: SET_TOKEN, payload: {token}});

export const logoutUser = () => ({type: LOGOUT_USER, payload: {}});
export const updateUserProperty = (category, key, value) => ({
  type: UPDATE_USER_PROPERTY,
  payload: {category, key, value}
});
