import IconProperties from '../../components/Properties/Elements/IconProperties';
import { ElementVisible } from '../../modules/consts/Elements';

const IconModel = {
  title: 'Icon',
  category: 'Media',
  helpPage: '/documentation/elements/icon',
  component: 'IconElement',
  propertyComponent: IconProperties,
  hasChildElements: false,
  generalProperties: {
    hasVisibility: true,
    hasTextAlign: true,
    hasTextColor: false,
    hasBackground: true,
    hideFont: true,
  },
  defaults: {
    demo: true,
    _id: null,
    identifier: 'New Icon',
    component: 'IconElement',
    componentProperties: {
      icon: 'paperplane',
      size: 800,
      color: 'primary',
      title: '',
      link: '',
      linkTarget: '_self',
      isNofollow: false,
    },
    generalProperties: {
      visibility: [ElementVisible.sm, ElementVisible.md, ElementVisible.lg, ElementVisible.xl],
      published: true,
      margin: [0, 0, 0, 0],
      padding: [10, 10, 10, 10],
      textAlign: 'center',
      display: 'inline-block',
    },
  },
};

export default IconModel;
