import * as components from '../components/Elements';

import React from 'react';
import elementHelper from './elementHelper';
import { showModal } from '../store/actions/modals';

const componentHelper = {
  getElementComponent(element, {
    parent,
    parentType,
    parentIndex = -1,
    index = -1,
    resortElement = () => {},
    resortEnd = () => {},
    role = "",
  } = {}) {
    const model = elementHelper.getElementModel(element.component);

    // Some helper functions
    const add = (e, dispatch) => {
      stop(e);
      const options = {to: element._id, toType: 'element'};

      if (model.setCategoryTab) {
        options.setCategoryTab = model.setCategoryTab;
      }

      dispatch(showModal('AddElementModal', options));
    };

    const stop = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    // TODO style + class here? ;)

    return React.createElement(components[element.component], {
      key: element._id,
      element,
      model,
      parentId: parent._id,
      parentType,
      parentIndex,
      index,
      resortElement,
      resortEnd,
      stop,
      addElement: add,
      role,
    });
  }
};

export default componentHelper;
