import React from 'react';
import { connect } from 'react-redux';

import Site from '../components/Site/Site';
import Section from '../components/Sections/Section';
import SectionErrorRenderer from '../components/SectionControls/SectionErrorRenderer';
import Loading from '../components/General/Loading';

const Render = props => {
  if (!props.site || !props.page || !props.sections || !props.elements) {
    return <Loading/>;
  }

  // Set render mode
  window.isRender = true;

  return (
    <div id="site-content">
      <Site>
        {props.page.sections.map(sectionId => {
          const section = props.sections.find(s => s._id === sectionId);

          if (!section) {
            // Mostly used for working against props propagating issues
            return <SectionErrorRenderer id={sectionId} key={sectionId}/>;
          }

          if (!section.generalProperties.published) {
            return null;
          }

          return <Section key={sectionId} id={sectionId} section={section}/>;
        })}
      </Site>
    </div>
  );
};

const mapStateToProps = state => ({
  page: state.activeSite.page,
  site: state.activeSite.site,
  sections: state.activeSite.sections,
  elements: state.activeSite.elements,
});

export default connect(mapStateToProps)(Render);
