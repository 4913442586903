import React from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import propertyHelper from '../../../modules/propertyHelper';
import SelectInput from './SelectInput';
import TextInput from './TextInput';
import { showModal } from '../../../store/actions/modals';
import InputGroup from '../InputGroup';

const MediaPicker = props => {
  const labelClass = props.showLabel ? '' : 'sr-only';

  const openMediaManager = () => {
    props.dispatch(showModal('MediaManagerModal', {
      image: props.image,
      action: props.action,
      createThumbnail: props.createThumbnail || false,
    }));
  };

  return (
    <>
      {props.title && <label className={labelClass}>{props.title}</label>}
      <div style={{'--d': 'flex', '--ai': 'center', '--mb': '10px'}}>
        {props.image && <img src={propertyHelper.getImagePath(props.image)} style={{'--mr': '15px'}}
                             alt="Preview" className="image-preview"/>}
        <button onClick={openMediaManager} className="mame-primary">
          <fbt desc="MediaPicker">
            Select Image
          </fbt>
        </button>
      </div>
      {props.hasAdvancedImage && (
        <>
          <SelectInput title={fbt('Image Size', 'MediaPicker')}
                       value={props.imageSize || ''}
                       action={props.imageSizeAction}
                       options={{
                         '': fbt('Custom', 'MediaPicker'),
                         'auto auto': fbt('Auto', 'MediaPicker'),
                         'cover': fbt('Cover', 'MediaPicker'),
                         'contain': fbt('Contain', 'MediaPicker'),
                       }}
                       showLabel
          />

          {!['auto auto', 'cover', 'contain'].includes(props.imageSize) && (
            <InputGroup>
              <TextInput title={fbt('Custom Image Size', 'MediaPicker')}
                         placeholder={fbt('Set an custom image size, like 3em 25%', 'MediaPicker')}
                         value={props.imageSize || ''}
                         action={props.imageSizeAction}
                         showLabel
              />
            </InputGroup>
          )}

          <InputGroup>
            <SelectInput title={fbt('Image Position', 'MediaPicker')}
                         value={props.imagePosition || ''}
                         action={props.imagePositionAction}
                         options={{
                           '': fbt('Custom', 'MediaPicker'),
                           'center': fbt('Center', 'MediaPicker'),
                           'left': fbt('Left', 'MediaPicker'),
                           'right': fbt('Right', 'MediaPicker'),
                           'top': fbt('Top', 'MediaPicker'),
                           'bottom': fbt('Bottom', 'MediaPicker'),
                         }}
                         showLabel
            />
          </InputGroup>

          {!['center', 'left', 'right', 'top', 'bottom'].includes(props.imagePosition) && (
            <InputGroup>
              <TextInput title={fbt('Custom Image Position', 'MediaPicker')}
                         placeholder={fbt('Set an custom image position, like 25% 75%', 'MediaPicker')}
                         value={props.imagePosition || ''}
                         action={props.imagePositionAction}
                         showLabel
              />
            </InputGroup>
          )}

          <InputGroup>
            <SelectInput title={fbt('Repeat Image', 'MediaPicker')}
                         showLabel
                         value={props.imageRepeat || ''}
                         action={props.imageRepeatAction}
                         options={{
                           'repeat': fbt('Repeat', 'MediaPicker'),
                           'no-repeat': fbt('No Repeat', 'MediaPicker'),
                           'repeat-x': fbt('Repeat x', 'MediaPicker'),
                           'repeat-y': fbt('Repeat y', 'MediaPicker'),
                           'space': fbt('Space', 'MediaPicker'),
                           'round': fbt('Round', 'MediaPicker'),
                           'repeat space': fbt('Repeat and Space', 'MediaPicker'),
                           'round space': fbt('Round and Space', 'MediaPicker'),
                           'no-repeat round': fbt('No-Repeat and Round', 'MediaPicker'),
                         }}
            />
          </InputGroup>
        </>
      )}
    </>

  );
};

export default connect()(MediaPicker);
