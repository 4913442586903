import React, { useEffect, useState } from 'react';
import fbt from 'fbt';
import CodeEditor from '@uiw/react-textarea-code-editor';

import HelpText from './HelpText';
import InputGroup from '../InputGroup';

const CodeInput = props => {
  const {language = 'html', value} = props;

  const [code, setCode] = useState(value);

  useEffect(() => {
    setCode(value);
  }, [value]);

  const handleBlur = () => {
    // TODO Validate would be nice with JSHint for example
    props.action(code);
  };

  const labelClass = props.showLabel ? '' : 'sr-only';

  return (
    <InputGroup className="mame-code-input">
      <label className={labelClass} htmlFor={props.id || null}>{props.title}</label>
      <CodeEditor
        value={code}
        language={language}
        placeholder={props.placeholder || fbt('<b>42</b>', 'CodeInput')}
        onChange={e => setCode(e.target.value)}
        padding="15"
        id={props.id || null}
        minHeight={200}
        onBlur={handleBlur}
        style={{
          fontSize: 12,
          backgroundColor: '#ddd',
          color: '#000',
          fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
          margin: '8px 0',
          borderRadius: '3px',
        }}
      />
      {props.showHelp && (
        <HelpText>
          <fbt desc="CodeEditor">Be careful this might break your site or even the builder.</fbt>
        </HelpText>
      )}
    </InputGroup>
  );
};

export default CodeInput;
