import AccordionProperties from '../../components/Properties/Elements/AccordionProperties';
import { ElementVisible } from '../../modules/consts/Elements';

const AccordionModel = {
  title: 'Accordion',
  category: 'Basic',
  helpPage: '/documentation/elements/accordion',
  component: 'AccordionElement',
  propertyComponent: AccordionProperties,
  hasChildElements: false,
  generalProperties: {
    hasVisibility: true,
    hasTextAlign: true,
    hasTextColor: false,
    hasBackground: false,
  },
  defaults: {
    demo: true,
    _id: null,
    identifier: 'New Accordion',
    component: 'AccordionElement',
    componentProperties: {
      style: '',
      items: [
        {
          itemId: 42,
          title: 'Accordion 1',
          isOpen: true,
          icon: '',
          elements: [],
        },
        {
          itemId: 43,
          title: 'Accordion 2',
          isOpen: false,
          icon: '',
          elements: [],
        }
      ],
    },
    generalProperties: {
      visibility: [ElementVisible.sm, ElementVisible.md, ElementVisible.lg, ElementVisible.xl],
      published: true,
      margin: [0, 0, 0, 0],
      padding: [3, 0, 3, 0],
    },
  },
  itemTemplate: {
    itemId: 0,
    title: 'New Accordion',
    isOpen: true,
    icon: '',
    elements: [],
  }
};

export default AccordionModel;
