import MapProperties from '../../components/Properties/Elements/MapProperties';
import { ElementVisible } from '../../modules/consts/Elements';

const MapModel = {
  title: 'Map',
  image: '/img/elements/map.webp',
  helpPage: '/documentation/elements/map',
  category: 'Actions',
  component: 'MapElement',
  propertyComponent: MapProperties,
  hasChildElements: false,
  generalProperties: {
    hasVisibility: true,
    hasTextAlign: false,
    hasTextColor: false,
    hasBackground: false,
  },
  defaults: {
    demo: true,
    _id: null,
    identifier: 'New Map',
    component: 'MapElement',
    componentProperties: {
      apiKey: '',
      height: 300,
      lat: '48.137',
      long: '11.575',
      zoom: 13,
      routing: false,
    },
    generalProperties: {
      visibility: [ElementVisible.sm, ElementVisible.md, ElementVisible.lg, ElementVisible.xl],
      published: true,
      margin: [0, 0, 0, 0],
      padding: [0, 0, 0, 0],
    },
  },
};

export default MapModel;
