import { ElementVisible } from '../../modules/consts/Elements';
import TextProperties from '../../components/Properties/Elements/TextProperties';

const TextModel = {
  title: 'Formatted Text',
  category: 'Basic',
  helpPage: '/documentation/elements/formatted-text',
  component: 'TextElement',
  propertyComponent: TextProperties,
  hasChildElements: false,
  generalProperties: {
    hasVisibility: true,
    hasTextAlign: true,
    hasTextColor: true,
    hasBackground: true,
  },
  defaults: {
    demo: true,
    _id: null,
    identifier: 'New Text',
    component: 'TextElement',
    componentProperties: {
      html: 'Lorem ipsum dolor <u>sit amet</u>, consetetur sadipscing <b>elitr</b>, sed diam nonumy eirmod tempor invidunt ut <b>labore</b>. '
    },
    generalProperties: {
      visibility: [ElementVisible.sm, ElementVisible.md, ElementVisible.lg, ElementVisible.xl],
      published: true,
      margin: [0, 0, 0, 0],
      padding: [0, 0, 0, 0],
    },
  },
};

export default TextModel;
