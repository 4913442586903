import React from 'react';
import fbt from 'fbt';

import SelectInput from './SelectInput';

const LinkTarget = props => {
  return (
    <SelectInput title={props.title}
                 value={props.value}
                 action={props.action}
                 options={{
                   '_blank': fbt('New Window or Tab', 'LinkTarget'),
                   '_self': fbt('Same Window', 'LinkTarget'),
                   '_parent': fbt('Parent Window (Frame)', 'LinkTarget'),
                   '_top': fbt('Full Body Window (for Popups)', 'LinkTarget'),
                 }}
                 showLabel
    />
  );
};

export default LinkTarget;
