import React from 'react';

// Redux
import { connect } from 'react-redux';

// Actions
import elementHelper from '../../modules/elementHelper';
import ElementDiv from '../ElementControls/ElementDiv';
import HtmlEdit from '../Properties/Controls/HtmlEdit';
import { updateElementHtml } from '../../store/actions/activeSite';
import { setActiveElement } from '../../store/actions/builder';
import { isPreviewSelector } from '../../store/selectors';

const HtmlEditor = props => {
  const element = props.element;

  const handleHtmlChange = (html) => {
    props.dispatch(updateElementHtml(element, html));
  };

  // Get classes
  const id = elementHelper.getId(element);
  const classes = elementHelper.getClasses(element);
  const style = elementHelper.getStyle(element);

  const setActive = () => {
    props.dispatch(setActiveElement(props.element));
  };

  return (
    <ElementDiv {...props} id={id} classes={classes} action={setActive}>
      <HtmlEdit
        id={id ? id + '-editor' : null}
        style={style}
        html={element.componentProperties.html}
        action={handleHtmlChange}
      />
    </ElementDiv>
  );
}

const mapStateToProps = state => ({
  isPreview: isPreviewSelector(state),
});

export default connect(mapStateToProps)(HtmlEditor);
