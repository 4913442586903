import produce from 'immer';
import { SET_LOCALE } from '../actionTypes';
import { IntlVariations } from 'fbt';

const initialState = {
  GENDER: IntlVariations.GENDER_UNKNOWN,
  locale: 'en_US',
};

const viewerContextState = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOCALE: {
      const {locale} = action.payload;

      return produce(state, draft => {
        draft.locale = locale;
      });
    }

    default: {
      return state;
    }
  }
};

export default viewerContextState;
