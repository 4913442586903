import React, { useState } from 'react';
import fbt from 'fbt';

import TextInput from './TextInput';
import ActionIcon from '../../General/ActionIcon';

const GenericSlider = (props) => {
  const [showDetails, setShowDetails] = useState(false);

  let style = {'--d': 'grid', '--gtc': '1fr 15px', '--gg': '10px', '--jc': 'center', '--ai': 'center'};

  if (props.icon) {
    style['--gtc'] = '20px 1fr 15px';
  }

  const sliderClass = props.className ? `${props.className} mame-primary` : 'mame-primary';
  const labelClass = props.showLabel ? '' : 'sr-only';
  const title = props.value ? `${props.title}: ${props.value}` : props.title;

  const id = props.id || null;
  const openIcon = `icon icon-${showDetails ? 'angle-up' : 'angle-down'}`;

  const showReset = props.hasReset && !!props.value;

  return (
    <div className="b-range-slider">
      <label className={labelClass} htmlFor={id}>
        {props.title}
        {showReset && (
          <ActionIcon icon="times"
                      title={fbt('Reset to default', 'AdvancedColorPicker')}
                      action={() => {
                        props.action(props.hasOwnProperty('defaultValue') ? parseFloat(props.defaultValue) : '');
                      }}
          />
        )}
      </label>

      <div style={style}>
        {props.icon && <span className={`icon icon-${props.icon}`}/>}
        <div>
          <input id={id}
                 style={props.hideMinMax ? null : {'--m': '7px 0 0'}}
                 className={sliderClass}
                 type="range"
                 title={title || null}
                 min={props.min || null}
                 max={props.max || null}
                 step={props.step}
                 value={props.value}
                 onBlur={props.actionBlur}
                 onChange={(e) => props.action(parseFloat(e.target.value))}
          />
          {!props.hideMinMax && (
            <div style={{'--d': 'flex', '--ai': 'center', '--jc': 'space-between'}}>
              {props.min && (<span style={{'--op': '0.7'}}>{props.min}</span>)}
              {props.max && (<span style={{'--op': '0.7'}}>{props.max}</span>)}
            </div>
          )}
        </div>
        <span className={openIcon}
              style={{'--cur': 'pointer'}}
              title={fbt('Numeric Input', 'GenericSlider')}
              onClick={() => {
                setShowDetails(!showDetails);
              }}/>
      </div>
      {showDetails && (
        <div style={{
          '--d': props.applyToAll ? 'grid' : 'block',
          '--gtc': '1fr auto',
          '--gg': '10px',
          '--ai': 'center',
        }}>
          <TextInput type="number"
                     title={title}
                     min={props.min}
                     max={props.max}
                     step={props.step || null}
                     value={props.value}
                     onBlur={props.actionBlur}
                     action={val => props.action(parseFloat(val))}
          />

          {props.applyToAll && (
            <button className="white"
                    style={{'--x': '80'}}
                    onClick={() => {
                      props.applyToAll(props.value);
                    }}>
              <fbt desc="GenericSlider">
                Apply to all
              </fbt>
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default GenericSlider;
