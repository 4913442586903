import React, { useEffect, useState } from 'react';
import fbt from 'fbt';

// Redux / Form
import { connect } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useHistory } from 'react-router-dom';
import { formatRelative } from 'date-fns';

import Fieldset from './FieldSet';
import ImageUpload from './Controls/ImageUpload';
import TextInput from './Controls/TextInput';
import CheckboxInput from './Controls/CheckboxInput';
import SelectInput from './Controls/SelectInput';
import AdvancedColorPicker from './Controls/AdvancedColorPicker';
import ActionIcon from '../General/ActionIcon';
import { updatePageProperty } from '../../store/actions/activePage';
import propertyHelper from '../../modules/propertyHelper';
import MetaFieldset from './Fieldsets/MetaFieldset';
import CustomizationFieldset from './Fieldsets/CustomizationFieldset';
import { removePage, updateSiteProperty } from '../../store/actions/activeSite';
import { closeActiveProperty, confirmationDialog } from '../../store/actions/builder';
import Alert from '../General/Alert';
import {
  addPageTemplate,
  apiLoadPageTemplates,
  pageToTemplate
} from '../../store/actions/templates';
import serviceHelper from '../../modules/serviceHelper';
import { showModal } from '../../store/actions/modals';
import HelpText from './Controls/HelpText';
import logger from '../../modules/logger';
import TypographyFieldset from './Fieldsets/TypographyFieldset';
import ColorsFieldset from './Fieldsets/ColorsFieldset';
import InputGroup from './InputGroup';

/**
 * - [x] Typography
 *   - [x] FontFamily
 *   - [x] SecondaryFont
 *   - [x] fontSize
 *   - [x] fontWeight
 *   - [x] lineHeight
 *
 * - [x] Colors
 *   - [x] Default Color
 *   - [x] Link Color
 *   - [x] Link Hover Color
 *   - [x] Primary Color
 *   - [x] Secondary Color
 *   - [x] Website Background
 *   - [x] Success Background
 *   - [x] Warning Background
 *
 * - [] Other defaults
 *    - [] Border Radius
 *    - [] Padding / Margin
 *
 * - [x] Meta
 *
 * - [] Settings
 *   - [x] Logo
 *   - [x] Logo Text
 *   - [x] Favicon
 *   - [x] TouchIcons (??)
 *   - [x] Cookie
 *     - [x] Enabled
 *     - [x] DisplayMode
 *     - [x] Position
 *     - [x] Text
 *     - [x] Agree Text
 *     - [x] Reject Text
 *     - [x] Link
 *   - [x] custom Code
 *     - [x] JavaScript
 *     - [x] CSS
 *
 * @param props
 * @returns {JSX.Element|string}
 * @constructor
 */
const SiteProperties = props => {
  if (!props.site) {
    return null;
  }

  const site = props.site;

  const handlePropertyChange = (category, key, value, subKey = null) => {
    props.dispatch(updateSiteProperty(category, key, value, subKey));
  };

  return (
    <div>
      <Tabs>
        <TabList>
          <Tab>
            <fbt desc="SiteProperties">General</fbt>
          </Tab>
          <Tab>
            <fbt desc="SiteProperties">Utilities</fbt>
          </Tab>
          <Tab>
            <fbt desc="SiteProperties">Pages</fbt>
          </Tab>
          <Tab>
            <fbt desc="SiteProperties">Hosting</fbt>
          </Tab>
          <Tab>
            <fbt desc="SiteProperties">Meta</fbt>
          </Tab>
        </TabList>
        <TabPanel>
          <Fieldset title={fbt('Site Title', 'SiteProperties')} isOpen>
            <InputGroup>
              <TextInput title={fbt('Site Title (Global)', 'SiteProperties')}
                         value={site.title}
                         id="input-site-title"
                         blur={val => {
                           if (val) {
                             handlePropertyChange('title', null, val);
                           }
                         }}
                         required
              />

              <HelpText>
                <fbt desc="SiteProperties">
                  The site title is used to find the site in the Dashboard and may be appended to the Page Title,
                  e.g. "Homepage - My Site Title"
                </fbt>
              </HelpText>
            </InputGroup>

            <InputGroup>
              <SelectInput title={fbt('Append the Site Title to the Page Title', 'SiteProperties')}
                           value={site.settings.titlePosition}
                           id="select-title-position"
                           options={{
                             '': fbt('After Page Title', 'SiteProperties'),
                             'before': fbt('Before Page Title', 'SiteProperties'),
                             'hide': fbt('Hide', 'SiteProperties')
                           }}
                           action={val => handlePropertyChange('settings', 'titlePosition', val)}
                           showLabel
              />
            </InputGroup>
          </Fieldset>
          <Fieldset title={fbt('Logo', 'SiteProperties')}>
            <HelpText style={{'--m': '10px 0'}}>
              <fbt desc="SiteProperties">
                Your logo is used in various elements and section templates. For best results
                use an image with dimensions less than 512px. (JPG / PNG / WEBP)
              </fbt>
            </HelpText>

            <ImageUpload image={site.settings.logo}
                         text={fbt('Click to add your Logo or drop it here.', 'SiteProperties')}
                         action={val => handlePropertyChange('settings', 'logo', val)}
                         defaultResize={false}
            />

            <InputGroup>
              <TextInput title={fbt('Logo Text', 'SiteProperties')}
                         value={site.settings.logoText}
                         id="input-site-logo-text"
                         tooltip={fbt('Used if you haven\'t set a Logo and as alternate Text for the image', 'SiteProperties')}
                         blur={val => handlePropertyChange('settings', 'logoText', val)}
                         showLabel
              />
              <HelpText>
                <fbt desc="SiteProperties">
                  If you don't have a logo this text is used instead, else it's used as alternate text (alt)
                  attribute for the logo.
                </fbt>
              </HelpText>
            </InputGroup>
          </Fieldset>

          <TypographyFieldset
            typography={site.typography}
            handlePropertyChange={handlePropertyChange}
          />

          <ColorsFieldset
            colors={site.colors}
            handlePropertyChange={handlePropertyChange}
          />

          <CustomizationFieldset
            customCode={site.settings.customCode}
            action={(subKey, val) => handlePropertyChange('settings', 'customCode', val, subKey)}
          />
        </TabPanel>
        <TabPanel>
          <FavIcon
            value={site.settings.favIcon}
            uploadAction={(path) => handlePropertyChange('settings', 'favIcon', path)}
          />

          <Fieldset title={fbt('Cookie Banner', 'SiteProperties')}>
            <HelpText style={{'--m': '10px 0'}}>
              <fbt desc="SiteProperties">
                Sites generated with Mame set no cookies directly, but certain services you use might.
                (For example Google Maps, Analytics..). For these you may want to include the cookie banner.
              </fbt>
            </HelpText>
            <InputGroup>
              <CheckboxInput checked={site.settings.cookie.enabled}
                             title={fbt('Enable Cookie Banner', 'SiteProperties')}
                             id="checkbox-cookie-enable"
                             action={() => handlePropertyChange('settings', 'cookie', !site.settings.cookie.enabled, 'enabled')}
              />
            </InputGroup>

            <InputGroup>
              <CheckboxInput checked={site.settings.cookie.devEnabled}
                             title={fbt('Show during Development (Builder)', 'SiteProperties')}
                             id="checkbox-cookie-devEnableddev"
                             action={() => handlePropertyChange('settings', 'cookie', !site.settings.cookie.devEnabled, 'devEnabled')}
              />
            </InputGroup>

            <HelpText style={{'--mb': '10px'}}>
              <fbt desc="SiteProperties">
                Edit the text directly on the cookie bar. You may want to include a link to the cookie policy.
              </fbt>
            </HelpText>

            <InputGroup>
              <SelectInput id="cookie-display-as"
                           title={fbt('Display as', 'SiteProperties')}
                           options={{
                             bar: fbt('Bar', 'SiteProperties'),
                             inline: fbt('Inline Element', 'SiteProperties')
                           }}
                           value={site.settings.cookie.displayAs}
                           action={val => handlePropertyChange('settings', 'cookie', val, 'displayAs')}
                           showLabel
              />
            </InputGroup>

            <InputGroup>
              <SelectInput id="cookie-position"
                           title={fbt('Position', 'SiteProperties')}
                           options={{
                             bottom: fbt('Bottom of the page', 'SiteProperties'),
                             top: fbt('Top of the Page', 'SiteProperties')
                           }}
                           value={site.settings.cookie.position}
                           action={val => handlePropertyChange('settings', 'cookie', val, 'position')}
                           showLabel
              />
            </InputGroup>

            <InputGroup>
              <TextInput title={fbt('Accept Button Text', 'SiteProperties')}
                         value={site.settings.cookie.agreeText}
                         action={val => handlePropertyChange('settings', 'cookie', val, 'agreeText')}
                         showLabel
              />
            </InputGroup>

            <InputGroup>
              <TextInput title={fbt('Reject Button Text', 'SiteProperties')}
                         value={site.settings.cookie.rejectText}
                         action={val => handlePropertyChange('settings', 'cookie', val, 'rejectText')}
                         showLabel
              />
            </InputGroup>

            <AdvancedColorPicker title={fbt('Background Color', 'SiteProperties')}
                                 value={site.settings.cookie.backgroundColor}
                                 action={(val) => handlePropertyChange('settings', 'cookie', val, 'backgroundColor')}
                                 valueText={site.settings.cookie.textColor || ''}
                                 actionText={(val) => handlePropertyChange('settings', 'cookie', val, 'textColor')}
            />
          </Fieldset>

          <Integrations
            {...props}
            handlePropertyChange={handlePropertyChange}
          />
        </TabPanel>
        <TabPanel>
          <PageFieldset {...props} />
        </TabPanel>
        <TabPanel>
          {!site.hosting.deployType && (
            <p style={{'--ta': 'center'}}>
              <fbt desc="SiteProperties">
                You need to <span className="fake-link" onClick={() => {
                props.dispatch(showModal('PublishModal'));
              }}>publish</span> your website first.
              </fbt>
            </p>
          )}

          {site.hosting.deployType === 'mame' && (
            <MameHosting {...props}/>
          )}

          {site.hosting.deployType === 'aws' && (
            <AwsHosting {...props}/>
          )}
        </TabPanel>
        <TabPanel>
          <Fieldset title={fbt('SEO Settings', 'SiteProperties')} isOpen>
            <InputGroup style={{'--m': '10px 0'}}>
              <TextInput title={fbt('Canonical Base URL (optional)', 'SiteProperties')}
                         type="url"
                         value={site.meta.canonicalUrl}
                         placeholder="https://..."
                         blur={val => handlePropertyChange('meta', 'canonicalUrl', val)}
                         showLabel
              />
              <HelpText>
                <fbt desc="SiteProperties">
                  Set the base URL (like https://mame.app) for the website. With canonical URLs you can avoid duplicate
                  URLs and content in search engines. You can override it per page in the page properties.
                </fbt>
              </HelpText>
            </InputGroup>
          </Fieldset>

          <MetaFieldset
            meta="site"
          />

          <Fieldset title={fbt('Security Settings', 'SiteProperties')}>
            <InputGroup style={{'--mt': '10px'}}>
              <CheckboxInput
                title={fbt('Enable Content Security Policy', 'SiteProperties')}
                checked={site.settings.csp}
                action={() => handlePropertyChange('settings', 'csp', !site.settings.csp)}
              />
            </InputGroup>

            <InputGroup>
              <CheckboxInput
                title={fbt('Referrer Policy (same-origin)', 'SiteProperties')}
                checked={site.settings.referrerPolicy}
                action={() => handlePropertyChange('settings', 'referrerPolicy', !site.settings.referrerPolicy)}
              />
            </InputGroup>
          </Fieldset>

          <Fieldset title={fbt('Manifest Generation', 'SiteProperties')}>
            <HelpText style={{'--m': '10px 0'}}>
              <fbt desc="SiteProperties">
                With a web app manifest your website can be "installed" to a mobile devices home screen without the app
                store.
                It provides information about your website and styles the icon. Mame generates most of it automatically,
                these settings are offering some customization options.
              </fbt>
            </HelpText>

            <InputGroup>
              <TextInput title={fbt('Application Name', 'SiteProperties')}
                         value={site.settings.manifest.name}
                         blur={val => handlePropertyChange('settings', 'manifest', val, 'name')}
                         showLabel
              />
            </InputGroup>

            <InputGroup>
              <TextInput title={fbt('Short Name', 'SiteProperties')}
                         value={site.settings.manifest.shortName}
                         blur={val => handlePropertyChange('settings', 'manifest', val, 'shortName')}
                         showLabel
              />
            </InputGroup>

            <AdvancedColorPicker title={fbt('App Background Color', 'SiteProperties')}
                                 value={site.settings.manifest.backgroundColor || ''}
                                 action={val => {
                                   handlePropertyChange('settings', 'manifest', val, 'backgroundColor');
                                 }}
                                 actionReset={() => {
                                   handlePropertyChange('settings', 'manifest', '', 'backgroundColor');
                                 }}
            />

            <AdvancedColorPicker title={fbt('App Theme Color', 'SiteProperties')}
                                 value={site.settings.manifest.themeColor || ''}
                                 action={val => {
                                   handlePropertyChange('settings', 'manifest', val, 'themeColor');
                                 }}
                                 actionReset={() => {
                                   handlePropertyChange('settings', 'manifest', '', 'backgroundColor');
                                 }}
            />

            <InputGroup>
              <TextInput title={fbt('Start URL', 'SiteProperties')}
                         value={site.settings.manifest.startUrl || '/'}
                         blur={val => handlePropertyChange('settings', 'manifest', val, 'startUrl')}
                         showLabel
              />
            </InputGroup>
          </Fieldset>


        </TabPanel>

      </Tabs>
    </div>
  );
};

const FavIcon = props => {
  const [cache, setCache] = useState(Date.now());

  const handleFaviconUpload = (data) => {
    if (!data.path) {
      logger.warn('No path in upload request (handleFaviconUpload)', data);
      return;
    }

    setCache(Date.now());
    props.uploadAction(data.path);
  };

  const imgPath = propertyHelper.getImagePath(`${props.value}original.png?t=${cache}`);

  return (
    <Fieldset title={fbt('Favicon', 'SiteProperties')} isOpen>
      <HelpText style={{'--m': '10px 0'}}>
        <fbt desc="SiteProperties">
          Icons for mobile devices (iOS and Android) and application Icons are
          automatically generated.
          For best results use a uniform high resolution image with transparency.
          (>= 512px by 512px)
        </fbt>
      </HelpText>

      {props.value && (
        <div style={{'--ta': 'center'}}>
          <img src={imgPath} alt="FavIcon Preview" style={{'--maxh': '100px', '--maxw': '200px'}}/>
        </div>
      )}

      <ImageUpload
        task="uploadFavicon"
        disableResize
        disableWebp
        action={(data) => {
          handleFaviconUpload(data);
        }}
      />
    </Fieldset>
  );
};

const MameHosting = props => {
  const {hosting} = props.site;

  return (
    <>
      <Fieldset title={fbt('Overview', 'SiteProperties')} isOpen>
        <fbt desc="SiteProperties">
          Your site is hosted on our servers and available at:
        </fbt>
        <br/>
        <a href={`https://${hosting.subDomain}.mame.app`} target="_blank"
           rel="noreferrer noopener">{hosting.subDomain}.mame.app</a>

        <br/>
        <br/>
        <fbt desc="SiteProperties">
          Last update:
        </fbt>
        <br/>

        {formatRelative(new Date(hosting.updated_at), new Date())}
      </Fieldset>

      <Fieldset title={fbt('Domains', 'SiteProperties')} isOpen>
        <TextInput title={fbt('Subdomain', 'SiteProperties')}
                   disabled
                   showLabel
                   value={hosting.subDomain}
        />
        <HelpText>
          <fbt desc="SiteProperties">
            You can't change the subdomain.
          </fbt>
        </HelpText>

        <div>
          <label>
            <fbt desc="SiteProperties">Other Domains</fbt>
          </label>
        </div>

        {!hosting.domains.length && (
          <div>
            <fbt desc="SiteProperties">
              You haven't added any other domains yet.
            </fbt>
          </div>
        )}

        {hosting.domains.map((domain, index) => {
          return (
            <div key={index}
                 style={{'--p': '10px', '--b': '1px solid #eee', '--my': '10px'}}>
              {domain}
            </div>
          );
        })}

        <div>
          <button className="mame-primary" onClick={() => {
            props.dispatch(showModal('AddDomainModal'));
          }}>
            <fbt desc="SiteProperties">
              Add Domain
            </fbt>
          </button>
        </div>
      </Fieldset>
    </>
  );
};

const AwsHosting = props => {
  const {hosting} = props.site;

  const removeAws = async () => {
    // We just remove the hosting settings here, easy..
    props.dispatch(updateSiteProperty('hosting', 'deployType', '', null));
  };

  return (
    <>
      <Fieldset title={fbt('Overview', 'SiteProperties')} isOpen>
        <h4 style={{'--m': '10px 0'}}>
          <fbt desc="SiteProperties">
            Hosted on AWS
          </fbt>
        </h4>

        <fbt desc="SiteProperties">
          Your site is hosted on Amazon Web Services S3.
        </fbt>
        <br/>
        <br/>

        <fbt desc="SiteProperties">
          Bucket:
        </fbt>
        <br/>
        <strong>
          {hosting.awsSettings.bucket}
        </strong>
        <br/>
        <br/>

        <fbt desc="SiteProperties">
          Key Id:
        </fbt>
        <br/>
        {hosting.awsSettings.id}

        <br/>
        <br/>
        <fbt desc="SiteProperties">
          Last update:
        </fbt>
        <br/>
        {formatRelative(new Date(hosting.updated_at), new Date())}
      </Fieldset>

      <Fieldset title={fbt('Settings', 'SiteProperties')} isOpen>
        <HelpText style={{'--m': '10px 0'}}>
          <fbt desc="SiteProperties">
            In order to change your AWS or hosting settings you have to remove the current AWS Hosting and publish the
            site again.
          </fbt>
        </HelpText>
        <button className="mame-primary" onClick={removeAws}>
          <fbt desc="SiteProperties">
            Remove Hosting
          </fbt>
        </button>
      </Fieldset>
    </>
  );
};

const Integrations = props => {
  const {integrations} = props.site;

  // We have to wait until it shows up, after an site update
  if (!integrations) {
    return null;
  }

  return (
    <Fieldset title={fbt('Analytics (Visitor Statistics)', 'SiteProperties')}>
      <h4 style={{'--m': '10px 0'}}>Plausible.io</h4>
      <HelpText style={{'--m': '10px 0'}}>
        <fbt desc="SiteProperties">
          Plausible is a privacy friendly and lightweight analytics software.
        </fbt>
      </HelpText>
      <InputGroup>
        <CheckboxInput
          title={fbt('Enable Plausible.io', 'SiteProperties')}
          checked={integrations.plausible.enabled}
          action={() => props.handlePropertyChange('integrations', 'plausible', !integrations.plausible.enabled, 'enabled')}
        />
      </InputGroup>

      <InputGroup>
        <TextInput
          title={fbt('Plausible Domain (data-domain)', 'SiteProperties')}
          value={integrations.plausible.domain}
          action={val => props.handlePropertyChange('integrations', 'plausible', val, 'domain')}
          showLabel
        />
      </InputGroup>

      <h4 style={{'--m': '25px 0 15px'}}>Google Analytics / Tag Manager</h4>

      <InputGroup>
        <CheckboxInput
          title={fbt('Enable Google Analytics', 'SiteProperties')}
          checked={integrations.analytics.enabled}
          action={() => props.handlePropertyChange('integrations', 'analytics', !integrations.analytics.enabled, 'enabled')}
        />
      </InputGroup>

      <InputGroup>
        <TextInput
          title={fbt('Tracking Code (e.g. UA-12354123-1)', 'SiteProperties')} showLabel
          value={integrations.analytics.uaCode}
          action={val => props.handlePropertyChange('integrations', 'analytics', val, 'uaCode')}
        />
      </InputGroup>

      <InputGroup>
        <CheckboxInput
          title={fbt('Anonymize IP', 'SiteProperties')}
          checked={integrations.analytics.anonymizeIp}
          action={() => props.handlePropertyChange('integrations', 'analytics', !integrations.analytics.anonymizeIp, 'anonymizeIp')}
        />
      </InputGroup>
    </Fieldset>
  );
};

const PageFieldset = props => {
  const [duplicatePages, setDuplicatePages] = useState([]);
  const [filteredPages, setFilteredPages] = useState(props.pages);
  const [search, setSearch] = useState('');
  const history = useHistory();

  const {pages} = props;

  useEffect(() => {
    setFilteredPages(pages);
    setSearch('');

    if (!pages || !pages.length) {
      return;
    }

    const aliases = pages.map(p => p.alias);

    const duplicates = aliases.filter((item, index) => aliases.indexOf(item) !== index);
    setDuplicatePages([...new Set(duplicates)]);
  }, [pages]);

  const site = props.site;

  const handlePagePropertyChange = (page, category, key, value, subKey = null) => {
    props.dispatch(updatePageProperty(page, category, key, value, subKey));
  };

  const handleAliasChange = (page, value) => {
    if (!value) {
      return;
    }

    if (page.alias === props.page.alias) {
      logger.debug('CHANGING Current open alias, redirecting');
    }

    handlePagePropertyChange(page, 'alias', null, value);

    if (page.alias === props.page.alias) {
      history.push(`/builder/${props.site._id}/${value}`);
    }
  };

  const handleSearch = term => {
    setSearch(term);
    setFilteredPages(props.pages.filter(p => (p.title.toLowerCase().includes(term) || p.alias.includes(term))));
  };

  const handlePageRedirect = item => {
    if (item._id === props.page._id) {
      return;
    }

    props.dispatch(closeActiveProperty());
    history.push(`/builder/${site._id}/${item.alias}`);
  };

  return (
    <Fieldset title={fbt('Pages', 'SiteProperties')} isOpen>
      {!!duplicatePages.length && (
        <Alert variant="mame-warning" className="alert-alias-warning" style={{'--m': '10px 0'}} hideDismiss>
          <b>
            <fbt desc="SiteProperties">
              Warning: You have multiple pages with the same alias.
            </fbt>
          </b>
          <br/>
          <br/>
          <fbt desc="Site Properties">
            <b>Duplicate Aliases:</b>
          </fbt>
          <br/>
          {duplicatePages.map(page => {
            return (
              <div key={page}>{page}</div>
            );
          })}
        </Alert>
      )}

      <TextInput value={search}
                 type="search"
                 id="input-page-search"
                 placeholder={fbt('Search Pages...', 'SiteProperties')}
                 action={handleSearch}
      />

      <HelpText style={{'--m': '10px 0'}}>
        <fbt desc="SiteProperties">
          Overview of your pages, for more options open the page and select Page Properties.
          Clicking on the title opens the page in the builder.
        </fbt>
      </HelpText>

      {filteredPages.map((item, index) => {
        const last = index === filteredPages.length - 1;

        return (
          <details key={item._id} className="site-properties-page" open={last ? item.alias !== '404' : null}>
            <summary style={{'--d': 'flex', '--ai': 'center'}} className="site-properties-page__summary">
              <div style={{'--fg': '1'}}>
                <span
                  onClick={() => {
                    handlePageRedirect(item);
                  }}
                  className={`site-properties-page__link fake-link ${item._id === props.page._id ? 'strong' : ''}`}
                  role="button">
                  {item.title}
                </span>
              </div>
              <div>
                {process.env.NODE_ENV === 'development' && (
                  <>
                    <ActionIcon icon="cloud-download" title={fbt('Save as System Template', 'SiteProperties')}
                                action={async e => {
                                  e.preventDefault();
                                  // TODO move to state, else we need sections and elements here (PERFORMANCE)
                                  const template = pageToTemplate(item, props.sections, props.elements);

                                  template.kind = 'system';
                                  template.image = `/img/page/${item.alias}.webp`;

                                  const service = serviceHelper.getService('PageTemplateService');
                                  const res = await service.store(template);

                                  props.dispatch(addPageTemplate(res.data.item));
                                }}
                    />

                    <ActionIcon icon="repeat" title={fbt('Update System Template', 'SiteProperties')}
                                action={async e => {
                                  e.preventDefault();

                                  console.log('Updating system page template');

                                  // TODO move to state, else we need sections and elements here (PERFORMANCE)
                                  const template = pageToTemplate(item, props.sections, props.elements);

                                  template.kind = 'system';
                                  template._id = item.fromTemplateId;

                                  const service = serviceHelper.getService('PageTemplateService');
                                  await service.store(template);

                                  props.dispatch(apiLoadPageTemplates());
                                }}
                    />
                  </>
                )}

                {item.isHome ? <ActionIcon title={fbt('Default Page (Homepage)', 'SiteProperties')} icon="star"/> : ''}

                <ActionIcon
                  icon="share-square-o"
                  title={fbt('Open Page', 'SiteProperties')}
                  action={(e) => {
                    e.preventDefault();
                    handlePageRedirect(item);
                  }}
                />

                <ActionIcon
                  icon="save"
                  title={fbt('Save Page as Template', 'SiteProperties')}
                  action={(e) => {
                    e.preventDefault();
                    props.dispatch(showModal('SaveTemplateModal', {item, type: 'page'}));
                  }}
                />

                <ActionIcon
                  icon="trash-o"
                  title={fbt('Delete Page', 'SiteProperties')}
                  action={(e) => {
                    e.preventDefault();

                    props.dispatch(confirmationDialog(
                      fbt('Do you really want to delete this page?', 'SiteProperties'),
                      () => {
                        props.dispatch(removePage(item._id));
                      },
                    ));
                  }}/>
              </div>
            </summary>
            <div className="site-properties-page__properties">
              <InputGroup>
                <TextInput title={fbt('Page Title', 'SiteProperties')}
                           className="site-properties-page__title"
                           value={item.title}
                           blur={val => {
                             handlePagePropertyChange(item, 'title', null, val);
                           }}
                           showLabel
                           required
                />
                <HelpText>
                  <fbt desc="SiteProperties">
                    The title of the Page. (Shown in the browser's title bar, search engines...)
                  </fbt>
                </HelpText>
              </InputGroup>

              <InputGroup>
                <TextInput title={fbt('Alias (URL)', 'SiteProperties')}
                           className="site-properties-page__alias"
                           value={item.alias}
                           filter="[^a-z0-9/_-]"
                           blur={val => {
                             handleAliasChange(item, val);
                           }}
                           required
                           showLabel
                />
                <HelpText>
                  <fbt desc="SiteProperties">
                    The alias sets the address under which the page can be found later.
                    For example 'test', makes this page available at /test.html.
                    Hint: If you use slashes it creates a sub folder.
                  </fbt>
                  <br/>
                  <a href="https://mame.app/documentation/routing/" rel="noreferrer noopener" target="_blank">
                    <fbt desc="SiteProperties">
                      Learn more
                    </fbt>
                  </a>
                </HelpText>
              </InputGroup>

              <InputGroup>
                <CheckboxInput title={fbt('Default Page (Homepage)', 'SiteProperties')}
                               tooltip={fbt('The default page is going to be the index.html', 'PageProperties')}
                               checked={item.isHome}
                               disabled
                               action={() => {
                                 // TODO make sure there is only one
                                 handlePagePropertyChange(item, 'isHome', null, !item.isHome);
                               }}
                />
              </InputGroup>
            </div>
          </details>
        );
      })}

      <div style={{'--ta': 'right'}}>
        <button className="mame-primary" id="btn-add-page" onClick={() => {
          props.dispatch(showModal('AddPageModal'));
        }}>
          <fbt desc="SiteProperties">
            Add Page
          </fbt>
        </button>
      </div>
    </Fieldset>
  );
};

const mapStateToProps = state => ({
  elements: state.activeSite.elements,
  sections: state.activeSite.sections,
  pages: state.activeSite.pages,
  site: state.activeSite.site,
  page: state.activeSite.page,
  activeType: state.builder.activeType,
});

export default connect(mapStateToProps)(SiteProperties);
