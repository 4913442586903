import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import SiteFigure from '../components/SiteControls/SiteFigure';
import Loading from '../components/General/Loading';
import TopMenu from '../components/Menu/TopMenu';
import NewSiteModal from '../components/Modals/NewSiteModal';
import Footer from '../components/General/Footer';
import Alert from '../components/General/Alert';
import { apiLoadUserSites } from '../store/actions/sites';
import { showModal } from '../store/actions/modals';
import { apiLoadSettings } from '../store/actions/settings';
import SharedModals from '../components/Modals/SharedModals';
import SaveTemplateModal from '../components/Modals/SaveTemplateModal';
import { apiLoadSiteTemplates } from '../store/actions/templates';

const Dashboard = props => {
  const {dispatch} = props;

  // Load sites, settings and templates
  useEffect(() => {
    dispatch(apiLoadUserSites());
    dispatch(apiLoadSiteTemplates());

    // Make sure settings are loaded (currently done twice to fix a race condition in joyride)..
    dispatch(apiLoadSettings());
  }, [dispatch]);

  const newSite = () => {
    props.dispatch(showModal('NewSiteModal', {}));
  };

  if (!props.user) {
    return (
      <Loading/>
    );
  }

  const handleReportClick = (e) => {
    e.preventDefault();
    props.dispatch(showModal('ReportModal'));
  };

  return (
    <>
      <TopMenu/>
      <Alert>
        <fbt desc="Dashboard Welcome Text">
          Welcome to the Mame Beta! Please <span onClick={handleReportClick}
                                                 style={{'--td': 'underline', '--cur': 'pointer'}}>report</span> any
          bugs you encounter. The shop, where you can offer and get templates, is currently not available.
        </fbt>
      </Alert>
      <div style={{'--bg': 'var(--sidebar-background)', '--c': 'var(--sidebar-color)', '--p': '40px'}}>
        <div className="wrapper" style={{'--gutter': '1vw'}}>
          <h3>
            <fbt desc="Dashboard">
              Your sites
            </fbt>
          </h3>

          <div style={{'--d': 'grid', '--gtc': '1fr 1fr 1fr', '--gg': '30px', '--mt': '20px'}}>
            {props.sites.map(site => {
              return (
                <SiteFigure
                  site={site}
                  key={site._id}
                />
              );
            })}

            <figure className="site-preview accent new-site-figure" style={{'--levitate-hvr': 5}}
                    onClick={newSite}>
              <div style={{'--ta': 'center', '--mt': '110px', '--mb': '80px'}}>
                <span className="icon icon-plus icon-4x"/>
              </div>
              <figcaption style={{'--ta': 'center'}}>
                <h4>
                  <fbt desc="New Site Button">
                    New Site
                  </fbt>
                </h4>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
      <Footer/>

      {/* Modals */}
      <NewSiteModal/>
      <SaveTemplateModal/>
      <SharedModals/>
    </>
  );
};

const mapStateToProps = state => ({
  user: state.user.user,
  token: state.user.token,
  sites: state.sites.sites,
});

export default connect(mapStateToProps)(Dashboard);
