import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import Site from '../components/Site/Site';
import Section from '../components/Sections/Section';
import Sidebar from '../components/Properties/SideBar';

import Loading from '../components/General/Loading';
import TopMenu from '../components/Menu/TopMenu';

import { updatePageProperty } from '../store/actions/activePage';
import SectionErrorRenderer from '../components/SectionControls/SectionErrorRenderer';

// Modals
import { showModal } from '../store/actions/modals';
import AddPageModal from '../components/Modals/AddPageModal';
import MediaManagerModal from '../components/Modals/MediaManagerModal';
import AddElementModal from '../components/Modals/AddElementModal';
import AddSectionModal from '../components/Modals/AddSectionModal';
import AddDomainModal from '../components/Modals/AddDomainModal';
import SharedModals from '../components/Modals/SharedModals';

// Drag and Drop
import DnDContainer from '../components/Sortable/DnDContainer';
import DnDItem from '../components/Sortable/DnDItem';
import { DragTypes } from '../modules/DragTypes';
import SaveTemplateModal from '../components/Modals/SaveTemplateModal';
import BuilderBreadcrumb from '../components/General/BuilderBreadcrumb';
import Mousetrap from 'mousetrap';

const Builder = props => {
  const [sortedSections, setSortedSections] = useState(null);
  const {page, dispatch} = props;

  useEffect(() => {
    if (!page) {
      return;
    }

    setSortedSections(page.sections);
  }, [page]);

  const addSection = () => {
    dispatch(showModal('AddSectionModal', {}));
  };

  const storeSorting = newSorting => {
    dispatch(updatePageProperty(page, 'sections', null, newSorting));
  };

  useEffect(() => {
    Mousetrap.bind('ctrl+alt+s', () => {
      dispatch(showModal('AddSectionModal', {}));
    });

    Mousetrap.bind('ctrl+alt+p', () => {
      dispatch(showModal('AddPageModal'));
    });

    return () => {
      Mousetrap.unbind('ctrl+alt+s');
      Mousetrap.unbind('ctrl+alt+p');
    }
  }, [dispatch]);

  // Wait until everything is loaded
  if (!props.site || !page || !props.sections || !props.elements || !sortedSections) {
    return (
      <Loading/>
    );
  }

  return (
    <>
      <TopMenu/>
      <div className="builder" style={{'--d': 'flex'}}>
        {props.settings.sidebarPlacement === 'left' && <Sidebar/>}
        <div id="site-content">
          <Site>
            <DnDContainer items={sortedSections} dragHandler
                          dragType={DragTypes.SECTION}
                          onMove={updatedList => {
                            setSortedSections(updatedList);
                          }}
                          group="sections"
                          onEnd={storeSorting}>
              {sortedSections.map((sectionId, index) => {
                const section = props.sections.find(s => s._id === sectionId);

                if (!section) {
                  // Mostly used for working against props propagating issues
                  return <SectionErrorRenderer id={sectionId} key={sectionId}/>;
                }

                return (
                  <DnDItem key={sectionId} id={sectionId} index={index}>
                    <Section index={index}
                             key={sectionId}
                             id={sectionId}
                             section={section}
                    />
                  </DnDItem>
                );
              })}
            </DnDContainer>
            <div className="mame-new-section"
                 title={fbt('New Section', 'New Section')}
                 aria-label={fbt('New Section', 'New Section')}
                 role="button"
                 onClick={addSection}>
              <span className="icon icon-plus icon-3x" role="button" aria-hidden/>
            </div>
          </Site>
        </div>
        {props.settings.sidebarPlacement === 'right' && <Sidebar/>}
        <BuilderBreadcrumb/>
      </div>
      <AddPageModal/>
      <AddElementModal/>
      <AddSectionModal/>
      <MediaManagerModal/>
      <AddDomainModal/>
      <SaveTemplateModal/>
      <SharedModals/>
    </>
  );
};

const mapStateToProps = state => ({
  settings: state.settings,
  page: state.activeSite.page,
  site: state.activeSite.site,
  sections: state.activeSite.sections,
  elements: state.activeSite.elements,
  pages: state.activeSite.pages,
  sites: state.sites.sites,
});

export default connect(mapStateToProps)(Builder);
