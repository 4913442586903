import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import elementHelper from '../../modules/elementHelper';
import ElementDiv from '../ElementControls/ElementDiv';
import propertyHelper from '../../modules/propertyHelper';

import { setActiveElement } from '../../store/actions/builder';
import ElementLink from '../ElementControls/ElementLink';

const ImageElement = props => {
  const [dimensions, setDimensions] = useState({width: 0, height: 0});
  const {element} = props;
  const {componentProperties} = element;

  useEffect(() => {
    // Prevent wrong image dimensions, should be reset after loading the image
    if (componentProperties.image) {
      setDimensions({width: 0, height: 0});
    }
  }, [componentProperties.image]);

  const id = elementHelper.getId(element);
  const classes = elementHelper.getClasses(element);
  const style = elementHelper.getStyle(element);

  // Image is always block, but centered
  // style['--d'] = 'block';
  delete style['--d'];
  delete style['--m'];
  delete style['--pos'];
  delete style['--right'];
  delete style['--top'];
  delete style['--left'];
  delete style['--bottom'];
  // style['--m'] = '0 auto';

  const elementStyle = {};

  propertyHelper.setDisplay(elementStyle, element.generalProperties);
  propertyHelper.setTextAlign(elementStyle, element.generalProperties);
  propertyHelper.setSpacing(elementStyle, element.generalProperties.margin, false);
  propertyHelper.setDimensions(elementStyle, element.generalProperties);
  propertyHelper.setPosition(elementStyle, element.generalProperties);

  const setActive = e => {
    e.preventDefault();
    props.dispatch(setActiveElement(element));
  };

  const handleImageLoad = e => {
    if (!componentProperties.autoDimensions) {
      return;
    }

    setDimensions({width: e.target.offsetWidth, height: e.target.offsetHeight});
  };

  if (componentProperties.filterType) {
    if (componentProperties.filterType === 'blur') {
      style['--fr'] = `blur(${componentProperties.filterValue}px)`;
    } else {
      style['--fr'] = `${componentProperties.filterType}(${componentProperties.filterValue}%)`;
    }
  }

  if (componentProperties.autoDimensions) {
    // Hardcoded for now, let's see if that brings issues
    style['maxWidth'] = '100%';
    style['height'] = 'auto';
  }

  if (!componentProperties.alt) {
    console.log('[Mame] Image is missing alt attribute, please set one!');
  }

  const img = (
    <img src={propertyHelper.getImagePath(componentProperties.image)}
         alt={componentProperties.alt || null}
         className="mame-image"
         style={style}
         loading={componentProperties.lazy ? 'lazy' : null}
         onLoad={handleImageLoad}
         width={componentProperties.autoDimensions ? (dimensions.width || null) : null}
         height={componentProperties.autoDimensions ? (dimensions.height || null) : null}
    />
  );

  if (componentProperties.linkType) {
    return (
      <ElementDiv {...props}
                  id={id}
                  style={elementStyle}
                  classes={classes}>
        <ElementLink
          linkType={componentProperties.linkType}
          link={componentProperties.link}
          target={componentProperties.linkTarget}
          page={componentProperties.page}
          isNofollow={componentProperties.isNofollow}
          onClick={setActive}>
          {img}
        </ElementLink>
      </ElementDiv>
    );
  }

  return (
    <ElementDiv {...props} id={id} style={elementStyle} classes={classes}>
      {img}
    </ElementDiv>
  );
};

export default connect()(ImageElement);
