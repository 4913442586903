import React from 'react';
import { connect } from 'react-redux';
import { isPreviewSelector, isRenderSelector, siteIdSelector } from '../../store/selectors';
import menuHelper from '../../modules/menuHelper';

const ElementLink = props => {
  const {
    linkType,
    link: originalLink,
    page,
    target,
    isNofollow,
    className,
    onClick,

    // Redux
    siteId,
    isPreview,
    isRender
  } = props;

  const link = menuHelper.getLink({
    linkType,
    link: originalLink,
    page,
    siteId,
    isPreview,
    isRender,
  });

  // Make sure Anchor is self
  const linkTarget = linkType === 'anchor' ? '_self' : target;

  let rel = '';

  if (linkTarget === '_blank') {
    rel = 'noopener';
  }

  if (linkType === 'external') {
    rel = 'noopener noreferrer';
  }

  if (isNofollow) {
    rel += ' nofollow';
  }

  return (
    <a href={link}
       rel={rel || null}
       className={className || null}
       target={linkTarget}
       role={props.role || null}
       aria-pressed={props.hasOwnProperty('ariaPressed') ? props.ariaPressed : null}
       style={props.style || null}
       title={props.title || null}
       onClick={onClick || null}
    >
      {props.children}
    </a>
  );
};

const mapStateToProps = state => ({
  isPreview: isPreviewSelector(state),
  isRender: isRenderSelector(state),
  siteId: siteIdSelector(state),
});

export default connect(mapStateToProps)(ElementLink);
