import {
  CLOSE_ACTIVE_PROPERTY,
  SET_ACTIVE_ELEMENT,
  SET_ACTIVE_SECTION,
  SET_ACTIVE_TYPE,
  SET_API_VERSION, SET_CLIPBOARD_ELEMENT_STYLE,
  SET_CLIPBOARD_SECTION_STYLE,
  SET_PREVIEW,
  SET_RENDER,
  SET_SIDEBAR_VIEW,
} from '../actionTypes';
import { showModal } from './modals';

export const confirmationDialog = (
  text,
  action,
  cancelAction = null,
  cancelText = null,
  continueText = null
) => (
  async (dispatch) => {
    dispatch(showModal('ConfirmDialog', {text, action, cancelAction, cancelText, continueText}));
  }
);

export const setSidebarView = view => ({type: SET_SIDEBAR_VIEW, payload: {view}});
export const setActiveElement = element => ({type: SET_ACTIVE_ELEMENT, payload: {element}});
export const setActiveSection = section => ({type: SET_ACTIVE_SECTION, payload: {section}});
export const setActiveType = type => ({type: SET_ACTIVE_TYPE, payload: {type}});
export const closeActiveProperty = () => ({type: CLOSE_ACTIVE_PROPERTY});
export const clearCurrentActiveProperty = () => ({type: CLOSE_ACTIVE_PROPERTY});
export const setPreview = preview => ({type: SET_PREVIEW, payload: {preview}});
export const setRender = render => ({type: SET_RENDER, payload: {render}});
export const setApiVersion = apiVersion => ({type: SET_API_VERSION, payload: {apiVersion}});
export const setClipboardSectionStyle = clipboardSectionStyle => ({type: SET_CLIPBOARD_SECTION_STYLE, payload: {clipboardSectionStyle}});
export const setClipboardElementStyle = clipboardElementStyle => ({type: SET_CLIPBOARD_ELEMENT_STYLE, payload: {clipboardElementStyle}});
