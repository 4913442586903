import VideoProperties from '../../components/Properties/Elements/VideoProperties';
import { ElementVisible } from '../../modules/consts/Elements';

const VideoModel = {
  title: 'Video',
  category: 'Media',
  helpPage: '/documentation/elements/video',
  component: 'VideoElement',
  propertyComponent: VideoProperties,
  hasChildElements: false,
  generalProperties: {
    hasVisibility: true,
    hasTextAlign: false,
    hasTextColor: false,
    hasBackground: false,
  },
  defaults: {
    demo: true,
    _id: null,
    identifier: 'New Video',
    component: 'VideoElement',
    componentProperties: {
      url: '/video/bonsai.mp4',
      loop: true,
      autoPlay: true,
      controls: false,
      muted: true,
      preload: 'auto',
      poster: '',
    },
    generalProperties: {
      visibility: [ElementVisible.sm, ElementVisible.md, ElementVisible.lg, ElementVisible.xl],
      published: true,
      margin: [0, 0, 0, 0],
      padding: [0, 0, 0, 0],
    },
  },
};

export default VideoModel;
