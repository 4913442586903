import React from 'react';
import Fieldset from '../FieldSet';
import fbt from 'fbt';

import GenericSlider from '../Controls/GenericSlider';
import TextInput from '../Controls/TextInput';
import DisplayFieldset from '../Fieldsets/DisplayFieldset';
import SelectInput from '../Controls/SelectInput';
import HelpText from '../Controls/HelpText';
import InputGroup from '../InputGroup';

const GridProperties = props => {
  const {element} = props;
  const {componentProperties} = element;

  return (
    <div>
      <Fieldset title={fbt('Grid Properties', 'GridProperties')} isOpen>
        <InputGroup style={{'--mt': '10px'}}>
          <GenericSlider title={fbt('Column Count (Default)', 'GridProperties')}
                         min="1" max="12"
                         id="slider-grid-column-count"
                         value={componentProperties.gridTemplateColumns}
                         action={val => props.handlePropertyChange('gridTemplateColumns', val)}
                         showLabel
          />
          <HelpText style={{'--mt': '8px'}}>
            <fbt desc="GridProperties">
              You may want to configure the values for other device screen sizes (Responsive Overrides) below.
              If you do the default value is used for extra small devices (width &lt; 640px).
            </fbt>
          </HelpText>
        </InputGroup>

        <InputGroup>
          <GenericSlider title={fbt('Grid Gap', 'GridProperties')}
                         min="0" max="100"
                         id="slider-grid-gap"
                         value={componentProperties.gridGap}
                         action={val => props.handlePropertyChange('gridGap', val)}
                         showLabel
          />
        </InputGroup>
      </Fieldset>

      <Fieldset title={fbt('Advanced', 'GridProperties')} isOpen>

        <InputGroup style={{'--mt': '10px'}}>
          <SelectInput title={fbt('Grid column width distribution', 'GridProperties')}
                       showLabel
                       id="select-grid-column-width"
                       value={componentProperties.gridTemplateColumnWidth}
                       action={val => {
                         props.handlePropertyChange('gridTemplateColumnWidth', val);
                       }}
                       options={{
                         '': 'Evenly',
                         'auto': 'Auto',
                         'custom': 'Custom',
                       }}
          />
        </InputGroup>

        {componentProperties.gridTemplateColumnWidth === 'custom' && (
          <InputGroup>
            <TextInput title={fbt('Custom Grid Columns', 'GridProperties')} showLabel
                       id="input-custom-grid-columns"
                       value={componentProperties.gridTemplateColumnCustomWidth}
                       action={val => props.handlePropertyChange('gridTemplateColumnCustomWidth', val)}
            />
            <HelpText>
              <fbt desc="GridProperties">
                Set the grid column width manually, like <b>1fr 1fr</b> or <b>200px 1fr</b>. Learn more in the the <a
                href="https://mame.app/documentation/elements/grid">Grid
                Element</a> documentation. If you set up responsive overrides this is the value for extra small devices.
              </fbt>
            </HelpText>
          </InputGroup>
        )}
      </Fieldset>

      <DisplayFieldset
        hideDisplay
        isOpen
        justifyContent={componentProperties.justifyContent}
        titleJustifyContent={fbt('Justify Content', 'GridProperties')}
        actionJustifyContent={val => props.handlePropertyChange('justifyContent', val)}
        titleAlignItems={fbt('Align Items', 'GridProperties')}
        alignItems={componentProperties.alignItems}
        actionAlignItems={val => props.handlePropertyChange('alignItems', val)}
      />

      <Fieldset title={fbt('Responsive Overrides', 'GridProperties')} isOpen>
        {componentProperties.gridTemplateColumnWidth !== 'custom' && (
          <>
            <InputGroup style={{'--mt': '10px'}}>
              <GenericSlider title={fbt('Column count for extra small devices', 'GridProperties')}
                             min="0" max="12" showLabel="true"
                             id="slider-grid-column-count-xs"
                             value={element.componentProperties.gridTemplateColumns}
                             action={val => props.handlePropertyChange('gridTemplateColumns', val)}
              />
            </InputGroup>

            <InputGroup>
              <GenericSlider title={fbt('Column count for small devices', 'GridProperties')}
                             min="0" max="12" showLabel="true"
                             id="slider-grid-column-count-sm"
                             value={element.componentProperties.gridTemplateColumnsSm}
                             action={val => props.handlePropertyChange('gridTemplateColumnsSm', val)}
              />
            </InputGroup>

            <InputGroup>
              <GenericSlider title={fbt('Column count for medium devices', 'GridProperties')}
                             min="0" max="12" showLabel="true"
                             id="slider-grid-column-count-md"
                             value={element.componentProperties.gridTemplateColumnsMd}
                             action={val => props.handlePropertyChange('gridTemplateColumnsMd', val)}
              />
            </InputGroup>

            <InputGroup>
              <GenericSlider title={fbt('Column count for large devices', 'GridProperties')}
                             min="0" max="12" showLabel="true"
                             id="slider-grid-column-count-lg"
                             value={element.componentProperties.gridTemplateColumnsLg}
                             action={val => props.handlePropertyChange('gridTemplateColumnsLg', val)}
              />
            </InputGroup>

            <InputGroup>
              <GenericSlider title={fbt('Column count for extra large devices', 'GridProperties')}
                             min="0" max="12" showLabel="true"
                             id="slider-grid-column-count-xl"
                             value={element.componentProperties.gridTemplateColumnsXl}
                             action={val => props.handlePropertyChange('gridTemplateColumnsXl', val)}
              />
            </InputGroup>
          </>
        )}

        {componentProperties.gridTemplateColumnWidth === 'custom' && (
          <>
            <HelpText style={{'--m': '10px 0'}}>
              <fbt desc="GridProperties">
                Set the grid column width for different screen resolutions (responsive).
                Like <b>1fr 1fr</b> or <b>200px 1fr</b>. Learn more in the the <a
                href="https://mame.app/documentation/elements/grid">Grid
                Element</a> documentation.
              </fbt>
            </HelpText>
            <InputGroup>
              <TextInput title={fbt('Custom grid column width for small devices', 'GridProperties')}
                         value={componentProperties.gridTemplateColumnCustomSm}
                         action={val => props.handlePropertyChange('gridTemplateColumnCustomSm', val)}
                         showLabel
              />
            </InputGroup>

            <InputGroup>
              <TextInput title={fbt('Custom grid column width for medium devices', 'GridProperties')}
                         value={componentProperties.gridTemplateColumnCustomMd}
                         action={val => props.handlePropertyChange('gridTemplateColumnCustomMd', val)}
                         showLabel
              />
            </InputGroup>

            <InputGroup>
              <TextInput title={fbt('Custom grid column width for large devices', 'GridProperties')}
                         value={componentProperties.gridTemplateColumnCustomLg}
                         action={val => props.handlePropertyChange('gridTemplateColumnCustomLg', val)}
                         showLabel
              />
            </InputGroup>

            <InputGroup>
              <TextInput title={fbt('Custom grid column width for extra large devices', 'GridProperties')}
                         value={componentProperties.gridTemplateColumnCustomXl}
                         action={val => props.handlePropertyChange('gridTemplateColumnCustomXl', val)}
                         showLabel
              />
            </InputGroup>
          </>
        )}

      </Fieldset>
    </div>
  );
};

export default GridProperties;

