import React from 'react';
import fbt from 'fbt';

import GenericSlider from './GenericSlider';

const Spacing = (props) => {
  const title = props.category === 'Padding'
    ? fbt('Inner spacing (Padding)', 'Spacing')
    : fbt('Outer spacing (Margin)', 'Spacing');

  const applyToAll = val => {
    for (let i = 0; i < 4; i++) {
      props.action(i, val);
    }
  };

  return (
    <>
      <h4>{title}</h4>
      <GenericSlider min={props.min} max={props.max} icon="border_top"
                     action={(val) => props.action(0, val)}
                     applyToAll={applyToAll}
                     value={props.values[0]}
                     title={fbt('Top ', 'Spacing') + ' ' + props.category}
                     hideMinMax
      />
      <GenericSlider min={props.min} max={props.max} icon="border_right"
                     action={(val) => props.action(1, val)}
                     applyToAll={applyToAll}
                     value={props.values[1]}
                     title={fbt('Right ', 'Spacing') + ' ' + props.category}
                     hideMinMax
      />
      <GenericSlider min={props.min} max={props.max} icon="border_bottom"
                     action={(val) => props.action(2, val)}
                     applyToAll={applyToAll}
                     value={props.values[2]}
                     title={fbt('Bottom ', 'Spacing') + ' ' + props.category}
                     hideMinMax
      />
      <GenericSlider min={props.min} max={props.max} icon="border_left"
                     action={(val) => props.action(3, val)}
                     applyToAll={applyToAll}
                     value={props.values[3]}
                     title={fbt('Left ', 'Spacing') + ' ' + props.category}
                     hideMinMax
      />
    </>
  );
};

export default Spacing;
