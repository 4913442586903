import React, { useCallback, useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import { DragTypes } from '../../modules/DragTypes';
import { connect } from 'react-redux';
import { updateElementProperty } from '../../store/actions/activeSite';
import logger from '../../modules/logger';

const DropZone = props => {
  const [sortingItems, setSortingItems] = useState(props.items);
  const [isSortingUpdated, setSortingUpdated] = useState(false);

  useEffect(() => {
    setSortingItems(props.items);
  }, [props.items]);

  const [{isOverCurrent}, drop] = useDrop({
    accept: DragTypes.ELEMENT,
    drop: (item, monitor) => {
      if (monitor.didDrop()) {
        return;
      }

      return {
        elementId: props.element._id,
        type: props.type || 'element',
        index: props.hasOwnProperty('index') ? props.index : null,
      };
    },
    collect: (monitor) => ({
      isOverCurrent: monitor.isOver({shallow: true}),
      canDrop: monitor.canDrop(),
    }),
  });

  const resortOverwrite = useCallback((dragIndex, dropIndex) => {
    const draggedElement = sortingItems[dragIndex];
    const copy = [...sortingItems];

    copy.splice(dragIndex, 1);
    copy.splice(dropIndex, 0, draggedElement);

    setSortingItems(copy);
    setSortingUpdated(true);
  }, [sortingItems]);

  const endOverwrite = item => {
    if (!isSortingUpdated) {
      return;
    }

    logger.debug('Saving DropZone Resort', sortingItems);
    props.dispatch(updateElementProperty(props.element, null, 'elements', sortingItems));
  };

  return React.Children.map(props.children, child => {
    const addProps = {
      isOverCurrent,
      dropRef: drop,
      resortOverwrite,
      endOverwrite,
      sortingItems,
    };

    return React.cloneElement(child, addProps);
  });
};

export default connect()(DropZone);
