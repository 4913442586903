import BaseService from './BaseService';

const paths = {
  get: '/settings',
  patch: '/settings',
};

/**
 * Service for querying the Settings
 */
class SettingService extends BaseService {
  constructor() {
    super(paths);
    this.requireLogin = true;
  }
}

export default new SettingService();
