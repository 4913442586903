import { createSelector } from 'reselect';

export const siteSelector = state => state.activeSite.site;
export const siteSelectorId = state => state.activeSite.site._id;

export const activeElementSelector = state => state.builder.activeElement;
export const activeSectionSelector = state => state.builder.activeSection;
export const builderSelector = state => state.builder;
export const builderSelectorIsPreview = state => state.builder.isPreview;
export const builderSelectorIsRender = state => state.builder.isRender;
export const sectionTemplatesSelector = state => state.templates.sectionTemplates;
export const pageTemplatesSelector = state => state.templates.pageTemplates;
export const siteTemplatesSelector = state => state.templates.siteTemplates;
export const settingsSelector = state => state.settings;

export const selectElementById = (state, elementId) => state.activeSite.elements.find(e => e._id === elementId);
export const selectElementsByIds = (state, elementIds) => state.activeSite.elements.filter(e => elementIds.includes(e._id));

export const isActiveSectionSelector = (state, _id) => {
  return state.builder.activeType === 'section' && state.builder.activeSection === _id;
};

export const isActiveElementSelector = (state, _id) => {
  return state.builder.activeType === 'element' && state.builder.activeElement === _id;
};

export const fieldsetTypeSelector = createSelector([settingsSelector], (settings) => {
  return settings.fieldsetType;
});

export const siteIdSelector = createSelector([siteSelectorId], (id) => {
  return id;
});

export const isPreviewSelector = createSelector([builderSelectorIsPreview], (is) => {
  return is;
});

export const isRenderSelector = createSelector([builderSelectorIsRender], (is) => {
  return is;
});

/* Templates */
export const systemSectionTemplatesSelector = createSelector(
  [sectionTemplatesSelector],
  (templates) => {
    return templates.filter(t => t.kind === 'system');
  }
);

export const userSectionTemplatesSelector = createSelector(
  [sectionTemplatesSelector],
  (templates) => {
    return templates.filter(t => t.kind === 'user');
  }
);

export const systemPageTemplatesSelector = createSelector(
  [pageTemplatesSelector],
  (templates) => {
    return templates.filter(t => t.kind === 'system');
  }
);

export const userPageTemplatesSelector = createSelector(
  [pageTemplatesSelector],
  (templates) => {
    return templates.filter(t => t.kind === 'user');
  }
);

export const systemSiteTemplatesSelector = createSelector(
  [siteTemplatesSelector],
  (templates) => {
    return templates.filter(t => t.kind === 'system');
  }
);

export const userSiteTemplatesSelector = createSelector(
  [siteTemplatesSelector],
  (templates) => {
    return templates.filter(t => t.kind === 'user');
  }
);
