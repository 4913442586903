import React from 'react';
import fbt from 'fbt';

import ActionIcon from '../General/ActionIcon';

// Preview for Pages
const PagePreview = props => {
  const defaultImg = '/img/section/no-preview.jpg';

  return (
    <figure className="preview accent"
            data-title={props.title}
            style={{
              '--cur': 'pointer',
              '--jc': 'space-between',
              '--h': '275px',
              '--of': 'hidden',
              '--levitate-hvr': 5
            }}
            onClick={props.action}>
      <div style={{
        '--d': 'flex',
        '--ai': 'center',
        '--jc': 'center',
        '--fg': 1,
        '--bg': '#f1f1f1',
        '--maxh': '210px',
        '--of': 'hidden',
      }}>
        {!props.icon && (
          <img src={props.previewImg}
               alt="Page Preview"
               style={props.imageStyle || null}
               onError={(e) => {
                 e.target.onerror = null;
                 e.target.src = defaultImg;
               }}
          />
        )}

        {props.icon && (
          <span className={`icon icon-${props.icon}`}
                style={{
                  '--size': '800%',
                }}
                title={props.title}/>
        )}
      </div>
      <figcaption>
        <h4 style={{'--line-clamp': 1}}>{props.title}</h4>
      </figcaption>

      {props.handleDelete && (
        <div className="preview-menu"
             onClick={(e) => {
               e.stopPropagation();
             }}>
          <ActionIcon icon="trash-o" title={fbt('Delete Template', 'PagePreview')}
                      action={props.handleDelete}/>
          <br/>
          <ActionIcon icon="shopping-basket"
                      title={fbt('Offer Page on Store', 'PagePreview')}
                      action={props.handleSell}/>
          <br/>
        </div>
      )}
    </figure>
  );
};

export default PagePreview;
