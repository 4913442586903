import axios from 'axios';

import BaseService from './BaseService';

const paths = {
  get: '/profiles',
};

/**
 * Service for querying the Profiles
 */
class ProfileService extends BaseService {
  constructor() {
    super(paths);
    this.requireLogin = false;
  }

  getProfile = username => {
    const url = `/profiles/${username}`;

    return axios.get(url);
  };
}

export default new ProfileService();
