import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { DragTypes } from '../../modules/DragTypes';

/**
 * Injects a dragRef in the children
 * Only React Components as children and one child!
 *
 * @param props
 * @constructor
 */
const DnDItem = props => {
  const ref = useRef(null);
  const previewRef = useRef(null);

  const {group = 'dnd'} = props;
  const {dragType = DragTypes.Element} = props;

  const [, drop] = useDrop({
    accept: dragType,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }

      if (item.group !== group) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = props.index;

      if (dragIndex === -1) {
        return;
      }

      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = props.dragHandler
        ? previewRef.current?.getBoundingClientRect()
        : ref.current?.getBoundingClientRect();

      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // console.log('DnD MOVED', item, group);

      props.moveItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    }
  });

  const [{isDragging}, drag, preview] = useDrag({
    type: dragType,
    item: {
      id: props.id || null,
      index: props.index,
      group,
      caller: 'dnd'
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();

      // We have to always trigger it when it is outside
      props.moveEnd(item.index, dropResult);
    }
  });

  if (props.dragHandler) {
    drag(ref);
    preview(previewRef);
    drop(previewRef);
  } else {
    drag(drop(ref));
  }

  return React.Children.map(props.children, child => {
    const addProps = {
      dndDragRef: ref,
      dndPreviewRef: previewRef,
      isDragging,
    };

    return React.cloneElement(child, addProps);
  });
};

export default DnDItem;
