import React from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import Fieldset from '../FieldSet';
import CodeInput from '../Controls/CodeInput';
import HelpText from '../Controls/HelpText';

const EmbedHtmlProperties = props => {
  const {element} = props;
  const {componentProperties} = element;

  return (
    <Fieldset title={fbt('Embedded HTML', 'EmbedHtmlProperties')} isOpen>
      <HelpText style={{'--m': '10px 0'}}>
        <fbt desc="EmbedHtmlProperties">
          Careful this may break your site.
        </fbt>
      </HelpText>
      <CodeInput title={fbt('HTML Code', 'EmbedHtmlProperties')}
                 id="code-custom-html"
                 language="html"
                 value={componentProperties.html}
                 showLabel
                 action={val => props.handlePropertyChange('html', val)}
      />
    </Fieldset>
  );
};

export default connect()(EmbedHtmlProperties);
