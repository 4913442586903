import TestimonialProperties from '../../components/Properties/Elements/TestimonialProperties';
import { ElementVisible } from '../../modules/consts/Elements';

const TestimonialModel = {
  title: 'Testimonial',
  image: '/img/elements/testimonial.webp',
  category: 'Basic',
  helpPage: '/documentation/elements/testimonial',
  component: 'TestimonialElement',
  propertyComponent: TestimonialProperties,
  hasChildElements: false,
  generalProperties: {
    hasVisibility: true,
    hasTextAlign: true,
    hasTextColor: false,
    hasBackground: true,
  },
  defaults: {
    demo: true,
    _id: null,
    identifier: 'New Testimonial',
    component: 'TestimonialElement',
    componentProperties: {
      style: '',
      name: 'John Doe',
      subTitle: 'Massive Dynamic',
      image: 'https://source.unsplash.com/UxujkVMyY_0/300x300',
      alt: 'Sample Image',
      lazy: false,
      autoDimensions: false,
      filterType: '',
      filterValue: 0,
      html: 'Duis aute irure dolor in reprehenderit in <b>voluptate velit esse cillum</b> dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui <b>officia deserunt mollit</b> anim id est.',
    },
    generalProperties: {
      visibility: [ElementVisible.sm, ElementVisible.md, ElementVisible.lg, ElementVisible.xl],
      published: true,
      margin: [0, 0, 0, 0],
      padding: [15, 10, 15, 10],
      textAlign: 'center',
      hoverType: 'levitate',
      hover: 5,
      borderRadius: 5,
    },
  },
  itemTemplate: {},
};

export default TestimonialModel;
