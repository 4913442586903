import React from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import Fieldset from '../FieldSet';
import TextInput from '../Controls/TextInput';
import CheckboxInput from '../Controls/CheckboxInput';
import InputGroup from '../InputGroup';

const CheckboxProperties = props => {
  const {element} = props;
  const {componentProperties} = element;

  return (
    <Fieldset title={fbt('Checkbox Properties', 'CheckboxProperties')} isOpen>
      <InputGroup style={{'--mt': '10px'}}>
        <TextInput title={fbt('Label (Title)', 'CheckboxProperties')} value={componentProperties.title}
                   id="input-input-title"
                   action={val => props.handlePropertyChange('title', val)}
                   showLabel
        />
      </InputGroup>

      <InputGroup>
        <TextInput title={fbt('Custom Name (for email placeholders / 3rd party API)', 'CheckboxProperties')}
                   value={componentProperties.customName}
                   showLabel id="input-custom-name"
                   filter="[^A-Za-z0-9_-]"
                   action={val => props.handlePropertyChange('customName', val)}
        />
      </InputGroup>

      <InputGroup>
        <CheckboxInput title={fbt('Checked by default', 'CheckboxProperties')}
                       checked={componentProperties.isChecked} id="check-default-checked"
                       action={() => props.handlePropertyChange('isChecked', !componentProperties.isChecked)}
        />
      </InputGroup>

      <InputGroup>
        <CheckboxInput title={fbt('Required', 'CheckboxProperties')}
                       checked={componentProperties.required}
                       id="check-required"
                       action={() => props.handlePropertyChange('required', !componentProperties.required)}
        />
      </InputGroup>
    </Fieldset>
  );
};

export default connect()(CheckboxProperties);
