import React from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import SelectInput from './SelectInput';

const MenuPagePicker = props => {
  const options = [
    {value: '', label: fbt('Please Select', 'MenuPagePicker')}
  ];

  props.pages.forEach(p => {
    options.push({
      value: p.alias,
      label: p.title,
    });
  });

  return (
    <SelectInput title={props.title}
                 newOptions={options}
                 showLabel
                 id={props.id || null}
                 value={props.value} action={props.action}
    />
  );
};

const mapStateToProps = state => ({
  pages: state.activeSite.pages,
});

export default connect(mapStateToProps)(MenuPagePicker);
