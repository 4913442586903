module.exports = {
  en_US: {
    bcp47: 'en-US',
    displayName: 'English (US)\u200e',
    englishName: 'English (US)',
    rtl: false,
  },
  de_DE: {
    bcp47: 'de-DE',
    displayName: 'Deutsch',
    englishName: 'German',
    rtl: false,
  },
};
