import RangeProperties from '../../components/Properties/Elements/RangeProperties';
import { ElementVisible } from '../../modules/consts/Elements';

const RangeModel = {
  title: 'Range',
  category: 'Form',
  helpPage: '/documentation/elements/form',
  component: 'RangeElement',
  propertyComponent: RangeProperties,
  hasChildElements: false,
  generalProperties: {
    hasVisibility: true,
    hasTextAlign: true,
    hasTextColor: false,
    hasBackground: false,
  },
  defaults: {
    demo: true,
    _id: null,
    identifier: 'New Range',
    component: 'RangeElement',
    componentProperties: {
      title: 'New Range Input',
      showLabel: true,
      customName: '',
      defaultValue: 0,
      min: 0,
      max: 100,
    },
    generalProperties: {
      visibility: [ElementVisible.sm, ElementVisible.md, ElementVisible.lg, ElementVisible.xl],
      published: true,
      margin: [0, 0, 0, 0],
      padding: [0, 0, 0, 0],
    },
  },
};

export default RangeModel;
