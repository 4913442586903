import React from 'react';

const Container = (props) => {
  const classes = [];
  const style = {};

  const {containerType, containerCustomMaxWidth} = props.section.sectionProperties;

  if (containerType === 'none') {
    return props.children;
  }

  if (!containerType || containerType === 'fixed') {
    // Default
    classes.push('wrapper');
    style['--gutter'] = '1vw';
  } else if (containerType === 'fluid') {
    classes.push('wrapper-fluid');
  } else if (containerType === 'custom') {
    // classes.push('container custom-container');
    classes.push('wrapper');
    style['--gutter'] = '1vw';
    style['--maxw'] = containerCustomMaxWidth || '1280px';
  }

  if (props.section.sectionProperties.containerSize) {
    classes.push(props.section.sectionProperties.containerSize);
  }

  return (
    <div className={classes.join(' ')} style={style}>
      {props.children}
    </div>
  );
};

export default Container;
