import React from 'react';
import fbt from 'fbt';

import SelectInput from './SelectInput';
import InputWithUnitPicker from './InputWithUnitPicker';
import InputGroup from '../InputGroup';

const ContainerProperties = props => {
  return (
    <>
      <InputGroup>
        <SelectInput title={fbt('Container Type', 'ContainerProperties')}
                     value={props.type}
                     action={val => props.typeAction(val)}
                     options={{
                       'none': fbt('None', 'ContainerProperties'),
                       'fluid': fbt('Fluid (100% width)', 'ContainerProperties'),
                       'fixed': fbt('Fixed width (default)', 'ContainerProperties'),
                       'custom': fbt('Custom maximum width', 'ContainerProperties'),
                     }}
        />
      </InputGroup>
      <CustomMaxWidth
        type={props.type}
        customMaxWidth={props.customMaxWidth}
        action={props.customMaxWidthAction}
      />
    </>
  );
};

const CustomMaxWidth = props => {
  if (props.type !== 'custom') {
    return null;
  }

  return (
    <InputGroup>
      <InputWithUnitPicker
        title={fbt('Custom maximum Container Width', 'ElementProperties')}
        value={props.customMaxWidth}
        action={props.action}
        required
        isWidth
        showLabel
      />
    </InputGroup>
  );
};

export default ContainerProperties;
