import React from 'react';
import fbt from 'fbt';

import SelectInput from './SelectInput';
import LinkInput from './LinkInput';
import MenuPagePicker from './MenuPagePicker';
import LinkTarget from './LinkTarget';
import HelpText from './HelpText';
import AnchorSectionPicker from './AnchorSectionPicker';
import CheckboxInput from './CheckboxInput';
import InputGroup from '../InputGroup';


const LinkGroup = props => {
  return (
    <>
      <InputGroup>
        <SelectInput title={fbt('Link Type', 'LinkGroup')}
                     value={props.linkType || ''}
                     options={{
                       '': fbt('None', 'LinkGroup'),
                       'internal': fbt('Internal', 'LinkGroup'),
                       'external': fbt('External', 'LinkGroup'),
                       'anchor': fbt('Anchor (Same Page Target)', 'LinkGroup'),
                     }}
                     action={props.actionLinkType}
                     showLabel
        />
      </InputGroup>

      {props.linkType === 'external' && (
        <>
          <InputGroup>
            <LinkInput title={fbt('Link', 'LinkGroup')}
                       value={props.link}
                       action={props.actionLink}
                       showLabel
            />
          </InputGroup>

          <InputGroup>
            <CheckboxInput title={fbt('No Follow', 'LinkGroup')}
                           checked={props.isNofollow}
                           action={() => {
                             props.actionNofollow(!props.isNofollow);
                           }}
            />
          </InputGroup>
        </>
      )}

      {props.linkType === 'internal' && (
        <InputGroup>
          <MenuPagePicker title={fbt('Page', 'LinkGroup')}
                          value={props.page}
                          action={props.actionPage}
          />
        </InputGroup>
      )}

      {props.linkType === 'anchor' && (
        <InputGroup>
          <AnchorSectionPicker title={fbt('Anchor Target', 'LinkGroup')}
                               value={props.link}
                               action={props.actionLink}
          />
          <HelpText>
            <fbt desc="LinkGroup">
              An anchor is an in-page link pointing to an section on the same site.
            </fbt>
          </HelpText>
        </InputGroup>
      )}

      {props.linkType && props.linkType !== 'anchor' && (
        <InputGroup>
          <LinkTarget title={fbt('Target', 'LinkGroup')}
                      value={props.linkTarget}
                      action={props.actionLinkTarget}
          />
        </InputGroup>
      )}
    </>
  );
};

export default LinkGroup;
