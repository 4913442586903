import React, { useEffect, useState } from 'react';
import { usePopper } from 'react-popper';

const Dropdown = props => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const options = {
    placement: props.placement || 'bottom-start',
    strategy: 'fixed',
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrowElement,
        },
      },
      {
        name: 'preventOverflow',
        enabled: true,
        options: {
          rootBoundary: 'viewport',
          altBoundary: 'body',
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        }
      },
    ]
  };

  if (!props.disableFlip) {
    options.modifiers.push({
      name: 'flip',
      options: {
        fallbackPlacements: ['bottom-start'],
      }
    });
  }

  const popper = usePopper(referenceElement, popperElement, options);

  const popperStyles = popper.styles;
  const popperAttributes = popper.attributes;

  const dropdownStyle = {
    ...popperStyles.popper,
  };

  useEffect(() => {
    const handleOutsideClick = e => {
      if (popperElement && popperElement.contains(e.target)) {
        return;
      }

      setIsOpen(false);
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [popperElement, isOpen, setIsOpen]);

  const toggleOpen = async e => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    // Make sure popper is on the right position
    if (popper && popper.forceUpdate) {
      await popper.forceUpdate();
    }

    setIsOpen(!isOpen);
    return false;
  };

  const contentWithProps = React.Children.map(props.content, child => {
    const addProps = {
      toggleOpen,
      isOpen,
      updatePopper: popper.update,
    };

    return React.cloneElement(props.content, addProps);
  });

  if (props.width) {
    dropdownStyle['--w'] = props.width || 'auto';
    dropdownStyle['--p'] = props.padding || '20px 15px';
    dropdownStyle['visibility'] = isOpen ? 'visible' : 'hidden';
    dropdownStyle['--bt'] = props.border || '3px solid var(--mame-primary)';
  }

  const arrowClass = `popper-arrow popper-arrow-${props.placementArrow || 'top'}`;

  return (
    <>
      <div className="dropdown-menu"
           role="button"
           ref={setReferenceElement}
           onMouseDown={toggleOpen}>
        {props.menu}
      </div>
      {isOpen && (
        <div className="dropdown-content" ref={setPopperElement}
             style={dropdownStyle} {...popperAttributes.popper}>
          {contentWithProps}
          <div className={arrowClass} ref={setArrowElement}
               style={popperStyles.arrow}/>
        </div>
      )}
    </>
  );
};

export default Dropdown;
