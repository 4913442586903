import {
  ADD_ELEMENT,
  REMOVE_ELEMENT,
  UPDATE_ELEMENT_HTML,
  UPDATE_ELEMENT_PROPERTY,
  UPDATE_ELEMENT_ITEM_PROPERTY,
  SET_ACTIVE_SITE,
  UPDATE_SECTION_PROPERTY,
  UPDATE_SECTION_PROPERTIES,
  UPDATE_SITE_PROPERTY,
  SET_ACTIVE_SITE_SECTIONS,
  SET_ACTIVE_SITE_ELEMENTS,
  ADD_SECTION,
  REMOVE_SECTION,
  SET_ACTIVE_SITE_PAGES,
  ADD_PAGE,
  SET_ACTIVE_PAGE,
  UPDATE_PAGE_PROPERTY,
  SET_SITE_MEDIA,
  REMOVE_PAGE,
  MOVE_ELEMENT,
  SET_ACTIVE_SITE_ACTIVITIES,
  ADD_ACTIVE_SITE_ACTIVITIES,
  UPDATE_ELEMENT_PROPERTIES,
  SET_ACTIVE_PAGE_CANONICAL,
  SET_ACTIVE_PAGE_TITLE,
  SET_PRESET_STYLES,
} from '../actionTypes';
import produce from 'immer';

import { toast } from 'react-toastify';
import logger from '../../modules/logger';

const initialState = {
  // Active site
  site: null,
  // TODO: Probably should just be the _id
  page: null,
  pageCanonical: null,
  pageTitle: null,
  pages: [],
  sections: null,
  elements: null,
  media: [],
  activities: [],
  presetStyles: [
    '#4a69bd', // Primary
    '#06847d', // secondary
    '#ffffff', // White
    '#e6e6e6', // Grey
    '#484747', // darkgrey
    '#262626', // black
    '#06847d', // green
    '#4a69bd', // blue
    '#a46313', // orange
    '#e02e06', // red
    '#262626', // text
    '#4a69bd', // link
    '#3939de', // hover
    '#06847d', // success
    '#e02e06', // warning
  ],
};

const removeElementFromParent = (state, draft, elementId, options) => {
  if (options.parentType === 'section') {
    const sectionIndex = state.sections.findIndex(s => s._id === options.parentId);

    const index = state.sections[sectionIndex].elements.findIndex(s => s === elementId);
    draft.sections[sectionIndex].elements.splice(index, 1);
  }

  if (options.parentType === 'element') {
    const elementInnerIndex = state.elements.findIndex(e => e._id === options.parentId);

    const index = state.elements[elementInnerIndex].elements.findIndex(s => s === elementId);

    draft.elements[elementInnerIndex].elements.splice(index, 1);
  }

  if (options.parentType === 'item') {
    const parentIndex = state.elements.findIndex(e => e._id === options.parentId);
    const tabIndex = state.elements[parentIndex].componentProperties.items.findIndex(t => t.elements.includes(elementId));

    if (tabIndex !== -1) {
      const final = state.elements[parentIndex].componentProperties.items[tabIndex].elements.findIndex(e => e === elementId);
      draft.elements[parentIndex].componentProperties.items[tabIndex].elements.splice(final, 1);
    }
  }
};

const activeSiteState = (state = initialState, action) => {
  switch (action.type) {
    // Set start properties
    case SET_ACTIVE_SITE: {
      const {site} = action.payload;

      return {...state, site};
    }

    case SET_ACTIVE_SITE_PAGES: {
      const {pages} = action.payload;

      return {...state, pages};
    }

    case SET_ACTIVE_SITE_SECTIONS: {
      const {sections} = action.payload;

      return {...state, sections};
    }

    case SET_ACTIVE_SITE_ELEMENTS: {
      const {elements} = action.payload;

      return {...state, elements};
    }

    // Update properties
    case UPDATE_SITE_PROPERTY: {
      const {category, key, value, subKey} = action.payload;

      return produce(state, draft => {
        if (subKey) {
          draft.site[category][key][subKey] = value;
        } else if (key) {
          draft.site[category][key] = value;
        } else {
          draft.site[category] = value;
        }
      });
    }

    // Pages
    case ADD_PAGE: {
      const {page} = action.payload;

      return produce(state, draft => {
        if (state.site) {
          draft.site.pages.push(page._id);
        }

        draft.pages.push(page);
      });
    }

    case SET_ACTIVE_PAGE: {
      const {page} = action.payload;

      return {
        ...state,
        page,
      };
    }

    case SET_ACTIVE_PAGE_CANONICAL: {
      const {canonical} = action.payload;

      return {
        ...state,
        pageCanonical: canonical,
      };
    }

    case SET_PRESET_STYLES: {
      const {colors} = action.payload;

      return {
        ...state,
        presetStyles: colors,
      };
    }

    case SET_ACTIVE_PAGE_TITLE: {
      const {title} = action.payload;

      return {
        ...state,
        pageTitle: title,
      };
    }

    case UPDATE_PAGE_PROPERTY: {
      const {page, category, key, value, subKey} = action.payload;

      const pageIndex = state.pages.findIndex(p => p._id === page._id);

      // Hack for fixing the active site, which should only be a reference
      const isCurrentPage = page._id === state.page._id;

      return produce(state, draft => {
        if (subKey) {
          draft.pages[pageIndex][category][key][subKey] = value;

          if (isCurrentPage) {
            draft.page[category][key][subKey] = value;
          }
        } else if (key) {
          draft.pages[pageIndex][category][key] = value;

          if (isCurrentPage) {
            draft.page[category][key] = value;
          }
        } else {
          draft.pages[pageIndex][category] = value;

          if (isCurrentPage) {
            draft.page[category] = value;
          }
        }
      });
    }

    case REMOVE_PAGE: {
      const {pageId} = action.payload;

      const pageIndex = state.pages.findIndex(p => p._id === pageId);
      const siteIndex = state.site.pages.findIndex(p => p === pageId);

      toast.warning('Page deleted.');

      return produce(state, draft => {
        draft.pages.splice(pageIndex, 1);
        draft.site.pages.splice(siteIndex, 1);

        if (state.page._id === pageId) {
          draft.page = null;
        }
      });
    }

    // Sections
    case ADD_SECTION: {
      const {section, pageId = null, after = null} = action.payload;

      return produce(state, draft => {
        // All sections
        if (state.site && state.site.sections) {
          draft.site.sections.push(section._id);
        }

        // Add it to the active page
        if (!pageId) {
          draft.page.sections.push(section._id);

          // Add it to the saved page
          const currentPage = draft.pages.find(p => p._id === draft.page._id);

          if (currentPage) {
            currentPage.sections.push(section._id);
          }
        } else {
          const page = draft.pages.find(p => p._id === pageId);

          if (!after) {
            page.sections.push(section._id);
          } else {
            const index = page.sections.findIndex(s => s === after);
            page.sections.splice(index + 1, 0, section._id);
          }

          if (state.page && page._id === state.page._id) {
            if (!after) {
              draft.page.sections.push(section._id);
            } else {
              const index = draft.page.sections.findIndex(s => s === after);
              draft.page.sections.splice(index + 1, 0, section._id);
            }
          }
        }

        // The real section
        if (state.sections) {
          draft.sections.push(section);
        }
      });
    }

    case UPDATE_SECTION_PROPERTY: {
      const {section, category, key, value, index} = action.payload;

      const sectionIndex = state.sections.findIndex((s) => s._id === section._id);

      return produce(state, draft => {
        let basePath = draft.sections[sectionIndex];

        // Too many dimensions, should flatten the site
        if (index !== undefined) {
          basePath[category][key][index] = value;
        } else if (category) {
          basePath[category][key] = value;
        } else {
          basePath[key] = value;
        }
      });
    }

    case UPDATE_SECTION_PROPERTIES: {
      const {section, category, properties} = action.payload;

      const sectionIndex = state.sections.findIndex((s) => s._id === section._id);

      return produce(state, draft => {
        if (category === 'generalProperties') {
          draft.sections[sectionIndex].generalProperties = properties;
        } else if (category === 'sectionProperties') {
          draft.sections[sectionIndex].sectionProperties = properties;
        }
      });
    }

    case REMOVE_SECTION: {
      const {sectionId} = action.payload;

      const sectionIndex = state.sections.findIndex(s => s._id === sectionId);
      const siteIndex = state.site.sections.findIndex(s => s === sectionId);

      // A section may exist on multiple pages
      const pageIndex = state.page.sections.findIndex(s => s === sectionId);

      const pagesWithSections = state.pages.filter(p => p.sections.includes(sectionId));
      const onMultiplePages = pagesWithSections.length > 1;

      toast.warning(onMultiplePages ? 'Shared Section removed from page.' : 'Section deleted.');

      if (onMultiplePages) {
        // We don't delete the section here, just remove it from the page
        return produce(state, draft => {
          if (pageIndex !== -1) {
            logger.debug('Removed section from page', pageIndex);
            draft.page.sections.splice(pageIndex, 1);
          }
        });
      }

      // Child elements are handled on API level
      return produce(state, draft => {
        if (pageIndex !== -1) {
          logger.debug('Removed section from page', pageIndex);
          draft.page.sections.splice(pageIndex, 1);
        }

        draft.pages.forEach(p => {
          const index = p.sections.findIndex(s => s === sectionId);

          if (index !== -1) {
            p.sections.splice(index, 1);
          }
        });

        draft.sections.splice(sectionIndex, 1);
        draft.site.sections.splice(siteIndex, 1);
      });
    }

    // Elements
    case UPDATE_ELEMENT_PROPERTY: {
      logger.debug('UPDATE_ELEMENT_PROPERTY payload', action.payload);
      const {element, category, key, value, index} = action.payload;

      const elementIndex = state.elements.findIndex(e => e._id === element._id);

      return produce(state, draft => {
        let basePath = draft.elements[elementIndex];

        if (index !== undefined) {
          basePath[category][key][index] = value;
        } else if (category) {
          if (!basePath[category]) {
            // Workaround for not existing componentProperties, should not happen
            basePath[category] = {};
          }

          basePath[category][key] = value;
        } else {
          basePath[key] = value;
        }
      });
    }

    case UPDATE_ELEMENT_ITEM_PROPERTY: {
      logger.debug('UPDATE_ELEMENT_ITEM_PROPERTY payload', action.payload);
      const {element, index, key, value} = action.payload;

      const elementIndex = state.elements.findIndex(e => e._id === element._id);

      return produce(state, draft => {
        draft.elements[elementIndex]['componentProperties']['items'][index][key] = value;
      });
    }

    case UPDATE_ELEMENT_PROPERTIES: {
      const {element, properties} = action.payload;

      const index = state.elements.findIndex(e => e._id === element._id);

      return produce(state, draft => {
        draft.elements[index].generalProperties = properties;
      });
    }

    case UPDATE_ELEMENT_HTML: {
      logger.debug('UPDATE_ELEMENT_HTML payload', action.payload);
      const {element, html} = action.payload;

      const elementIndex = state.elements.findIndex(e => e._id === element._id);

      return produce(state, draft => {
        draft.elements[elementIndex].componentProperties.html = html;
      });
    }

    case ADD_ELEMENT: {
      const {element, options} = action.payload;

      if (!state.sections) {
        return state;
      }

      logger.debug('ADD_ELEMENT', element, options);
      const {toType, to} = options;

      if (toType === 'section') {
        const sectionIndex = state.sections.findIndex(s => s._id === to);

        return produce(state, draft => {
          draft.elements.push(element);
          draft.sections[sectionIndex].elements.push(element._id);
        });
      }

      if (toType === 'element') {
        const elementIndex = state.elements.findIndex(e => e._id === to);

        return produce(state, draft => {
          draft.elements.push(element);
          draft.elements[elementIndex].elements.push(element._id);
        });
      }

      if (toType === 'item') {
        const elementIndex = state.elements.findIndex(e => e._id === to);

        return produce(state, draft => {
          draft.elements.push(element);
          draft.elements[elementIndex].componentProperties.items[options.index].elements.push(element._id);
        });
      }

      logger.warn('NOT IMPLEMENTED', to, toType);
      return state;
    }

    case MOVE_ELEMENT: {
      const {elementId, options} = action.payload;

      // Delete from old position and add to new one
      return produce(state, draft => {
        removeElementFromParent(state, draft, elementId, options);

        if (options.targetType === 'section') {
          const sectionIndex = state.sections.findIndex(s => s._id === options.targetId);

          draft.sections[sectionIndex].elements.push(elementId);
        }

        if (options.targetType === 'element') {
          const elementIndex = state.elements.findIndex(e => e._id === options.targetId);

          draft.elements[elementIndex].elements.push(elementId);
        }

        if (options.targetType === 'item') {
          const elementIndex = state.elements.findIndex(e => e._id === options.targetId);

          draft.elements[elementIndex].componentProperties.items[options.targetItemIndex].elements.push(elementId);
        }

      });
    }

    case REMOVE_ELEMENT: {
      const {elementId, options} = action.payload;

      // Find it everywhere
      const elementIndex = state.elements.findIndex(e => e._id === elementId);

      toast.warning('Element deleted.');

      return produce(state, draft => {
        if (elementIndex !== -1) {
          draft.elements.splice(elementIndex, 1);
        }

        removeElementFromParent(state, draft, elementId, options);
      });
    }

    case SET_SITE_MEDIA: {
      const {media} = action.payload;

      return {
        ...state,
        media,
      };
    }

    case SET_ACTIVE_SITE_ACTIVITIES: {
      const {activities} = action.payload;

      return {
        ...state,
        activities,
      };
    }

    case ADD_ACTIVE_SITE_ACTIVITIES: {
      const {activities} = action.payload;

      return produce(state, draft => {
        draft.activities.concat(activities);
      });
    }

    default:
      return state;
  }
};

export default activeSiteState;
