import ImageFigureProperties from '../../components/Properties/Elements/ImageFigureProperties';
import { ElementVisible } from '../../modules/consts/Elements';

const ImageFigureModel = {
  title: 'Image Figure',
  category: 'Media',
  helpPage: '/documentation/elements/image-figure',
  component: 'ImageFigureElement',
  propertyComponent: ImageFigureProperties,
  hasChildElements: true,
  generalProperties: {
    hasVisibility: true,
    hasTextAlign: true,
    hasTextColor: true,
    hasBackground: false,
  },
  defaults: {
    demo: true,
    _id: null,
    identifier: 'New Image Figure',
    component: 'ImageFigureElement',
    componentProperties: {
      image: '/img/elements/image-figure-sample.webp',
      alt: 'Building with Mame Builder',
      lazy: true,
      autoDimensions: true,
      filterType: '',
      filterValue: 0,
      linkType: '',
      page: '',
      link: '',
      linkTarget: '_self',
      isNofollow: false,
      backgroundType: 'color',
      backgroundColor: '#ffffff',
      backgroundGradientColor: 'f1f1f1',
      backgroundGradientDegree: 0,
      backgroundPattern: 'pattern1.webp',
    },
    generalProperties: {
      visibility: [ElementVisible.sm, ElementVisible.md, ElementVisible.lg, ElementVisible.xl],
      published: true,
      margin: [0, 0, 0, 0],
      padding: [15, 15, 15, 15],
    },
    elements: [],
  },
};

export default ImageFigureModel;
