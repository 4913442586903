import React from 'react';
import fbt from 'fbt';

import Fieldset from '../FieldSet';
import SelectInput from '../Controls/SelectInput';
import TextInput from '../Controls/TextInput';
import CheckboxInput from '../Controls/CheckboxInput';
import GenericSlider from '../Controls/GenericSlider';
import InputGroup from '../InputGroup';

const TypedTextProperties = props => {
  const {element} = props;
  const {componentProperties} = element;

  const handleAdd = () => {
    const items = [...componentProperties.sentences];

    items.push('');

    props.handlePropertyChange('sentences', items);
  };

  const handleSentenceChange = (index, newVal) => {
    const items = [...componentProperties.sentences];

    items[index] = newVal;

    props.handlePropertyChange('sentences', items);
  };

  const handleRemove = index => {
    const items = [...componentProperties.sentences];

    items.splice(index, 1);

    props.handlePropertyChange('sentences', items);
  };

  return (
    <Fieldset title={fbt('Typed Text Properties', 'TypedTextProperties')} isOpen>
      <InputGroup style={{'--mt': '10px'}}>
        <SelectInput title={fbt('Display as', 'FormProperties')}
                     className="b-typed-text__display-as"
                     value={componentProperties.displayAs}
                     action={val => props.handlePropertyChange('displayAs', val)}
                     options={{
                       'h1': fbt('Heading 1', 'FormProperties'),
                       'h2': fbt('Heading 2', 'FormProperties'),
                       'h3': fbt('Heading 3', 'FormProperties'),
                       'h4': fbt('Heading 4', 'FormProperties'),
                       'h5': fbt('Heading 5', 'FormProperties'),
                       'h6': fbt('Heading 6', 'FormProperties'),
                       'span': fbt('Normal Text', 'FormProperties'),
                     }}
                     showLabel
        />
      </InputGroup>

      <InputGroup>
        <GenericSlider title={fbt('Type Speed', 'TypedTextProperties')}
                       min="5" max="200"
                       value={componentProperties.typeSpeed}
                       action={val => props.handlePropertyChange('typeSpeed', val)}
                       showLabel
        />
      </InputGroup>

      <InputGroup>
        <CheckboxInput title={fbt('Play in Loop', 'TypedTextProperties')}
                       checked={componentProperties.loop}
                       action={() => props.handlePropertyChange('loop', !componentProperties.loop)}
        />
      </InputGroup>

      <InputGroup>
        <CheckboxInput title={fbt('Show Cursor', 'TypedTextProperties')}
                       checked={componentProperties.showCursor}
                       action={() => props.handlePropertyChange('showCursor', !componentProperties.showCursor)}
        />
      </InputGroup>


      {componentProperties.sentences.map((item, index) => {
        const removeSentence = index === 0 ? null : () => {
          handleRemove(index);
        };

        return (
          <div key={index}>
            <InputGroup>
              <TextInput title={fbt('Sentence', 'TypedTextProperties') + ` ${index + 1}`}
                         className="b-typed-text__sentence"
                         value={item}
                         blur={val => handleSentenceChange(index, val)}
                         removeAction={removeSentence}
                         showLabel
              />
            </InputGroup>
          </div>
        );
      })}

      <div style={{'--ta': 'right'}}>
        <button className="mame-primary" onClick={handleAdd}>
          <fbt desc="TypedTextProperties">
            Add Sentence
          </fbt>
        </button>
      </div>
    </Fieldset>
  );
};

export default TypedTextProperties;
