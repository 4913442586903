import { ElementColorClasses, ElementVisible } from './consts/Elements';
import config from '../config';

const propertyHelper = {
  setBorder(style, p) {
    if (!p.borderType) {
      return;
    }

    const color = ElementColorClasses.includes(p.borderColor) ? `var(--${p.borderColor})` : p.borderColor;

    if (p.borderWidth && p.borderWidth.length) {
      const hasValue = p.borderWidth.some(w => w > 0);

      if (hasValue) {
        if (p.borderWidth[0]) {
          style['--bt'] = `${p.borderWidth[0]}px ${p.borderType} ${color}`;
        }

        if (p.borderWidth[1]) {
          style['--br'] = `${p.borderWidth[1]}px ${p.borderType} ${color}`;
        }

        if (p.borderWidth[2]) {
          style['--bb'] = `${p.borderWidth[2]}px ${p.borderType} ${color}`;
        }

        if (p.borderWidth[3]) {
          style['--bl'] = `${p.borderWidth[3]}px ${p.borderType} ${color}`;
        }

        return;
      }
    }


    style['--b'] = `${p.border}px ${p.borderType} ${color}`;
  },

  setBorderRadius(style, p) {
    if (p.borderRadius) {
      style['--radius'] = p.borderRadius + 'px';
    }
  },

  setTextAlignment(style, p) {
    if (p.textAlignment) {
      style['--ta'] = p.textAlignment;
    }
  },

  setLevitate(style, p) {
    if (p.levitate) {
      style['--levitate'] = p.levitate;
    }
  },

  setPosition(style, p) {
    if (!p.position) {
      return;
    }

    // Maybe replace by Class
    style['--pos'] = p.position;

    if (p.left && p.left !== 'auto') {
      style['--left'] = p.left;
    }

    if (p.right && p.right !== 'auto') {
      style['--right'] = p.right;
    }

    if (p.top && p.top !== 'auto') {
      style['--top'] = p.top;
    }

    if (p.bottom && p.bottom !== 'auto') {
      style['--bottom'] = p.bottom;
    }

    // We need some z-index here
    style['--z'] = p.zIndex || 'auto';
  },

  setSpacing(style, values, padding = true) {
    const s = padding ? 'p' : 'm';

    if (!values.some(v => v > 0)) {
      return;
    }

    // style[`--${s}t`] = values[0] + 'px';
    // style[`--${s}r`] = values[1] + 'px';
    // style[`--${s}b`] = values[2] + 'px';
    // style[`--${s}l`] = values[3] + 'px';
    // Hacky ;)

    style[`--${s}`] = values.join('px ') + 'px';
  },

  setVisibility(classes, p) {
    if (!p.visibility) {
      return;
    }

    if (!p.visibility.includes(ElementVisible.sm)) {
      classes.push('hidden-sm');
    }

    if (!p.visibility.includes(ElementVisible.md)) {
      classes.push('hidden-md');
    }

    if (!p.visibility.includes(ElementVisible.lg)) {
      classes.push('hidden-lg');
    }

    if (!p.visibility.includes(ElementVisible.xl)) {
      classes.push('hidden-xl');
    }
  },

  setBackground(style, p) {
    if (!p.backgroundType) {
      return;
    }

    if (p.backgroundType === 'image' && p.backgroundImage) {
      style['backgroundImage'] = `url("${encodeURI(this.getImagePath(p.backgroundImage))}")`;
      style['--bgr'] = p.backgroundImageRepeat || 'repeat';
      style['--bgs'] = p.backgroundImageSize || 'cover';
      style['--bgp'] = p.backgroundImagePosition || 'center';
    }

    if (p.backgroundColor) {
      style['--bgc'] = p.backgroundColor;
    }

    if (p.backgroundType === 'gradient') {
      style['--grad'] = p.backgroundGradientDegree + 'deg';
      style['--grad-color'] = p.backgroundGradientColor;
    }

    if (p.backgroundType === 'pattern' && p.backgroundPattern) {
      const path = `/img/pattern/${p.backgroundPattern}`;

      style['--bgi'] = `url("${path}")`;
    }
  },

  setPrefixedBackground(style, p, prefix) {
    if (!p[`${prefix}BackgroundType`]) {
      return;
    }

    if (p[`${prefix}BackgroundType`] === 'image' && p[`${prefix}BackgroundImage`]) {
      style['backgroundImage'] = `url("${encodeURI(this.getImagePath(p[`${prefix}BackgroundImage`]))}")`;
      style['--bgr'] = p[`${prefix}BackgroundImageRepeat`] || 'repeat';
      style['--bgs'] = p[`${prefix}BackgroundImageSize`] || 'cover';
      style['--bgp'] = p[`${prefix}BackgroundImagePosition`] || 'center';
    }

    if (p[`${prefix}BackgroundColor`]) {
      style['--bgc'] = p[`${prefix}BackgroundColor`];
    }

    if (p[`${prefix}BackgroundType`] === 'gradient') {
      style['--grad'] = p[`${prefix}BackgroundGradientDegree`] + 'deg';
      style['--grad-color'] = p[`${prefix}BackgroundGradientColor`];
    }

    if (p[`${prefix}BackgroundType`] === 'pattern' && p[`${prefix}BackgroundPattern`]) {
      const path = `/img/pattern/${p[`${prefix}BackgroundPattern`]}`;

      style['--bgi'] = `url("${path}")`;
    }
  },

  setTextColor(style, p) {
    if (!p.color) {
      return;
    }

    if (ElementColorClasses.includes(p.color)) {
      style['--c'] = `var(--${p.color})`;
    } else {
      style['--c'] = p.color;
    }
  },

  setText3d(style, p) {
    if (!p.text3d) {
      return;
    }

    if (ElementColorClasses.includes(p.text3d)) {
      style['--text-3d'] = `var(--${p.text3d})`;
    } else {
      style['--text-3d'] = p.text3d;
    }
  },

  setFontSize(style, p) {
    if (p.fontSize) {
      style['--size'] = p.fontSize + 'px';
    }

    if (p.fontWeight) {
      style['--weight'] = p.fontWeight;
    }
  },

  setTextAlign(style, p) {
    if (p.textAlign) {
      style['--ta'] = p.textAlign;
    }
  },

  setLineClamp(style, p) {
    if (p.lineClamp) {
      style['--line-clamp'] = p.lineClamp;
    }
  },

  setShadow(style, p) {
    if (!p.shadow) {
      return;
    }

    let base = '--shadow';

    if (p.shadowType) {
      base += `-${p.shadowType}`;
    }

    style[base] = p.shadow;
  },

  setHoverEffect(style, p) {
    if (!p.hoverType) {
      return;
    }

    if (p.hoverType === 'shadow') {
      style['--shadow-hvr'] = p.hover;
      return;
    }

    if (p.hoverType === 'levitate') {
      style['--levitate-hvr'] = p.hover;
      return;
    }

    if (p.hoverType === 'translateX') {
      style['--translatex-hvr'] = p.hover + 'px';
      return;
    }

    if (p.hoverType === 'translateY') {
      style['--translatey-hvr'] = p.hover + 'px';
      return;
    }

    if (p.hoverType === 'scale') {
      style['--scale-hvr'] = (100 + p.hover) + '%';
      return;
    }

    if (p.hoverType === 'rotate') {
      style['--rotate-hvr'] = p.hover + 'deg';
      return;
    }

    if (p.hoverType === 'skew') {
      style['--skew-hvr'] = p.hover + 'deg';
      return;
    }

    let color = ElementColorClasses.includes(p.hoverColor) ? `var(--${p.hoverColor})` : p.hoverColor;

    if (p.hoverType === 'border') {
      style['--hvr-b'] = `${p.hover}px solid ${color}`;
    }

    if (p.hoverType === 'background') {
      style['--hvr-bg'] = color;
    }
  },

  setDimensions(style, p) {
    if (p.width && p.width !== '0') {
      style['--w'] = p.width;
    }

    if (p.height && p.height !== '0') {
      style['--h'] = p.height;
    }

    if (p.maxWidth && p.maxWidth !== '0') {
      style['--maxw'] = p.maxWidth;
    }

    if (p.maxHeight && p.maxHeight !== '0') {
      style['--maxh'] = p.maxHeight;
    }
  },

  setDisplay(style, p) {
    if (!p.display || p.display === 'block') {
      return;
    }

    style['--d'] = p.display;

    if (p.alignItems) {
      style['--ai'] = p.alignItems;
    }

    if (p.justifyContent) {
      style['--jc'] = p.justifyContent;
    }
  },

  setTransform(style, p) {
    if (!p.transform) {
      return;
    }

    style['--t'] = p.transform;
  },

  getImagePath(img) {
    if (!img) {
      return '';
    }

    if (img.startsWith('/')) {
      return img;
    }

    if (img.startsWith('http')) {
      return img;
    }

    return config.baseUrl + '/' + img;
  }
};

export default propertyHelper;
