import React, { useState } from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import elementHelper from '../../modules/elementHelper';
import ElementDiv from '../ElementControls/ElementDiv';
import DropZone from '../Sortable/DropZone';
import FigureElementsRenderer from '../ElementControls/FigureElementsRenderer';
import propertyHelper from '../../modules/propertyHelper';
import { setActiveElement } from '../../store/actions/builder';

const ImageOverlayElement = props => {
  const [dimensions, setDimensions] = useState({width: 0, height: 0});

  const {element} = props;
  const {componentProperties} = element;

  let additionalClasses = 'mame-image-overlay image-overlay';

  if (componentProperties.overlayOn === 'hover') {
    additionalClasses += ' image-overlay--hover';
  }

  if (componentProperties.overlayShowAlways) {
    additionalClasses += ' image-overlay--show';
  }

  const id = elementHelper.getId(element);
  const classes = elementHelper.getClasses(element, additionalClasses);
  const style = elementHelper.getStyle(element);

  // Image is always block
  style['--d'] = 'block';
  delete style['--m'];
  delete style['--pos'];
  delete style['--right'];
  delete style['--top'];
  delete style['--left'];
  delete style['--bottom'];

  // This is needed for grids
  style['--w'] = '100%';
  style['--h'] = '100%';

  // Some things are on the element
  const elementStyle = {};

  propertyHelper.setDisplay(elementStyle, element.generalProperties);
  propertyHelper.setTextAlign(elementStyle, element.generalProperties);
  propertyHelper.setSpacing(elementStyle, element.generalProperties.margin, false);
  propertyHelper.setDimensions(elementStyle, element.generalProperties);
  propertyHelper.setPosition(elementStyle, element.generalProperties);

  if (componentProperties.filterType) {
    if (componentProperties.filterType === 'blur') {
      style['--fr'] = `blur(${componentProperties.filterValue}px)`;
    } else {
      style['--fr'] = `${componentProperties.filterType}(${componentProperties.filterValue}%)`;
    }
  }

  if (componentProperties.autoDimensions) {
    style['maxWidth'] = '100%';
    style['height'] = 'auto';
  }

  if (!componentProperties.alt) {
    console.log('[Mame] Overlay Image is missing alt attribute, please set one!');
  }

  const overlayStyle = {};
  propertyHelper.setPrefixedBackground(overlayStyle, componentProperties, 'overlay');

  const setActive = e => {
    if (e.target.classList.contains('mame-image-overlay')
      || e.target.classList.contains('image-overlay__image')
      || e.target.classList.contains('image-overlay__overlay')
    ) {
      props.dispatch(setActiveElement(element));
    }
  };

  const getImageDimensions = e => {
    if (!componentProperties.autoDimensions) {
      return;
    }

    setDimensions({width: e.target.offsetWidth, height: e.target.offsetHeight});
  };

  return (
    <ElementDiv {...props} id={id} classes={classes} style={elementStyle} action={setActive}>
      <img src={propertyHelper.getImagePath(componentProperties.image)}
           alt={componentProperties.alt || null}
           className="image-overlay__image"
           style={style}
           loading={componentProperties.lazy ? 'lazy' : null}
           onLoad={getImageDimensions}
           width={componentProperties.autoDimensions ? (dimensions.width || null) : null}
           height={componentProperties.autoDimensions ? (dimensions.height || null) : null}
      />

      <div className="image-overlay__overlay" style={overlayStyle}>
        <DropZone element={element}
                  items={element.elements}>
          <FigureElementsRenderer
            tag="div"
            element={element}
            className="image-overlay__inner"
            moveText={fbt('Move Element to Overlay.', 'ImageOverlayElement')}
          />
        </DropZone>
      </div>
    </ElementDiv>
  );
};

export default connect()(ImageOverlayElement);
