import { Route, Redirect } from 'react-router-dom';
import React from 'react';
import { connect } from 'react-redux';

const PrivateRoute = ({children, token, ...rest}) => {
  return (
    <Route
      {...rest}
      render={({location}) =>
        token ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: {from: location}
            }}
          />
        )
      }
    />
  );
};

const mapStateToProps = state => ({token: state.user.token});

export default connect(mapStateToProps)(PrivateRoute);
