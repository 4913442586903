import React from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import Fieldset from '../FieldSet';
import BackgroundPicker from '../Controls/BackgroundPicker';
import LinkGroup from '../Controls/LinkGroup';
import HelpText from '../Controls/HelpText';
import InputGroup from '../InputGroup';

const FigureProperties = props => {
  const {element} = props;
  const {componentProperties} = element;

  return (
    <Fieldset title={fbt('Figure Properties', 'FigureProperties')} isOpen>
      <HelpText style={{'--m': '10px 0'}}>
        <fbt desc="FigureProperties">
          Add and edit the content directly on the element.
        </fbt>
      </HelpText>

      <InputGroup>
        <BackgroundPicker
          title={fbt('Custom Background', 'FigureProperties')}
          type={componentProperties.backgroundType}
          image={componentProperties.backgroundImage}
          color={componentProperties.backgroundColor}
          gradientColor={componentProperties.backgroundGradientColor}
          gradientDegree={componentProperties.backgroundGradientDegree}
          pattern={componentProperties.backgroundPattern}
          typeAction={(val) => {
            props.handlePropertyChange('backgroundType', val);
          }}
          colorAction={(val) => {
            props.handlePropertyChange('backgroundColor', val);
          }}
          imageAction={(val) => {
            props.handlePropertyChange('backgroundImage', val);
          }}
          gradientColorAction={(val) => {
            props.handlePropertyChange('backgroundGradientColor', val);
          }}
          gradientDegreeAction={(val) => {
            props.handlePropertyChange('backgroundGradientDegree', val);
          }}
          patternAction={(val) => {
            props.handlePropertyChange('backgroundPattern', val);
          }}
          showLabel
          hasNone
          hideFieldset
          hideVideo
        />
      </InputGroup>

      <LinkGroup
        linkType={componentProperties.linkType}
        actionLinkType={val => props.handlePropertyChange('linkType', val)}
        link={componentProperties.link}
        actionLink={val => props.handlePropertyChange('link', val)}
        page={componentProperties.page}
        actionPage={val => props.handlePropertyChange('page', val)}
        linkTarget={componentProperties.linkTarget}
        actionLinkTarget={val => props.handlePropertyChange('linkTarget', val)}
        isNofollow={componentProperties.isNofollow}
        actionNofollow={val => props.handlePropertyChange('isNofollow', val)}
      />
    </Fieldset>
  );
};

export default connect()(FigureProperties);
