import React from 'react';
import { connect } from 'react-redux';

import elementHelper from '../../modules/elementHelper';
import ElementDiv from '../ElementControls/ElementDiv';
import { setActiveElement } from '../../store/actions/builder';
import DropZone from '../Sortable/DropZone';
import FigureElementsRenderer from '../ElementControls/FigureElementsRenderer';
import propertyHelper from '../../modules/propertyHelper';


import ElementLink from '../ElementControls/ElementLink';

const FigureElement = props => {
  const element = props.element;
  const {componentProperties} = element;

  const id = elementHelper.getId(element);
  const classes = elementHelper.getClasses(element, 'accent mame-figure');
  const style = elementHelper.getStyle(element);

  propertyHelper.setBackground(style, element.componentProperties);

  const setActive = e => {
    if (!e.target.classList.contains('mame-figure')) {
      return;
    }

    props.dispatch(setActiveElement(element));
  };
  const content = (
    <DropZone element={element}
              items={element.elements}>
      <FigureElementsRenderer
        className="mame-figure__body"
        tag="div"
        element={element}
      />
    </DropZone>
  );

  return (
    <ElementDiv {...props} id={id} classes={classes} style={style}
                tag="figure" action={setActive}
    >
      {componentProperties.linkType && (
        <ElementLink
          linkType={componentProperties.linkType}
          link={componentProperties.link}
          target={componentProperties.linkTarget}
          page={componentProperties.page}
          isNofollow={componentProperties.isNofollow}
          onClick={e => {
            e.preventDefault();
            props.dispatch(setActiveElement(element));
          }}
        >
          {content}
        </ElementLink>
      )}

      {!componentProperties.linkType && content}
    </ElementDiv>
  );
};

export default connect()(FigureElement);
