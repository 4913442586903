import EmbedHtmlProperties from '../../components/Properties/Elements/EmbedHtmlProperties';
import { ElementVisible } from '../../modules/consts/Elements';

const EmbedHtmlModel = {
  title: 'Embedded HTML',
  category: 'Actions',
  icon: 'html5',
  helpPage: '/documentation/elements/embedded-html',
  component: 'EmbedHtmlElement',
  propertyComponent: EmbedHtmlProperties,
  hasChildElements: false,
  generalProperties: {
    hasVisibility: true,
    hasTextAlign: true,
    hasTextColor: false,
  },
  defaults: {
    demo: true,
    _id: null,
    identifier: 'New Embedded HTML',
    component: 'EmbedHtmlElement',
    componentProperties: {
      html: '<h3>Hello Mame</h3>',
    },
    generalProperties: {
      visibility: [ElementVisible.sm, ElementVisible.md, ElementVisible.lg, ElementVisible.xl],
      published: true,
      margin: [0, 0, 0, 0],
      padding: [0, 0, 0, 0],
    },
  },
};

export default EmbedHtmlModel;
