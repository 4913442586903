import React from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import Fieldset from '../FieldSet';
import ItemsFieldsetWrapper from '../Fieldsets/ItemsFieldsetWrapper';
import TextInput from '../Controls/TextInput';
import MediaPicker from '../Controls/MediaPicker';
import LinkGroup from '../Controls/LinkGroup';
import SelectInput from '../Controls/SelectInput';
import CheckboxInput from '../Controls/CheckboxInput';
import HelpText from '../Controls/HelpText';
import InputGroup from '../InputGroup';

const GalleryProperties = props => {
  const {model, element} = props;
  const {componentProperties} = element;

  return (
    <>
      <Fieldset title={fbt('Gallery Properties', 'GalleryProperties')} isOpen>
        <InputGroup style={{'--mt': '10px'}}>
          <SelectInput id="gallery-style" title={fbt('Style', 'GalleryProperties')}
                       showLabel
                       value={componentProperties.style || ''}
                       action={val => {
                         props.handlePropertyChange('style', val);
                       }}
                       options={{
                         '': fbt('Default', 'GalleryProperties'),
                       }}
          />
        </InputGroup>

        <InputGroup>
          <CheckboxInput title={fbt('Generate Thumbnails', 'GalleryProperties')}
                         checked={componentProperties.thumbnailAutoGenerate}
                         id="check-generate-thumbnails"
                         action={() => {
                           props.handlePropertyChange('thumbnailAutoGenerate', !componentProperties.thumbnailAutoGenerate);
                         }}
          />
        </InputGroup>
      </Fieldset>

      <ItemsFieldsetWrapper
        title={fbt('Items', 'GalleryProperties')}
        items={componentProperties.items}
        actionDispatch={props.dispatchItems}
        template={model.itemTemplate}
        isOpen
      >
        <Controls
          action={props.handleItemChange}
          handleWholeItemChange={props.handleWholeItemChange}
        />
      </ItemsFieldsetWrapper>
    </>
  );
};

const Controls = (props) => {
  const item = props.item;
  const index = props.index;

  const linkTypeId = `link-type-${index}`;

  const handleImageUpload = (file, data) => {
    const copy = JSON.parse(JSON.stringify(item));

    copy.image = file;
    copy.thumbnail = '';

    // Store thumbnail
    if (data && data.thumbs && data.thumbs.length) {
      copy.thumbnail = data.thumbs[0];
    }

    props.handleWholeItemChange(index, copy);
  };

  return (
    <>
      <InputGroup>
        <TextInput title={fbt('Image Title and Caption', 'GalleryProperties')}
                   value={item.title} showLabel
                   action={val => props.action(index, 'title', val)}
        />
        <HelpText>
          <fbt desc="GalleryProperties">
            Keep it empty if you don't want a caption for the image.
          </fbt>
        </HelpText>
      </InputGroup>

      <InputGroup>
        <TextInput title={fbt('Image Alt', 'GalleryProperties')}
                   value={item.alt} showLabel
                   action={val => props.action(index, 'alt', val)}
        />
        <HelpText>
          <fbt desc="GalleryProperties">
            If you don't enter one, the image caption is used as alt.
          </fbt>
        </HelpText>
      </InputGroup>

      <MediaPicker
        title={fbt('Choose an Image', 'GalleryProperties')}
        image={item.thumbnail || item.image}
        action={handleImageUpload}
        createThumbnail
      />

      <LinkGroup
        linkTypeId={linkTypeId}
        linkType={item.linkType}
        actionLinkType={(val) => props.action(index, 'linkType', val)}
        link={item.link}
        actionLink={(val) => props.action(index, 'link', val)}
        page={item.page}
        actionPage={(val) => props.action(index, 'page', val)}
        linkTarget={item.linkTarget}
        actionLinkTarget={(val) => props.action(index, 'linkTarget', val)}
        isNofollow={item.isNofollow}
        actionNofollow={val => props.action(index, 'isNofollow', val)}
      />
    </>
  );
};

export default connect()(GalleryProperties);
