import React from 'react';
import { connect } from 'react-redux';

import elementHelper from '../../modules/elementHelper';
import ElementDiv from '../ElementControls/ElementDiv';

const CaptchaElement = props => {
  const element = props.element;
  // const {componentProperties} = element;

  const id = elementHelper.getId(element);
  const classes = elementHelper.getClasses(element, 'mame-captcha');
  const style = elementHelper.getStyle(element);

  return (
    <ElementDiv {...props} id={id} classes={classes}
                style={style}>
      <h3>Not implemented yet</h3>
    </ElementDiv>
  )
}

export default connect()(CaptchaElement);
