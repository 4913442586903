import React from 'react';
import { connect } from 'react-redux';
import { fieldsetTypeSelector } from '../../store/selectors';

const Fieldset = props => {
  const {fieldsetType} = props;

  if (fieldsetType === 'fieldset') {
    return (
      <fieldset id={props.id || null} className="fieldset fieldset-fieldset">
        <legend className="fieldset__legend">{props.title || ''}</legend>
        {props.children}
      </fieldset>
    );
  }

  // Default
  return (
    <details className="fieldset fieldset-details"
             id={props.id || null}
             open={props.isOpen || false}
    >
      <summary className="fieldset__summary">
        {props.title || ''}
      </summary>

      <div className="fieldset__items">
        {props.children}
      </div>
    </details>
  );
};

const mapStateToProps = state => ({
  fieldsetType: fieldsetTypeSelector(state),
});

export default connect(mapStateToProps)(Fieldset);
