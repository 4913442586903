import React from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import elementHelper from '../../modules/elementHelper';
import ElementDiv from '../ElementControls/ElementDiv';

const PayPalDonateElement = props => {
  const element = props.element;
  const {componentProperties} = element;

  const id = elementHelper.getId(element);
  const classes = elementHelper.getClasses(element, 'mame-paypal-button');
  const style = elementHelper.getStyle(element);

  const buttonTypes = {
    '_cart': `https://www.paypalobjects.com/${componentProperties.countryCode}/i/btn/btn_cart_LG.gif`,
    '_xclick': `https://www.paypalobjects.com/${componentProperties.countryCode}/i/btn/btn_buynow_LG.gif`,
    '_donations': `https://www.paypalobjects.com/${componentProperties.countryCode}/i/btn/btn_donate_LG.gif`,
  };

  return (
    <ElementDiv {...props} id={id} classes={classes}>
      <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post" style={style}>
        <input type="hidden" name="business" value={componentProperties.recipient}/>
        <input type="hidden" name="cmd" value={componentProperties.type}/>
        <input type="hidden" name="add" value="1"/>

        <input type="hidden" name="item_name" value={componentProperties.itemName}/>
        {componentProperties.amount && <input type="hidden" name="amount" value={componentProperties.amount}/>}

        <input type="hidden" name="currency_code" value={componentProperties.currencyCode}/>

        <input type="image" name="submit"
               src={buttonTypes[componentProperties.type]} alt={fbt('Pay with PayPal', 'PayPalDonateElement')}
               style={{'--w': 'auto', '--d': 'inline-block', '--b': '0'}}
        />
      </form>
    </ElementDiv>
  );
};

export default connect()(PayPalDonateElement);
