import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import elementHelper from '../../modules/elementHelper';
import ElementDiv from '../ElementControls/ElementDiv';

const DisqusElement = props => {
  const {element, isRender} = props;
  const {componentProperties} = element;

  const pageUrl = componentProperties.pageUrlType === 'custom'
    ? componentProperties.pageUrl
    : (props.pageCanonical || '');

  const pageIdentifier = componentProperties.pageIdentifierType === 'custom'
    ? componentProperties.pageIdentifier
    : (props.page._id || '');

  useEffect(() => {
    if (!componentProperties.shortName) {
      return;
    }

    // Only execute for the preview
    if (isRender) {
      return;
    }

    window.setupDisqus();
  }, [componentProperties, pageIdentifier, pageUrl, isRender]);

  const id = elementHelper.getId(element);
  const classes = elementHelper.getClasses(element, 'mame-disqus');
  const style = elementHelper.getStyle(element);

  if (!componentProperties.shortName) {
    return (
      <ElementDiv {...props} id={id} classes={classes} style={style}>
        <h3>{fbt('Please configure Disqus in the Element Properties first', 'DisqusElement')}</h3>
      </ElementDiv>
    );
  }
  return (
    <ElementDiv {...props} id={id} classes={classes} style={style}>
      <div id="disqus_thread"
           data-short-name={componentProperties.shortName}
           data-page-url={pageUrl}
           data-page-identifier={pageIdentifier}
      />
    </ElementDiv>
  );
};

const mapStateToProps = state => ({
  pageCanonical: state.activeSite.pageCanonical,
  page: state.activeSite.page,
  isRender: state.builder.isRender,
});

export default connect(mapStateToProps)(DisqusElement);
