import React, { useState } from 'react';

const Alert = (props) => {
  const [visible, setVisible] = useState(true);

  const dismiss = (e) => {
    if (props.action) {
      props.action(e);
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    setVisible(false);
  };

  return (
    <div
      className={props.className || null}
      style={{
        ...props.style,
        '--bgc': `var(--${props.variant || 'mame-primary'})`,
        '--c': props.fontColor || '#fff',
        '--radius': props.radius || 0,
        '--my': props.my || '0',
        '--mx': props.mx || '0',
        '--d': visible ? 'block' : 'none',
      }}>
      <div className="wrapper" style={{'--gutter': '1vw'}}>
        <div style={{'--d': 'flex', '--py': props.py || '20px', '--px': props.px || '20px',}}>
          <div style={{'--fg': 1}}>
            {props.msg || props.children}
          </div>
          {!props.hideDismiss && (
            <div>
            <span className="icon icon-times" onClick={dismiss} style={{'--cur': 'pointer'}}/>
          </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Alert;
