import React from 'react';

const ActionIconButton = (props) => {
  let iconClass = `icon icon-${props.icon || 'cross'}`;

  if (props.size) {
    iconClass += ` icon-${props.size}x`;
  }

  const buttonClass = 'action-icon-button ' + (props.active ? 'mame-primary' : 'transparent');

  return (
    <button className={buttonClass}
            onClick={props.action}
            style={props.style || null}
            id={props.id || null}
            aria-label={props.title || ''}
            title={props.title || ''}>
      <span className={iconClass}
            aria-hidden
            style={{'--cur': props.cursor || 'pointer'}}
      />
    </button>
  );
};

export default ActionIconButton;
