import React from 'react';
import fbt from 'fbt';

import Fieldset from '../FieldSet';
import TextInput from '../Controls/TextInput';
import SelectInput from '../Controls/SelectInput';
import TextArea from '../Controls/TextArea';
import AdvancedColorPicker from '../Controls/AdvancedColorPicker';
import HelpText from '../Controls/HelpText';
import { ElementColorClasses } from '../../../modules/consts/Elements';
import CheckboxInput from '../Controls/CheckboxInput';
import InputGroup from '../InputGroup';

const FormProperties = props => {
  const {element} = props;
  const {componentProperties} = element;

  return (
    <Fieldset title={fbt('Form Properties', 'FormProperties')} isOpen>
      <InputGroup style={{'--mt': '10px'}}>
        <TextInput title={fbt('Form Title', 'FormProperties')}
                   value={componentProperties.title} showLabel
                   id="input-form-title"
                   action={val => props.handlePropertyChange('title', val)}
        />
      </InputGroup>

      <InputGroup>
        <SelectInput title={fbt('Display title as', 'FormProperties')}
                     value={componentProperties.titleTag}
                     id="form-show-title"
                     action={val => props.handlePropertyChange('titleTag', val)}
                     options={{
                       '': fbt('Hide', 'FormProperties'),
                       'h1': fbt('Heading 1', 'FormProperties'),
                       'h2': fbt('Heading 2', 'FormProperties'),
                       'h3': fbt('Heading 3', 'FormProperties'),
                       'h4': fbt('Heading 4', 'FormProperties'),
                       'h5': fbt('Heading 5', 'FormProperties'),
                       'h6': fbt('Heading 6', 'FormProperties'),
                     }}
                     showLabel
        />
      </InputGroup>

      <InputGroup>
        <TextInput title={fbt('Submit Button Text', 'FormProperties')}
                   value={componentProperties.submitText || ''}
                   id="input-submit-button-text"
                   action={val => props.handlePropertyChange('submitText', val)}
                   showLabel
        />
      </InputGroup>

      <AdvancedColorPicker title={fbt('Submit Button Color Style', 'FormProperties')}
                           id="form-submit-color"
                           value={componentProperties.submitBackground}
                           action={val => props.handlePropertyChange('submitBackground', val)}
                           valueText={componentProperties.submitColor}
                           actionText={val => props.handlePropertyChange('submitColor', val)}
      />

      {ElementColorClasses.includes(componentProperties.submitBackground) && (
        <InputGroup>
          <CheckboxInput
            checked={componentProperties.submitOutline}
            title={fbt('Submit Button Outline only', 'FormProperties')}
            action={() => props.handlePropertyChange('submitOutline', !componentProperties.submitOutline)}
          />
        </InputGroup>
      )}

      <InputGroup>
        <SelectInput title={fbt('Form Action', 'FormProperties')}
                     value={componentProperties.action} showLabel
                     id="form-action-select"
                     action={val => props.handlePropertyChange('action', val)}
                     options={{
                       'email': fbt('Send to Email', 'FormProperties'),
                       'custom': fbt('Send the Form to a custom address (POST)', 'FormProperties'),
                       'api': fbt('Send to a 3rd party API (advanced)', 'FormProperties'),
                     }}
        />
      </InputGroup>

      {componentProperties.action === 'custom' && (
        <InputGroup>
          <TextInput title={fbt('Custom Address', 'FormProperties')}
                     value={componentProperties.customActionAddress || ''}
                     type="url"
                     id="input-form-custom-address" placeholder="https://example.com/post"
                     action={val => props.handlePropertyChange('customActionAddress', val)}
                     showLabel
          />
          <HelpText>
            <fbt desc="FormProperties">
              Set the URL where the form data is send to (POST). Make sure your custom identifiers (name) match the
              target.
            </fbt>
          </HelpText>
        </InputGroup>
      )}

      {componentProperties.action === 'email' && (
        <>
          <HelpText style={{'--m': '8px 0'}}>
            <fbt desc="FormProperties">
              These email settings are <strong>NOT</strong> publicly visible. If you delete this site
              in Mame, the form is no longer going to work.
            </fbt>
          </HelpText>
          <InputGroup>
            <TextInput title={fbt('Recipient', 'FormProperties')}
                       showLabel
                       value={componentProperties.emailAddress}
                       action={val => props.handlePropertyChange('emailAddress', val)}
            />
          </InputGroup>

          <InputGroup>
            <TextInput title={fbt('BCC Email Address (Optional)', 'FormProperties')} showLabel
                       value={componentProperties.bccAddress}
                       action={val => props.handlePropertyChange('bccAddress', val)}
            />
          </InputGroup>

          <InputGroup>
            <TextInput title={fbt('Subject', 'FormProperties')} showLabel
                       value={componentProperties.subject}
                       action={val => props.handlePropertyChange('subject', val)}
            />
          </InputGroup>

          <InputGroup>
            <TextInput title={fbt('Reply to (Optional)', 'FormProperties')} showLabel
                       value={componentProperties.replyTo}
                       action={val => props.handlePropertyChange('replyTo', val)}
            />
          </InputGroup>

          <InputGroup>
            <TextArea title={fbt('Email Content (Body)', 'FormProperties')} showLabel
                      value={componentProperties.emailTemplate}
                      action={val => props.handlePropertyChange('emailTemplate', val)}
            />
          </InputGroup>
        </>
      )}

      {componentProperties.action === 'api' && (
        <>
          <HelpText style={{'--m': '8px 0'}}>
            <fbt desc="FormProperties">
              The form data is serialized and send JSON encoded to the API URL (via POST).
              Make sure the API returns status codes for errors (i.e. 4xx)
              and success (200). This is done via the Mame backend API, headers or additional data
              are <b>NOT</b> publicly visible on the form.
            </fbt>
          </HelpText>

          <InputGroup>
            <TextInput title={fbt('API Url', 'FormProperties')}
                       showLabel
                       value={componentProperties.apiUrl}
                       action={val => props.handlePropertyChange('apiUrl', val)}
            />
          </InputGroup>

          <InputGroup>
            <TextArea title={fbt('Additional Headers', 'FormProperties')} showLabel
                      value={componentProperties.apiHeaders}
                      action={val => props.handlePropertyChange('apiHeaders', val)}
            />
          </InputGroup>

          <InputGroup>
            <TextArea title={fbt('Additional Data (JSON)', 'FormProperties')} showLabel
                      value={componentProperties.apiAdditional}
                      action={val => props.handlePropertyChange('apiAdditional', val)}
            />
          </InputGroup>
        </>
      )}

      <InputGroup>
        <TextArea title={fbt('Success Text (shown after submit)', 'FormProperties')}
                  value={componentProperties.successText || ''} showLabel
                  action={val => props.handlePropertyChange('successText', val)}
        />
      </InputGroup>
    </Fieldset>
  );
};

export default FormProperties;
