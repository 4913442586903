import React from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import { setSidebarView } from '../../store/actions/builder';
import SiteProperties from './SiteProperties';
import SectionProperties from './SectionProperties';
import ElementProperties from './ElementProperties';
import PageProperties from './PageProperties';
import Activities from '../Activities/Activities';

const Sidebar = props => {
  const classes = ['sidebar'];

  if (props.activeType) {
    classes.push('sidebar--visible');
  }

  const view = props.sidebarView;

  classes.push(`sidebar--${props.sidebarView}`);
  classes.push(`sidebar--${props.sidebarPlacement}`);

  return (
    <div className="sidebar__placeholder">
      <div className={classes.join(' ')}>
        <div className="sidebar__switch"
             style={{'--d': 'grid', '--gtc': '1fr 1fr 1fr 1fr 1fr', '--jc': 'stretch', '--ai': 'center'}}>
          <button id="btn-site-properties"
                  className={'sidebar__switcher sidebar__switcher-site transparent' + (view === 'site' ? ' sidebar__switcher-active' : '')}
                  title={fbt('Site Properties', 'SideBar')}
                  onClick={() => {
                    props.dispatch(setSidebarView('site'));
                  }}
                  style={{'--radius': '0', '--m': '0'}}>
            <span className="icon icon-globe" aria-hidden/>
            {view === 'site' ? (<span style={{'--ml': '10px'}}><fbt desc="SideBar">Site</fbt></span>) : ''}
          </button>
          <button id="btn-page-properties"
                  className={'sidebar__switcher sidebar__switcher-page transparent' + (view === 'page' ? ' sidebar__switcher-active' : '')}
                  title={fbt('Page Properties', 'SideBar')}
                  onClick={() => {
                    props.dispatch(setSidebarView('page'));
                  }}
                  style={{'--radius': '0', '--m': '0'}}>
            <span className="icon icon-file-o" aria-hidden/>
            {view === 'page' ? (<span style={{'--ml': '10px'}}><fbt desc="SideBar">Page</fbt></span>) : ''}
          </button>
          <button id="btn-section-properties"
                  className={'sidebar__switcher sidebar__switcher-section transparent' + (view === 'section' ? ' sidebar__switcher-active' : '')}
                  title={fbt('Section Properties', 'SideBar')}
                  onClick={() => {
                    props.dispatch(setSidebarView('section'));
                  }}
                  style={{'--radius': '0', '--m': '0'}} aria-hidden>
            <span className="icon icon-object-group"/>
            {view === 'section' ? (<span style={{'--ml': '10px'}}><fbt desc="SideBar">Section</fbt></span>) : ''}
          </button>
          <button id="btn-element-properties"
                  className={'sidebar__switcher sidebar__switcher-element transparent' + (view === 'element' ? ' sidebar__switcher-active' : '')}
                  title={fbt('Element Properties', 'SideBar')}
                  onClick={() => {
                    props.dispatch(setSidebarView('element'));
                  }}
                  style={{'--radius': '0', '--m': '0'}}>
            <span className="icon icon-map-o" aria-hidden/>
            {view === 'element' ? (<span style={{'--ml': '10px'}}><fbt desc="SideBar">Element</fbt></span>) : ''}
          </button>
          <button id="btn-activity"
                  className={'sidebar__switcher sidebar__switcher-activity transparent' + (view === 'activity' ? ' sidebar__switcher-active' : '')}
                  title={fbt('Activities', 'SideBar')}
                  onClick={() => {
                    props.dispatch(setSidebarView('activity'));
                  }}
                  style={{'--radius': '0', '--m': '0', '--br': '0'}}>
            <span className="icon icon-history" aria-hidden/>
            {view === 'activity' ? (<span style={{'--ml': '10px'}}><fbt desc="SideBar">Activity</fbt></span>) : ''}
          </button>
        </div>
        {/* Fix with CSS*/}
        <div
          className={'sidebar__properties sidebar__properties-site ' + (view === 'site' ? 'sidebar__properties-visible' : 'sidebar__properties-hidden')}>
          {view === 'site' && <SiteProperties/>}
        </div>
        <div
          className={'sidebar__properties sidebar__properties-page ' + (view === 'page' ? 'sidebar__properties-visible' : 'sidebar__properties-hidden')}>
          {<PageProperties/>}
        </div>
        <div
          className={'sidebar__properties sidebar__properties-section ' + (view === 'section' ? 'sidebar__properties-visible' : 'sidebar__properties-hidden')}>
          {<SectionProperties/>}
        </div>
        <div
          className={'sidebar__properties sidebar__properties-element ' + (view === 'element' ? 'sidebar__properties-visible' : 'sidebar__properties-hidden')}>
          {<ElementProperties/>}
        </div>
        <div
          className={'sidebar__properties sidebar__properties-activity ' + (view === 'activity' ? 'sidebar__properties-visible' : 'sidebar__properties-hidden')}>
          {view === 'activity' && <Activities />}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  sidebarView: state.builder.sidebarView,
  activeType: state.builder.activeType,
  sidebarPlacement: state.settings.sidebarPlacement,
});

export default connect(mapStateToProps)(Sidebar);

