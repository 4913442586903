import ImageOverlayProperties from '../../components/Properties/Elements/ImageOverlayProperties';
import { ElementVisible } from '../../modules/consts/Elements';

const ImageOverlayModel = {
  title: 'Image with Overlay',
  category: 'Media',
  helpPage: '/documentation/elements/image-overlay',
  component: 'ImageOverlayElement',
  propertyComponent: ImageOverlayProperties,
  hasChildElements: true,
  generalProperties: {
    hasVisibility: true,
    hasTextAlign: true,
    hasTextColor: false,
    hasBackground: false,
    hideFont: true,
  },
  defaults: {
    demo: true,
    _id: null,
    identifier: 'New Image with Overlay',
    component: 'ImageOverlayElement',
    componentProperties: {
      image: 'https://source.unsplash.com/Aq7id0ZjEW4/400x200',
      alt: 'Mountains',
      lazy: true,
      autoDimensions: true,
      filterType: '',
      filterValue: 0,
      overlayOn: 'hover',
      overlayShowAlways: false,
      overlayBackgroundType: 'color',
      overlayBackgroundImage: '',
      overlayBackgroundPattern: '',
      overlayBackgroundColor: 'rgba(0, 0, 0, 0.6)',
      overlayBackgroundGradientColor: '#f1f1f1',
      overlayBackgroundGradientDegree: 0,
    },
    generalProperties: {
      visibility: [ElementVisible.sm, ElementVisible.md, ElementVisible.lg, ElementVisible.xl],
      published: true,
      margin: [0, 0, 0, 0],
      padding: [0, 0, 0, 0],
      display: 'inline-block',
    },
    elements: [],
  },
};

export default ImageOverlayModel;
