import BaseService from './BaseService';

const paths = {
  get: '/ping',
};

/**
 * Service for pinging the API
 */
class PingService extends BaseService {
  constructor() {
    super(paths);
    this.requireLogin = true;
  }
}

export default new PingService();
