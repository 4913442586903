import React from 'react';
import { connect } from 'react-redux';

import Loading from '../components/General/Loading';
import Site from '../components/Site/Site';
import Section from '../components/Sections/Section';

import SectionErrorRenderer from '../components/SectionControls/SectionErrorRenderer';

const PreviewContent = props => {
  if (!props.site || !props.pages || !props.page || !props.sections || !props.elements) {
    return <Loading/>;
  }

  return (
    <div className="preview">
      <div id="site-content">
        <Site>
          {props.page.sections.map(sectionId => {
            const section = props.sections.find(s => s._id === sectionId);

            if (!section) {
              // Mostly used for working against props propagating issues
              return <SectionErrorRenderer id={sectionId} key={sectionId}/>;
            }

            return <Section key={sectionId} id={sectionId} section={section}/>;
          })}
        </Site>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  page: state.activeSite.page,
  site: state.activeSite.site,
  sections: state.activeSite.sections,
  elements: state.activeSite.elements,
  pages: state.activeSite.pages,
  sites: state.sites.sites,
});

export default connect(mapStateToProps)(PreviewContent);
