import serviceHelper from '../../modules/serviceHelper';
import { SET_SITE_MEDIA } from '../actionTypes';

export const apiLoadMedia = (siteId) => (
  async (dispatch) => {
    const service = serviceHelper.getService('MediaService');

    const res = await service.get({siteId: siteId});

    const items = res.data.items;

    dispatch(setSiteMedia(items));
  }
);

export const setSiteMedia = (media) => ({type: SET_SITE_MEDIA, payload: {media}});
