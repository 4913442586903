import ColumnProperties from '../../components/Properties/Elements/ColumnProperties';
import { ElementVisible } from '../../modules/consts/Elements';

const ColumnModel = {
  title: 'Columns',
  category: 'Basic',
  helpPage: '/documentation/elements/columns',
  component: 'ColumnElement',
  propertyComponent: ColumnProperties,
  hasChildElements: true,
  generalProperties: {
    hasVisibility: true,
    hasTextAlign: true,
    hasTextColor: false,
    hasBackground: true,
  },
  defaults: {
    demo: true,
    _id: null,
    identifier: 'New Columns',
    component: 'ColumnElement',
    componentProperties: {
      columns: 2,
      gap: 5,
      columnsSm: 0,
      columnsMd: 0,
      columnsLg: 0,
      columnsXl: 0,
    },
    generalProperties: {
      visibility: [ElementVisible.sm, ElementVisible.md, ElementVisible.lg, ElementVisible.xl],
      published: true,
      margin: [0, 0, 0, 0],
      padding: [0, 0, 0, 0],
    },
    elements: [],
  },
};

export default ColumnModel;
