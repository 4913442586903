import React from 'react';
import fbt from 'fbt';

import Fieldset from '../FieldSet';
import HelpText from '../Controls/HelpText';
import ColorPicker from '../Controls/ColorPicker';

const ColorsFieldset = props => {
  return (
    <Fieldset title={fbt('Colors', 'ColorsFieldset')} isOpen={props.isOpen || false}>
      {!props.override && (
        <HelpText style={{'--m': '8px 0'}}>
          <fbt desc="ColorsFieldset">
            Set the default colors, these should match your corporate layout.
          </fbt>
        </HelpText>
      )}

      {props.override && (
        <HelpText style={{'--m': '8px 0'}}>
          <fbt desc="ColorsFieldset">
            Here you can override the colors of the website for this page.
          </fbt>
        </HelpText>
      )}

      <ColorPicker title={fbt('Primary Color', 'ColorsFieldset')}
                   value={props.colors.primaryColor}
                   action={val => props.handlePropertyChange('colors', 'primaryColor', val)}
                   allowReset={props.override || false}
                   disablePreset
                   showLabel
      />
      <ColorPicker title={fbt('Secondary Color', 'ColorsFieldset')}
                   value={props.colors.secondaryColor}
                   action={val => props.handlePropertyChange('colors', 'secondaryColor', val)}
                   allowReset={props.override || false}
                   disablePreset
                   showLabel
      />
      <ColorPicker title={fbt('Text Color', 'ColorsFieldset')}
                   value={props.colors.color}
                   action={val => props.handlePropertyChange('colors', 'color', val)}
                   allowReset={props.override || false}
                   showLabel
      />
      <ColorPicker title={fbt('Link Color', 'ColorsFieldset')}
                   value={props.colors.linkColor}
                   action={val => props.handlePropertyChange('colors', 'linkColor', val)}
                   allowReset={props.override || false}
                   showLabel
      />
      <ColorPicker title={fbt('Link Hover Color', 'ColorsFieldset')}
                   value={props.colors.linkHoverColor}
                   action={val => props.handlePropertyChange('colors', 'linkHoverColor', val)}
                   allowReset={props.override || false}
                   showLabel
      />
      <ColorPicker title={fbt('Background Color', 'ColorsFieldset')}
                   value={props.colors.background}
                   action={val => props.handlePropertyChange('colors', 'background', val)}
                   allowReset={props.override || false}
                   showLabel
      />
      <ColorPicker title={fbt('Success Background', 'ColorsFieldset')}
                   value={props.colors.successBackground}
                   action={val => props.handlePropertyChange('colors', 'successBackground', val)}
                   allowReset={props.override || false}
                   showLabel
      />
      <ColorPicker title={fbt('Warning Background', 'ColorsFieldset')}
                   value={props.colors.warningBackground}
                   action={val => props.handlePropertyChange('colors', 'warningBackground', val)}
                   allowReset={props.override || false}
                   showLabel
      />
      <ColorPicker title={fbt('White', 'ColorsFieldset')}
                   value={props.colors.white}
                   action={val => props.handlePropertyChange('colors', 'white', val)}
                   allowReset={props.override || false}
                   showLabel
      />
      <ColorPicker title={fbt('Grey', 'ColorsFieldset')}
                   value={props.colors.grey}
                   action={val => props.handlePropertyChange('colors', 'grey', val)}
                   allowReset={props.override || false}
                   showLabel
      />
      <ColorPicker title={fbt('Dark Grey', 'ColorsFieldset')}
                   value={props.colors.darkGrey}
                   action={val => props.handlePropertyChange('colors', 'darkGrey', val)}
                   allowReset={props.override || false}
                   showLabel
      />
      <ColorPicker title={fbt('Black', 'ColorsFieldset')}
                   value={props.colors.black}
                   action={val => props.handlePropertyChange('colors', 'black', val)}
                   allowReset={props.override || false}
                   showLabel
      />
      <ColorPicker title={fbt('Green', 'ColorsFieldset')}
                   value={props.colors.green}
                   action={val => props.handlePropertyChange('colors', 'green', val)}
                   allowReset={props.override || false}
                   showLabel
      />
      <ColorPicker title={fbt('Blue', 'ColorsFieldset')}
                   value={props.colors.blue}
                   action={val => props.handlePropertyChange('colors', 'blue', val)}
                   allowReset={props.override || false}
                   showLabel
      />
      <ColorPicker title={fbt('Orange', 'ColorsFieldset')}
                   value={props.colors.orange}
                   action={val => props.handlePropertyChange('colors', 'orange', val)}
                   allowReset={props.override || false}
                   showLabel
      />
      <ColorPicker title={fbt('Red', 'ColorsFieldset')}
                   value={props.colors.red}
                   action={val => props.handlePropertyChange('colors', 'red', val)}
                   allowReset={props.override || false}
                   showLabel
      />
      {/*<button className="mame-primary">Add more color variables</button>*/}
    </Fieldset>
  );
};

export default ColorsFieldset;
