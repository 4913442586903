import React from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import { updateUserProperty } from '../../store/actions/user';
import TextInput from '../Properties/Controls/TextInput';
import TextArea from '../Properties/Controls/TextArea';
import ImageUpload from '../Properties/Controls/ImageUpload';
import userHelper from '../../modules/userHelper';
import SocialIcon from '../General/SocialIcon';
import HelpText from '../Properties/Controls/HelpText';

const UserProfile = (props) => {
  const handleChange = (key, value, category = 'profile') => {
    props.dispatch(updateUserProperty(category, key, value));
  };

  const user = props.user;
  const avatar = userHelper.getUserAvatarUrl(user, 100);

  return (
    <div style={{'--d': 'grid', '--gtc': '250px 1fr', '--gg': '20px'}}>
      <div>
        <figure className="accent" style={{'--w': '100%'}}>
          <figcaption style={{'--ta': 'center'}}>
            <div>
              <img src={avatar} alt={user.username}
                   style={{'--br': '50%', '--maxw': '60%'}}
              />
            </div>

            <h4 style={{'--mb': 0}}>{user.username}</h4>
            <div className="tag grey" style={{'--m': 0, '--p': '1px 8px', '--size': '80%'}}>
              {user.account_subscription === 'free' ? fbt('Mame Free', 'UserProfile') : fbt('Mame Premium', 'UserProfile')}
            </div>

            <p style={{'--mt': '15px'}}>
              {user.profile.bio}
            </p>
            <div>
              <SocialIcon type="website" data={user.profile.website}/>
              <SocialIcon type="facebook" data={user.socialNetworks.facebookId}/>
              <SocialIcon type="twitter" data={user.socialNetworks.twitterUsername}/>
              <SocialIcon type="github" data={user.socialNetworks.githubUsername}/>
              <SocialIcon type="linkedin" data={user.socialNetworks.linkedInUrl}/>
              <SocialIcon type="unsplash" data={user.socialNetworks.unsplashUsername}/>
              <SocialIcon type="youtube" data={user.socialNetworks.youtubeUsername}/>
            </div>
          </figcaption>
        </figure>
      </div>
      <div>
        <HelpText>
          <fbt desc="UserProfile">
            Your profile is shown on the template store, on your teams and on other public accessible locations.
          </fbt>
        </HelpText>

        <TextInput title={fbt('User Name (Read Only)', 'UserProfile')}
                   value={user.username}
                   action={val => handleChange('username', val)}
                   showLabel
                   disabled
                   required
        />
        <TextInput title={fbt('Shorthand', 'UserProfile')}
                   value={user.profile.initials}
                   action={val => handleChange('initials', val)}
                   showLabel
        />
        <TextArea title={fbt('Profile Text / Biography', 'UserProfile')}
                  value={user.profile.bio}
                  action={val => handleChange('bio', val)}
                  showLabel
        />

        <ImageUpload title={fbt('Avatar', 'UserProfile')}
                     text={fbt('Click to choose your avatar or drop the image here.', 'UserProfile')}
                     image={user.profile.avatar}
                     action={val => handleChange('avatar', val)}
                     disableResize
                     showLabel
        />

        <TextInput title={fbt('Website', 'UserProfile')}
                   value={user.profile.website}
                   action={val => handleChange('website', val)}
                   showLabel
        />

        <TextInput title={fbt('FaceBook Profile', 'UserProfile')}
                   placeholder="facebook.com/.."
                   value={user.socialNetworks.facebookId}
                   action={val => handleChange('facebookId', val, 'socialNetworks')}
                   showLabel
        />

        <TextInput title={fbt('Twitter Username', 'UserProfile')}
                   placeholder="twitter.com/.."
                   value={user.socialNetworks.twitterUsername}
                   action={val => handleChange('twitterUsername', val, 'socialNetworks')}
                   showLabel
        />

        <TextInput title={fbt('GitHub Username', 'UserProfile')}
                   placeholder="github.com/.."
                   value={user.socialNetworks.githubUsername}
                   action={val => handleChange('githubUsername', val, 'socialNetworks')}
                   showLabel
        />

        <TextInput title={fbt('LinkedIn Profile', 'UserProfile')}
                   placeholder="linkedin.com/in/.."
                   value={user.socialNetworks.linkedInUrl}
                   action={val => handleChange('linkedInUrl', val, 'socialNetworks')}
                   showLabel
        />

        <TextInput title={fbt('Youtube Channel', 'UserProfile')}
                   placeholder="youtube.com/channel/.."
                   value={user.socialNetworks.youtubeUsername}
                   action={val => handleChange('linkedInUrl', val, 'youtubeUsername')}
                   showLabel
        />

        <TextInput title={fbt('Unsplash Username', 'UserProfile')}
                   placeholder="unsplash.com/@.."
                   value={user.socialNetworks.unsplashUsername}
                   action={val => handleChange('unsplashUsername', val, 'socialNetworks')}
                   showLabel
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.user.user,
});

export default connect(mapStateToProps)(UserProfile);

