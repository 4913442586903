import React from 'react';

const ImagePreviewFrame = props => {
  // Maybe make some of these props
  return (
    <div style={{
      '--d': 'grid',
      '--w': '100%',
      '--h': '200px',
      '--ta': 'center',
      '--cur': 'pointer',
      '--b': '1px solid var(--mame-border)',
      '--radius': '5px',
      '--jc': 'center',
      '--ai': 'center',
      '--p': '15px 8px',
      '--levitate-hvr': '4',
      '--of': 'hidden',
      '--bg': 'rgba(255, 255, 255, 0.1)',
      '--c': 'var(--mame-text-main)'
    }}
         onClick={props.action}>
      {props.children}
    </div>
  );
};

export default ImagePreviewFrame;
