import React from 'react';
import fbt from 'fbt';

import Fieldset from '../FieldSet';
import CheckboxInput from '../Controls/CheckboxInput';
import HelpText from '../Controls/HelpText';
import FilterSelect from '../Controls/FilterSelect';
import InputGroup from '../InputGroup';

const LogoProperties = props => {
  const {element} = props;
  const {componentProperties} = element;

  return (
    <Fieldset title={fbt('Logo Properties', 'LogoProperties')} isOpen>
      <HelpText style={{'--m': '10px 0'}}>
        <fbt desc="LogoProperties">
          The Logo is configured in the global site settings.
        </fbt>
      </HelpText>

      <InputGroup>
        <CheckboxInput title={fbt('Link to Homepage', 'LogoProperties')}
                       checked={componentProperties.linkToHomepage}
                       action={() => {
                         props.handlePropertyChange('linkToHomepage', !componentProperties.linkToHomepage);
                       }}
        />
      </InputGroup>

      <HelpText style={{'--m': '15px 0'}}>
        <fbt desc="LogoProperties">
          The following settings apply to images only.
        </fbt>
      </HelpText>

      <InputGroup>
        <CheckboxInput title={fbt('Add Width and Height automatically', 'ImageProperties')}
                       checked={componentProperties.autoDimensions}
                       action={() => {
                         props.handlePropertyChange('autoDimensions', !componentProperties.autoDimensions);
                       }}
        />
      </InputGroup>

      <InputGroup>
        <CheckboxInput title={fbt('Lazy Loading', 'ImageProperties')}
                       checked={componentProperties.lazy}
                       action={() => {
                         props.handlePropertyChange('lazy', !componentProperties.lazy);
                       }}
        />

        <HelpText>
          <fbt desc="ImageProperties">
            Image lazy loading is useful for images outside the initial viewport, allowing to save up resources
            until the visitor scrolls to it.
          </fbt>
        </HelpText>
      </InputGroup>

      <FilterSelect
        filterType={componentProperties.filterType}
        actionFilterType={val => props.handlePropertyChange('filterType', val)}
        filterValue={componentProperties.filterValue || 0}
        actionFilterValue={val => props.handlePropertyChange('filterValue', val)}
      />
    </Fieldset>
  );
};

export default LogoProperties;
