import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import serviceHelper from '../modules/serviceHelper';
import ProfileFigure from '../components/Profile/ProfileFigure';

const Profile = props => {
  const {username} = useParams();

  const [profile, setProfile] = useState(null);

  const profileService = serviceHelper.getService('ProfileService');

  useEffect(() => {
    if (!username) {
      setProfile(null);
      return;
    }

    profileService
      .getProfile(username)
      .then(result => {
        setProfile(result.data.item);
      });
  }, [username, profileService]);

  if (!username) {
    return (
      <div>
        No Username
      </div>
    );
  }

  if (!profile) {
    return (
      <div>
      </div>
    );
  }

  return (
    <div className="wrapper" style={{'--gutter': '1vw'}}>
      <div style={{'--mt': '20px'}}>
        <ProfileFigure profile={profile}/>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps)(Profile);
