import React from 'react';
import { connect } from 'react-redux';

import elementHelper from '../../modules/elementHelper';
import ElementDiv from '../ElementControls/ElementDiv';
import Interweave from 'interweave';

const EmbedHtmlElement = props => {
  const element = props.element;
  const {componentProperties} = element;

  const id = elementHelper.getId(element);
  const classes = elementHelper.getClasses(element, 'mame-embed-html');
  const style = elementHelper.getStyle(element);

  return (
    <ElementDiv {...props} id={id} classes={classes} style={style}>
      <Interweave content={componentProperties.html}
                  allowAttributes allowElements noWrap/>
    </ElementDiv>
  );
};

export default connect()(EmbedHtmlElement);
