import CountdownProperties from '../../components/Properties/Elements/CountdownProperties';
import { ElementVisible } from '../../modules/consts/Elements';

const CountdownModel = {
  title: 'Countdown',
  image: '/img/elements/countdown.webp',
  helpPage: '/documentation/elements/countdown',
  category: 'Actions',
  component: 'CountdownElement',
  propertyComponent: CountdownProperties,
  hasChildElements: false,
  generalProperties: {
    hasVisibility: true,
    hasTextAlign: true,
    hasTextColor: true,
    hasBackground: true,
  },
  defaults: {
    demo: true,
    _id: null,
    identifier: 'New Countdown',
    component: 'CountdownElement',
    componentProperties: {
      targetDateTime: '2029-12-31T23:59',
      dayLabel: 'Days',
      hourLabel: 'Hours',
      minuteLabel: 'Minutes',
      secondLabel: 'Seconds',
      separator: ' ',
      showHours: true,
      showMinutes: true,
      showSeconds: true,
    },
    generalProperties: {
      visibility: [ElementVisible.sm, ElementVisible.md, ElementVisible.lg, ElementVisible.xl],
      published: true,
      margin: [0, 0, 0, 0],
      padding: [0, 0, 0, 0],
    },
  },
};

export default CountdownModel;
