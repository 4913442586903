import React from 'react';
import fbt from 'fbt';

import Fieldset from '../FieldSet';
import SelectInput from '../Controls/SelectInput';
import HelpText from '../Controls/HelpText';
import InputGroup from '../InputGroup';

const AnimationFieldset = props => {
  return (
    <Fieldset title={fbt('Animation', 'AnimationFieldset')} isOpen={props.isOpen || false}>
      <InputGroup>
        <SelectInput title={fbt('Choose an Animation', 'AnimationFieldset')}
                     value={props.animation}
                     options={{
                       '': fbt('None', 'AnimationFieldset'),
                       'fade': fbt('Fade', 'AnimationFieldset'),
                       'shrink': fbt('Shrink', 'AnimationFieldset'),
                       'grow': fbt('Grow', 'AnimationFieldset'),
                       'swing': fbt('Swing', 'AnimationFieldset'),
                       'slide-top': fbt('Slide In (Top)', 'AnimationFieldset'),
                       'slide-bottom': fbt('Slide In (End)', 'AnimationFieldset'),
                       'slide-left': fbt('Slide In (Left)', 'AnimationFieldset'),
                       'slide-right': fbt('Slide In (Right)', 'AnimationFieldset'),
                     }}
                     action={props.animationAction}
        />
        <HelpText>
          <fbt desc="AnimationFieldset">
            Animations are "played" once the element is scrolled into the view.
          </fbt>
        </HelpText>
      </InputGroup>
    </Fieldset>
  );
};

export default AnimationFieldset;
