import React from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import Fieldset from '../FieldSet';
import HelpText from '../Controls/HelpText';
import GenericSlider from '../Controls/GenericSlider';
import InputGroup from '../InputGroup';

const ColumnProperties = props => {
  const {element} = props;
  const {componentProperties} = element;

  return (
    <>
      <Fieldset title={fbt('Column Properties', 'ColumnProperties')} isOpen>
        <HelpText style={{'--m': '10px 0'}}>
          <fbt desc="ColumnProperties">
            With the column element you divide elements by columns.
            For more complex layouts and control use the Grid Element.
          </fbt>
        </HelpText>

        <InputGroup>
          <GenericSlider title={fbt('Column Count (Default)', 'ColumnProperties')}
                         min="1"
                         max="12"
                         id="slider-column-count"
                         value={componentProperties.columns}
                         action={val => props.handlePropertyChange('columns', val)}
                         showLabel
          />

          <HelpText style={{'--mt': '10px'}}>
            <fbt desc="ColumnProperties">
              You may also want to configure the values for other device sizes (Responsive Overrides) below.
              If you do so the default value is used for extra small devices (width &lt; 640px)
            </fbt>
          </HelpText>
        </InputGroup>

        <InputGroup>
          <GenericSlider title={fbt('Column Gap', 'ColumnProperties')}
                         min="0" max="100"
                         id="slider-column-gap"
                         value={componentProperties.gap}
                         action={val => props.handlePropertyChange('gap', val)}
                         showLabel
          />
        </InputGroup>
      </Fieldset>

      <Fieldset title={fbt('Responsive Overrides', 'ColumnProperties')} isOpen>
        <InputGroup style={{'--mt': '10px'}}>
          <GenericSlider title={fbt('Column count for extra small devices', 'ColumnProperties')}
                         min="0" max="12"
                         id="slider-column-count-xsm"
                         value={element.componentProperties.columns}
                         action={val => props.handlePropertyChange('columns', val)}
                         showLabel
          />
        </InputGroup>

        <InputGroup>
          <GenericSlider title={fbt('Column count for small devices', 'ColumnProperties')}
                         min="0" max="12"
                         showLabel
                         id="slider-column-count-sm"
                         value={element.componentProperties.columnsSm}
                         action={(val) => props.handlePropertyChange('columnsSm', val)}
          />
        </InputGroup>

        <InputGroup>
          <GenericSlider title={fbt('Column count for medium devices', 'ColumnProperties')}
                         min="0" max="12"
                         showLabel
                         id="slider-column-count-md"
                         value={element.componentProperties.columnsMd}
                         action={(val) => props.handlePropertyChange('columnsMd', val)}
          />
        </InputGroup>

        <InputGroup>
          <GenericSlider title={fbt('Column count for large devices', 'ColumnProperties')}
                         min="0" max="12"
                         showLabel
                         id="slider-column-count-lg"
                         value={element.componentProperties.columnsLg}
                         action={(val) => props.handlePropertyChange('columnsLg', val)}
          />
        </InputGroup>

        <InputGroup>
          <GenericSlider title={fbt('Column count for extra large devices', 'ColumnProperties')}
                         min="0" max="12"
                         showLabel
                         id="slider-column-count-xl"
                         value={element.componentProperties.columnsXl}
                         action={(val) => props.handlePropertyChange('columnsXl', val)}
          />
        </InputGroup>
      </Fieldset>
    </>
  );
};

export default connect()(ColumnProperties);
