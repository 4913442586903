import React, { useEffect, useState } from 'react';
import TextInput from './TextInput';
import Select from 'react-select';

const InputWithUnitPicker = props => {
  const [numericValue, setNumericValue] = useState('');
  const [unitValue, setUnitValue] = useState('px');

  useEffect(() => {
    const units = ['px', '%', 'rem', 'em', 'vw', 'vmin', 'vmax'];

    if (!props.value) {
      setNumericValue('');
      setUnitValue('px');
      return;
    }

      for (let unit of units) {
      if (props.value.endsWith(unit)) {
        setUnitValue(unit);
        setNumericValue(props.value.replace(unit, ''));
        break;
      }
    }
  }, [props.value]);

  const handleValueChange = (num, unit) => {
    if (!num) {
      // make sure to not emit an empty number
      return;
    }

    const newValue = `${num}${unit}`;

    if (props.action) {
      props.action(newValue);
    }
  };

  const handleInputChange = val => {
    if (!val) {
      setNumericValue('');
      setUnitValue('px');

      if (props.action) {
        props.action('');
      }
      return;
    }

    setNumericValue(val);
    handleValueChange(val, unitValue);
  };

  const handleSelectChange = result => {
    setUnitValue(result.value);

    handleValueChange(numericValue, result.value);
  };

  const options = [
    {value: 'px', label: 'px'},
    {value: '%', label: '%'},
    {value: 'rem', label: 'rem'},
    {value: 'em', label: 'em'},
  ];

  if (props.isWidth) {
    options.push({value: 'vw', label: 'vw'});
    options.push({value: 'vmin', label: 'vmin'});
    options.push({value: 'vmax', label: 'vmax'});
  }

  if (props.isHeight) {
    options.push({value: 'vh', label: 'vh'});
    options.push({value: 'vmin', label: 'vmin'});
    options.push({value: 'vmax', label: 'vmax'});
  }

  const valueForSelect = options.find(i => i.value === unitValue);

  return (
    <div>
      {props.showLabel && props.title}
      {!props.showLabel && props.title && <span className="sr-only">{props.title}</span>}
      <div style={{'--d': 'grid', '--ai': 'center', '--gtc': '70% 30%'}}>
        <TextInput
          id={props.id || null}
          type="number"
          required={props.required || null}
          value={numericValue}
          step={props.step || null}
          min={props.min || null}
          max={props.max || null}
          action={handleInputChange}
        />
        <Select value={valueForSelect}
                options={options}
                className="builder-select"
                onChange={handleSelectChange}
                isSearchable
        />
      </div>
    </div>
  );
};

export default InputWithUnitPicker;
