import React from 'react';
import { connect } from 'react-redux';
import fbt from 'fbt';

import Fieldset from '../FieldSet';
import TextInput from '../Controls/TextInput';
import CheckboxInput from '../Controls/CheckboxInput';
import InputGroup from '../InputGroup';

const RangeProperties = props => {
  const {element} = props;
  const {componentProperties} = element;

  return (
    <Fieldset title={fbt('Range Slider Properties', 'RangeProperties')} isOpen>
      <InputGroup style={{'--mt': '10px'}}>
        <TextInput title={fbt('Label (Title)', 'RangeProperties')}
                   value={componentProperties.title}
                   id="input-input-title"
                   action={val => props.handlePropertyChange('title', val)}
                   showLabel
        />
      </InputGroup>

      <InputGroup>
        <CheckboxInput title={fbt('Show Label', 'RangeProperties')}
                       checked={componentProperties.showLabel} id="check-show-label"
                       action={() => props.handlePropertyChange('showLabel', !componentProperties.showLabel)}
        />
      </InputGroup>

      <InputGroup>
        <TextInput title={fbt('Custom Name (for email placeholders / 3rd party API)', 'RangeProperties')}
                   value={componentProperties.customName}
                   id="input-custom-name"
                   action={val => props.handlePropertyChange('customName', val)}
                   filter="[^A-Za-z0-9_-]"
                   showLabel
        />
      </InputGroup>

      <blockquote>
        <fbt desc="RangeProperties">
          Please note: Webkit based Browser (Chromium, Google Chrome, Safari..) have currently issues
          updating range values. It's still reflected on the DOM (and after an reload the slider will look like
          expected).
        </fbt>
      </blockquote>

      <InputGroup>
        <TextInput type="number"
                   id="input-default-number"
                   title={fbt('Default Number', 'RangeProperties')}
                   value={componentProperties.defaultValue || 0}
                   action={val => props.handlePropertyChange('defaultValue', val)}
                   showLabel
        />
      </InputGroup>

      <InputGroup>
        <TextInput type="number" title={fbt('Minimum Value', 'RangeProperties')}
                   value={componentProperties.min || 0}
                   id="input-min-value"
                   action={val => props.handlePropertyChange('min', val)}
                   showLabel
        />
      </InputGroup>

      <InputGroup>
        <TextInput type="number" title={fbt('Maximum Value', 'RangeProperties')}
                   value={componentProperties.max || 100}
                   id="input-max-value"
                   action={val => props.handlePropertyChange('max', val)}
                   showLabel
        />
      </InputGroup>
    </Fieldset>
  );
};

export default connect()(RangeProperties);
