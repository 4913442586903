import React, { useEffect, useState } from 'react';
import { SketchPicker } from 'react-color';
import { usePopper } from 'react-popper';

import elementHelper from '../../../modules/elementHelper';
import { connect } from 'react-redux';

const ColorPicker = props => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperPopup, setPopperPopup] = useState(null);

  const popper = usePopper(referenceElement, popperPopup, elementHelper.getColorPickerPopperOptions());

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleOutsideClick = e => {
      if (popperPopup && popperPopup.contains(e.target)) {
        return;
      }

      if (!isVisible) {
        return;
      }

      if (e.target.classList.contains('color-picker__button')
        || e.target.classList.contains('color-picker__label')) {
        return;
      }

      // Hide it again
      setIsVisible(false);
    };

    if (isVisible) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isVisible, popperPopup]);

  const toggleVisible = () => {
    setIsVisible(!isVisible);

    if (popper && popper.update) {
      popper.update();
    }
  };

  return (
    <>
      <div style={{'--d': 'grid', '--gtc': '35px 1fr', '--ai': 'center', '--jc': 'center'}}
           className="color-picker"
      >
        {!props.showLabel && <label className="sr-only">{props.title}</label>}
        <button className="color-picker__button"
                style={{'--bg': props.value || '#fff'}}
                ref={setReferenceElement}
                onClick={toggleVisible}
                title={props.value}
                aria-label="Pick a Color"
        />
        {props.showLabel && (
          <label onClick={toggleVisible} style={{'--cur': 'pointer'}} className="color-picker__label">
            {props.title}

            {(props.allowReset && props.value) && (
              <span className="icon icon-times" role="button"
                    aria-label="Remove Color"
                    onClick={() => {
                      props.action('');
                    }}
                    style={{'--ml': '10px'}}
              />
            )}
          </label>
        )}
      </div>

      {isVisible && (
        <div className="color-picker__popup-open"
             ref={setPopperPopup}
             style={popper.styles.popper}
             {...popper.attributes.popper}
        >
          <div style={{'--w': '220px', '--h': '318px'}}>
            <SketchPicker
              color={props.value}
              presetColors={props.hasOwnProperty('disablePreset')
                ? ['#D0021B', '#F5A623', '#F8E71C', '#8B572A', '#7ED321', '#417505', '#BD10E0', '#9013FE', '#4A90E2', '#50E3C2', '#B8E986', '#000000', '#4A4A4A', '#9B9B9B', '#FFFFFF']
                : props.presetStyles
              }
              onChangeComplete={color => {
                if (color.rgb.a === 1) {
                  return props.action(color.hex);
                }

                return props.action(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`);
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  presetStyles: state.activeSite.presetStyles,
});

export default connect(mapStateToProps)(ColorPicker);
